import React from 'react';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/mode-mysql';
import 'ace-builds/src-noconflict/ext-language_tools';

import { Box, FormControl, FormHelperText, InputLabel } from '@mui/material';
import { alpha } from '@mui/material/styles';

const AceEditorInput = ({ label = null, error, helperText, value, onChange }) => {
    return (
        <FormControl variant="standard" fullWidth error={error} sx={{ margin: '38px 0' }}>
            {label && (
                <InputLabel shrink htmlFor="inputEditor" sx={{ color: '#5E6366', pl: 1, mt: '-22px' }}>
                    {label}
                </InputLabel>
            )}
            <Box
                id="inputEditor"
                sx={
                    helperText && {
                        boxShadow: (theme) => `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
                        borderColor: (theme) => theme.palette.error.main,
                    }
                }
            >
                <AceEditor
                    style={{ width: '100%', maxHeight: '200px', border: '1px solid #8B8D97', borderRadius: '5px' }}
                    //placeholder="Placeholder Text"
                    mode="mysql"
                    //onLoad={this.onLoad}
                    name="aceEditor"
                    onChange={onChange}
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={value}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2,
                    }}
                />
            </Box>
            {helperText && <FormHelperText sx={{ pl: 1 }}>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default AceEditorInput;
