import React from 'react';
import { Box, Grid, SvgIcon, ThemeProvider, Typography } from '@mui/material';
import pampersTheme from '~js/pages/backoffice/Tools/Quiz/components/Previews/previewTheme';
import iconGameChecked from '~/assets/images/icons/icon-game-checked.svg';
import iconGameUnchecked from '~/assets/images/icons/icon-game-unchecked.svg';
import IconCloseCoccoleSvg from '~/assets/images/icons/icon-close-coccole.svg?react';
import memoryCardRetro from '~/assets/images/tools/quizTool/memoryCardRetro.png';
import useFileReader from '~js/hooks/useFileReader';
import {
    QUIZ_MEMORY_CARDS_LENGTH,
    QUIZ_MEMORY_MIN_QUESTIONS,
} from '~js/pages/backoffice/Tools/Quiz/components/FormChallenges/MemoryQuestionsForm';

const PreviewMemoryQuestionScreen = ({
    image,
    couplesFound = 1,
    couplesToFind = QUIZ_MEMORY_MIN_QUESTIONS,
    cardCouples,
    cardImages,
}) => {
    const imageSxProps = {
        objectFit: 'contain',
        width: '100%',
        //userDrag: 'none',
        userSelect: 'none',
        borderRadius: '9px',
        border: '2.5px solid white',
    };

    const { fileUrl: imgUrl } = useFileReader(image);

    return (
        <ThemeProvider theme={pampersTheme}>
            <Box
                py={1.5}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '250px',
                    height: '540px',
                    borderRadius: '22px',
                    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.25)',
                    backgroundColor: '#EDF8F9',
                    position: 'relative',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <Box px={1.5}>
                    <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'end'}>
                        <SvgIcon sx={{ color: '#FFFFFF' }} fontSize={'large'} inheritViewBox>
                            <IconCloseCoccoleSvg />
                        </SvgIcon>
                    </Box>
                    <Typography
                        py={3}
                        sx={{
                            fontSize: '15.74px',
                            fontWeight: 800,
                            lineHeight: '16px',
                            textAlign: 'center',
                        }}
                    >
                        Concentrati e cerca le differenze fra le due immagini
                    </Typography>
                </Box>
                <Grid container px={1}>
                    {Array(QUIZ_MEMORY_CARDS_LENGTH)
                        .fill('')
                        .map((_, index) => (
                            <Grid key={index.toString()} item xs={6} p={0.5}>
                                <Box
                                    component={'img'}
                                    src={
                                        Object.keys(cardCouples).includes(`${index}`)
                                            ? imgUrl ?? memoryCardRetro
                                            : cardImages[index] ?? memoryCardRetro
                                    }
                                    borderRadius={'6px'}
                                    sx={imageSxProps}
                                />
                            </Grid>
                        ))}
                </Grid>
                <Box mb={2} px={1.5}>
                    <Typography
                        sx={{
                            fontSize: '11px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            textAlign: 'center',
                            marginBottom: '12px',
                        }}
                    >
                        Coppie trovate:{' '}
                        <b>
                            {couplesFound} di {couplesToFind}
                        </b>
                    </Typography>
                    <Box display={'flex'} justifyContent={'center'} sx={{ height: '25px' }}>
                        {Array(couplesToFind)
                            .fill('')
                            .map((_, index) => (
                                <Box
                                    key={index.toString()}
                                    component={'img'}
                                    src={index + 1 <= couplesFound ? iconGameChecked : iconGameUnchecked}
                                    mx={0.5}
                                />
                            ))}
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PreviewMemoryQuestionScreen;
