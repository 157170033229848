/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

//import "./bootstrap";

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import React from 'react';
import { render } from 'react-dom';
import { createInertiaApp } from '@inertiajs/react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import AppLayout from '~js/layouts/AppLayout';
import ErrorPage from '~js/pages/backoffice/ErrorPage';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

if (!import.meta.env.DEV) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new BrowserTracing(),
            new CaptureConsoleIntegration({
                // array of methods that should be captured
                // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
                levels: ['error'],
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

/**
 * Imports the given page component from the page record.
 */
function resolvePageComponent(name, pages) {
    for (const path in pages) {
        const parts = path.split('/');
        const file = parts[parts.length - 1];
        if (`${name}.jsx` === file) {
            return typeof pages[path] === 'function' ? pages[path]() : pages[path];
        }
    }
    console.error(`${name}.jsx`, pages);
    throw new Error(`Page not found: ${name}`);
}

createInertiaApp({
    progress: {
        color: 'rgba(255, 255, 255, 1)',
    },
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        const page = resolvePageComponent(name, import.meta.glob('./pages/**/*.jsx', { eager: true }));
        page.default.layout = (page) => <AppLayout children={page} />;
        return page;
    },
    setup({ el, App, props }) {
        return render(
            <Sentry.ErrorBoundary
                fallback={({ error }) => <ErrorPage error={error} props={props} />}
                beforeCapture={(scope) => {
                    scope.setTag('capturedBy', 'ErrorBoundary');
                }}
                onError={(error, componentStack, eventId) =>
                    console.debug('Sentry Error boundary caught an error', error, componentStack, eventId)
                }
            >
                <App {...props} />
            </Sentry.ErrorBoundary>,
            el,
        );
    },
});
