import React, { useContext } from 'react';
import { AppBar, Avatar, Box, IconButton, Toolbar } from '@mui/material';
import { router, usePage } from '@inertiajs/react';
import HomeIcon from '@mui/icons-material/Home';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';
import Breadcrumbs from '~js/components/Breadcrumbs';
import ToolContext from '~js/layouts/Tools/context';
import pampersAvatar from '~/assets/images/brands/pampers/avatar.svg';
import oldAvatar from '~/assets/images/brands/fater/old-avatar.png';

type SecondaryAppBarProps = React.PropsWithChildren & {
    breadcrumbItems: BreadcrumbList,
};
const SecondaryAppBar = ({ breadcrumbItems }: SecondaryAppBarProps) => {
    const { props } = usePage();
    const { brand } = useContext(ToolContext);

    const { user } = props;

    const goToUserProfile = () => router.get(route('Account.index'));

    const defaultAvatar = brand === 'pampers' ? pampersAvatar : oldAvatar;
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{ bgcolor: 'white' }} elevation={0}>
                <Toolbar sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <IconButton onClick={goToUserProfile}>
                        <Avatar src={user?.avatar ? user.avatar : defaultAvatar} />
                    </IconButton>
                    <Breadcrumbs breadcrumbs={breadcrumbItems} />
                    <HomeIcon sx={{ color: '#8B8D97', marginRight: 1 }} fontSize="small" />
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default SecondaryAppBar;
