import React, { useContext } from 'react';
import { Box, FormHelperText, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import AndOrButton from './AndOrButton';
import ClusterSelectInput from './ClusterSelectInput';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import { clientQuizStatus } from '~js/constants/missionStatuses';

const ClusterGroup = ({ clusterGroupId, clusterOptions }) => {
    const { currentClientQuizStatus, formDisabled } = useContext(QuizContext);

    const {
        control,
        formState: { errors },
        setValue,
        getValues,
        watch,
    } = useFormContext();
    console.log(`render ClusterGroup ${clusterGroupId}`, watch(`audience.clustersGroups.${clusterGroupId}`));
    const fieldBasePath = `audience.clustersGroups.${clusterGroupId}`;
    const errorsBasePath =
        errors?.audience?.clustersGroups && errors.audience.clustersGroups[clusterGroupId]
            ? errors.audience.clustersGroups[clusterGroupId]
            : null;

    const {
        fields: clustersFields,
        append: clustersAppend,
        remove: clustersRemove,
    } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: `${fieldBasePath}.clusters`, // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
        shouldUnregister: true,
    });

    const appendCluster = () => {
        clustersAppend(' ');
    };

    return (
        <Box p={2} display="flex" flexWrap="wrap">
            {clustersFields.map((c, index) => (
                <Box key={c.id} display="flex" alignItems="center">
                    <Box
                        sx={{
                            margin: '12px 0',
                        }}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{
                                position: 'relative',
                                bgcolor: 'rgba(233, 233, 233, 1)',
                                borderRadius: 1,
                                //margin: '12px 0',
                                p: '3px',
                            }}
                        >
                            <Controller
                                control={control}
                                name={`${fieldBasePath}.clusters.${index}`}
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                    validate: (value) => {
                                        if (value === ' ') {
                                            return trans('formValidation.requiredField');
                                        }
                                        if (watch(`${fieldBasePath}.clusters`).filter((o) => o === value).length > 1) {
                                            return trans('formValidation.selectUniqueClusterInGroup');
                                        }
                                    },
                                }}
                                defaultValue={clusterOptions[0]?.value}
                                shouldUnregister
                                render={({ field }) => (
                                    <ClusterSelectInput
                                        disabled={
                                            formDisabled ||
                                            currentClientQuizStatus === clientQuizStatus.prodActive ||
                                            currentClientQuizStatus === clientQuizStatus.prodNotActive
                                        }
                                        selectOptions={clusterOptions}
                                        /*     helperText={
                                            !!errorsBasePath?.clusters &&
                                            errorsBasePath?.clusters[index] &&
                                            errorsBasePath?.clusters[index]?.message
                                        }*/
                                        error={!!errorsBasePath?.clusters && !!errorsBasePath?.clusters[index]}
                                        {...field}
                                    />
                                )}
                            />
                            {clustersFields.length > 1 &&
                                !formDisabled &&
                                currentClientQuizStatus !== clientQuizStatus.prodActive &&
                                currentClientQuizStatus !== clientQuizStatus.prodNotActive && (
                                    <Box sx={{ borderLeft: '1px solid #828282', ml: 1 }}>
                                        <IconButton
                                            disabled={clustersFields.length === 1 || formDisabled}
                                            onClick={() => clustersRemove(index)}
                                        >
                                            <CancelIcon sx={{ color: '#828282' }} />
                                        </IconButton>
                                    </Box>
                                )}
                        </Box>
                        {!!errorsBasePath?.clusters &&
                        errorsBasePath?.clusters[index] &&
                        errorsBasePath?.clusters[index]?.message ? (
                            <FormHelperText error>{errorsBasePath?.clusters[index]?.message}</FormHelperText>
                        ) : (
                            <FormHelperText> </FormHelperText>
                        )}
                    </Box>
                    <Box>
                        {clustersFields.length - 1 > index ? (
                            <AndOrButton
                                disabled={
                                    formDisabled ||
                                    currentClientQuizStatus === clientQuizStatus.prodActive ||
                                    currentClientQuizStatus === clientQuizStatus.prodNotActive
                                }
                                bgcolor={(theme) => theme.palette.primary.main}
                                sx={{
                                    mx: 2,
                                }}
                                value={watch(`${fieldBasePath}.operatorInsideGroup`)}
                                onChange={(val) => setValue(`${fieldBasePath}.operatorInsideGroup`, val)}
                            />
                        ) : (
                            <IconButton
                                disabled={
                                    watch(`${fieldBasePath}.clusters.${index}`) === ' ' ||
                                    formDisabled ||
                                    currentClientQuizStatus === clientQuizStatus.prodActive ||
                                    currentClientQuizStatus === clientQuizStatus.prodNotActive
                                }
                                sx={{
                                    width: '100px',
                                    borderRadius: 1,
                                    border: '1px solid #dfdfe4',
                                    mx: 2,
                                }}
                                onClick={appendCluster}
                            >
                                <AddIcon />
                            </IconButton>
                        )}
                        <FormHelperText> </FormHelperText>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default ClusterGroup;
