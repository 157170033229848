import React, { useRef, useState } from 'react';
import { Box, FormControl, FormHelperText, MenuItem, Select, styled, Typography } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { isFuture, isPast } from 'date-fns';
import { trans } from 'matice';
import { clientQuizStatus } from '~js/constants/missionStatuses';
import ChangeStatusAlertModal from '~js/pages/backoffice/Tools/Survey/components/ChangeStatusAlertModal';
import iconPause from '~/assets/images/icons/icon-pause-mini-line.svg';
import iconClose from '~/assets/images/icons/icon-close-fill.svg';

export const CssTextField = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 5,
        position: 'relative',
        backgroundColor: 'transparent',
        fontSize: 16,
        width: '100%',
        padding: '10px 8px',
    },
}));

const statusBgColor = {
    [clientQuizStatus.draft]: '#FFD600',
    [clientQuizStatus.test]: '#10A9AE',
    [clientQuizStatus.prodNotActive]: '#21A700',
    [clientQuizStatus.prodActive]: '#39D602',
    [clientQuizStatus.expired]: '#CA0000',
    [clientQuizStatus.revoked]: '#6E7079',
    //[clientQuizStatus.paused]: '#6E7079',
};

const readableStatusMap = {
    [clientQuizStatus.draft]: trans('quiz.draft'),
    [clientQuizStatus.test]: trans('quiz.test'),
    [clientQuizStatus.prodNotActive]: trans('quiz.readyForProduction'),
    [clientQuizStatus.prodActive]: trans('quiz.active'),
    [clientQuizStatus.expired]: trans('quiz.expired'),
    [clientQuizStatus.revoked]: trans('quiz.revoked'),
    //[clientQuizStatus.paused]: trans('quiz.paused'),
};

const QuizStatusSelectInput = ({
    label = null,
    type = 'text',
    error,
    helperText,
    defaultValue = '',
    multiple,
    value,
    quizStartDate,
    quizEndDate,
    onChange,
    testerGroups,
    selectedGroupsTest = [],
    canChangeStatusToProd = true,
    ...other
}) => {
    let currentStatus = value;

    if (value === 'prod') {
        if (isFuture(new Date(quizStartDate))) {
            currentStatus = clientQuizStatus.prodNotActive;
        } else if (isPast(new Date(quizEndDate))) {
            currentStatus = clientQuizStatus.expired;
        } else {
            currentStatus = clientQuizStatus.prodActive;
        }
    }
    // if (value === 'paused' && isPast(new Date(quizEndDate))) {
    //     currentStatus = clientQuizStatus.expired;
    // }

    let selectOptions = [
        { _id: 1, label: trans('quiz.draft'), value: clientQuizStatus.draft },
        { _id: 2, label: trans('quiz.test'), value: clientQuizStatus.test },
        { _id: 3, label: trans('quiz.readyForProduction'), value: clientQuizStatus.prodNotActive },
        { _id: 4, label: trans('quiz.active'), value: clientQuizStatus.prodActive },
        { _id: 5, label: trans('quiz.expired'), value: clientQuizStatus.expired },
        { _id: 6, label: trans('quiz.revoked'), value: clientQuizStatus.revoked },
        //{ _id: 7, label: trans('quiz.paused'), value: clientQuizStatus.paused },
    ];

    if (currentStatus === 'draft') {
        selectOptions = [
            { _id: 1, label: trans('quiz.draft'), value: clientQuizStatus.draft },
            { _id: 2, label: trans('quiz.test'), value: clientQuizStatus.test },
        ];
    }

    if (currentStatus === 'test') {
        selectOptions = [
            { _id: 2, label: trans('quiz.test'), value: clientQuizStatus.test },
            { _id: 1, label: trans('quiz.draft'), value: clientQuizStatus.draft },
        ];
        if (canChangeStatusToProd) {
            selectOptions.push({
                _id: 3,
                label: trans('quiz.readyForProduction'),
                value: clientQuizStatus.prodNotActive,
            });
        }
    }

    if (currentStatus === 'prodNotActive') {
        selectOptions = [
            { _id: 3, label: trans('quiz.readyForProduction'), value: clientQuizStatus.prodNotActive },
            { _id: 6, label: trans('quiz.revoked'), value: clientQuizStatus.revoked },
            //{ _id: 7, label: trans('quiz.paused'), value: clientQuizStatus.paused },
        ];
    }

    if (currentStatus === 'prodActive') {
        selectOptions = [
            { _id: 4, label: trans('quiz.active'), value: clientQuizStatus.prodActive },
            { _id: 6, label: trans('quiz.revoke'), value: clientQuizStatus.revoked },
            //{ _id: 7, label: trans('quiz.paused'), value: clientQuizStatus.paused },
        ];
    }

    if (currentStatus === 'paused') {
        if (isFuture(new Date(quizStartDate))) {
            selectOptions = [
                //{ _id: 7, label: trans('quiz.paused'), value: clientQuizStatus.paused },
                { _id: 3, label: trans('quiz.readyForProduction'), value: clientQuizStatus.prodNotActive },
            ];
        } else {
            selectOptions = [
                //{ _id: 7, label: trans('quiz.paused'), value: clientQuizStatus.paused },
                { _id: 4, label: trans('quiz.active'), value: clientQuizStatus.prodActive },
            ];
        }
    }

    if (currentStatus === 'expired') {
        selectOptions = [{ _id: 5, label: trans('quiz.expired'), value: clientQuizStatus.expired }];
    }

    if (currentStatus === 'revoked') {
        selectOptions = [{ _id: 6, label: trans('quiz.revoked'), value: clientQuizStatus.revoked }];
    }

    const [isLoading, setIsLoading] = useState(false);
    const [alertModal, setAlertModal] = useState({
        visible: false,
        title: '',
        description: '',
        allowCloseAction: false,
        allowConfirmAction: false,
    });
    const newStatusRef = useRef(null);

    const closeAlertModal = () => {
        setAlertModal({
            visible: false,
            title: '',
            description: '',
            allowCloseAction: false,
            allowConfirmAction: false,
        });
        newStatusRef.current = null;
    };

    const onChangeHandler = (e) => {
        let newStatusValue = e.target.value;

        newStatusRef.current = newStatusValue;

        // se provo a cambiare stato ma la data di inizio è passata
        if (
            (currentStatus === clientQuizStatus.draft &&
                !isFuture(new Date(quizStartDate)) &&
                newStatusValue === clientQuizStatus.test) ||
            (currentStatus === clientQuizStatus.test &&
                !isFuture(new Date(quizStartDate)) &&
                (newStatusValue === clientQuizStatus.prodActive || newStatusValue === clientQuizStatus.prodNotActive))
        ) {
            setAlertModal({
                visible: true,
                title: trans('global.attention'),
                description: trans('quiz.alertChangeStatusDataExpired'),
                allowCloseAction: true,
                allowConfirmAction: false,
            });
            return;
        }

        //se passa da draft a test
        if (currentStatus === clientQuizStatus.draft && newStatusValue === clientQuizStatus.test) {
            setAlertModal({
                visible: true,
                title: trans('quiz.changeStatusTitleModal', {
                    args: {
                        currentStatus: readableStatusMap[currentStatus],
                        newStatus: readableStatusMap[newStatusValue],
                    },
                }),
                description: trans('quiz.changeStatusDraftToTestDescription'),
                allowCloseAction: true,
                allowConfirmAction: true,
            });
            return;
        }

        //se passa da test a prod
        if (currentStatus === clientQuizStatus.test && newStatusValue === clientQuizStatus.prodNotActive) {
            setAlertModal({
                visible: true,
                title: trans('quiz.changeStatusTitleModal', {
                    args: {
                        currentStatus: readableStatusMap[currentStatus],
                        newStatus: readableStatusMap[newStatusValue],
                    },
                }),
                description: trans('quiz.changeStatusTestToProdDescription'),
                allowCloseAction: true,
                allowConfirmAction: true,
            });
            return;
        }

        //alert se da prod passa a revocata
        if (currentStatus === clientQuizStatus.prodNotActive || currentStatus === clientQuizStatus.prodActive) {
            setAlertModal({
                visible: true,
                title: trans('quiz.changeStatusTitleModal', {
                    args: {
                        currentStatus: readableStatusMap[currentStatus],
                        newStatus: readableStatusMap[newStatusValue],
                    },
                }),
                description: trans('quiz.changeStatusProdToRevokedDescription'),
                allowCloseAction: true,
                allowConfirmAction: true,
            });
            return;
        }

        setAlertModal({
            visible: true,
            title: trans('quiz.changeStatusTitleModal', {
                args: {
                    currentStatus: readableStatusMap[currentStatus],
                    newStatus: readableStatusMap[newStatusValue],
                },
            }),
            description: '',
            allowCloseAction: true,
            allowConfirmAction: true,
        });
    };

    const confirmStatusChange = async (testGroups) => {
        if (
            newStatusRef.current === clientQuizStatus.prodActive ||
            newStatusRef.current === clientQuizStatus.prodNotActive
        ) {
            newStatusRef.current = 'prod';
        }
        try {
            setIsLoading(true);
            await onChange(newStatusRef.current, testGroups);
            closeAlertModal();
        } catch (e) {
            console.error('error during confirmStatusChange', { newStatus: newStatusRef.current, testGroups }, e);
        } finally {
            setIsLoading(false);
        }
    };

    const MenuItemColoredLabel = ({ status, ...props }) => {
        return (
            <Box
                component={[clientQuizStatus.paused, clientQuizStatus.revoked].includes(status) ? 'img' : 'div'}
                width="10px"
                height="10px"
                borderRadius="5px"
                bgcolor={statusBgColor[status]}
                {...props}
                {...([clientQuizStatus.paused, clientQuizStatus.revoked].includes(status)
                    ? {
                          src: status === clientQuizStatus.paused ? iconPause : iconClose,
                      }
                    : {})}
            />
        );
    };

    return (
        <>
            <FormControl variant="standard" fullWidth error={error} sx={{ margin: '12px 0' }} size="small">
                <Box display="flex" alignItems="center">
                    <MenuItemColoredLabel status={currentStatus} />
                    {label && (
                        <Typography fontSize="14px" pb={0.2} pl="8px">
                            {label}:
                        </Typography>
                    )}
                    <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        input={<CssTextField />}
                        defaultValue={defaultValue}
                        displayEmpty
                        multiple={multiple}
                        {...other}
                        value={currentStatus}
                        onChange={onChangeHandler}
                    >
                        {selectOptions?.map((op, index) => {
                            return (
                                <MenuItem
                                    key={`${op.value}-${index}`}
                                    value={op.value}
                                    selected={op.value === currentStatus}
                                    sx={index > 0 ? { borderTop: '1px solid #dfdfe4' } : {}}
                                >
                                    {op.value !== currentStatus && (
                                        <MenuItemColoredLabel status={op.value} sx={{ mr: 1 }} />
                                    )}
                                    <Typography fontSize="14px">{op.label}</Typography>
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </Box>
            </FormControl>
            <ChangeStatusAlertModal
                show={alertModal.visible}
                title={alertModal.title}
                description={alertModal.description}
                containerSx={{ pb: 1 }}
                onClose={alertModal.allowCloseAction ? closeAlertModal : null}
                cancelButtonText={trans('global.close')}
                onConfirm={alertModal.allowConfirmAction ? confirmStatusChange : null}
                loading={isLoading}
                showGroupTestSelect={newStatusRef.current === clientQuizStatus.test && alertModal.allowConfirmAction}
                testerGroups={testerGroups}
                selectedGroupsTest={selectedGroupsTest}
            />
        </>
    );
};

export default QuizStatusSelectInput;
