import React, { forwardRef } from 'react';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';

const CheckboxInput = forwardRef(({ label, tooltipText = null, ...other }, ref) => {
    return (
        <Tooltip title={tooltipText} placement="bottom-start" sx={{ zIndex: 99 }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={other.value ? !!other.value : false}
                        sx={{
                            '&.Mui-disabled': {
                                cursor: 'not-allowed',
                                pointerEvents: 'all !important',
                            },
                        }}
                        {...other}
                        ref={ref}
                    />
                }
                label={label}
            />
        </Tooltip>
    );
});

export default CheckboxInput;
