import React, { forwardRef } from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { trans } from 'matice';

const DaysFilterRadioInput = forwardRef(({ value, onChange, ...other }, ref) => {
    const onChangeHandler = (e) => {
        if (e.target.value === value) {
            onChange('');
            return;
        }
        onChange(e.target.value);
    };
    return (
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="radio-buttons-group"
                onClick={onChangeHandler}
                value={value}
                {...other}
                ref={ref}
            >
                <Box>
                    <FormControlLabel
                        value="last7Days"
                        control={<Radio />}
                        label={<Typography fontSize="14px">{trans('survey.last7Days')}</Typography>}
                    />
                    <FormControlLabel
                        value="last30Days"
                        control={<Radio />}
                        label={<Typography fontSize="14px">{trans('survey.last30Days')}</Typography>}
                    />
                </Box>
                <Box>
                    <FormControlLabel
                        value="last90Days"
                        control={<Radio />}
                        label={<Typography fontSize="14px">{trans('survey.last90Days')}</Typography>}
                    />
                </Box>
            </RadioGroup>
        </FormControl>
    );
});

export default DaysFilterRadioInput;
