import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { trans } from 'matice';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ExternalDataSourceMongodbInput, ExternalDataSourceMySqlInput } from '../../Settings/components';
import { SelectInput, TextInput } from '~js/components/input';
import CtaButton from '~js/components/CtaButton';

const driverSelectOptions = [
    {
        _id: 1,
        label: 'mysql',
        value: 'mysql',
    },
    {
        _id: 2,
        label: 'mongodb',
        value: 'mongodb',
    },
    // {
    //     _id: 3,
    //     label: 'pgsql',
    //     value: 'pgsql',
    // },
];

const ExternalDataSourceForm = ({ onSubmit, closeHandler, formDefaultValues, formDisabled = false }) => {
    const SchemaValidation = yup.object().shape({
        name: yup.string().required(trans('formValidation.requiredField')),
        driver: yup.string().required(trans('formValidation.requiredField')),
        config: yup.object().shape({
            database: yup
                .string()
                .trim()
                .when('driver', () => {
                    const driver = watch('driver');
                    if (driver && (driver === 'mysql' || driver === 'mongodb'))
                        return yup
                            .string()
                            .trim()
                            .required(trans('formValidation.requiredField'))
                            .max(50, trans('formValidation.fieldTooLong'));
                }),
            dns: yup
                .string()
                .trim()
                .when('driver', () => {
                    const driver = watch('driver');
                    if (driver && driver === 'mongodb')
                        return yup.string().trim().required(trans('formValidation.requiredField'));
                }),
            host: yup
                .string()
                .trim()
                .when('driver', () => {
                    const driver = watch('driver');
                    if (driver && driver === 'mysql')
                        return yup.string().trim().required(trans('formValidation.requiredField'));
                }),
            port: yup.number().when('driver', () => {
                const driver = watch('driver');
                if (driver && driver === 'mysql')
                    return yup
                        .string()
                        .trim()
                        .required(trans('formValidation.requiredField'))
                        .max(9999, trans('formValidation.fieldTooLong'));
            }),
            username: yup
                .string()
                .trim()
                .when('driver', () => {
                    const driver = watch('driver');
                    if (driver && driver === 'mysql')
                        return yup
                            .string()
                            .trim()
                            .required(trans('formValidation.requiredField'))
                            .max(50, trans('formValidation.fieldTooLong'));
                }),
            password: yup
                .string()
                .trim()
                .when('driver', () => {
                    const driver = watch('driver');
                    if (driver && driver === 'mysql')
                        return yup.string().trim().required(trans('formValidation.requiredField'));
                }),
            charset: yup
                .string()
                .trim()
                .when('driver', () => {
                    const driver = watch('driver');
                    if (driver && driver === 'mysql')
                        return yup
                            .string()
                            .trim()
                            .required(trans('formValidation.requiredField'))
                            .max(10, trans('formValidation.fieldTooLong'));
                }),
            collation: yup
                .string()
                .trim()
                .when('driver', () => {
                    const driver = watch('driver');
                    if (driver && driver === 'mysql')
                        return yup.string().trim().required(trans('formValidation.requiredField'));
                }),
            prefix: yup
                .string()
                .trim()
                .when('driver', () => {
                    const driver = watch('driver');
                    if (driver && driver === 'mysql') return yup.string().trim();
                    //.required(trans("formValidation.requiredField"));
                }),
            prefix_indexes: yup
                .string()
                .trim()
                .when('driver', () => {
                    const driver = watch('driver');
                    if (driver && driver === 'mysql')
                        return yup.string().trim().required(trans('formValidation.requiredField'));
                }),
            strict: yup
                .string()
                .trim()
                .when('driver', () => {
                    const driver = watch('driver');
                    if (driver && driver === 'mysql') return yup.string().trim();
                    // .required(trans("formValidation.requiredField"));
                }),
            unix_socket: yup
                .string()
                .trim()
                .when('driver', () => {
                    const driver = watch('driver');
                    if (driver && driver === 'mysql') return yup.string().trim();
                    //.required(trans("formValidation.requiredField"));
                }),
            engine: yup
                .string()
                .trim()
                .when('driver', () => {
                    const driver = watch('driver');
                    if (driver && driver === 'mysql') return yup.string().trim();
                    //.required(trans("formValidation.requiredField"));
                }),
        }),
    });

    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
        setError,
        trigger,
    } = useForm({
        defaultValues: formDefaultValues
            ? formDefaultValues
            : {
                  name: '',
                  driver: '',
                  config: {},
              },
        resolver: yupResolver(SchemaValidation),
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTestConnection, setIsLoadingTestConnection] = useState(false);

    const onCancelPress = () => {
        closeHandler();
    };

    const onSubmitHandler = async (data) => {
        setIsLoading(true);
        try {
            await onSubmit(data);
        } catch (e) {
            Object.keys(e).forEach((key) => {
                setError(key, { type: 'custom', message: e[key] });
            });
        } finally {
            setIsLoading(false);
        }
    };

    const testConnection = async () => {
        const formIsValid = await trigger();
        if (!formIsValid) {
            return;
        }
        try {
            setIsLoadingTestConnection(true);
            const res = await axios.post(route('ExternalDataSource.test'), watch());
            toast.success(trans('surveyToolSettings.testConnectionSuccess'));
        } catch (e) {
            let message = 'Error on test connection';
            if (e?.response?.data?.message) {
                message = e?.response?.data?.message;
            } else {
                message = !!e?.message ? e.message : 'Error on test connection';
            }
            toast.error(typeof message === 'object' ? JSON.stringify(message) : message);
        } finally {
            setIsLoadingTestConnection(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6} order={{ xs: 2, sm: 2, md: 1 }}>
                    <Box sx={{ maxWidth: '500px' }}>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field }) => (
                                <TextInput
                                    id={'name'}
                                    disabled={formDisabled}
                                    label={trans('surveyToolSettings.connectionName')}
                                    placeholder={trans('surveyToolSettings.insertConnectionName')}
                                    helperText={errors?.name?.message}
                                    error={!!errors?.name}
                                    {...field}
                                />
                            )}
                        />
                        {watch('driver') === 'mysql' && (
                            <ExternalDataSourceMySqlInput
                                control={control}
                                errors={errors}
                                formDisabled={formDisabled}
                            />
                        )}
                        {watch('driver') === 'mongodb' && (
                            <ExternalDataSourceMongodbInput
                                control={control}
                                errors={errors}
                                formDisabled={formDisabled}
                            />
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 2 }}>
                    <Box sx={{ maxWidth: '500px' }}>
                        <Controller
                            control={control}
                            name="driver"
                            defaultValue={''}
                            render={({ field }) => (
                                <SelectInput
                                    id={'driver'}
                                    disabled={formDisabled}
                                    label={trans('surveyToolSettings.driver')}
                                    selectOptions={driverSelectOptions}
                                    helperText={errors?.driver?.message}
                                    error={!!errors?.driver}
                                    {...field}
                                    ref={null}
                                />
                            )}
                        />
                        <Box display="flex" justifyContent="flex-end">
                            <CtaButton
                                sx={{ textDecoration: 'underline' }}
                                onClick={testConnection}
                                loading={isLoadingTestConnection}
                            >
                                {trans('surveyToolSettings.testConnection')}
                            </CtaButton>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    mt: 5,
                }}
            >
                <CtaButton variant="outlined" type="button" disabled={isLoading} onClick={onCancelPress}>
                    {trans('global.cancel')}
                </CtaButton>
                <CtaButton variant="contained" type="submit" loading={isLoading} disabled={formDisabled}>
                    {trans('global.saveChanges')}
                </CtaButton>
            </Box>
        </form>
    );
};

export default ExternalDataSourceForm;
