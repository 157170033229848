import React, { useRef, useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { Box, Tab, Tabs } from '@mui/material';
import ExternalDataSourceSettingsSection from './components/ExternalDataSourceSettingsSection';
import { trans } from 'matice';
import CtaButton from '~js/components/CtaButton';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import TesterSettingSection from './components/TesterSettingSection';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';
import ToolsUsersSection from '~js/pages/backoffice/Settings/ToolsUsers/components/ToolsUsersSection';

const breadcrumbs: BreadcrumbList = [
    {
        routeName: 'Settings.index',
        routeLabel: 'Impostazioni',
    },
];

const sectionMap = {
    toolsUsers: 0,
    tester: 1,
    connections: 2,
    //communications: 3,
};

const SettingsIndex = ({
    user,
    externalDataSources,
    testerGroups,
    section,
    toolsUsers,
    toolsRoles,
    can,
    canReadToolIds,
    ...other
}) => {
    console.log('ToolSurveySettings Props', {
        can,
        user,
        externalDataSources,
        testerGroups,
        section,
        toolsUsers,
        toolsRoles,
        ...other,
    });
    const [tabsValue, setTabsValue] = useState(section ? sectionMap[section] : 0);
    const toolsUserSectionRef = useRef(null);

    const handleChange = (event, newValue) => {
        setTabsValue(newValue);
    };

    const onAddClick = () => {
        switch (tabsValue) {
            case sectionMap['toolsUsers']:
                toolsUserSectionRef.current.showNewToolsUserAddModal();
                break;
            case sectionMap['tester']:
                router.get(route('TesterGroups.create'));
                break;
            case sectionMap['connections']:
                router.get(route('ExternalDataSource.create'));
                break;
            default:
                console.log(`unhandled action for tabsValue: ${tabsValue}`);
        }
    };

    return (
        <>
            <Head title={trans('navBarSurvey.settings')} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <Tabs value={tabsValue} onChange={handleChange} aria-label="basic tabs example" sx={{ mb: 4 }}>
                        {can.toolsUsers.viewAny && (
                            <Tab label={trans('settings.tools-users.tab-name')} value={sectionMap['toolsUsers']} />
                        )}
                        {can.testerGroup.viewAny && (
                            <Tab label={trans('surveyToolSettings.tester')} value={sectionMap['tester']} />
                        )}
                        {can.externalDataSource.viewAny && (
                            <Tab label={trans('surveyToolSettings.connections')} value={sectionMap['connections']} />
                        )}

                        {/* <Tab label={trans('survey.communications')} /> */}
                    </Tabs>
                    {((tabsValue === 0 && toolsUsers.length > 0 && can.toolsUsers.create) ||
                        (tabsValue === 1 && testerGroups.length > 0 && can.testerGroup.create) ||
                        (tabsValue === 2 && externalDataSources.length > 0 && can.externalDataSource.create)) && (
                        <CtaButton variant="contained" sx={{ px: { xl: 5, lg: 5, md: 5 } }} onClick={onAddClick}>
                            {trans('global.add')}
                        </CtaButton>
                    )}
                </Box>
                {can.toolsUsers.viewAny && tabsValue === sectionMap['toolsUsers'] && (
                    <ToolsUsersSection
                        ref={toolsUserSectionRef}
                        toolsUsers={toolsUsers}
                        toolsRoles={toolsRoles}
                        canUpdate={can.toolsUsers.updateAny}
                        canDelete={can.toolsUsers.deleteAny}
                    />
                )}
                {can.testerGroup.viewAny && tabsValue === sectionMap['tester'] && (
                    <TesterSettingSection
                        testerGroups={testerGroups}
                        canView={can.testerGroup.viewAny}
                        canDelete={can.testerGroup.deleteAny}
                    />
                )}
                {can.externalDataSource.viewAny && tabsValue === sectionMap['connections'] && (
                    <ExternalDataSourceSettingsSection
                        canReadToolIds={canReadToolIds}
                        externalDataSources={externalDataSources}
                    />
                )}
            </ToolContentPageLayout>
        </>
    );
};

export default SettingsIndex;
