import React, { useContext } from 'react';
import { Box, Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import TextInput from '~js/components/TextInput';
import { SURVEY_TYPOLOGIES } from '~js/constants/survey.js';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';
import CaptionInput from './CaptionInput';
import ActionMultiFlowResponse from './ActionMultiFlowResponse';
import SurveyContext from '../context';

const TextAreaForm = ({ questionIndex }) => {
    const { formDisabled, surveyTypology } = useContext(SurveyContext);
    const isMultiFlowSurvey = surveyTypology === SURVEY_TYPOLOGIES.multiFlow;

    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext();

    const fieldBasePath = `questions.${questionIndex}`;
    const errorsBasePath =
        errors?.questions && errors.questions[questionIndex] ? errors.questions[questionIndex] : null;

    return (
        <Box>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ maxWidth: '500px' }}>
                        <CaptionInput questionIndex={questionIndex} />
                        <Box display="flex">
                            <Controller
                                control={control}
                                name={`${fieldBasePath}.responses.${0}.min`}
                                defaultValue={watch(`${fieldBasePath}.responses.${0}.min`) || ''}
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                    /* validate: (value) =>
                                        value <=
                                            watch(`${fieldBasePath}.max`) ||
                                        trans("survey.minMustBeLessThanMax"),*/
                                    deps: [`${fieldBasePath}.responses.${0}.max`],
                                    pattern: {
                                        value: REGEX_ONLY_PLAIN_CHAR,
                                        message: trans('formValidation.charNotAllowed'),
                                    },
                                }}
                                shouldUnregister
                                render={({ field }) => (
                                    <TextInput
                                        disabled={formDisabled}
                                        inputProps={{ min: 0 }}
                                        type="number"
                                        label={trans('survey.minCharNumber')}
                                        helperText={
                                            !!errorsBasePath?.responses &&
                                            errorsBasePath?.responses[0] &&
                                            errorsBasePath?.responses[0]?.min?.message
                                        }
                                        error={!!errorsBasePath?.responses && !!errorsBasePath?.responses[0]?.min}
                                        {...field}
                                    />
                                )}
                            />
                            <Box width="100%" pl={3}>
                                <Controller
                                    control={control}
                                    name={`${fieldBasePath}.responses.${0}.max`}
                                    defaultValue={watch(`${fieldBasePath}.responses.${0}.max`) || ''}
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        validate: (value) =>
                                            +value >= +watch(`${fieldBasePath}.responses.${0}.min`) ||
                                            trans('survey.maxMustBeGreaterOrEqualThanMin'),
                                        deps: [`${fieldBasePath}.responses.${0}.min`],
                                        pattern: {
                                            value: REGEX_ONLY_PLAIN_CHAR,
                                            message: trans('formValidation.charNotAllowed'),
                                        },
                                    }}
                                    shouldUnregister
                                    render={({ field }) => (
                                        <TextInput
                                            disabled={formDisabled}
                                            inputProps={{ min: 0 }}
                                            type="number"
                                            label={trans('survey.maxCharNumber')}
                                            helperText={
                                                !!errorsBasePath?.responses &&
                                                errorsBasePath?.responses[0] &&
                                                errorsBasePath?.responses[0]?.max?.message
                                            }
                                            error={!!errorsBasePath?.responses && !!errorsBasePath?.responses[0]?.max}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {isMultiFlowSurvey && (
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px">
                            <ActionMultiFlowResponse
                                questionIndex={questionIndex}
                                fieldBasePath={`${fieldBasePath}.responses.${0}`}
                                errorsBasePath={errorsBasePath?.responses && errorsBasePath?.responses[0]}
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default TextAreaForm;
