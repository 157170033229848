import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { FormProvider, useForm } from 'react-hook-form';
import { trans } from 'matice';
import { clientSurveyStatus } from '~js/constants/missionStatuses';
import EmailConfigurationForm from '~js/components/EmailConfigurationForm';
import NotificationConfigurationForm from '~js/components/NotificationConfigurationForm';
import NewSurveyActionButtons from './NewSurveyActionButtons';
import SurveyContext from '../context';

const NewSurveyCommunicationsStep = ({ isLoading, handleStepPrevious, handleStepNext, stepFormValue }) => {
    const { formDisabled, isEditMode, currentClientSurveyStatus } = useContext(SurveyContext);

    const formMethods = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: stepFormValue ? stepFormValue : {},
    });

    const { handleSubmit } = formMethods;

    const onSubmit = (data) => {
        console.log(data);
        handleStepNext({ communication: data });
    };
    return (
        <FormProvider {...formMethods}>
            <Box component="form" width="100%" onSubmit={handleSubmit(onSubmit)}>
                <Box p={3} pt={5}>
                    <Typography width="100%" mb={1}>
                        {trans('survey.communications')}
                    </Typography>

                    {isEditMode &&
                        (currentClientSurveyStatus === clientSurveyStatus.prodNotActive ||
                            currentClientSurveyStatus === clientSurveyStatus.test) && (
                            <Box
                                sx={{
                                    width: '100%',
                                    bgcolor: 'rgb(249,233,229)',
                                    borderRadius: 1,
                                    px: 4,
                                    py: 0.8,
                                    mt: 4,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <InfoIcon color="primary" fontSize="small" sx={{ mr: 1.5 }} />

                                <Typography sx={{ textAlign: 'left' }} color="primary">
                                    {trans('survey.communicationStepDisclaimer')}
                                </Typography>
                            </Box>
                        )}

                    <EmailConfigurationForm
                        formDisabled={formDisabled}
                        isEditMode={isEditMode}
                        editable={!!currentClientSurveyStatus && currentClientSurveyStatus !== clientSurveyStatus.draft}
                    />
                    <NotificationConfigurationForm
                        formDisabled={formDisabled}
                        isEditMode={isEditMode}
                        editable={!!currentClientSurveyStatus && currentClientSurveyStatus !== clientSurveyStatus.draft}
                    />

                    <Box width="100%">
                        {!formDisabled && (
                            <NewSurveyActionButtons
                                isLoading={isLoading}
                                onLeftButtonClick={handleStepPrevious}
                                rightButtonText={trans('global.save')}
                                leftButtonText={trans('global.back')}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </FormProvider>
    );
};

export default NewSurveyCommunicationsStep;
