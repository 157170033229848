import React, { useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { trans } from 'matice';
import { Box, Paper, Typography } from '@mui/material';
import AuthLayout from '~js/layouts/AuthLayout';
import TextInput from '~js/components/TextInput';
import CtaButton from '~js/components/CtaButton';
import toolsLogo from '~/assets/images/tools-logo.svg';

const UpdateExpiredPasswordPage = () => {
    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
        setError,
    } = useForm({
        defaultValues: {
            password: '',
            confirmPassword: '',
        },
    });

    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        const { password } = data;
        router.post(
            route('UpdateExpiredPassword'),
            { password },
            {
                onStart: () => setLoading(true),
                onFinish: () => setLoading(false),
                onError: (e) => {
                    if (!!e?.generic) {
                        toast.error(e.generic);
                        return;
                    }
                    Object.keys(e).forEach((key) => {
                        setError(key, { type: 'custom', message: e[key] });
                    });
                },
                onSuccess: () => toast.success(trans('auth.updateExpiredPassword.successMessage')),
            },
        );
    };
    return (
        <>
            <Head title={trans('auth.updateExpiredPassword.title')} />
            <AuthLayout>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Paper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                minWidth: 750,
                                p: 8,
                            }}
                        >
                            <Box component="img" src={toolsLogo} maxWidth="270px" alt={'Logo tools loyalty'} mb={3} />
                            <Typography variant="h6" mt={3}>
                                {trans('auth.updateExpiredPassword.title')}
                            </Typography>

                            <Box width="100%" maxWidth="500px">
                                <Typography mt={3} mb={6} textAlign="center">
                                    {trans('auth.updateExpiredPassword.explanation')}
                                </Typography>
                            </Box>
                            <Box width="100%" maxWidth="400px">
                                <Controller
                                    control={control}
                                    name="password"
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            type="password"
                                            label={trans('auth.resetPassword.newPasswordPlaceholder')}
                                            placeholder={trans('auth.resetPassword.newPasswordPlaceholder')}
                                            helperText={errors?.password?.message}
                                            error={!!errors?.password}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="confirmPassword"
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        validate: (valConfirmPass) =>
                                            watch('password') === valConfirmPass || trans('auth.signin.checkPassword'),
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            type="password"
                                            label={trans('auth.resetPassword.confirmNewPassword')}
                                            placeholder={trans('auth.resetPassword.rePasswordPlaceholder')}
                                            helperText={errors?.confirmPassword?.message}
                                            error={!!errors?.confirmPassword}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                            <CtaButton type="submit" variant="contained" sx={{ px: 6, mt: 6 }} loading={loading}>
                                {trans('global.save')}
                            </CtaButton>
                        </Paper>
                    </Box>
                </form>
            </AuthLayout>
        </>
    );
};

export default UpdateExpiredPasswordPage;
