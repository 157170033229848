import React, { useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { toast } from 'react-toastify';
import { Typography } from '@mui/material';
import { trans } from 'matice';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import SuccessModal from '~js/components/SuccessModal';
import TriggerForm from './components/TriggerForm';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';

const breadcrumbs: BreadcrumbList = [
    {
        routeName: 'Triggers.create',
        routeLabel: 'Crea Trigger',
    },
    {
        routeName: 'Triggers.index',
        routeLabel: 'Triggers',
    },
];

const TriggersCreate = ({ can, ...other }) => {
    console.log('TriggersCreate props', { can, ...other });

    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const goToClusterListPage = () => router.get(route('Triggers.index'));

    const onSubmit = async (data) => {
        console.log(data);
        const promise = new Promise((resolve, reject) =>
            router.post(route('Triggers.store'), data, {
                onError: (e) => {
                    reject(e);
                    if (!!e?.generic) {
                        toast.error(e.generic);
                    }
                },
                onSuccess: () => {
                    resolve();
                    setShowModalSuccess(true);
                },
            }),
        );

        await promise;
    };

    return (
        <>
            <Head title={trans('triggers.createNewTrigger')} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                    {trans('triggers.createNewTrigger')}
                </Typography>
                <TriggerForm onSubmit={onSubmit} onCancelPress={goToClusterListPage} formDisabled={!can.create} />
            </ToolContentPageLayout>
            <SuccessModal
                open={showModalSuccess}
                handleClose={() => setShowModalSuccess(false)}
                title={trans('global.compliments')}
                description={trans('triggers.triggerCreatedSuccess')}
                buttonText={trans('triggers.backToTriggerList')}
                onPressButton={goToClusterListPage}
                onExitPress={() => setShowModalSuccess(false)}
            />
        </>
    );
};

export default TriggersCreate;
