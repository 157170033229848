import React, { useEffect, useState } from 'react';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Box, Menu, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { differenceInDays, endOfDay, startOfDay, sub } from 'date-fns';
import { trans } from 'matice';
import CoverageSliderInput from './CoverageSliderInput';
import DaysFilterRadioInput from './DaysFilterRadioInput';
import { CheckboxInput, SelectInput, SwitchInput } from '~js/components/input';
import CtaButton from '~js/components/CtaButton';
import { QUIZ_TYPOLOGIES } from '~js/constants/quiz';

const TODAY = new Date();
export const QUIZ_TYPOLOGY_FILTER_OPTIONS = [
    { _id: 1, label: trans('quiz.typologies.classic'), value: QUIZ_TYPOLOGIES.classic },
    { _id: 2, label: trans('quiz.typologies.time'), value: QUIZ_TYPOLOGIES.time },
    { _id: 3, label: trans('quiz.typologies.difference'), value: QUIZ_TYPOLOGIES.difference },
    { _id: 4, label: trans('quiz.typologies.memory'), value: QUIZ_TYPOLOGIES.memory },
    { _id: 5, label: trans('quiz.typologies.drag'), value: QUIZ_TYPOLOGIES.drag },
];
export const SURVEY_TYPOLOGIES_FILTER_OPTIONS = [
    { _id: 1, label: 'Lineare', value: 'linear' },
    { _id: 2, label: 'Multi Flusso', value: 'multiFlow' },
];
const FilterMenuTable = ({ buttonFocused = false, onSubmit, appliedFilters, typology = 'survey' }) => {
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const typologiesOptions = typology === 'quiz' ? QUIZ_TYPOLOGY_FILTER_OPTIONS : SURVEY_TYPOLOGIES_FILTER_OPTIONS;
    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            showArchived: false,
            intervalDay: '',
            activeCoverage: false,
            coverageRange: [20000, 80000],
            typologies: [],
        },
    });

    useEffect(() => {
        if (!!appliedFilters) {
            const defaultValueFilter = {
                showArchived: !!appliedFilters?.showArchived,
                intervalDay: `last${differenceInDays(
                    new Date(appliedFilters?.endDate),
                    new Date(appliedFilters?.startDate),
                )}Days`,
                activeCoverage: !!appliedFilters?.fromCoverage && !!appliedFilters?.toCoverage,
                coverageRange: [appliedFilters?.fromCoverage, appliedFilters?.toCoverage],
                typologies: (appliedFilters?.typologies ?? []).map((opt) => opt?.value ?? opt),
            };
            reset(defaultValueFilter);
        }
    }, [appliedFilters]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const resetFilter = () => {
        reset({
            showArchived: false,
            intervalDay: '',
            activeCoverage: false,
            coverageRange: [20000, 80000],
        });
        handleSubmit(onSubmitHandler)();
    };

    const onSubmitHandler = async (data) => {
        const { showArchived, intervalDay, activeCoverage, coverageRange, typologies } = data;

        const queryObj = { showArchived, typologies };

        switch (intervalDay) {
            case 'last7Days':
                queryObj['startDate'] = startOfDay(sub(TODAY, { days: 7 }));
                queryObj['endDate'] = endOfDay(TODAY);
                break;
            case 'last30Days':
                queryObj['startDate'] = startOfDay(sub(TODAY, { days: 30 }));
                queryObj['endDate'] = endOfDay(TODAY);
                break;
            case 'last90Days':
                queryObj['startDate'] = startOfDay(sub(TODAY, { days: 90 }));
                queryObj['endDate'] = endOfDay(TODAY);
                break;
        }

        if (activeCoverage) {
            queryObj['fromCoverage'] = coverageRange[0];
            queryObj['toCoverage'] = coverageRange[1];
        }

        try {
            setLoading(true);
            await onSubmit(queryObj);
        } finally {
            //  setLoading(false);
        }
    };
    return (
        <>
            <CtaButton
                variant={buttonFocused || open ? 'contained' : 'outlined'}
                sx={
                    !buttonFocused && !open
                        ? { color: '#8B8D97', borderColor: '#8B8D97', py: 0.5, px: 1.5 }
                        : { py: 0.5, px: 1.5 }
                }
                onClick={handleClick}
            >
                <FilterAltOutlinedIcon sx={{ mr: 1 }} fontSize="small" /> {trans('survey.filter')}
            </CtaButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                /* MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}*/
            >
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <Box sx={{ p: 2, minWidth: '300px' }}>
                        <Typography fontWeight="bold">{trans('survey.filter')}</Typography>
                        <Box sx={{ borderBottom: '1px solid #E1E2E9', pb: 1, mb: 4, mt: 2 }}>
                            <Controller
                                control={control}
                                name="intervalDay"
                                defaultValue=""
                                render={({ field }) => <DaysFilterRadioInput {...field} />}
                            />
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                            <Typography fontSize="14px">{trans('cluster.coverage')}</Typography>
                            <Controller
                                control={control}
                                name="activeCoverage"
                                render={({ field }) => <SwitchInput {...field} />}
                            />
                        </Box>
                        {watch('activeCoverage') && (
                            <Controller
                                control={control}
                                name="coverageRange"
                                render={({ field }) => <CoverageSliderInput {...field} />}
                            />
                        )}

                        <Typography fontSize="14px" mt={2}>
                            {trans('survey.archived')}
                        </Typography>
                        <Controller
                            control={control}
                            name="showArchived"
                            render={({ field }) => <CheckboxInput label={trans('survey.showArchived')} {...field} />}
                        />

                        {typologiesOptions.length > 0 && (
                            <Controller
                                control={control}
                                name={'typologies'}
                                render={({ field }) => (
                                    <SelectInput
                                        multiple
                                        label={trans('quiz.typologiesFilter')}
                                        selectOptions={typologiesOptions}
                                        helperText={errors?.typologies?.message}
                                        error={!!errors?.typologies}
                                        {...field}
                                    />
                                )}
                            />
                        )}

                        <CtaButton variant="contained" sx={{ width: '100%', mt: 4 }} type="submit" loading={loading}>
                            {trans('survey.filter')}
                        </CtaButton>

                        {!!appliedFilters && !loading && (
                            <CtaButton sx={{ width: '100%', mt: 1 }} onClick={resetFilter} loading={loading}>
                                {trans('survey.removeFilter')}
                            </CtaButton>
                        )}
                    </Box>
                </form>
            </Menu>
        </>
    );
};

export default FilterMenuTable;
