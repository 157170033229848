import React, { useReducer } from 'react';

const reducer = (state, action) => {
    switch (action.type) {
        case 'setProgress': {
            return {
                ...state,
                progress: action.data.progress,
                elapsedMs: action.data.elapsedMs,
            };
        }
        case 'pause': {
            return {
                ...state,
                paused: true,
            };
        }
        case 'restart': {
            return {
                ...state,
                paused: false,
            };
        }
        case 'reset': {
            return {
                elapsedMs: 0,
                progress: 100,
                prevSeconds: action.data.prevSeconds,
                paused: false,
            };
        }
    }
    throw Error('Unknown action: ' + action.type);
};

const useTimer = ({ seconds, timeoutMs = 500 }) => {
    const [state, dispatch] = useReducer(reducer, {
        elapsedMs: 0,
        progress: 100,
        prevSeconds: seconds,
        paused: false,
    });

    React.useEffect(() => {
        const timer = setInterval(() => {
            if (state.prevSeconds !== seconds) {
                dispatch({ type: 'reset', data: { prevSeconds: seconds } });
                return;
            }
            if (state.paused) {
                return;
            }
            const diff = 100 / (seconds * (1000 / timeoutMs));
            const newProgress = state.progress - diff;
            dispatch({
                type: 'setProgress',
                data: { progress: Math.max(newProgress, 0), elapsedMs: state.elapsedMs + timeoutMs },
            });
            if (newProgress <= 0) {
                dispatch({ type: 'pause' });
            }
        }, timeoutMs);
        return () => {
            clearInterval(timer);
        };
    }, [seconds, state]);

    return { progress: state.progress, elapsed: parseInt(state.elapsedMs / 1000, 10) };
};

export default useTimer;
