import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { trans } from 'matice';
import { TextInput } from '~js/components/input';
import CtaButton from '~js/components/CtaButton';
import AutocompleteInput from '~js/components/AutocompleteInput';

const ToolsUserForm = ({ toolUser, toolsRoles, onSubmitPress, onCancelPress, mode = 'create' }) => {
    const {
        handleSubmit,
        watch,
        setError,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: {
            name: toolUser?.name || '',
            surname: toolUser?.surname || '',
            email: toolUser?.email || '',
            password: '',
            roles: toolUser?.roles || [],
        },
    });

    const [isLoading, setIsLoading] = useState(false);

    const onSubmitHandler = async (data) => {
        try {
            setIsLoading(true);

            await onSubmitPress(data);
        } catch (e) {
            if (!e?.generic) {
                Object.keys(e).forEach((key) => {
                    setError(key, { type: 'custom', message: e[key] });
                });
            }
            setIsLoading(false);
        }
    };
    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Controller
                        control={control}
                        name="name"
                        rules={{
                            maxLength: {
                                value: 55,
                                message: trans('formValidation.fieldTooLong'),
                            },
                            required: trans('formValidation.requiredField'),
                        }}
                        render={({ field }) => (
                            <TextInput
                                id={'name'}
                                label={trans('settings.tools-users.fields.name')}
                                placeholder={trans('surveyToolSettings.insertName')}
                                helperText={errors?.name?.message}
                                error={!!errors?.name}
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        control={control}
                        name="surname"
                        rules={{
                            maxLength: {
                                value: 55,
                                message: trans('formValidation.fieldTooLong'),
                            },
                            required: trans('formValidation.requiredField'),
                        }}
                        render={({ field }) => (
                            <TextInput
                                id={'surname'}
                                label={trans('settings.tools-users.fields.surname')}
                                placeholder={trans('surveyToolSettings.insertSurname')}
                                helperText={errors?.surname?.message}
                                error={!!errors?.surname}
                                {...field}
                            />
                        )}
                    />
                </Grid>
                {mode === 'create' && (
                    <>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="email"
                                rules={{
                                    maxLength: {
                                        value: 55,
                                        message: trans('formValidation.fieldTooLong'),
                                    },
                                    required: trans('formValidation.requiredField'),
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        id={'email'}
                                        type={'email'}
                                        label={trans('settings.tools-users.fields.email')}
                                        placeholder={trans('surveyToolSettings.insertEmail')}
                                        helperText={errors?.email?.message}
                                        error={!!errors?.email}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="password"
                                rules={{
                                    maxLength: {
                                        value: 55,
                                        message: trans('formValidation.fieldTooLong'),
                                    },
                                    required: trans('formValidation.requiredField'),
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        id={'password'}
                                        type={'password'}
                                        autoComplete={'off'}
                                        label={trans('settings.tools-users.fields.password')}
                                        placeholder={trans('surveyToolSettings.insertPassword')}
                                        helperText={errors?.password?.message}
                                        error={!!errors?.password}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="roles"
                        rules={{
                            required: trans('formValidation.requiredField'),
                            validate: (value) => {
                                if (Array.isArray(value) && value.length < 1) {
                                    return trans('settings.tools-users.add-user-modal.at-least-one-role');
                                }
                            },
                        }}
                        render={({ field }) => (
                            <AutocompleteInput
                                label={trans('settings.tools-users.fields.roles')}
                                placeholder={trans('surveyToolSettings.chooseRoles')}
                                helperText={errors?.roles?.message}
                                error={!!errors?.roles}
                                options={toolsRoles}
                                multiple={true}
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    mb: 2,
                }}
            >
                <CtaButton onClick={onCancelPress} variant="outlined" sx={{ mt: 10, px: 6 }} disabled={isLoading}>
                    {trans('global.cancel')}
                </CtaButton>
                <CtaButton type="submit" variant="contained" sx={{ mt: 10, ml: 2, px: 6 }} loading={isLoading}>
                    {trans(mode === 'create' ? 'global.save' : 'global.update')}
                </CtaButton>
            </Box>
        </form>
    );
};

export default ToolsUserForm;
