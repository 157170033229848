import React, { useEffect, useMemo, useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { isFuture } from 'date-fns';
import { toast } from 'react-toastify';
import { trans } from 'matice';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import CtaButton from '~js/components/CtaButton';
import { clientQuizStatus } from '~js/constants/missionStatuses';
import useConfirmReload from '~js/hooks/useConfirmReload';
import {
    QuizStep0General,
    QuizStep1Introduction,
    QuizStep2Challenge,
    QuizStep3Closure,
    QuizStep4Administration,
} from '~js/pages/backoffice/Tools/Quiz/components/FormSteps';
import QuizStatusSelectInput from '~js/pages/backoffice/Tools/Quiz/components/QuizStatusSelectInput';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import { calculateTotalPoints } from '~js/pages/backoffice/Tools/Quiz/utils';
import { QUIZ_TYPOLOGIES_WITH_CHALLENGE } from '~js/constants/quiz';

const changeOrientationBreakPoint = 1100;

const QuizEdit = ({ quiz, clusters, events, testerGroups, advertising, can }) => {
    useConfirmReload();

    const [tabValue, setTabValue] = useState(0);
    const [tabsOrientation, setTabsOrientation] = useState(
        window.innerWidth < changeOrientationBreakPoint ? 'horizontal' : 'vertical',
    );
    const [isLoading, setIsLoading] = useState(false);

    const breadcrumbs: BreadcrumbList = [
        {
            routeName: 'Quiz.edit',
            routeLabel: 'Modifica Quiz',
            params: {
                id: quiz._id,
            },
        },
        {
            routeName: 'Quiz.index',
            routeLabel: 'Quiz',
        },
    ];
    const steps = useMemo(() => {
        return [
            trans('quiz.steps.general'),
            trans('quiz.steps.introduction'),
            QUIZ_TYPOLOGIES_WITH_CHALLENGE.includes(quiz?.general?.typology)
                ? trans('quiz.steps.challenge')
                : trans('quiz.steps.questions'),
            trans('quiz.steps.closure'),
            trans('quiz.steps.administration'),
            //trans("quiz.steps.communications")
        ];
    }, [quiz?.general?.typology]);

    const editDisabled =
        quiz?.general?.status === clientQuizStatus.expired || quiz?.general?.status === clientQuizStatus.revoked;

    let currentClientQuizStatus = quiz?.general?.status;

    if (currentClientQuizStatus === 'prod') {
        currentClientQuizStatus = isFuture(new Date(quiz?.general?.startDate))
            ? clientQuizStatus.prodNotActive
            : clientQuizStatus.prodActive;
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (window.innerWidth < changeOrientationBreakPoint) {
                setTabsOrientation('horizontal');
            }
            if (window.innerWidth >= changeOrientationBreakPoint) {
                setTabsOrientation('vertical');
            }
        });
        return () => window.removeEventListener('resize', () => {});
    }, []);

    const changeQuizStatusHandler = async (idSurvey, newStatus, groupTest = []) => {
        const promise = new Promise((resolve, reject) =>
            router.patch(
                route('Quiz.changeStatus', { id: idSurvey, status: newStatus }),
                { groupTest },
                {
                    onStart: () => setIsLoading(true),
                    onFinish: () => setIsLoading(false),
                    onSuccess: () => {
                        toast.success(trans('quiz.statusChanged'));
                        resolve();
                    },
                    onError: (e) => {
                        reject();
                        toast.error(!!e?.generic ? e.generic : trans('quiz.errorOnChangeStatus'));
                    },
                },
            ),
        );
        await promise;
    };

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };
    const goToQuizList = () => router.get(route('Quiz.index'));

    const clusterGroupAdministrationStep = quiz.audience.active
        ? quiz.audience.definition?.map((cg) => {
              const operatorWithPrevious = Object.keys(cg)[0];
              const operatorInsideGroup = Object.keys(cg[operatorWithPrevious])[0];
              const clusters = cg[operatorWithPrevious][operatorInsideGroup];
              return { operatorWithPrevious, operatorInsideGroup, clusters };
          })
        : [
              {
                  operatorWithPrevious: 'AND',
                  operatorInsideGroup: 'AND',
                  clusters: [' '],
              },
          ];

    const onSavePress = (newData, step) => {
        console.log('newData', newData);
        let dataToSubmit = { ...quiz };
        switch (step) {
            case 'typology':
                dataToSubmit['general'] = {
                    ...quiz.general,
                    ...newData.general,
                };
                break;
            case 'introduction':
                dataToSubmit['introduction'] = newData.introduction;
                break;
            case 'questions':
                dataToSubmit['quizQuestions'] = newData.quizQuestions;
                delete dataToSubmit['questions'];
                break;
            case 'closure':
                dataToSubmit['closure'] = newData.closure;
                break;
            case 'administration':
                dataToSubmit['audience'] = {
                    active: newData.administration.audience.active,
                    definition: newData.administration.audience.clustersGroups.map((cg) => ({
                        [cg.operatorWithPrevious]: {
                            [cg.operatorInsideGroup]: cg.clusters,
                        },
                    })),
                };
                delete newData.administration.clustersGroups;
                delete newData.administration.selectedAudience;
                dataToSubmit['general'] = {
                    ...quiz.general,
                    ...newData.administration,
                    event: !!newData.administration.isEventQuiz ? newData.administration.event : 'none',
                };
                break;
            // case "communications":
            //     dataToSubmit["general"] = {
            //         ...quiz.general,
            //         ...newData.communication
            //     };
            //     break;
        }

        console.log('dataToSubmit', dataToSubmit);

        router.post(
            route('Quiz.update', quiz._id),
            { _method: 'patch', ...dataToSubmit },
            {
                preserveScroll: true,
                onStart: () => setIsLoading(true),
                onFinish: () => setIsLoading(false),
                onSuccess: () => toast.success(trans('quiz.quizEdited')),
                onError: (e) => {
                    toast.error(!!e?.generic ? e.generic : trans('quiz.errorOnEditQuiz'));
                },
            },
        );
    };

    const totalPoints = useMemo(() => calculateTotalPoints(quiz), [quiz]);

    return (
        <>
            <Head title={trans('quiz.summary')} />

            <QuizContext.Provider
                value={{
                    isEditMode: true,
                    currentClientQuizStatus,
                    formDisabled: editDisabled || !can.update,
                    quizTypology: quiz?.general.typology,
                    quizTitle: quiz?.introduction?.name,
                    quizStartDate: quiz?.general?.startDate,
                    quizEndDate: quiz?.general?.endDate,
                    quizQuestionsCount: quiz?.quizQuestions?.questions?.length ?? 4,
                    quizTimePointsPerResponse: quiz?.introduction?.time?.responsePoints,
                    quizDragCorrectProducts: quiz?.introduction?.followUp?.correctProducts,
                }}
            >
                <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                    <Box display="flex" flexDirection={tabsOrientation === 'vertical' ? 'row' : 'column'}>
                        <Box
                            sx={{
                                borderRight: tabsOrientation === 'vertical' ? '1px solid #E1E2E9' : '',
                            }}
                            p={2}
                        >
                            <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={4}>
                                <Box>
                                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                        {trans('quiz.summary')}
                                    </Typography>
                                    <Box display="flex" alignItems="center">
                                        <QuizStatusSelectInput
                                            disabled={!can.update}
                                            label={trans('quiz.status')}
                                            quizStartDate={quiz?.general?.startDate}
                                            quizEndDate={quiz?.general?.endDate}
                                            value={quiz?.general?.status}
                                            onChange={(newStatus, testGroups) =>
                                                changeQuizStatusHandler(quiz._id, newStatus, testGroups)
                                            }
                                            testerGroups={testerGroups}
                                            selectedGroupsTest={quiz?.general?.groupTest}
                                            canChangeStatusToProd={can.changeStatusToProd}
                                        />
                                    </Box>
                                </Box>
                                {tabsOrientation === 'horizontal' && (
                                    <CtaButton
                                        variant="outlined"
                                        sx={{ px: 4 }}
                                        onClick={goToQuizList}
                                        disabled={isLoading}
                                    >
                                        {trans('global.close')}
                                    </CtaButton>
                                )}
                            </Box>
                            <Tabs
                                textColor="inherit"
                                orientation={tabsOrientation}
                                variant="scrollable"
                                value={tabValue}
                                onChange={handleChangeTab}
                                TabIndicatorProps={{
                                    style: {
                                        background: 'none',
                                    },
                                }}
                                sx={{
                                    '& .Mui-selected': {
                                        bgcolor: (theme) => theme.palette.primary.main,
                                        borderRadius: 1,
                                        color: 'white',
                                    },
                                }}
                            >
                                {steps.map((label, index) => (
                                    <Tab key={index.toString()} label={label} sx={{ textTransform: 'none' }} />
                                ))}
                            </Tabs>
                        </Box>
                        <Box width="100%">
                            {tabsOrientation === 'vertical' && (
                                <Box display="flex" justifyContent="flex-end" width="100%">
                                    <CtaButton
                                        variant="outlined"
                                        sx={{ px: 6 }}
                                        onClick={goToQuizList}
                                        disabled={isLoading}
                                    >
                                        {trans('global.close')}
                                    </CtaButton>
                                </Box>
                            )}
                            <Box sx={{ pl: 4, position: 'relative' }}>
                                {tabValue === 0 && (
                                    <>
                                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                                            {trans('quiz.typology')}
                                        </Typography>
                                        <QuizStep0General
                                            isLoading={isLoading}
                                            handleStepNext={(data) => onSavePress(data, 'typology')}
                                            points={totalPoints}
                                            advertising={advertising}
                                            stepFormValue={{
                                                title: quiz?.general?.title,
                                                description: quiz?.general?.description,
                                                typology: quiz?.general?.typology,
                                                startDate: new Date(quiz?.general?.startDate),
                                                endDate: new Date(quiz?.general?.endDate),
                                                points: quiz?.general?.points,
                                                advertisingEnabled: quiz?.general?.advertisingEnabled,
                                                advertisingIds: quiz?.general?.advertisingIds, //.map((v) => ({...v, checked: true}))
                                            }}
                                        />
                                    </>
                                )}
                                {tabValue === 1 && (
                                    <>
                                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                                            {trans('quiz.introduction')}
                                        </Typography>
                                        <QuizStep1Introduction
                                            isLoading={isLoading}
                                            handleStepNext={(data) => onSavePress(data, 'introduction')}
                                            stepFormValue={quiz.introduction}
                                            points={totalPoints}
                                        />
                                    </>
                                )}

                                {tabValue === 2 && (
                                    <>
                                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                                            {trans('quiz.questions')}
                                        </Typography>

                                        <QuizStep2Challenge
                                            typology={quiz.general.typology}
                                            isLoading={isLoading}
                                            handleStepNext={(data) => onSavePress(data, 'questions')}
                                            stepFormValue={quiz.quizQuestions}
                                        />
                                    </>
                                )}

                                {tabValue === 3 && (
                                    <>
                                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                                            {trans('quiz.closure')}
                                        </Typography>
                                        <QuizStep3Closure
                                            isLoading={isLoading}
                                            handleStepNext={(data) => onSavePress(data, 'closure')}
                                            points={totalPoints}
                                            stepFormValue={quiz.closure}
                                        />
                                    </>
                                )}

                                {tabValue === 4 && (
                                    <>
                                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                                            {trans('quiz.administration')}
                                        </Typography>
                                        <QuizStep4Administration
                                            isLoading={isLoading}
                                            handleStepNext={(data) => onSavePress(data, 'administration')}
                                            clusters={clusters}
                                            events={events}
                                            testerGroups={testerGroups}
                                            stepFormValue={{
                                                event: quiz?.general?.event || 'none',
                                                isEventQuiz: quiz?.general?.event !== 'none',
                                                silentActivation: quiz?.general?.silentActivation,
                                                realTimeActivation: quiz?.general?.realTimeActivation,
                                                groupTest: quiz?.general?.groupTest || [],
                                                audience: {
                                                    active: !!quiz?.audience.active,
                                                    clustersGroups: clusterGroupAdministrationStep,
                                                },
                                            }}
                                        />
                                    </>
                                )}

                                {/*tabValue === 5 && (
                                    <>
                                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                                            {trans("quiz.communications")}
                                        </Typography>
                                        <NewQuizCommunicationsStep
                                            handleStepNext={(data) => onSavePress(data, "communications")}
                                            // handleStepPrevious={handleStepPrevious}
                                            isLoading={isLoading}
                                            stepFormValue={{
                                                isEmailActive: quiz?.general?.isEmailActive,
                                                isNotificationActive: quiz?.general?.isNotificationActive
                                            }}
                                        />
                                    </>
                                )*/}
                                {editDisabled && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            bottom: 0,
                                            right: 0,
                                            left: 0,
                                            zIndex: 9,
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </ToolContentPageLayout>
            </QuizContext.Provider>
        </>
    );
};

export default QuizEdit;
