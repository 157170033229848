import React from 'react';
import { Head, router } from '@inertiajs/react';
import { Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { trans } from 'matice';
import CtaButton from '~js/components/CtaButton';
import EmptySection from '~js/components/EmptySection';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import TriggersTable from './components/TriggersTable';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';

const breadcrumbs: BreadcrumbList = [
    {
        routeName: 'Triggers.index',
        routeLabel: 'Trigger',
    },
];

const TriggersIndex = ({ triggers, can, canReadToolIds, ...other }) => {
    console.log('TriggersIndex props', { triggers, can, ...other });
    const goToCreateTriggerPage = () => router.get(route('Triggers.create'));

    const deleteTrigger = async (idTrigger) => {
        const promise = new Promise((resolve, reject) =>
            router.delete(route('Triggers.destroy', idTrigger), {
                onSuccess: () => {
                    toast.success(trans('triggers.triggerDeleted'));
                    resolve();
                },
                onError: (e) => {
                    reject();
                    toast.error(!!e?.generic ? e.generic : trans('triggers.errorOnDeleteTrigger'));
                },
            }),
        );

        await promise;
    };

    return (
        <>
            <Head title={trans('triggers.triggers')} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    {triggers.length > 0 && can.create && (
                        <CtaButton
                            variant="contained"
                            sx={{
                                px: 5,
                            }}
                            onClick={goToCreateTriggerPage}
                        >
                            {trans('global.createNew')}
                        </CtaButton>
                    )}
                </Box>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                    {trans('triggers.triggers')}
                </Typography>
                <TriggersTable
                    triggers={triggers}
                    deleteTrigger={deleteTrigger}
                    canReadToolIds={canReadToolIds}
                    canDelete={can.deleteAny}
                    canUpdate={can.updateAny}
                />
                {triggers.length === 0 && (
                    <EmptySection
                        title={trans('triggers.noTriggerTitle')}
                        description={trans('triggers.noTriggerDescription')}
                        buttonComponent={
                            <CtaButton
                                variant="contained"
                                sx={{
                                    px: 5,
                                }}
                                onClick={goToCreateTriggerPage}
                            >
                                {trans('global.createNew')}
                            </CtaButton>
                        }
                    />
                )}
            </ToolContentPageLayout>
        </>
    );
};

export default TriggersIndex;
