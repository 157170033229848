import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import SelectInput from '~js/components/SelectInput';
import { clientSurveyStatus } from '~js/constants/missionStatuses';
import SurveyContext from '../context';

const MinMaxInput = ({ questionIndex, responsesFieldsLength }) => {
    const { currentClientSurveyStatus, formDisabled } = useContext(SurveyContext);

    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext();

    const fieldBasePath = `questions.${questionIndex}`;
    const errorsBasePath =
        errors?.questions && errors.questions[questionIndex] ? errors.questions[questionIndex] : null;

    const minMaxSelectOptions = [...Array(responsesFieldsLength).keys()].map((i) => ({
        _id: i,
        label: i + 1,
        value: i + 1,
    }));

    return (
        <Box width="100%" display="flex">
            <Controller
                control={control}
                name={`${fieldBasePath}.min`}
                rules={{
                    required: trans('formValidation.requiredField'),
                    deps: [`${fieldBasePath}.max`],
                }}
                defaultValue={watch(`${fieldBasePath}.min`) || ''}
                shouldUnregister
                render={({ field }) => (
                    <SelectInput
                        disabled={
                            formDisabled ||
                            currentClientSurveyStatus === clientSurveyStatus.prodNotActive ||
                            currentClientSurveyStatus === clientSurveyStatus.prodActive
                        }
                        label={trans('survey.minResponseNumber')}
                        selectOptions={minMaxSelectOptions}
                        helperText={errorsBasePath && errorsBasePath?.min?.message}
                        error={!!errorsBasePath?.min}
                        {...field}
                    />
                )}
            />
            <Box width="100%" pl={3}>
                <Controller
                    control={control}
                    name={`${fieldBasePath}.max`}
                    rules={{
                        required: trans('formValidation.requiredField'),
                        validate: (value) =>
                            value >= watch(`${fieldBasePath}.min`) || trans('survey.maxMustBeGreaterOrEqualThanMin'),
                        deps: [`${fieldBasePath}.min`],
                    }}
                    defaultValue={watch(`${fieldBasePath}.max`) || ''}
                    shouldUnregister
                    render={({ field }) => (
                        <SelectInput
                            disabled={
                                formDisabled ||
                                currentClientSurveyStatus === clientSurveyStatus.prodNotActive ||
                                currentClientSurveyStatus === clientSurveyStatus.prodActive
                            }
                            label={trans('survey.maxResponseNumber')}
                            selectOptions={minMaxSelectOptions}
                            helperText={errorsBasePath && errorsBasePath?.max?.message}
                            error={!!errorsBasePath?.max}
                            {...field}
                        />
                    )}
                />
            </Box>
        </Box>
    );
};

export default MinMaxInput;
