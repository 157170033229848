import React from 'react';
import { Box, Typography } from '@mui/material';
import rating5Item from '~/assets/images/tools/surveyTool/rating5QuestionItem.png';
import rating10Item from '~/assets/images/tools/surveyTool/rating10QuestionItem.png';
import RatingImagePreview from './RatingImagePreview';

const RatingPreview = ({ responses }) => {
    return (
        <div>
            {responses?.map((r, index) => (
                <Box key={index.toString()} mt="15px">
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '10px',
                            lineHeight: '12px',
                            color: 'black',
                            mb: '10px',
                            fontFamily: 'Nunito',
                        }}
                    >
                        {r?.response}
                    </Typography>
                    {!!r.image && <RatingImagePreview image={r.image} />}
                    <Box
                        component="img"
                        src={r?.max === 5 ? rating5Item : rating10Item}
                        sx={{
                            width: '100%',
                            objectFit: 'contain',
                        }}
                    />
                    <Box width="100%" display="flex" justifyContent="space-between">
                        <Typography
                            sx={{
                                fontSize: '7px',
                                lineHeight: '9px',
                                color: 'black',
                                mb: '10px',
                                fontFamily: 'Nunito',
                            }}
                        >
                            {r?.negativeLabel?.slice(0, 30)}
                        </Typography>

                        <Typography
                            sx={{
                                fontSize: '7px',
                                lineHeight: '9px',
                                color: 'black',
                                mb: '10px',
                                fontFamily: 'Nunito',
                            }}
                        >
                            {r?.positiveLabel?.slice(0, 30)}
                        </Typography>
                    </Box>
                </Box>
            ))}
        </div>
    );
};

export default RatingPreview;
