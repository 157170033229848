import React from 'react';
import SecondaryAppBar from '~js/components/SecondaryAppBar';
import { Container, Paper } from '@mui/material';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';

type ToolContentPageLayoutProps = React.PropsWithChildren & {
    breadcrumbItems: BreadcrumbList,
};
const ToolContentPageLayout = ({ children, breadcrumbItems = [] }: ToolContentPageLayoutProps) => {
    return (
        <>
            <SecondaryAppBar breadcrumbItems={breadcrumbItems} />
            <Container maxWidth="xl" disableGutters>
                <Paper sx={{ p: 4, m: 4, mt: 6, position: 'relative' }} elevation={0}>
                    {children}
                </Paper>
            </Container>
        </>
    );
};

export default ToolContentPageLayout;
