import React, { forwardRef } from 'react';
import { Autocomplete, Checkbox, FormControl, FormHelperText, SvgIcon, TextField } from '@mui/material';
import { alpha } from '@mui/material/styles';
import CheckRadioChecked from '~/assets/images/icons/check-radio-checked.svg?react';
import CheckRadioUnchecked from '~/assets/images/icons/check-radio-unchecked.svg?react';

const checkedIcon = (
    <SvgIcon inheritViewBox>
        <CheckRadioChecked />
    </SvgIcon>
);
const uncheckedIcon = (
    <SvgIcon inheritViewBox>
        <CheckRadioUnchecked />
    </SvgIcon>
);

/**
 * If you want to use a different option structure make sure to 'override' the isOptionEqualToValue equality check.
 *
 * options: Array<{ label: string, value: mixed }>
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{readonly onChange?: *, readonly defaultValue?: string, readonly options?: [], readonly multiple?: *, readonly label?: null, readonly placeholder?: null, readonly type?: string, readonly error?: *, readonly helperText?: *, readonly value?: *}> & React.RefAttributes<unknown>>}
 */
const AutocompleteInput = forwardRef(
    (
        {
            label = null,
            placeholder = null,
            type = 'text',
            error,
            helperText,
            options = [],
            defaultValue = '',
            multiple,
            value,
            onChange,
            ...other
        },
        ref,
    ) => {
        return (
            <FormControl variant="standard" fullWidth error={error} sx={{ margin: '12px 0' }}>
                <Autocomplete
                    multiple={multiple}
                    id="checkboxes-autocomplete"
                    options={options}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                        <li {...props} key={props.id}>
                            <Checkbox
                                icon={uncheckedIcon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    sx={[
                        helperText && {
                            boxShadow: (theme) => `${alpha(theme.palette.error.main, 0.5)} 0 0 0 0.2rem`,
                            borderColor: (theme) => theme.palette.error.main,
                            borderRadius: '8px',
                        },
                    ]}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            placeholder={placeholder}
                            sx={{
                                color: '#5E6366',
                                '&.Mui-focused': { color: error ? '#F9473C' : '#5E6366 !important' },
                            }}
                        />
                    )}
                    value={value}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    {...other}
                    onChange={(event, value, reason, details) => {
                        onChange(value);
                    }}
                />
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        );
    },
);

export default AutocompleteInput;
