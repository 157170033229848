import React from 'react';
import { Box, FormControl, FormHelperText, InputLabel } from '@mui/material';
import yesNoSmile from '~/assets/images/tools/surveyTool/yesNoSmile.png';
import yesNoSmileSelected from '~/assets/images/tools/surveyTool/yesNoSmileSelected.png';
import yesNoHand from '~/assets/images/tools/surveyTool/yesNoHand.png';
import yesNoHandSelected from '~/assets/images/tools/surveyTool/yesNoHandSelected.png';

const BooleanRadioButton = ({ value, onChange, helperText, error, label, disabled = false }) => {
    return (
        <FormControl variant="standard" fullWidth error={error} sx={{ margin: '12px 0' }}>
            {label && (
                <InputLabel
                    shrink
                    htmlFor="input"
                    sx={{
                        color: '#5E6366',
                        pl: 1,
                        '&.Mui-focused': { color: error ? '#F9473C' : '#5E6366 !important' },
                    }}
                >
                    {label}
                </InputLabel>
            )}
            <Box id="input" sx={{ position: 'relative', mt: 3 }} display="flex" gap={2} flexWrap="wrap">
                <Box
                    flex={1}
                    p={2}
                    sx={{
                        border: (theme) =>
                            value === 'smile' ? `1px solid ${theme.palette.primary.dark}` : '1px solid #5E6366',
                        borderRadius: 1,
                        cursor: 'pointer',
                    }}
                    onClick={!disabled ? () => onChange('smile') : () => {}}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    maxWidth="250px"
                >
                    <Box component="img" src={value === 'smile' ? yesNoSmileSelected : yesNoSmile} maxWidth="200px" />
                </Box>
                <Box
                    flex={1}
                    p={2}
                    sx={{
                        border: (theme) =>
                            value === 'thumbs' ? `1px solid ${theme.palette.primary.dark}` : '1px solid #5E6366',
                        borderRadius: 1,
                        cursor: 'pointer',
                    }}
                    onClick={!disabled ? () => onChange('thumbs') : () => {}}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    maxWidth="250px"
                >
                    <Box component="img" src={value === 'thumbs' ? yesNoHandSelected : yesNoHand} maxWidth="200px" />
                </Box>
            </Box>

            {helperText && <FormHelperText sx={{ pl: 1 }}>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default BooleanRadioButton;
