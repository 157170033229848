import React, { useContext, useState } from 'react';
import { Box, FormHelperText, Grid, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Controller, useFormContext } from 'react-hook-form';
import axios from 'axios';
import { trans } from 'matice';
import { CheckboxInput, SelectInput, TextInput } from '~js/components/input';
import CtaButton from '~js/components/CtaButton';
import CustomTooltip from '~js/components/CustomTooltip';
import { clientSurveyStatus } from '~js/constants/missionStatuses';
import SurveyContext from '../context';

const CoverageExclusionsForm = ({ surveys = [], setAudienceTested }) => {
    const { currentClientSurveyStatus, formDisabled } = useContext(SurveyContext);

    const [coverage, setCoverage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorCheck, setErrorCheck] = useState(null);

    const {
        control,
        formState: { errors },
        watch,
        setValue,
        trigger,
        clearErrors,
    } = useFormContext();

    const surveysSelectOptions = surveys.map((s) => ({
        _id: s._id,
        label: s.general.title,
        value: s._id,
    }));

    const checkAudience = async () => {
        try {
            setCoverage(null);
            setErrorCheck(null);
            setAudienceTested(false);
            setValue('selectedAudience', '');
            const validated = await trigger('clustersGroups');
            if (!validated) {
                setErrorCheck(trans('survey.selectCluster'));
                return;
            }
            setLoading(true);
            const definition = watch('clustersGroups').map((cg) => ({
                [cg.operatorWithPrevious]: {
                    [cg.operatorInsideGroup]: cg.clusters,
                },
            }));
            const surveysToExclude = watch('surveysToExclude');
            const response = await axios.post(route('Surveys.checkAudience'), {
                audience: { surveysToExclude, definition },
            });
            setCoverage(response.data.data.coverage);
            setAudienceTested(true);
            clearErrors('checkAudience');
        } catch (e) {
            setErrorCheck(trans('global.errorOccurred'));
            setAudienceTested(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            width="100%"
            sx={{
                border: '1px solid #dfdfe4',
                borderRadius: 1,
                bgcolor: 'white',
                mt: 5,
            }}
        >
            <Box p={3}>
                <Typography width="100%" mb={1}>
                    {trans('cluster.coverage')}
                </Typography>

                <Grid container columnSpacing={2} mt={4}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px" display="flex" alignItems="center">
                            <CtaButton
                                disabled={
                                    formDisabled ||
                                    currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                    currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                }
                                sx={{ py: 0, px: 1, textDecoration: 'underline' }}
                                onClick={checkAudience}
                                loading={loading}
                            >
                                {trans('survey.checkCoverage')}
                            </CtaButton>
                            <CustomTooltip
                                title={trans('survey.checkCoverageTooltip')}
                                placement="bottom-start"
                                sx={{ zIndex: 99 }}
                            >
                                <InfoIcon sx={{ color: '#828282', zIndex: 99 }} />
                            </CustomTooltip>
                            <Box>
                                <Box
                                    sx={{
                                        borderRadius: '8px',
                                        border: '1px solid #5E6366',
                                        padding: '10px 12px',
                                        minWidth: '200px',
                                        ml: 1,
                                        bgcolor: '#e9e9e9',
                                    }}
                                >
                                    {coverage !== null ? coverage : '-'}
                                </Box>
                                {(errorCheck || !!errors?.checkAudience?.message) && (
                                    <FormHelperText sx={{ pl: 1 }} error>
                                        {errorCheck ? errorCheck : errors?.checkAudience?.message}
                                    </FormHelperText>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px" display="flex" alignItems="center">
                            <Controller
                                control={control}
                                name="withExclusions"
                                shouldUnregister
                                render={({ field }) => (
                                    <CheckboxInput
                                        disabled={
                                            formDisabled ||
                                            currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                            currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                        }
                                        label={trans('survey.selectSample')}
                                        {...field}
                                    />
                                )}
                            />
                            <CustomTooltip
                                title={trans('survey.selectSampleTooltip')}
                                placement="bottom-start"
                                sx={{ zIndex: 99 }}
                            >
                                <InfoIcon sx={{ color: '#828282', zIndex: 99 }} />
                            </CustomTooltip>
                        </Box>
                    </Grid>
                </Grid>

                {!!watch('withExclusions') && (
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box maxWidth="500px">
                                <Controller
                                    control={control}
                                    name={`surveysToExclude`}
                                    rules={
                                        {
                                            // required: trans('formValidation.requiredField'),
                                        }
                                    }
                                    defaultValue={[]}
                                    shouldUnregister
                                    render={({ field }) => (
                                        <SelectInput
                                            disabled={
                                                formDisabled ||
                                                currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                                currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                            }
                                            multiple
                                            label={trans('survey.exclude')}
                                            selectOptions={surveysSelectOptions}
                                            helperText={errors?.surveysToExclude?.message}
                                            error={!!errors?.surveysToExclude}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box maxWidth="500px">
                                <Controller
                                    control={control}
                                    name="selectedAudience"
                                    shouldUnregister
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        validate: (val) =>
                                            (!!coverage && coverage >= val) || trans('survey.sampleError'),
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextInput
                                            disabled={
                                                formDisabled ||
                                                currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                                currentClientSurveyStatus === clientSurveyStatus.prodNotActive ||
                                                !coverage
                                            }
                                            type="number"
                                            inputProps={{ min: 0 }}
                                            label={trans('survey.userSample')}
                                            placeholder={trans('survey.userSamplePlaceholder')}
                                            helperText={errors?.selectedAudience?.message}
                                            error={!!errors?.selectedAudience}
                                            tooltipText={!coverage && trans('survey.checkAudienceBefore')}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Box>
    );
};

export default CoverageExclusionsForm;
