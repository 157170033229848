import React, { useContext, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import { SURVEY_TYPOLOGIES } from '~js/constants/survey.js';
import SurveyContext from '../context';
import CaptionInput from './CaptionInput';
import ActionMultiFlowResponse from './ActionMultiFlowResponse';
import BooleanRadioButton from './BooleanRadioButton';

const BooleanForm = ({ questionIndex }) => {
    const { formDisabled, surveyTypology } = useContext(SurveyContext);
    const isMultiFlowSurvey = surveyTypology === SURVEY_TYPOLOGIES.multiFlow;

    const {
        control,
        formState: { errors },
        setValue,
        watch,
    } = useFormContext();

    const fieldBasePath = `questions.${questionIndex}`;
    const errorsBasePath =
        errors?.questions && errors.questions[questionIndex] ? errors.questions[questionIndex] : null;

    useEffect(() => {
        if (!watch(`${fieldBasePath}.responses.${0}.response`)) {
            setValue(`${fieldBasePath}.responses.${0}.response`, 'yes');
        }
        if (!watch(`${fieldBasePath}.responses.${1}.response`)) {
            setValue(`${fieldBasePath}.responses.${1}.response`, 'no');
        }
    }, []);

    return (
        <Box>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box maxWidth="500px">
                        <CaptionInput questionIndex={questionIndex} />
                    </Box>
                </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box maxWidth="500px">
                        <Controller
                            control={control}
                            name={`${fieldBasePath}.extraData.iconType`}
                            defaultValue={watch(`${fieldBasePath}.extraData.iconType`) || ''}
                            rules={{
                                required: trans('formValidation.requiredField'),
                            }}
                            shouldUnregister
                            render={({ field: { onChange, value } }) => (
                                <BooleanRadioButton
                                    disabled={formDisabled}
                                    label={trans('survey.selectIcons')}
                                    value={value}
                                    onChange={onChange}
                                    helperText={
                                        errors?.questions &&
                                        errors?.questions[questionIndex]?.extraData?.iconType &&
                                        errors?.questions[questionIndex]?.extraData?.iconType?.message
                                    }
                                    error={errors?.questions && !!errors?.questions[questionIndex]?.extraData?.iconType}
                                />
                            )}
                        />
                    </Box>
                </Grid>
                {isMultiFlowSurvey && (
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px">
                            <ActionMultiFlowResponse
                                label={`${trans('survey.action')} Si`}
                                questionIndex={questionIndex}
                                fieldBasePath={`${fieldBasePath}.responses.${0}`}
                                errorsBasePath={errorsBasePath?.responses && errorsBasePath?.responses[0]}
                            />
                            <ActionMultiFlowResponse
                                label={`${trans('survey.action')} No`}
                                questionIndex={questionIndex}
                                fieldBasePath={`${fieldBasePath}.responses.${1}`}
                                errorsBasePath={errorsBasePath?.responses && errorsBasePath?.responses[1]}
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default BooleanForm;
