import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { trans } from 'matice';
import { SelectInput, TextInput } from '~js/components/input';
import FullPageLoader from '~js/components/FullPageLoader';
import CtaButton from '~js/components/CtaButton';
import useTesterGroupForm from '../hooks/useTesterGroupForm';

const EditTesterGroupForm = ({ externalDataSourceOption, testerGroupData, updateGroup, onCancelPress }) => {
    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: {
            name: testerGroupData.name || '',
            externalDataSource: testerGroupData?.settings?.connection || '',
            table: testerGroupData?.settings?.table || '',
            columnEmail: testerGroupData?.settings?.columnEmail || '',
            columnIdentifier: testerGroupData?.settings?.columnIdentifier || '',
        },
    });

    const {
        fetchEntitiesFromDataSource,
        fetchAttributesFromEntity,
        isLoadingFetchOptions,
        entitiesOptions,
        attributesOptions,
    } = useTesterGroupForm();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (testerGroupData?.settings?.connection) {
            fetchEntitiesFromDataSource(testerGroupData?.settings?.connection);
        }

        if (testerGroupData?.settings?.connection && testerGroupData?.settings?.table) {
            fetchAttributesFromEntity(testerGroupData?.settings?.connection, testerGroupData?.settings?.table);
        }
    }, [testerGroupData?.settings]);

    const onSubmitHandler = async (data) => {
        const { name, externalDataSource, table, columnIdentifier, columnEmail } = data;
        try {
            setIsLoading(true);
            await updateGroup({
                name,
                settings: { connection: externalDataSource, table, columnIdentifier, columnEmail },
            });
        } catch (e) {
            if (!e?.generic) {
                Object.keys(e).forEach((key) => {
                    setError(key, { type: 'custom', message: e[key] });
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <FullPageLoader isVisible={isLoadingFetchOptions} />
            <Controller
                control={control}
                name="name"
                rules={{
                    maxLength: {
                        value: 1000,
                        message: trans('formValidation.fieldTooLong'),
                    },
                    required: trans('formValidation.requiredField'),
                }}
                render={({ field }) => (
                    <TextInput
                        label={trans('surveyToolSettings.testGroupName')}
                        placeholder={trans('surveyToolSettings.insertName')}
                        helperText={errors?.name?.message}
                        error={!!errors?.name}
                        {...field}
                    />
                )}
            />

            <Controller
                control={control}
                name="externalDataSource"
                rules={{
                    required: trans('formValidation.requiredField'),
                }}
                render={({ field }) => (
                    <SelectInput
                        label={trans('cluster.connection')}
                        selectOptions={externalDataSourceOption}
                        helperText={errors?.externalDataSource?.message}
                        error={!!errors?.externalDataSource}
                        {...field}
                        onChange={(e) => {
                            field.onChange(e);
                            fetchEntitiesFromDataSource(e.target.value);
                        }}
                        ref={null}
                    />
                )}
            />

            <Controller
                control={control}
                name="table"
                rules={{
                    required: trans('formValidation.requiredField'),
                }}
                shouldUnregister
                render={({ field }) => (
                    <SelectInput
                        label="Tabella"
                        disabled={entitiesOptions.length === 0}
                        selectOptions={entitiesOptions}
                        helperText={errors?.table?.message}
                        error={!!errors?.table}
                        {...field}
                        onChange={(e) => {
                            field.onChange(e);
                            fetchAttributesFromEntity(watch('externalDataSource'), e.target.value);
                        }}
                        ref={null}
                    />
                )}
            />

            <Controller
                control={control}
                name="columnEmail"
                rules={{
                    required: trans('formValidation.requiredField'),
                }}
                render={({ field }) => (
                    <SelectInput
                        label="Colonna email"
                        disabled={attributesOptions.length === 0}
                        selectOptions={attributesOptions}
                        helperText={errors?.columnEmail?.message}
                        error={!!errors?.columnEmail}
                        {...field}
                        ref={null}
                    />
                )}
            />

            <Controller
                control={control}
                name="columnIdentifier"
                rules={{
                    required: trans('formValidation.requiredField'),
                }}
                render={({ field }) => (
                    <SelectInput
                        label="Colonna identificativa"
                        disabled={attributesOptions.length === 0}
                        selectOptions={attributesOptions}
                        helperText={errors?.columnIdentifier?.message}
                        error={!!errors?.columnIdentifier}
                        {...field}
                        ref={null}
                    />
                )}
            />

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    mb: 2,
                }}
            >
                <CtaButton onClick={onCancelPress} variant="outlined" sx={{ mt: 10, px: 6 }} disabled={isLoading}>
                    {trans('global.cancel')}
                </CtaButton>
                <CtaButton type="submit" variant="contained" sx={{ mt: 10, ml: 2, px: 6 }} loading={isLoading}>
                    {trans('global.save')}
                </CtaButton>
            </Box>
        </form>
    );
};

export default EditTesterGroupForm;
