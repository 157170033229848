import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

const FullPageLoader = ({ isVisible }) => {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isVisible}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default FullPageLoader;
