import React, { useContext, useRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Typography } from '@mui/material';
import { trans } from 'matice';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Controller, useFormContext } from 'react-hook-form';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import { ASPECT_RATIO_IMG, ONE_MB } from '~js/constants/images';
import FileUploaderInput from '~js/components/FileUploaderInput';
import PreviewMemoryQuestionScreen from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewMemoryQuestionScreen';
import PreviewQuestionSuccessModalScreen from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewQuestionSuccessModalScreen';
import QuestionSuccessModalForm from '~js/pages/backoffice/Tools/Quiz/components/FormChallenges/QuestionSuccessModalForm';

const accordionIconSx = {
    height: 24,
    width: 24,
    zIndex: 99,
    py: '0px',
    px: '6px',
    borderRadius: '50%',
    fontWeight: 'bold',
    backgroundColor: (theme) => theme.palette.primary.iconBackground,
    color: (theme) => theme.palette.primary.main,
};

const ACCORDION_TRANSITION_DURATION_MS = 250;

const MemoryQuestionForm = ({
    questionIndex,
    currentFocusedQuestion,
    setCurrentFocusedQuestion,
    cardCouples,
    cardImages,
    setCardCoupleImage,
}) => {
    const { formDisabled } = useContext(QuizContext);

    const detailRef = useRef();

    const {
        control,
        setError,
        clearErrors,
        formState: { errors },
        watch,
        trigger,
    } = useFormContext();

    const quizQuestionsKey = `questions`;
    const quizQuestionKey = `${quizQuestionsKey}[${questionIndex}]`;
    const itemErrors = errors?.[quizQuestionsKey]?.[questionIndex];

    const collapseQuestion = () => {
        trigger(quizQuestionKey).then((isValid) => {
            if (isValid) {
                setCurrentFocusedQuestion(null);
            }
        });
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" ref={detailRef}>
            <Box
                width="100%"
                px={2}
                mb={2}
                sx={{
                    border:
                        errors?.questions && errors.questions[questionIndex]
                            ? '1px solid #f44336'
                            : '1px solid #dfdfe4',
                    borderRadius: 1,
                    bgcolor: 'white',
                }}
            >
                <Accordion
                    elevation={0}
                    disableGutters
                    expanded={currentFocusedQuestion === questionIndex}
                    slotProps={{ transition: { timeout: ACCORDION_TRANSITION_DURATION_MS } }}
                >
                    <AccordionSummary
                        sx={{
                            '&.Mui-focusVisible': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                            <Grid container columnSpacing={2} alignItems={'center'}>
                                <Grid
                                    item
                                    xs={12}
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                >
                                    <Box display={'flex'} flexDirection={'row'} py={3}>
                                        {currentFocusedQuestion !== questionIndex ? (
                                            <KeyboardArrowDownIcon
                                                sx={accordionIconSx}
                                                onClick={(event) => {
                                                    setCurrentFocusedQuestion(questionIndex);
                                                    setTimeout(() => {
                                                        detailRef.current.scrollIntoView({
                                                            inline: 'start',
                                                            behavior: 'smooth',
                                                        });
                                                    }, ACCORDION_TRANSITION_DURATION_MS * 2);
                                                }}
                                            />
                                        ) : (
                                            <KeyboardArrowUpIcon
                                                sx={accordionIconSx}
                                                onClick={(event) => {
                                                    setCurrentFocusedQuestion(questionIndex);
                                                    collapseQuestion();
                                                }}
                                            />
                                        )}
                                        <Box display={'flex'} flexDirection={'column'} sx={{ pl: 2 }}>
                                            <Typography variant={'subtitle2'} fontWeight={'bold'}>
                                                {trans('quiz.memory.questionTitle', {
                                                    args: { index: questionIndex + 1 },
                                                })}
                                            </Typography>
                                            <Typography
                                                variant={'caption'}
                                                color={(theme) => theme.palette.text.secondary}
                                            >
                                                {trans('quiz.memory.questionSubTitle')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            {/* Images */}
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box sx={{ maxWidth: 500 }}>
                                        {/* Image */}
                                        <Controller
                                            control={control}
                                            name={`${quizQuestionKey}.image`}
                                            defaultValue=""
                                            rules={{ required: trans('formValidation.requiredField') }}
                                            render={({ field: { onChange, value } }) => (
                                                <FileUploaderInput
                                                    disabled={formDisabled}
                                                    value={value}
                                                    label={trans('quiz.memory.image')}
                                                    tooltipText={trans('formValidation.aspectRatioError', {
                                                        args: {
                                                            aspectRatio: ASPECT_RATIO_IMG.QUIZ_MEMORY_IMAGE.toFixed(1),
                                                        },
                                                    })}
                                                    onChange={(fileUrl) => {
                                                        setCardCoupleImage(fileUrl);
                                                        onChange(fileUrl);
                                                    }}
                                                    helperText={
                                                        itemErrors?.imageRight?.message ?? trans('quiz.fileMaxSizeHint')
                                                    }
                                                    validateFn={async (value) => {
                                                        if (!value || typeof value !== 'object') {
                                                            clearErrors(`${quizQuestionKey}.image`);
                                                            return true;
                                                        }
                                                        return await new Promise((resolve) => {
                                                            let img = new Image();
                                                            img.src = window.URL.createObjectURL(value);
                                                            img.onload = () => {
                                                                const aspectRatioImgUp = (
                                                                    img.width / img.height
                                                                ).toFixed(1);
                                                                const aspectRatioRule =
                                                                    ASPECT_RATIO_IMG.QUIZ_MEMORY_IMAGE.toFixed(1);
                                                                if (aspectRatioImgUp !== aspectRatioRule) {
                                                                    setError(`${quizQuestionKey}.image`, {
                                                                        type: 'custom',
                                                                        message: trans(
                                                                            'formValidation.aspectRatioError',
                                                                            {
                                                                                args: {
                                                                                    aspectRatio: aspectRatioRule,
                                                                                },
                                                                            },
                                                                        ),
                                                                    });
                                                                    resolve(false);
                                                                } else {
                                                                    if (value.size > ONE_MB) {
                                                                        setError(`${quizQuestionKey}.image`, {
                                                                            type: 'custom',
                                                                            message: trans(
                                                                                'formValidation.max1MbFileError',
                                                                                {
                                                                                    args: {
                                                                                        uploadedSize: (
                                                                                            value.size / ONE_MB
                                                                                        ).toFixed(2),
                                                                                    },
                                                                                },
                                                                            ),
                                                                        });
                                                                        resolve(false);
                                                                    } else {
                                                                        clearErrors(`${quizQuestionKey}.imageRight`);
                                                                        resolve(true);
                                                                    }
                                                                }
                                                            };
                                                        });
                                                    }}
                                                    error={!!itemErrors?.image}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Grid>
                                {/* Images Preview */}
                                <Grid item xs={6}>
                                    <Box
                                        borderRadius={'12px'}
                                        bgcolor={(theme) => theme.palette.background.default}
                                        p={4}
                                        display={'flex'}
                                        justifyContent={'center'}
                                    >
                                        <PreviewMemoryQuestionScreen
                                            image={watch(`${quizQuestionKey}.image`)}
                                            cardCouples={cardCouples}
                                            cardImages={cardImages}
                                            couplesFound={questionIndex + (!!watch(`${quizQuestionKey}.image`) ? 1 : 0)}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Divider width={'100%'} sx={{ marginTop: 4, marginBottom: 4 }} />

                            {/* Modal */}
                            <Grid container>
                                <Grid item xs={12} mb={8}>
                                    <Typography variant={'subtitle2'} fontWeight={'bold'}>
                                        {trans('quiz.memory.modalTitle')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ maxWidth: 500 }}>
                                        <QuestionSuccessModalForm
                                            questionKey={quizQuestionKey}
                                            questionValidationErrors={itemErrors}
                                        />
                                    </Box>
                                </Grid>
                                {/* Images Preview */}
                                <Grid item xs={6}>
                                    <Box
                                        borderRadius={'12px'}
                                        bgcolor={(theme) => theme.palette.background.default}
                                        p={4}
                                        display={'flex'}
                                        justifyContent={'center'}
                                    >
                                        <PreviewQuestionSuccessModalScreen
                                            subTitle={watch(`${quizQuestionKey}.modal.subTitle`)}
                                            description={watch(`${quizQuestionKey}.modal.description`)}
                                            image={watch(`${quizQuestionKey}.modal.image`)}
                                        >
                                            <PreviewMemoryQuestionScreen
                                                image={watch(`${quizQuestionKey}.image`)}
                                                cardCouples={cardCouples}
                                                cardImages={cardImages}
                                                couplesFound={
                                                    questionIndex + (!!watch(`${quizQuestionKey}.image`) ? 1 : 0)
                                                }
                                            />
                                        </PreviewQuestionSuccessModalScreen>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );
};

export default MemoryQuestionForm;
