import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import SelectInput from '~js/components/SelectInput';
import SurveyContext from '../context';

const layoutOptions = [
    { _id: 0, label: trans('survey.list'), value: false },
    { _id: 1, label: trans('survey.grid'), value: true },
];

const QuestionLayoutSelect = ({ questionIndex }) => {
    const { formDisabled } = useContext(SurveyContext);

    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext();

    const fieldBasePath = `questions.${questionIndex}`;
    const errorsBasePath =
        errors?.questions && errors.questions[questionIndex] ? errors.questions[questionIndex] : null;
    return (
        <Controller
            control={control}
            name={`${fieldBasePath}.useGridLayout`}
            rules={{
                //deps validation image aspect ratio on change layout
                deps:
                    watch(fieldBasePath)?.questionType === 'imageChoiceList' ||
                    watch(fieldBasePath)?.questionType === 'multipleImageChoiceList'
                        ? watch(fieldBasePath)?.responses?.map(
                              (r, index) => `${fieldBasePath}.responses.${index}.image`,
                          )
                        : [],
            }}
            defaultValue={watch(`${fieldBasePath}.useGridLayout`) || false}
            shouldUnregister
            render={({ field }) => (
                <SelectInput
                    disabled={formDisabled}
                    label={trans('survey.responsesLayout')}
                    selectOptions={layoutOptions}
                    helperText={errorsBasePath && errorsBasePath?.useGridLayout?.message}
                    error={!!errorsBasePath?.useGridLayout}
                    {...field}
                />
            )}
        />
    );
};

export default QuestionLayoutSelect;
