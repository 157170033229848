import React, { useState } from "react";
import { Head, router } from "@inertiajs/react";
import { toast } from "react-toastify";
import { trans } from "matice";
import { Typography } from "@mui/material";
import SuccessModal from "~js/components/SuccessModal";
import ClusterForm from "./components/ClusterForm";
import ToolContentPageLayout from "~js/layouts/Tools/ToolContentPageLayout";
import type { BreadcrumbList } from "~js/components/Breadcrumbs";

const breadcrumbs: BreadcrumbList = [
    {
        routeName: 'Cluster.create',
        routeLabel: 'Crea Clusters',
    },
    {
        routeName: 'Cluster.index',
        routeLabel: 'Clusters',
    },
];

const ClusterCreate = ({ externalDataSources, triggers, missionTypes, can, ...other }) => {
    console.log('ClusterCreate props', {
        externalDataSources,
        triggers,
        can,
        ...other,
    });

    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const goToClusterListPage = () => router.get(route('Cluster.index'));

    const onSubmit = async (data) => {
        const dataToSend = { ...data };
        if (!data.triggers || data.triggers.length === 0) {
            dataToSend['triggers'] = [];
        }

        const promise = new Promise((resolve, reject) =>
            router.post(route('Cluster.store'), dataToSend, {
                onError: (e) => {
                    reject(e);
                    if (!!e?.generic) {
                        toast.error(e.generic);
                    }
                },
                onSuccess: () => {
                    resolve();
                    setShowModalSuccess(true);
                },
            }),
        );

        await promise;
    };

    return (
        <>
            <Head title={trans('cluster.createNewCluster')} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                    {trans('cluster.createNewCluster')}
                </Typography>
                <ClusterForm
                    triggers={triggers.map((t) => ({
                        _id: t._id,
                        label: t.name,
                        value: t._id,
                    }))}
                    externalDataSourceOption={externalDataSources?.map((ds) => ({
                        _id: ds._id,
                        label: ds.name,
                        value: ds._id,
                    }))}
                    missionTypes={missionTypes}
                    onCancelPress={goToClusterListPage}
                    onSubmit={onSubmit}
                    formDisable={!can.create && !can.createCsvCluster}
                    canCreateOnlyCsvCluster={can.createCsvCluster && !can.create}
                    canAssociateTriggerToSQLCluster={can?.associateTriggerToSQLCluster}
                />
            </ToolContentPageLayout>
            <SuccessModal
                open={showModalSuccess}
                handleClose={() => setShowModalSuccess(false)}
                title={trans('global.compliments')}
                description={trans('cluster.clusterCreatedSuccess')}
                buttonText={trans('cluster.backToClusterList')}
                onPressButton={goToClusterListPage}
                onExitPress={() => setShowModalSuccess(false)}
            />
        </>
    );
};

export default ClusterCreate;
