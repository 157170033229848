import React, { useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { trans, transChoice } from 'matice';
import SelectInput from '~js/components/SelectInput';
import { clientQuizStatus } from '~js/constants/missionStatuses';
import QuizContext, { QuizAudienceContext } from '~js/pages/backoffice/Tools/Quiz/context';

const SelectTestGroupForm = () => {
    const { currentClientQuizStatus, formDisabled } = useContext(QuizContext);
    const { testerGroups } = useContext(QuizAudienceContext);

    const {
        control,
        formState: { errors },
    } = useFormContext();

    const testGroupOptions = testerGroups.map((e) => ({
        _id: e._id,
        label: e.name,
        value: e._id,
    }));

    return (
        <Box
            width="100%"
            sx={{
                border: '1px solid #dfdfe4',
                borderRadius: 1,
                bgcolor: 'white',
            }}
        >
            <Box p={3}>
                <Typography width="100%" mb={2} fontWeight={'bold'}>
                    {transChoice('quiz.testGroups', 2)}
                </Typography>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px">
                            <Controller
                                control={control}
                                name={`groupTest`}
                                rules={{
                                    validate: (value) => {
                                        if (value.length === 0) {
                                            return trans('quiz.requiredAtLeastOne', {
                                                args: { elem: transChoice('quiz.testGroups', 1) },
                                            });
                                        }
                                        return true;
                                    },
                                }}
                                render={({ field }) => (
                                    <SelectInput
                                        disabled={
                                            formDisabled ||
                                            currentClientQuizStatus === clientQuizStatus.prodActive ||
                                            currentClientQuizStatus === clientQuizStatus.prodNotActive
                                        }
                                        multiple
                                        label={trans('quiz.testersLabel')}
                                        placeholderLabel={trans('quiz.testGroupsSelectPlaceholder')}
                                        selectOptions={testGroupOptions}
                                        helperText={errors?.groupTest?.message}
                                        error={!!errors?.groupTest}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default SelectTestGroupForm;
