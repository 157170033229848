import React, { useMemo, useState } from 'react';
import { trans } from 'matice';
import {
    Box,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    Typography,
    useTheme,
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InfoIcon from '@mui/icons-material/Info';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomTooltip from './CustomTooltip';
import useFileReader from '~js/hooks/useFileReader';
import axios from 'axios';
import { alpha } from '@mui/material/styles';

const FileUploaderInput = ({
    value,
    label = null,
    onChange,
    helperText,
    error,
    accept = '.png, .jpg, .jpeg',
    disabled = false,
    placeholder = null,
    tooltipText = null,
    validateFn = async () => true,
    multiple = false,
    numberOfFiles = undefined,
}) => {
    // drag state
    const [dragActive, setDragActive] = useState(false);
    const [uploading, setUploading] = useState(false);
    // ref
    const inputRef = React.useRef(null);
    const inputLabelRef = React.useRef(null);

    const { fileUrl } = useFileReader(value);
    const theme = useTheme();

    const inputHeight = useMemo(() => {
        if (fileUrl && typeof fileUrl === 'string' && fileUrl.includes('.mp4')) {
            return 300;
        }
        return 150;
    }, [fileUrl]);

    // handle drag events
    const handleDrag = function (e) {
        if (disabled) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            inputLabelRef.current.style.boxShadow = `${alpha(theme.palette.primary.main, 0.5)} 0 0 0 0.2rem`;
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
            inputLabelRef.current.style.boxShadow = `unset`;
        }
    };

    // triggers when file is dropped
    const handleDrop = async function (e) {
        if (disabled) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files) {
            if (numberOfFiles && e.dataTransfer.files.length > numberOfFiles) {
                alert(`Puoi caricare ancora al più ${numberOfFiles} file`);
                return;
            }
            // workaround need in order to work with datatransfer in async (different) call stacks
            const queue = [];
            for (const file of e.dataTransfer.files) {
                queue.push(
                    validateFn(file).then(async (res) => {
                        if (res === true) {
                            onChange(await uploadFile(file), file);
                        } else {
                            console.warn('validation failed, so file not uploaded.');
                        }
                    }),
                );
            }
            await Promise.all(queue);
        }
    };

    // triggers when file is selected with click
    const handleChange = async function (e) {
        if (disabled) {
            return;
        }
        e.preventDefault();

        const handle = async (file) => {
            const validationResult = await validateFn(file);
            if (validationResult) {
                onChange(await uploadFile(file), file);
            } else {
                console.warn('validation failed, so file not uploaded.', validationResult);
            }
        };

        inputLabelRef.current.style.boxShadow = `unset`;
        if (!multiple) {
            if (e.target.files && e.target.files[0] && typeof e.target.files[0] === 'object') {
                await handle(e.target.files[0]);
            }
        } else {
            if (numberOfFiles && e.target.files.length > numberOfFiles) {
                alert(`Puoi caricare ancora al più ${numberOfFiles} file`);
                return;
            }
            for (const file of e.target.files) {
                if (typeof file === 'object') {
                    await handle(file);
                }
            }
        }
    };

    const handleClick = (event) => {
        inputLabelRef.current.style.boxShadow = `${alpha(theme.palette.primary.main, 0.5)} 0 0 0 0.2rem`;
        event.target.value = null;
    };

    const uploadFile = async (file) => {
        setUploading(true);
        try {
            const formData = new FormData();
            formData.append('tmpFile', file);
            const {
                status,
                data: {
                    data: { url },
                },
            } = await axios.post(route('Quiz.storeTmp', [], false), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setUploading(false);
            return url;
        } catch (e) {
            setUploading(false);
            return null;
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = (e) => {
        if (disabled) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        inputRef.current.click();
    };

    const removeImage = (e) => {
        if (disabled) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        onChange('');
    };

    return (
        <Box my="12px">
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <InputLabel
                    shrink
                    sx={{
                        color: error ? '#F9473C' : '#5E6366',
                        pl: 1,
                        '&.Mui-focused': { color: error ? '#F9473C' : '#5E6366 !important' },
                    }}
                >
                    {label ?? ''}
                </InputLabel>
                {tooltipText && (
                    <CustomTooltip
                        title={
                            <>
                                {tooltipText}
                                <p>Formati accettati: {accept}</p>
                            </>
                        }
                        placement="bottom-start"
                        sx={{ zIndex: 99 }}
                    >
                        <InfoIcon sx={{ color: '#828282', zIndex: 99 }} />
                    </CustomTooltip>
                )}
            </Box>
            <FormControl variant="standard" fullWidth error={error}>
                <Box mb="24px" mt={'4px'} onDragEnter={handleDrag}>
                    <Box
                        disabled={disabled}
                        component="input"
                        ref={inputRef}
                        //workaround per selezionare la stessa immagine
                        onClick={handleClick}
                        type="file"
                        display="none"
                        onChange={handleChange}
                        accept={accept}
                        multiple={multiple}
                    />
                    <Box
                        ref={inputLabelRef}
                        component="label"
                        htmlFor="input-file-upload"
                        className={dragActive ? 'drag-active' : ''}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '1px solid #5E6366',
                            borderRadius: 1,
                            borderStyle: 'dashed',
                            height: `${inputHeight}px`,
                            overflow: 'hidden',
                            position: 'relative',
                            //borderColor: (theme) => (error ? theme.palette.error.main : '#5E6366'),
                            boxShadow: (theme) =>
                                error ? `${alpha(theme.palette.error.main, 0.5)} 0 0 0 0.2rem !important` : 'unset',
                        }}
                    >
                        {uploading ? (
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                <CircularProgress />
                                <Typography mt={2}>Caricamento del file in corso...</Typography>
                            </Box>
                        ) : (
                            <Box
                                onClick={onButtonClick}
                                sx={{ cursor: !disabled ? 'pointer' : 'not-allowed', overflow: 'hidden' }}
                                display="flex"
                                textAlign={'center'}
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                            >
                                {fileUrl ? (
                                    <>
                                        <Box
                                            component={fileUrl.includes('.mp4') ? 'video' : 'img'}
                                            src={fileUrl}
                                            width="100%"
                                            height={`${inputHeight}px`}
                                            sx={{ objectFit: 'contain' }}
                                            controls={true}
                                        />
                                        <IconButton
                                            onClick={removeImage}
                                            sx={{
                                                position: 'absolute',
                                                top: 10,
                                                right: 10,
                                                color: (theme) => theme.palette.primary.main,
                                                bgcolor: (theme) => theme.palette.primary.iconBackground,
                                                border: (theme) => `1px solid ${theme.palette.primary.main}`,
                                                '&:hover': {
                                                    border: (theme) => `1px solid ${theme.palette.primary.main}`,
                                                },
                                            }}
                                            disabled={disabled}
                                        >
                                            <DeleteOutlineOutlinedIcon />
                                        </IconButton>
                                    </>
                                ) : (
                                    <>
                                        <FileUploadIcon />
                                        {placeholder ? (
                                            typeof placeholder === 'string' ? (
                                                <Typography sx={{ textAlign: 'center' }}>{placeholder}</Typography>
                                            ) : (
                                                placeholder
                                            )
                                        ) : (
                                            <>
                                                <Typography
                                                    sx={{
                                                        color: (theme) =>
                                                            !disabled ? theme.palette.text.primary : '#828282',
                                                    }}
                                                >
                                                    {trans('quiz.fileUploaderInputDragPlaceholder')}
                                                </Typography>

                                                <Typography
                                                    sx={{
                                                        color: (theme) =>
                                                            !disabled ? theme.palette.primary.main : '#828282',
                                                    }}
                                                >
                                                    {' o ' + trans('quiz.fileUploaderInputPickPlaceholder')}
                                                </Typography>
                                            </>
                                        )}
                                    </>
                                )}
                            </Box>
                        )}
                    </Box>
                    {helperText && <FormHelperText sx={{ pl: 1 }}>{helperText}</FormHelperText>}
                    {dragActive && (
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '100%',
                                height: ' 100%',
                                borderRadius: '1rem',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                        />
                    )}
                </Box>
            </FormControl>
        </Box>
    );
};

export default FileUploaderInput;
