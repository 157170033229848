import React, { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, IconButton, Typography } from '@mui/material';
import { trans } from 'matice';

import CustomModal from '~js/components/CustomModal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { router } from '@inertiajs/react';
import { toast } from 'react-toastify';
import CtaButton from '~js/components/CtaButton';

const DeleteToolsUserModal = ({ open, handleClose, toolUser }) => {
    const [isLoading, setIsLoading] = useState(false);
    const deleteToolUser = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        router.delete(route('ToolsUsers.destroy', { user: toolUser.id }), {
            onSuccess: () => {
                console.log('success');
                toast.success(trans('settings.tools-users.delete-user-modal.success-message'));

                handleClose();
                setIsLoading(false);
            },
            onError: (e) => {
                toast.error(
                    !!e?.generic ? e.generic : trans('settings.tools-users.delete-user-modal.generic-error-message'),
                );
                setIsLoading(false);
            },
        });
    };

    return (
        <CustomModal
            open={open}
            onClose={handleClose}
            sx={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: '700px', borderRadius: 2.3 }}
        >
            <Box maxWidth="500px" width="100%">
                <Box display="flex" justifyContent="space-between" flexWrap="wrap" mb={4}>
                    <Box width={'52px'} />
                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                        <Box
                            borderRadius={'100%'}
                            width={50}
                            height={50}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{
                                backgroundColor: (theme) => theme.palette.primary.main,
                            }}
                        >
                            <DeleteForeverIcon htmlColor={'white'} />
                        </Box>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {trans(`settings.tools-users.delete-user-modal.title`)}
                        </Typography>
                    </Box>
                    <Box width={52} height={52}>
                        <IconButton onClick={handleClose}>
                            <CancelIcon color="primary" fontSize="large" />
                        </IconButton>
                    </Box>
                </Box>

                <Box display={'flex'} justifyContent={'center'}>
                    <Typography variant={'subtitle2'} mb={1}>
                        {trans('settings.tools-users.delete-user-modal.description', {
                            args: {
                                name: toolUser?.name,
                                surname: toolUser?.surname,
                            },
                        })}
                    </Typography>
                </Box>

                <Box
                    component={'form'}
                    onSubmit={deleteToolUser}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                    }}
                >
                    <CtaButton onClick={handleClose} variant="outlined" sx={{ mt: 2, px: 6 }} disabled={isLoading}>
                        {trans('global.cancel')}
                    </CtaButton>
                    <CtaButton type="submit" variant="contained" sx={{ mt: 2, ml: 2, px: 6 }} loading={isLoading}>
                        {trans('global.save')}
                    </CtaButton>
                </Box>
            </Box>
        </CustomModal>
    );
};

export default DeleteToolsUserModal;
