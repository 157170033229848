import React, { useContext, useMemo } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { trans, transChoice } from 'matice';
import { TextInput } from '~js/components/input';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';
import {
    ASPECT_RATIO_IMG,
    EIGHT_MB,
    getFileSizeUploadTooltip,
    getImageUploadTooltip,
    Images,
    ONE_MB,
} from '~js/constants/images.jsx';
import NewQuizActionButtons from '~js/pages/backoffice/Tools/Quiz/components/NewQuizActionButtons';
import PreviewStartScreen from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewStartScreen';
import PreviewTimeQuizIntroScreen from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewTimeQuizIntroScreen';
import PreviewQuizCard from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewQuizCard';
import PreviewDragWinFollowUpScreen from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewDragWinFollowUpScreen';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import FileUploaderInput from '~js/components/FileUploaderInput';
import { QUIZ_TYPOLOGIES, QUIZ_TYPOLOGIES_WITH_VIDEO } from '~js/constants/quiz';
import DragWinFollowUpFormControllers from '~js/pages/backoffice/Tools/Quiz/components/FormSteps/SubSteps/DragWinFollowUpFormControllers';
import TimeIntroFormControllers from '~js/pages/backoffice/Tools/Quiz/components/FormSteps/SubSteps/TimeIntroFormControllers';

const QuizStep1Introduction = ({
    handleStepNext,
    handleStepPrevious,
    stepFormValue,
    points = null,
    isLoading = false,
}) => {
    const {
        isEditMode,
        formDisabled,
        quizTitle,
        quizEndDate,
        quizTypology,
        quizQuestionsCount,
        currentClientQuizStatus,
    } = useContext(QuizContext);
    const theme = useTheme();
    const formMethods = useForm({
        defaultValues: stepFormValue,
    });
    const {
        handleSubmit,
        watch,
        setError,
        setValue,
        setFocus,
        clearErrors,
        formState: { errors },
        control,
    } = formMethods;

    const onSubmitHandler = async (data) => {
        const dataToSubmit = { introduction: data };
        handleStepNext(dataToSubmit);
    };

    const title = useMemo(() => {
        switch (quizTypology) {
            case QUIZ_TYPOLOGIES.classic:
            case QUIZ_TYPOLOGIES.time:
                return 'Più risposte esatte,\npiù punti per te!';
            case QUIZ_TYPOLOGIES.difference:
                return 'Trova le differenze';
            case QUIZ_TYPOLOGIES.memory:
                return 'Trova le coppie!';
            case QUIZ_TYPOLOGIES.drag:
                return 'Trascina e vinci!';
        }
    }, [quizTypology]);

    return (
        <FormProvider {...formMethods}>
            <Box mt={4}>
                <Box component="form" onSubmit={handleSubmit(onSubmitHandler)}>
                    {/* Initial screen */}
                    <Grid container columnSpacing={2} p={5} border={'1px solid #DFDFE4'} borderRadius={'12px'}>
                        <Grid item xs={12} mb={4}>
                            <Typography component={'h1'}>{trans('quiz.initialPage')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box maxWidth="500px">
                                {/* Name */}
                                <Controller
                                    control={control}
                                    name="name"
                                    defaultValue=""
                                    shouldUnregister
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        pattern: {
                                            value: REGEX_ONLY_PLAIN_CHAR,
                                            message: trans('formValidation.charNotAllowed'),
                                        },
                                        maxLength: {
                                            value: 40,
                                            message: trans('formValidation.fieldTooLong'),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            disabled={formDisabled}
                                            label={trans('quiz.name')}
                                            placeholder={trans('quiz.name')}
                                            helperText={
                                                errors?.name?.message ||
                                                trans('formValidation.charLimit', {
                                                    args: { chars: 40 },
                                                })
                                            }
                                            error={!!errors?.name}
                                            inputProps={{ maxLength: 40 }}
                                            {...field}
                                        />
                                    )}
                                />

                                {/* Title */}
                                <Controller
                                    control={control}
                                    name="title"
                                    defaultValue={title}
                                    shouldUnregister
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        pattern: {
                                            value: REGEX_ONLY_PLAIN_CHAR,
                                            message: trans('formValidation.charNotAllowed'),
                                        },
                                        maxLength: {
                                            value: 80,
                                            message: trans('formValidation.fieldTooLong'),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            disabled={formDisabled}
                                            label={trans('quiz.title')}
                                            placeholder={trans('quiz.title')}
                                            helperText={
                                                errors?.description?.message ||
                                                trans('formValidation.charLimit', {
                                                    args: { chars: 80 },
                                                })
                                            }
                                            inputProps={{ maxLength: 80 }}
                                            error={!!errors?.title}
                                            {...field}
                                        />
                                    )}
                                />

                                {/* Description */}
                                <Controller
                                    control={control}
                                    name="description"
                                    shouldUnregister
                                    rules={{
                                        //required: trans('formValidation.requiredField'),
                                        pattern: {
                                            value: REGEX_ONLY_PLAIN_CHAR,
                                            message: trans('formValidation.charNotAllowed'),
                                        },
                                        maxLength: {
                                            value: 80,
                                            message: trans('formValidation.fieldTooLong'),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            multiline
                                            rows={6}
                                            disabled={formDisabled}
                                            label={trans('quiz.description')}
                                            placeholder={trans('quiz.description')}
                                            helperText={
                                                errors?.description?.message ||
                                                trans('formValidation.charLimit', {
                                                    args: { chars: 80 },
                                                })
                                            }
                                            inputProps={{ maxLength: 80 }}
                                            error={!!errors?.description}
                                            {...field}
                                        />
                                    )}
                                />

                                {/* Sub Description */}
                                <Controller
                                    control={control}
                                    name="subDescription"
                                    defaultValue=""
                                    shouldUnregister
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        pattern: {
                                            value: REGEX_ONLY_PLAIN_CHAR,
                                            message: trans('formValidation.charNotAllowed'),
                                        },
                                        maxLength: {
                                            value: 40,
                                            message: trans('formValidation.fieldTooLong'),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            disabled={formDisabled}
                                            label={trans('quiz.subDescription')}
                                            placeholder={trans('quiz.subDescription')}
                                            helperText={
                                                trans('formValidation.charLimit', {
                                                    args: { chars: 40 },
                                                }) || errors?.subDescription?.message
                                            }
                                            inputProps={{ maxLength: 40 }}
                                            error={!!errors?.subDescription}
                                            {...field}
                                        />
                                    )}
                                />

                                {/* Start Image */}
                                <Controller
                                    control={control}
                                    name="image"
                                    defaultValue=""
                                    shouldUnregister
                                    rules={{ required: trans('formValidation.requiredField') }}
                                    render={({ field: { onChange, value } }) => (
                                        <FileUploaderInput
                                            disabled={formDisabled}
                                            value={value}
                                            label={trans('quiz.image')}
                                            onChange={onChange}
                                            tooltipText={getImageUploadTooltip(Images.QUIZ_CLASSIC_START_BG, ONE_MB)}
                                            helperText={
                                                errors?.image?.message ||
                                                trans('formValidation.maxMbFile', {
                                                    args: { mb: (ONE_MB / ONE_MB).toFixed(1) },
                                                })
                                            }
                                            validateFn={async (value) => {
                                                if (!value || typeof value !== 'object') {
                                                    clearErrors('image');
                                                    return true;
                                                }
                                                return await new Promise((resolve) => {
                                                    let img = new Image();
                                                    img.src = window.URL.createObjectURL(value);
                                                    img.onload = () => {
                                                        const aspectRatioImgUp = (img.width / img.height).toFixed(1);
                                                        const aspectRatioRule =
                                                            ASPECT_RATIO_IMG.QUIZ_CLASSIC_START_BG.toFixed(1);
                                                        if (aspectRatioImgUp !== aspectRatioRule) {
                                                            setError('image', {
                                                                type: 'custom',
                                                                message: trans('formValidation.aspectRatioError', {
                                                                    args: {
                                                                        aspectRatio: aspectRatioRule,
                                                                    },
                                                                }),
                                                            });
                                                            resolve(false);
                                                        } else {
                                                            if (value.size > ONE_MB) {
                                                                setError('image', {
                                                                    type: 'custom',
                                                                    message: trans('formValidation.maxMbFileError', {
                                                                        args: {
                                                                            mb: (ONE_MB / ONE_MB).toFixed(1),
                                                                            uploadedSize: (value.size / ONE_MB).toFixed(
                                                                                2,
                                                                            ),
                                                                        },
                                                                    }),
                                                                });
                                                                resolve(false);
                                                            } else {
                                                                clearErrors('image');
                                                                resolve(true);
                                                            }
                                                        }
                                                    };
                                                });
                                            }}
                                            error={!!errors?.image}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box
                                borderRadius={'12px'}
                                bgcolor={theme.palette.background.default}
                                p={4}
                                display={'flex'}
                                justifyContent={'center'}
                            >
                                <PreviewStartScreen
                                    headerTitle={watch('name') ?? ''}
                                    headerSubTitle={transChoice(
                                        'quiz.quizQuestionsCountLabel',
                                        quizQuestionsCount ?? 4,
                                    )}
                                    endDate={quizEndDate}
                                    title={watch('title') ?? title}
                                    description={watch('description')}
                                    subDescription={watch('subDescription')}
                                    footerDescription={'Hai una sola possibilità'}
                                    ctaText={'Iniziamo!'}
                                    points={points}
                                    image={watch('image')}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Video screen */}
                    {QUIZ_TYPOLOGIES_WITH_VIDEO.includes(quizTypology) && (
                        <Grid
                            container
                            columnSpacing={2}
                            p={5}
                            mt={4}
                            border={'1px solid #DFDFE4'}
                            borderRadius={'12px'}
                        >
                            <Grid item xs={12} mb={4}>
                                <Typography component={'h1'}>{trans('quiz.initialPageVideoScreen')}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box maxWidth="500px">
                                    <Controller
                                        control={control}
                                        name="video"
                                        defaultValue=""
                                        shouldUnregister
                                        rules={{ required: trans('formValidation.requiredField') }}
                                        render={({ field: { onChange, value } }) => (
                                            <FileUploaderInput
                                                value={value}
                                                accept=".mp4, .wmv"
                                                label={trans('quiz.video')}
                                                tooltipText={getFileSizeUploadTooltip(EIGHT_MB)}
                                                placeholder={
                                                    <>
                                                        {trans('quiz.fileUploaderInputDragVideoPlaceholder')}
                                                        <Typography
                                                            sx={{ color: (theme) => theme.palette.primary.main }}
                                                        >
                                                            {' o ' + trans('quiz.fileUploaderInputPickPlaceholder')}
                                                        </Typography>
                                                    </>
                                                }
                                                onChange={onChange}
                                                helperText={
                                                    errors?.video?.message ||
                                                    trans('formValidation.maxMbFile', {
                                                        args: { mb: (EIGHT_MB / ONE_MB).toFixed(1) },
                                                    })
                                                }
                                                validateFn={async (value) => {
                                                    if (!value || typeof value !== 'object') {
                                                        clearErrors('video');
                                                        return true;
                                                    }
                                                    return await new Promise((resolve) => {
                                                        if (value.size > EIGHT_MB) {
                                                            setError('video', {
                                                                type: 'custom',
                                                                message: trans('formValidation.maxMbFileError', {
                                                                    args: {
                                                                        mb: (EIGHT_MB / ONE_MB).toFixed(1),
                                                                        uploadedSize: (value.size / ONE_MB).toFixed(2),
                                                                    },
                                                                }),
                                                            });
                                                            resolve(false);
                                                        } else {
                                                            clearErrors('video');
                                                            resolve(true);
                                                        }
                                                    });
                                                }}
                                                error={!!errors?.video}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box maxWidth="500px">
                                    <Controller
                                        control={control}
                                        name="videoPreview"
                                        defaultValue=""
                                        shouldUnregister
                                        rules={{ required: trans('formValidation.requiredField') }}
                                        render={({ field: { onChange, value } }) => (
                                            <FileUploaderInput
                                                disabled={formDisabled}
                                                value={value}
                                                label={trans('quiz.videoPreview')}
                                                tooltipText={getImageUploadTooltip(
                                                    Images.QUIZ_CLASSIC_PREVIEW_BG,
                                                    ONE_MB,
                                                )}
                                                placeholder={
                                                    <>
                                                        {trans('quiz.fileUploaderInputDragVideoPreviewPlaceholder')}
                                                        <Typography
                                                            sx={{ color: (theme) => theme.palette.primary.main }}
                                                        >
                                                            {' o ' + trans('quiz.fileUploaderInputPickPlaceholder')}
                                                        </Typography>
                                                    </>
                                                }
                                                onChange={onChange}
                                                helperText={
                                                    errors?.videoPreview?.message ||
                                                    trans('formValidation.maxMbFile', {
                                                        args: {
                                                            mb: (ONE_MB / ONE_MB).toFixed(1),
                                                        },
                                                    })
                                                }
                                                validateFn={async (value) => {
                                                    if (!value || typeof value !== 'object') {
                                                        clearErrors('videoPreview');
                                                        return true;
                                                    }
                                                    return await new Promise((resolve) => {
                                                        let img = new Image();
                                                        img.src = window.URL.createObjectURL(value);
                                                        img.onload = () => {
                                                            const aspectRatioImgUp = (img.width / img.height).toFixed(
                                                                1,
                                                            );
                                                            const aspectRatioRule =
                                                                ASPECT_RATIO_IMG.QUIZ_CLASSIC_PREVIEW_BG.toFixed(1);
                                                            if (aspectRatioImgUp !== aspectRatioRule) {
                                                                setError('videoPreview', {
                                                                    type: 'custom',
                                                                    message: trans('formValidation.aspectRatioError', {
                                                                        args: {
                                                                            aspectRatio: aspectRatioRule,
                                                                        },
                                                                    }),
                                                                });
                                                                resolve(false);
                                                            } else {
                                                                if (value.size > ONE_MB) {
                                                                    setError('videoPreview', {
                                                                        type: 'custom',
                                                                        message: trans(
                                                                            'formValidation.maxMbFileError',
                                                                            {
                                                                                args: {
                                                                                    mb: (ONE_MB / ONE_MB).toFixed(1),
                                                                                    uploadedSize: (
                                                                                        value.size / ONE_MB
                                                                                    ).toFixed(2),
                                                                                },
                                                                            },
                                                                        ),
                                                                    });
                                                                    resolve(false);
                                                                } else {
                                                                    clearErrors('videoPreview');
                                                                    resolve(true);
                                                                }
                                                            }
                                                        };
                                                    });
                                                }}
                                                error={!!errors?.videoPreview}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    )}

                    {/* Timed Quiz Intro screen */}
                    {quizTypology === QUIZ_TYPOLOGIES.time && (
                        <Grid
                            container
                            columnSpacing={2}
                            p={5}
                            mt={4}
                            border={'1px solid #DFDFE4'}
                            borderRadius={'12px'}
                        >
                            <Grid item xs={12} mb={4}>
                                <Typography component={'h1'}>{trans('quiz.initialPageIntroPreview')}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box maxWidth="500px">
                                    <TimeIntroFormControllers />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box
                                    maxWidth="500px"
                                    borderRadius={'12px'}
                                    bgcolor={theme.palette.background.default}
                                    p={4}
                                    display={'flex'}
                                    justifyContent={'center'}
                                >
                                    <PreviewTimeQuizIntroScreen
                                        headerTitle={watch('name')}
                                        endDate={quizEndDate}
                                        title={watch('time.introTitle')}
                                        description={watch('time.introDescription')}
                                        image={watch('time.introImage')}
                                        ctaText={watch('time.introCtaText')}
                                        points={watch('time.responsePoints')}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                    {/* Card Preview */}
                    <Grid container columnSpacing={2} p={5} mt={4} border={'1px solid #DFDFE4'} borderRadius={'12px'}>
                        <Grid item xs={12} mb={4}>
                            <Typography component={'h1'}>{trans('quiz.initialPageCardPreview')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box maxWidth="500px">
                                <Controller
                                    control={control}
                                    name="cardImg"
                                    defaultValue=""
                                    shouldUnregister
                                    rules={{ required: trans('formValidation.requiredField') }}
                                    render={({ field: { onChange, value } }) => (
                                        <FileUploaderInput
                                            disabled={formDisabled}
                                            value={value}
                                            label={trans('quiz.image')}
                                            tooltipText={getImageUploadTooltip(Images.QUIZ_CARD_ICON, ONE_MB)}
                                            onChange={onChange}
                                            helperText={
                                                errors?.cardImg?.message ??
                                                trans('formValidation.maxMbFile', {
                                                    args: { mb: (ONE_MB / ONE_MB).toFixed(1) },
                                                })
                                            }
                                            validateFn={async (value) => {
                                                if (!value || typeof value !== 'object') {
                                                    clearErrors('cardImg');
                                                    return true;
                                                }
                                                return await new Promise((resolve) => {
                                                    let img = new Image();
                                                    img.src = window.URL.createObjectURL(value);
                                                    img.onload = () => {
                                                        const aspectRatioImgUp = (img.width / img.height).toFixed(1);
                                                        const aspectRatioRule =
                                                            ASPECT_RATIO_IMG.QUIZ_CARD_ICON.toFixed(1);
                                                        if (aspectRatioImgUp !== aspectRatioRule) {
                                                            setError('cardImg', {
                                                                type: 'custom',
                                                                message: trans('formValidation.aspectRatioError', {
                                                                    args: {
                                                                        aspectRatio: aspectRatioRule,
                                                                    },
                                                                }),
                                                            });
                                                            resolve(false);
                                                        } else {
                                                            if (value.size > ONE_MB) {
                                                                setError('cardImg', {
                                                                    type: 'custom',
                                                                    message: trans('formValidation.maxMbFileError', {
                                                                        args: {
                                                                            mb: (ONE_MB / ONE_MB).toFixed(1),
                                                                            uploadedSize: (value.size / ONE_MB).toFixed(
                                                                                2,
                                                                            ),
                                                                        },
                                                                    }),
                                                                });
                                                                resolve(false);
                                                            } else {
                                                                clearErrors('cardImg');
                                                                resolve(true);
                                                            }
                                                        }
                                                    };
                                                });
                                            }}
                                            error={!!errors?.cardImg}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box
                                maxWidth="500px"
                                borderRadius={'12px'}
                                bgcolor={theme.palette.background.default}
                                py={5}
                                display={'flex'}
                                justifyContent={'center'}
                            >
                                <PreviewQuizCard
                                    endDate={quizEndDate}
                                    title={watch('name')}
                                    points={points}
                                    image={watch('cardImg')}
                                />
                            </Box>
                        </Grid>

                        {/* DragWin Quiz FollowUp screen */}
                        {quizTypology === QUIZ_TYPOLOGIES.drag && (
                            <Grid
                                container
                                columnSpacing={2}
                                p={5}
                                mt={4}
                                border={'1px solid #DFDFE4'}
                                borderRadius={'12px'}
                            >
                                <Grid item xs={12} mb={4}>
                                    <Typography component={'h1'}>{trans('quiz.initialPageFollowUp')}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box maxWidth="500px">
                                        <DragWinFollowUpFormControllers />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box
                                        maxWidth="500px"
                                        borderRadius={'12px'}
                                        bgcolor={theme.palette.background.default}
                                        p={4}
                                        display={'flex'}
                                        justifyContent={'center'}
                                    >
                                        <PreviewDragWinFollowUpScreen
                                            title={watch('followUp.title')}
                                            images={(watch('followUp.correctProducts') ?? []).map(
                                                (product, index) => product.image,
                                            )}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>

                    {!formDisabled && (
                        <NewQuizActionButtons
                            isLoading={isLoading}
                            onLeftButtonClick={handleStepPrevious}
                            rightButtonText={isEditMode ? trans('global.save') : trans('global.next')}
                            leftButtonText={trans('global.back')}
                        />
                    )}
                </Box>
            </Box>
        </FormProvider>
    );
};

export default QuizStep1Introduction;
