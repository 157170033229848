import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: '#8B8D97',
            //boxShadow: theme.shadows[1],
            fontSize: '15px',
            lineHeight: '18px',
            border: '1px solid #D9D9D9',
            borderRadius: '0px 12px 12px 12px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        },
    }),
);

export default CustomTooltip;
