import React, { useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import SelectInput from '~js/components/SelectInput';
import { clientSurveyStatus } from '~js/constants/missionStatuses';
import SurveyContext from '../context';

const SelectTestGroupForm = ({ testerGroups }) => {
    const { currentClientSurveyStatus, formDisabled } = useContext(SurveyContext);

    const {
        control,
        formState: { errors },
    } = useFormContext();

    const groupTestOptions = testerGroups.map((e) => ({
        _id: e._id,
        label: e.name,
        value: e._id,
    }));

    return (
        <Box
            width="100%"
            sx={{
                border: '1px solid #dfdfe4',
                borderRadius: 1,
                bgcolor: 'white',
                mt: 5,
            }}
        >
            <Box p={3}>
                <Typography width="100%" mb={1}>
                    Test
                </Typography>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px">
                            <Controller
                                control={control}
                                name={`groupTest`}
                                render={({ field }) => (
                                    <SelectInput
                                        disabled={
                                            formDisabled ||
                                            currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                            currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                        }
                                        multiple
                                        label={trans('survey.selectGroupTest')}
                                        selectOptions={groupTestOptions}
                                        helperText={errors?.groupTest?.message}
                                        error={!!errors?.groupTest}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default SelectTestGroupForm;
