import React, { useMemo } from 'react';
import { Box, ThemeProvider, Typography } from '@mui/material';
import useFileReader from '~js/hooks/useFileReader';
import PreviewQuizHeader from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewQuizHeader';
import PreviewCtaButton from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewCtaButton';
import { parseJsonToTextMap, parseTextMapToMuiText } from '~js/pages/backoffice/Tools/Quiz/components/LexicalEditor';
import previewStartImageDefault from '~/assets/images/tools/quizTool/previewStartImageDefault.svg';
import pampersTheme from '~js/pages/backoffice/Tools/Quiz/components/Previews/previewTheme';

const PreviewInDepthScreen = ({
    headerTitle = 'Quiz',
    headerSubTitle,
    endDate,
    title,
    description,
    ctaText = 'Procedi',
    image,
}) => {
    const { fileUrl: imgUrl } = useFileReader(image);
    const textMap = useMemo(() => parseJsonToTextMap(JSON.parse(description ?? '{}')) ?? [], [description]);
    return (
        <ThemeProvider theme={pampersTheme}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '236px',
                    height: '512px',
                    borderRadius: '22px',
                    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.25)',
                    position: 'relative',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                {/* Quiz Screen Header content */}
                <PreviewQuizHeader title={headerTitle} subTitle={headerSubTitle} endDate={endDate} />

                {/* Quiz In Depth Screen Body */}
                <Box
                    position={'relative'}
                    overflow={'hidden'}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    bgcolor={'#FFFFFF'}
                    width={'100%'}
                    height={'100%'}
                >
                    <Box
                        component="img"
                        src={imgUrl ?? previewStartImageDefault}
                        sx={{
                            position: 'absolute',
                            objectFit: 'cover',
                            objectPosition: 'top',
                            width: '100%',
                            height: '100%',
                            zIndex: 0,
                            filter: imgUrl ? 'none' : 'grayscale(100%);',
                        }}
                    />
                    <Box
                        px="10px"
                        height={'100%'}
                        sx={{
                            zIndex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'end',
                        }}
                    >
                        <Box flex={1}></Box>
                        <Box
                            flex={1}
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            mb={2}
                            px={2}
                        >
                            <Box flex={1} justifyContent={'center'} textAlign={'center'} pt="40px">
                                <Typography
                                    mb={2}
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                        lineHeight: '16px',
                                        color: '#354953',
                                        textAlign: 'center',
                                    }}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    component={'p'}
                                    sx={{
                                        fontSize: '10px',
                                        lineHeight: '12px',
                                        color: '#354953',
                                        mb: 1,
                                    }}
                                >
                                    {parseTextMapToMuiText(textMap)}
                                </Typography>
                            </Box>

                            <Box flex={1} display={'flex'} flexDirection={'column'} justifyContent={'end'}>
                                {/*
                                    <Typography
                                    sx={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        fontSize: '8px',
                                        lineHeight: '12px',
                                        marginBottom: '10px',
                                        color: '#354953',
                                    }}
                                >
                                    Video pubblicitario. Vuoi guardarlo e rispondere alle domande? Hai solo una
                                    possibilità!
                                </Typography>
                                */}

                                <PreviewCtaButton ctaText={ctaText} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PreviewInDepthScreen;
