import React, { useContext } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { trans, transChoice } from 'matice';
import { SelectInput, TextInput } from '~js/components/input';
import { REGEX_ONLY_PLAIN_CHAR, REGEX_URL } from '~js/constants/textValidation';
import { ASPECT_RATIO_IMG, getImageUploadTooltip, Images, ONE_MB } from '~js/constants/images.jsx';
import NewQuizActionButtons from '~js/pages/backoffice/Tools/Quiz/components/NewQuizActionButtons';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import FileUploaderInput from '~js/components/FileUploaderInput';
import LexicalTextEditor, { parseJsonToTextMap } from '~js/pages/backoffice/Tools/Quiz/components/LexicalEditor';
import PreviewFinishScreen from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewFinishScreen';
import PreviewClassicInDepthScreen from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewClassicInDepthScreen';
import PreviewInDepthScreen from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewInDepthScreen';
import { QUIZ_TYPOLOGIES } from '~js/constants/quiz';

const QuizStep3Closure = ({ handleStepNext, handleStepPrevious, stepFormValue, points = null, isLoading = false }) => {
    const { isEditMode, formDisabled, quizTitle, quizEndDate, quizTypology, quizQuestionsCount } =
        useContext(QuizContext);
    const theme = useTheme();

    const defaultValue = {};

    const {
        handleSubmit,
        watch,
        setError,
        clearErrors,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: !!stepFormValue ? stepFormValue : defaultValue,
    });

    const onSubmitHandler = async (data) => {
        const dataToSubmit = { closure: data };
        handleStepNext(dataToSubmit);
    };
    return (
        <Box mt={4}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* InDepth screen */}
                <Grid container columnSpacing={2} p={5} border={'1px solid #DFDFE4'} borderRadius={'12px'}>
                    <Grid item xs={12} mb={4}>
                        <Typography component={'h1'}>{trans('quiz.inDepthPage')}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px">
                            {/* In Depth Title */}
                            <Controller
                                control={control}
                                name="inDepth.title"
                                shouldUnregister
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                    pattern: {
                                        value: REGEX_ONLY_PLAIN_CHAR,
                                        message: trans('formValidation.charNotAllowed'),
                                    },
                                    maxLength: {
                                        value: 60,
                                        message: trans('formValidation.fieldTooLong'),
                                    },
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        disabled={formDisabled}
                                        label={trans('quiz.title')}
                                        placeholder={trans('quiz.title')}
                                        helperText={errors?.inDepth?.title?.message}
                                        inputProps={{ maxLength: 60 }}
                                        error={!!errors?.inDepth?.title}
                                        {...field}
                                    />
                                )}
                            />

                            {/* In Depth Description */}
                            {quizTypology === QUIZ_TYPOLOGIES.classic ? (
                                <Controller
                                    control={control}
                                    name="inDepth.description"
                                    shouldUnregister
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        pattern: {
                                            value: REGEX_ONLY_PLAIN_CHAR,
                                            message: trans('formValidation.charNotAllowed'),
                                        },
                                        maxLength: {
                                            value: 200,
                                            message: trans('formValidation.fieldTooLong'),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            multiline
                                            rows={6}
                                            disabled={formDisabled}
                                            label={trans('quiz.description')}
                                            placeholder={trans('quiz.description')}
                                            helperText={errors?.inDepth?.description?.message}
                                            inputProps={{ maxLength: 200 }}
                                            error={!!errors?.inDepth?.description}
                                            {...field}
                                        />
                                    )}
                                />
                            ) : (
                                <Controller
                                    control={control}
                                    name="inDepth.description"
                                    rules={{
                                        validate: (value) => {
                                            if (!value) {
                                                return trans('formValidation.requiredField');
                                            }
                                            return parseJsonToTextMap(JSON.parse(value)).length > 0
                                                ? true
                                                : 'textmap assente';
                                        },
                                    }}
                                    render={({ field }) => (
                                        <LexicalTextEditor
                                            disabled={formDisabled}
                                            label={trans('quiz.description')}
                                            helperText={errors?.inDepth?.description?.message}
                                            inputProps={{ maxLength: 400 }}
                                            error={!!errors?.inDepth?.description}
                                            {...field}
                                        />
                                    )}
                                />
                            )}

                            {/* In Depth Image */}
                            <Controller
                                control={control}
                                name="inDepth.image"
                                defaultValue=""
                                shouldUnregister
                                rules={{ required: trans('formValidation.requiredField') }}
                                render={({ field: { onChange, value } }) => (
                                    <FileUploaderInput
                                        disabled={formDisabled}
                                        value={value}
                                        label={trans('quiz.image')}
                                        tooltipText={getImageUploadTooltip(Images.QUIZ_CLASSIC_END_BG, ONE_MB)}
                                        onChange={onChange}
                                        helperText={
                                            errors?.inDepth?.image?.message ??
                                            trans('formValidation.maxMbFile', {
                                                args: { mb: (ONE_MB / ONE_MB).toFixed(1) },
                                            })
                                        }
                                        validateFn={async (value) => {
                                            if (!value || typeof value !== 'object') {
                                                clearErrors('inDepth.image');
                                                return true;
                                            }
                                            return await new Promise((resolve) => {
                                                let img = new Image();
                                                img.src = window.URL.createObjectURL(value);
                                                img.onload = () => {
                                                    const aspectRatioImgUp = (img.width / img.height).toFixed(1);
                                                    const aspectRatioRule =
                                                        ASPECT_RATIO_IMG.QUIZ_CLASSIC_END_BG.toFixed(1);
                                                    if (aspectRatioImgUp !== aspectRatioRule) {
                                                        setError('inDepth.image', {
                                                            type: 'custom',
                                                            message: trans('formValidation.aspectRatioError', {
                                                                args: {
                                                                    aspectRatio: aspectRatioRule,
                                                                },
                                                            }),
                                                        });
                                                        resolve(false);
                                                    } else {
                                                        if (value.size > ONE_MB) {
                                                            setError('inDepth.image', {
                                                                type: 'custom',
                                                                message: trans('formValidation.maxMbFileError', {
                                                                    args: {
                                                                        mb: (ONE_MB / ONE_MB).toFixed(1),
                                                                        uploadedSize: (value.size / ONE_MB).toFixed(2),
                                                                    },
                                                                }),
                                                            });
                                                            resolve(false);
                                                        } else {
                                                            clearErrors('inDepth.image');
                                                            resolve(true);
                                                        }
                                                    }
                                                };
                                            });
                                        }}
                                        error={!!errors?.inDepth?.image}
                                    />
                                )}
                            />

                            {/* In Depth CTA Text */}
                            {[QUIZ_TYPOLOGIES.classic, QUIZ_TYPOLOGIES.time].includes(quizTypology) && (
                                <Controller
                                    control={control}
                                    name="inDepth.ctaText"
                                    shouldUnregister
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        pattern: {
                                            value: REGEX_ONLY_PLAIN_CHAR,
                                            message: trans('formValidation.charNotAllowed'),
                                        },
                                        maxLength: {
                                            value: 35,
                                            message: trans('formValidation.fieldTooLong'),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            disabled={formDisabled}
                                            label={trans('quiz.ctaText')}
                                            placeholder={trans('quiz.ctaText')}
                                            helperText={errors?.inDepth?.ctaText?.message}
                                            inputProps={{ maxLength: 35 }}
                                            error={!!errors?.inDepth?.ctaText}
                                            {...field}
                                        />
                                    )}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box
                            borderRadius={'12px'}
                            bgcolor={theme.palette.background.default}
                            p={4}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            {quizTypology === QUIZ_TYPOLOGIES.classic ? (
                                <PreviewClassicInDepthScreen
                                    headerTitle={quizTitle}
                                    headerSubTitle={transChoice(
                                        'quiz.quizQuestionsCountLabel',
                                        quizQuestionsCount ?? 4,
                                    )}
                                    endDate={quizEndDate}
                                    title={watch('inDepth.title')}
                                    description={watch('inDepth.description')}
                                    ctaText={watch('inDepth.ctaText')}
                                    image={watch('inDepth.image')}
                                />
                            ) : (
                                <PreviewInDepthScreen
                                    headerTitle={quizTitle}
                                    endDate={quizEndDate}
                                    title={watch('inDepth.title')}
                                    description={watch('inDepth.description')}
                                    ctaText={watch('inDepth.ctaText')}
                                    image={watch('inDepth.image')}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>

                {/* Finish screen */}
                <Grid container columnSpacing={2} p={5} mt={4} border={'1px solid #DFDFE4'} borderRadius={'12px'}>
                    <Grid item xs={12} mb={4}>
                        <Typography component={'h1'}>{trans('quiz.finishPage')}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px">
                            {/* Finish Title
                            <Controller
                                control={control}
                                name="finish.title"
                                shouldUnregister
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                    pattern: {
                                        value: REGEX_ONLY_PLAIN_CHAR,
                                        message: trans('formValidation.charNotAllowed'),
                                    },
                                    maxLength: {
                                        value: 200,
                                        message: trans('formValidation.fieldTooLong'),
                                    },
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        disabled={formDisabled}
                                        label={trans('quiz.title')}
                                        placeholder={trans('quiz.title')}
                                        helperText={errors?.finish?.title?.message}
                                        error={!!errors?.finish?.title}
                                        {...field}
                                    />
                                )}
                            />
                            */}

                            {/* Finish CTA Text */}
                            <Controller
                                control={control}
                                name="finish.ctaText"
                                shouldUnregister
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                    pattern: {
                                        value: REGEX_ONLY_PLAIN_CHAR,
                                        message: trans('formValidation.charNotAllowed'),
                                    },
                                    maxLength: {
                                        value: 35,
                                        message: trans('formValidation.fieldTooLong'),
                                    },
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        disabled={formDisabled}
                                        label={trans('quiz.ctaText')}
                                        placeholder={trans('quiz.ctaText')}
                                        helperText={errors?.finish?.ctaText?.message}
                                        inputProps={{ maxLength: 35 }}
                                        error={!!errors?.finish?.ctaText}
                                        {...field}
                                    />
                                )}
                            />

                            {/* Finish Deeplink type */}
                            <Controller
                                control={control}
                                name="finish.ctaAction"
                                defaultValue={[]}
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                }}
                                render={({ field }) => (
                                    <SelectInput
                                        disabled={formDisabled}
                                        label={trans('quiz.ctaAction')}
                                        selectOptions={[
                                            { _id: 1, label: trans('quiz.ctaActions.deeplink'), value: 'openDeeplink' },
                                            { _id: 2, label: trans('quiz.ctaActions.url'), value: 'openUrl' },
                                            {
                                                _id: 3,
                                                label: trans('quiz.ctaActions.webviewUrl'),
                                                value: 'openWebview',
                                            },
                                        ]}
                                        helperText={errors?.finish?.ctaAction?.message}
                                        error={!!errors?.finish?.ctaAction}
                                        {...field}
                                    />
                                )}
                            />

                            {/* Finish Cta Actions */}
                            {watch('finish.ctaAction') === 'openDeeplink' && (
                                <Controller
                                    control={control}
                                    name="finish.deeplink"
                                    shouldUnregister
                                    defaultValue=""
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        // pattern: {
                                        //     value: REGEX_ONLY_PLAIN_CHAR,
                                        //     message: trans('formValidation.charNotAllowed'),
                                        // },
                                        maxLength: {
                                            value: 200,
                                            message: trans('formValidation.fieldTooLong'),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            disabled={formDisabled}
                                            label={trans('quiz.deeplink') + ' URL'}
                                            placeholder={trans('quiz.deeplink')}
                                            helperText={errors?.finish?.deeplink?.message}
                                            error={!!errors?.finish?.deeplink}
                                            {...field}
                                        />
                                    )}
                                />
                            )}

                            {['openWebview', 'openUrl'].includes(watch('finish.ctaAction')) && (
                                <Controller
                                    control={control}
                                    name="finish.url"
                                    shouldUnregister
                                    defaultValue=""
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        pattern: {
                                            value: REGEX_URL,
                                            message: trans('formValidation.urlNotValid'),
                                        },
                                        maxLength: {
                                            value: 200,
                                            message: trans('formValidation.fieldTooLong'),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            disabled={formDisabled}
                                            label={
                                                watch('finish.ctaAction') === 'openUrl'
                                                    ? trans('quiz.ctaActions.url') + ' URL'
                                                    : trans('quiz.ctaActions.webviewUrl')
                                            }
                                            placeholder={
                                                watch('finish.ctaAction') === 'openUrl'
                                                    ? trans('quiz.ctaActions.url')
                                                    : trans('quiz.ctaActions.webviewUrl')
                                            }
                                            helperText={errors?.finish?.url?.message}
                                            error={!!errors?.finish?.url}
                                            {...field}
                                        />
                                    )}
                                />
                            )}

                            {watch('finish.ctaAction') === 'openWebview' && (
                                <Controller
                                    control={control}
                                    name="finish.webviewTitle"
                                    shouldUnregister
                                    defaultValue=""
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        pattern: {
                                            value: REGEX_ONLY_PLAIN_CHAR,
                                            message: trans('formValidation.charNotAllowed'),
                                        },
                                        maxLength: {
                                            value: 200,
                                            message: trans('formValidation.fieldTooLong'),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            disabled={formDisabled}
                                            label={trans('quiz.webviewTitle')}
                                            placeholder={trans('quiz.webviewTitlePlc')}
                                            helperText={errors?.finish?.webviewTitle?.message}
                                            inputProps={{ maxLength: 200 }}
                                            error={!!errors?.finish?.webviewTitle}
                                            {...field}
                                        />
                                    )}
                                />
                            )}

                            {/* Finish Image */}
                            <Controller
                                control={control}
                                name="finish.image"
                                defaultValue=""
                                shouldUnregister
                                rules={{ required: trans('formValidation.requiredField') }}
                                render={({ field: { onChange, value } }) => (
                                    <FileUploaderInput
                                        disabled={formDisabled}
                                        value={value}
                                        label={trans('quiz.image')}
                                        tooltipText={getImageUploadTooltip(Images.QUIZ_CLASSIC_END_BG, ONE_MB)}
                                        onChange={onChange}
                                        helperText={
                                            errors?.finish?.image?.message ??
                                            trans('formValidation.maxMbFile', {
                                                args: { mb: (ONE_MB / ONE_MB).toFixed(1) },
                                            })
                                        }
                                        validateFn={async (value) => {
                                            if (!value || typeof value !== 'object') {
                                                clearErrors('finish.image');
                                                return true;
                                            }
                                            return await new Promise((resolve) => {
                                                let img = new Image();
                                                img.src = window.URL.createObjectURL(value);
                                                img.onload = () => {
                                                    const aspectRatioImgUp = (img.width / img.height).toFixed(1);
                                                    const aspectRatioRule =
                                                        ASPECT_RATIO_IMG.QUIZ_CLASSIC_END_BG.toFixed(1);
                                                    if (aspectRatioImgUp !== aspectRatioRule) {
                                                        setError('finish.image', {
                                                            type: 'custom',
                                                            message: trans('formValidation.aspectRatioError', {
                                                                args: {
                                                                    aspectRatio: aspectRatioRule,
                                                                },
                                                            }),
                                                        });
                                                        resolve(false);
                                                    } else {
                                                        if (value.size > ONE_MB) {
                                                            setError('finish.image', {
                                                                type: 'custom',
                                                                message: trans('formValidation.maxMbFileError', {
                                                                    args: {
                                                                        mb: (ONE_MB / ONE_MB).toFixed(1),
                                                                        uploadedSize: (value.size / ONE_MB).toFixed(2),
                                                                    },
                                                                }),
                                                            });
                                                            resolve(false);
                                                        } else {
                                                            clearErrors('finish.image');
                                                            resolve(true);
                                                        }
                                                    }
                                                };
                                            });
                                        }}
                                        error={!!errors?.finish?.image}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box
                            borderRadius={'12px'}
                            bgcolor={theme.palette.background.default}
                            p={4}
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            {quizTypology === 'classic' ? (
                                <PreviewFinishScreen
                                    headerTitle={quizTitle}
                                    headerSubTitle={transChoice(
                                        'quiz.quizQuestionsCountLabel',
                                        quizQuestionsCount ?? 4,
                                    )}
                                    endDate={quizEndDate}
                                    title={'Complimenti, hai completato la missione'}
                                    ctaText={watch('finish.ctaText')}
                                    image={watch('finish.image')}
                                    earnedPoints={points}
                                    points={20000}
                                />
                            ) : (
                                <PreviewFinishScreen
                                    headerTitle={quizTitle}
                                    endDate={quizEndDate}
                                    title={'Complimenti, hai completato la missione'}
                                    ctaText={watch('finish.ctaText')}
                                    image={watch('finish.image')}
                                    earnedPoints={points}
                                    points={20000}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>

                {!formDisabled && (
                    <NewQuizActionButtons
                        isLoading={isLoading}
                        onLeftButtonClick={handleStepPrevious}
                        rightButtonText={isEditMode ? trans('global.save') : trans('global.next')}
                        leftButtonText={trans('global.back')}
                    />
                )}
            </form>
        </Box>
    );
};

export default QuizStep3Closure;
