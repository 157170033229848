import React, { useContext, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import { CheckboxInput, SelectInput, TextInput } from '~js/components/input';
import SurveyContext from '../context';
import { clientSurveyStatus } from '~js/constants/missionStatuses';
import CustomTooltip from '~js/components/CustomTooltip';
import InfoIcon from '@mui/icons-material/Info';
import { addDays } from 'date-fns';

const ExposureForm = ({ events }) => {
    const { currentClientSurveyStatus, formDisabled, surveyStartDate, surveyEndDate } = useContext(SurveyContext);

    const {
        control,
        formState: { errors },
        watch,
        setValue,
    } = useFormContext();

    const eventsOptions = events.map((e) => ({
        _id: e._id,
        label: e.name,
        value: e._id,
    }));

    useEffect(() => {
        if (!!watch('withExclusions')) {
            setValue('isPeriodic', false);
            setValue('realTimeActivation', false);
        }
    }, [watch('withExclusions')]);

    return (
        <Box
            width="100%"
            sx={{
                border: '1px solid #dfdfe4',
                borderRadius: 1,
                bgcolor: 'white',
                mt: 5,
            }}
        >
            <Box p={3}>
                <Typography width="100%" mb={1}>
                    {trans('survey.exposureSettings')}
                </Typography>

                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px">
                            <Controller
                                control={control}
                                name="isEventSurvey"
                                shouldUnregister
                                render={({ field }) => (
                                    <CheckboxInput
                                        disabled={
                                            formDisabled ||
                                            currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                            currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                        }
                                        label={trans('survey.eventSurvey')}
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            if (!!e.target.checked) {
                                                setValue('isPeriodic', false);
                                                setValue('realTimeActivation', false);
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                    {watch('isEventSurvey') && (
                        <Grid item xs={12} sm={12} md={6}>
                            <Box maxWidth="500px">
                                <Controller
                                    control={control}
                                    name={`event`}
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                    }}
                                    shouldUnregister
                                    render={({ field }) => (
                                        <SelectInput
                                            disabled={formDisabled}
                                            label={trans('events.events')}
                                            selectOptions={eventsOptions}
                                            helperText={errors?.event?.message}
                                            error={!!errors?.event}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                    )}
                </Grid>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px" display="flex" alignItems="center">
                            <Controller
                                control={control}
                                name="isPeriodic"
                                shouldUnregister
                                render={({ field }) => (
                                    <CheckboxInput
                                        disabled={
                                            formDisabled ||
                                            currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                            currentClientSurveyStatus === clientSurveyStatus.prodNotActive ||
                                            !!watch('withExclusions')
                                        }
                                        label={trans('survey.periodicSurvey')}
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            if (!!e.target.checked) {
                                                setValue('isEventSurvey', false);
                                                setValue('realTimeActivation', false);
                                            }
                                        }}
                                        tooltipText={!!watch('withExclusions') && trans('survey.isPeriodicTooltip')}
                                    />
                                )}
                            />
                            <CustomTooltip
                                title={trans('survey.periodicTooltip')}
                                placement="bottom-start"
                                sx={{ zIndex: 99 }}
                            >
                                <InfoIcon sx={{ color: '#828282', zIndex: 99 }} />
                            </CustomTooltip>
                        </Box>
                    </Grid>
                    {watch('isPeriodic') && (
                        <Grid item xs={12} sm={12} md={6}>
                            <Box maxWidth="500px" display="flex" columnGap={6}>
                                <Controller
                                    control={control}
                                    name="intervalDays"
                                    shouldUnregister
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        validate: (val) => {
                                            if (val === '' && watch('timeLapsMonth') === '') {
                                                return trans('survey.insertDaysOrMonths');
                                            }
                                            if (addDays(new Date(surveyStartDate), val) > new Date(surveyEndDate)) {
                                                return trans('survey.maxProgrammaticDeadlineDaysError');
                                            }

                                            return true;
                                        },
                                        min: {
                                            value: 1,
                                            message: trans('survey.insertValueGreaterThanZero'),
                                        },
                                        deps: ['timeLapsMonth'],
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextInput
                                            disabled={
                                                formDisabled ||
                                                currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                                currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                            }
                                            type="number"
                                            inputProps={{ min: 0 }}
                                            label={trans('survey.timeLaps')}
                                            placeholder={trans('survey.days')}
                                            helperText={errors?.intervalDays?.message}
                                            error={!!errors?.intervalDays}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="maxExposureNumber"
                                    shouldUnregister
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        min: {
                                            value: 1,
                                            message: trans('survey.insertValueGreaterThanZero'),
                                        },
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextInput
                                            disabled={
                                                formDisabled ||
                                                currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                                currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                            }
                                            type="number"
                                            inputProps={{ min: 1 }}
                                            label={trans('survey.maxExposureNumber')}
                                            helperText={errors?.maxExposureNumber?.message}
                                            error={!!errors?.maxExposureNumber}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                    )}
                </Grid>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px" display="flex" alignItems="center">
                            <Controller
                                control={control}
                                name="realTimeActivation"
                                shouldUnregister
                                render={({ field }) => (
                                    <CheckboxInput
                                        disabled={
                                            formDisabled ||
                                            ((!!watch('isPeriodic') || !!watch('isEventSurvey')) &&
                                                (currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                                    currentClientSurveyStatus === clientSurveyStatus.prodNotActive)) ||
                                            !!watch('withExclusions')
                                        }
                                        label={trans('survey.continuousActivation')}
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            if (!!e.target.checked) {
                                                setValue('isPeriodic', false);
                                                setValue('isEventSurvey', false);
                                            }
                                        }}
                                        tooltipText={
                                            !!watch('withExclusions') &&
                                            trans('survey.realTimeActivationDisabledTooltip')
                                        }
                                    />
                                )}
                            />

                            <CustomTooltip
                                title={trans('survey.continuousActivationDescription')}
                                placement="bottom-start"
                                sx={{ zIndex: 99 }}
                            >
                                <InfoIcon sx={{ color: '#828282', zIndex: 99 }} />
                            </CustomTooltip>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px" display="flex" alignItems="center">
                            <Controller
                                control={control}
                                name="shouldExpireProgrammatically"
                                shouldUnregister
                                render={({ field }) => (
                                    <CheckboxInput
                                        disabled={
                                            formDisabled ||
                                            currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                            currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                        }
                                        label={trans('survey.programmaticDeadline')}
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            /*   if (!!e.target.checked) {
                                                setValue('isPeriodic', false);
                                                setValue('isEventSurvey', false);
                                            }*/
                                        }}
                                    />
                                )}
                            />

                            <CustomTooltip
                                title={trans('survey.programmaticDeadlineTooltip')}
                                placement="bottom-start"
                                sx={{ zIndex: 99 }}
                            >
                                <InfoIcon sx={{ color: '#828282', zIndex: 99 }} />
                            </CustomTooltip>
                        </Box>
                    </Grid>
                    {watch('shouldExpireProgrammatically') && (
                        <Grid item xs={12} sm={12} md={6}>
                            <Box maxWidth="500px">
                                <Controller
                                    control={control}
                                    name="programmaticDeadlineDays"
                                    shouldUnregister
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        min: {
                                            value: 1,
                                            message: trans('survey.insertValueGreaterThanZero'),
                                        },
                                        validate: (val) =>
                                            addDays(new Date(surveyStartDate), val) <= new Date(surveyEndDate) ||
                                            trans('survey.maxProgrammaticDeadlineDaysError'),
                                    }}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextInput
                                            disabled={
                                                formDisabled ||
                                                currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                                currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                            }
                                            type="number"
                                            inputProps={{ min: 1 }}
                                            label={trans('survey.programmaticDeadlineDays')}
                                            helperText={errors?.programmaticDeadlineDays?.message}
                                            error={!!errors?.programmaticDeadlineDays}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    );
};

export default ExposureForm;
