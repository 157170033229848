import React, { useEffect, useState } from 'react';
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import axios from 'axios';
import { trans } from 'matice';
import FullPageLoader from '~js/components/FullPageLoader';
import toolsLogoWhite from '~/assets/images/tools-logo-white.svg';
import bgTestLeft from '~/assets/images/tools/settings/test-user-invitation-result-left.svg';
import bgTestRight from '~/assets/images/tools/settings/test-user-invitation-result-right.svg';

const TesterGroupsAcceptInvitation = () => {
    const [activationResponse, setActivationResponse] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const testGroupId = params.get('testGroupId');
        const activationToken = params.get('activationToken');
        activeUser(testGroupId, activationToken);
    }, []);

    const activeUser = async (testGroupId, activationToken) => {
        try {
            const res = await axios.get(route('TesterGroups.activeUser', { groupId: testGroupId, activationToken }));
            setActivationResponse(res.data);
        } catch (e) {
            setActivationResponse(e.response.data);
        }
    };

    console.log(activationResponse);
    return (
        <Box display="flex" alignItems="center" justifyContent="center" minHeight="100vh" flexDirection="column">
            <AppBar position="static">
                <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box component={'img'} src={toolsLogoWhite} alt={'logo tool loyalty'} padding={2} />
                </Toolbar>
            </AppBar>

            <FullPageLoader isVisible={!activationResponse} />
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    backgroundColor: (theme) => theme.palette.background.light,
                    overflow: 'hidden',
                }}
            >
                {activationResponse && (
                    <Box
                        sx={{
                            bgcolor: '#FFFFFF',
                            m: 5,
                            px: 5,
                            py: 8,
                            borderRadius: '24px',
                            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                            maxWidth: '600px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            position: 'relative',
                        }}
                    >
                        <Box
                            component={'img'}
                            src={bgTestLeft}
                            sx={{
                                position: 'absolute',
                                width: 120,
                                left: -120,
                                top: '50%',
                            }}
                        />
                        <Box
                            component={'img'}
                            src={bgTestRight}
                            sx={{
                                position: 'absolute',
                                width: 200,
                                right: -200,
                                top: '-65%',
                            }}
                        />
                        {activationResponse?.code === 200 && (
                            <>
                                <Typography mt={3} textAlign="center">
                                    {trans('surveyToolSettings.activeUser200Title')}
                                </Typography>
                                <Box
                                    sx={{
                                        borderTop: '1px solid white',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        pt: 3,
                                        mt: 3,
                                    }}
                                >
                                    <Typography textAlign="center">
                                        {trans('surveyToolSettings.activeUser200Description')}
                                    </Typography>
                                </Box>
                            </>
                        )}

                        {activationResponse?.code === 404 && (
                            <>
                                <Typography mt={3} textAlign="center" color="white">
                                    {trans('surveyToolSettings.activeUser404Title')}
                                </Typography>
                                <Box
                                    sx={{
                                        borderTop: '1px solid white',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        pt: 3,
                                        mt: 3,
                                    }}
                                >
                                    <Typography textAlign="center">
                                        {trans('surveyToolSettings.activeUser404Description')}
                                    </Typography>
                                </Box>
                            </>
                        )}

                        {activationResponse?.code === 422 && (
                            <>
                                <Typography mt={3} textAlign="center">
                                    {trans('surveyToolSettings.activeUser422Title')}
                                </Typography>
                                <Box
                                    sx={{
                                        borderTop: '1px solid grey',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        pt: 3,
                                        mt: 3,
                                    }}
                                >
                                    <Typography textAlign="center">
                                        {trans('surveyToolSettings.activeUser422Description')}
                                    </Typography>
                                </Box>
                            </>
                        )}
                        <Button component="a" href={route('BrandIndex')} variant="contained" sx={{ marginTop: 3 }}>
                            Vai al tool
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default TesterGroupsAcceptInvitation;
