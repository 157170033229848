import React, { useState } from 'react';
import { router } from '@inertiajs/react';
import { Box, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { trans } from 'matice';
import CtaButton from '~js/components/CtaButton';
import { PhotoAccountInput, TextInput } from '~js/components/input';

const AccountSettingsSection = ({ user }) => {
    const SchemaValidation = yup.object().shape({
        name: yup.string().required(trans('formValidation.requiredField')),
    });

    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
        setError,
        setValue,
    } = useForm({
        defaultValues: {
            name: user?.name,
            surname: user?.surname,
            email: user?.email,
            phoneNumber: user?.phoneNumber,
            avatar: user?.avatar,
        },
        resolver: yupResolver(SchemaValidation),
    });
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = (data) => {
        console.log(data);
        router.post(
            route('Settings.updateUserProfile'),
            { _method: 'patch', ...data },
            {
                onStart: () => setIsLoading(true),
                onFinish: () => setIsLoading(false),
                onError: (e) => {
                    if (!!e?.generic) {
                        toast.error(e.generic);
                        return;
                    }
                    Object.keys(e).forEach((key) => {
                        setError(key, { type: 'custom', message: e[key] });
                    });
                },
                onSuccess: () => {
                    toast.success(trans('surveyToolSettings.accountUpdated'));
                },
            },
        );
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container columnSpacing={4} rowSpacing={2}>
                <Grid item xs={12} sm={12} md={6} order={{ xs: 2, sm: 2, md: 1 }}>
                    <Controller
                        control={control}
                        name="name"
                        render={({ field }) => (
                            <TextInput
                                label={trans('surveyToolSettings.name')}
                                helperText={errors?.name?.message}
                                error={!!errors?.name}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="surname"
                        render={({ field }) => (
                            <TextInput
                                label={trans('surveyToolSettings.surname')}
                                helperText={errors?.surname?.message}
                                error={!!errors?.surname}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="email"
                        render={({ field }) => (
                            <TextInput
                                disabled
                                label={trans('surveyToolSettings.email')}
                                helperText={errors?.email?.message}
                                error={!!errors?.email}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="phoneNumber"
                        render={({ field }) => (
                            <TextInput
                                label={trans('surveyToolSettings.phoneNumber')}
                                helperText={errors?.phoneNumber?.message}
                                error={!!errors?.phoneNumber}
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 2 }}>
                    <PhotoAccountInput
                        avatarUrl={watch('avatar')}
                        onChange={(imgData) => setValue('avatar', imgData)}
                        errorMessage={errors?.avatar?.message}
                    />
                </Grid>
            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexWrap: 'wrap',
                    mt: 5,
                }}
            >
                <CtaButton variant="contained" type="submit" loading={isLoading}>
                    {trans('global.saveChanges')}
                </CtaButton>
            </Box>
        </form>
    );
};

export default AccountSettingsSection;
