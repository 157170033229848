import { Link } from '@inertiajs/react';
import { Typography, useTheme } from '@mui/material';
import React from 'react';

type Breadcrumb = {
    routeName: string,
    routeLabel: string,
};

/**
 * The list of Breadcrumb item, ordered by desc.
 * The first will be the current page.
 *
 * Breadcrumb:
 *  - routeName: string,
 *  - routeLabel: string
 */
export type BreadcrumbList = Array<Breadcrumb>;

type BreadcrumbsProps = {
    breadcrumbs: BreadcrumbList,
};

export default ({ breadcrumbs }: BreadcrumbsProps) => {
    const theme = useTheme();

    return breadcrumbs.map(({ routeName, routeLabel, params = {} }, index) => (
        <Link href={route(routeName, params)} as={'a'} className={'text-decoration-none'} key={index.toString()}>
            <Typography
                variant="caption"
                display="block"
                gutterBottom
                sx={{ color: index !== 0 ? '#8B8D97' : theme.palette.primary.main }}
                mb={0}
            >
                <span style={{ color: '#8B8D97' }}>/&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {routeLabel}
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </Typography>
        </Link>
    ));
};
