import React, { useContext, useMemo, useRef } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Typography } from '@mui/material';
import { trans } from 'matice';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Controller, useFormContext } from 'react-hook-form';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import { ASPECT_RATIO_IMG, getImageUploadTooltip, Images, ONE_MB } from '~js/constants/images.jsx';
import FileUploaderInput from '~js/components/FileUploaderInput';
import PreviewDifferenceQuestionScreen from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewDifferenceQuestionScreen';
import DifferenceGridSelector from '~js/pages/backoffice/Tools/Quiz/components/DifferenceGridSelector';
import LexicalTextEditor, { parseJsonToTextMap } from '~js/pages/backoffice/Tools/Quiz/components/LexicalEditor';
import PreviewQuestionSuccessModalScreen from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewQuestionSuccessModalScreen';

const accordionIconSx = {
    height: 24,
    width: 24,
    zIndex: 99,
    py: '0px',
    px: '6px',
    borderRadius: '50%',
    fontWeight: 'bold',
    backgroundColor: (theme) => theme.palette.primary.iconBackground,
    color: (theme) => theme.palette.primary.main,
};

const GRID_ROWS = 4;
const GRID_COLUMNS = 5;
const ACCORDION_TRANSITION_DURATION_MS = 250;

const DifferenceQuestionForm = ({ questionIndex, currentFocusedQuestion, setCurrentFocusedQuestion }) => {
    const { formDisabled, quizTitle, quizEndDate, currentClientQuizStatus } = useContext(QuizContext);

    const detailRef = useRef();

    const {
        control,
        setError,
        clearErrors,
        formState: { errors },
        watch,
        trigger,
    } = useFormContext();

    const quizQuestionsKey = `questions`;
    const quizQuestionKey = `${quizQuestionsKey}[${questionIndex}]`;
    const itemErrors = errors?.[quizQuestionsKey]?.[questionIndex];

    const collapseQuestion = () => {
        trigger(quizQuestionKey).then((isValid) => {
            if (isValid) {
                setCurrentFocusedQuestion(null);
            }
        });
    };

    const nthCountLabel = useMemo(() => {
        switch (questionIndex) {
            case 0:
                return 'prima';
            case 1:
                return 'seconda';
            case 2:
                return 'terza';
            case 3:
                return 'quarta';
            case 4:
                return 'quinta';
            case 5:
                return 'sesta';
            default:
                return 'n-esima';
        }
    }, [questionIndex]);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" ref={detailRef}>
            <Box
                width="100%"
                px={2}
                mb={2}
                sx={{
                    border:
                        errors?.questions && errors.questions[questionIndex]
                            ? '1px solid #f44336'
                            : '1px solid #dfdfe4',
                    borderRadius: 1,
                    bgcolor: 'white',
                }}
            >
                <Accordion
                    elevation={0}
                    disableGutters
                    expanded={currentFocusedQuestion === questionIndex}
                    slotProps={{ transition: { timeout: ACCORDION_TRANSITION_DURATION_MS } }}
                >
                    <AccordionSummary
                        sx={{
                            '&.Mui-focusVisible': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                            <Grid container columnSpacing={2} alignItems={'center'}>
                                <Grid
                                    item
                                    xs={12}
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                >
                                    <Box display={'flex'} flexDirection={'row'} py={3}>
                                        {currentFocusedQuestion !== questionIndex ? (
                                            <KeyboardArrowDownIcon
                                                sx={accordionIconSx}
                                                onClick={(event) => {
                                                    setCurrentFocusedQuestion(questionIndex);
                                                    setTimeout(() => {
                                                        detailRef.current.scrollIntoView({
                                                            inline: 'start',
                                                            behavior: 'smooth',
                                                        });
                                                    }, ACCORDION_TRANSITION_DURATION_MS * 2);
                                                }}
                                            />
                                        ) : (
                                            <KeyboardArrowUpIcon
                                                sx={accordionIconSx}
                                                onClick={(event) => {
                                                    setCurrentFocusedQuestion(questionIndex);
                                                    collapseQuestion();
                                                }}
                                            />
                                        )}
                                        <Box display={'flex'} flexDirection={'column'} sx={{ pl: 2 }}>
                                            <Typography variant={'subtitle2'} fontWeight={'bold'}>
                                                {trans('quiz.difference.questionTitle', {
                                                    args: { index: questionIndex + 1 },
                                                })}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            {/* Right/Wrong Images */}
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box sx={{ maxWidth: 500 }}>
                                        {/* Right Image */}
                                        <Controller
                                            control={control}
                                            name={`${quizQuestionKey}.imageRight`}
                                            defaultValue=""
                                            rules={{ required: trans('formValidation.requiredField') }}
                                            render={({ field: { onChange, value } }) => (
                                                <FileUploaderInput
                                                    disabled={formDisabled}
                                                    value={value}
                                                    label={trans('quiz.difference.imageRight')}
                                                    tooltipText={getImageUploadTooltip(
                                                        Images.QUIZ_DIFFERENCE_IMAGE,
                                                        ONE_MB,
                                                    )}
                                                    onChange={onChange}
                                                    helperText={
                                                        itemErrors?.imageRight?.message ?? trans('quiz.fileMaxSizeHint')
                                                    }
                                                    validateFn={async (value) => {
                                                        if (!value || typeof value !== 'object') {
                                                            clearErrors(`${quizQuestionKey}.imageRight`);
                                                            return true;
                                                        }
                                                        return await new Promise((resolve) => {
                                                            let img = new Image();
                                                            img.src = window.URL.createObjectURL(value);
                                                            img.onload = () => {
                                                                const aspectRatioImgUp = (
                                                                    img.width / img.height
                                                                ).toFixed(1);
                                                                const aspectRatioRule =
                                                                    ASPECT_RATIO_IMG.QUIZ_DIFFERENCE_IMAGE.toFixed(1);
                                                                if (aspectRatioImgUp !== aspectRatioRule) {
                                                                    setError(`${quizQuestionKey}.imageRight`, {
                                                                        type: 'custom',
                                                                        message: trans(
                                                                            'formValidation.aspectRatioError',
                                                                            {
                                                                                args: {
                                                                                    aspectRatio: aspectRatioRule,
                                                                                },
                                                                            },
                                                                        ),
                                                                    });
                                                                    resolve(false);
                                                                } else {
                                                                    if (value.size > ONE_MB) {
                                                                        setError(`${quizQuestionKey}.imageRight`, {
                                                                            type: 'custom',
                                                                            message: trans(
                                                                                'formValidation.max1MbFileError',
                                                                                {
                                                                                    args: {
                                                                                        uploadedSize: (
                                                                                            value.size / ONE_MB
                                                                                        ).toFixed(2),
                                                                                    },
                                                                                },
                                                                            ),
                                                                        });
                                                                        resolve(false);
                                                                    } else {
                                                                        clearErrors(`${quizQuestionKey}.imageRight`);
                                                                        resolve(true);
                                                                    }
                                                                }
                                                            };
                                                        });
                                                    }}
                                                    error={!!itemErrors?.imageRight}
                                                />
                                            )}
                                        />

                                        {/* Wrong Image */}
                                        <Controller
                                            control={control}
                                            name={`${quizQuestionKey}.imageWrong`}
                                            defaultValue=""
                                            rules={{ required: trans('formValidation.requiredField') }}
                                            render={({ field: { onChange, value } }) => (
                                                <FileUploaderInput
                                                    disabled={formDisabled}
                                                    value={value}
                                                    label={trans('quiz.difference.imageWrong')}
                                                    tooltipText={getImageUploadTooltip(
                                                        Images.QUIZ_DIFFERENCE_IMAGE,
                                                        ONE_MB,
                                                    )}
                                                    onChange={onChange}
                                                    helperText={
                                                        itemErrors?.imageWrong?.message ?? trans('quiz.fileMaxSizeHint')
                                                    }
                                                    validateFn={async (value) => {
                                                        if (!value || typeof value !== 'object') {
                                                            clearErrors(`${quizQuestionKey}.imageWrong`);
                                                            return true;
                                                        }
                                                        return await new Promise((resolve) => {
                                                            let img = new Image();
                                                            img.src = window.URL.createObjectURL(value);
                                                            img.onload = () => {
                                                                const aspectRatioImgUp = (
                                                                    img.width / img.height
                                                                ).toFixed(1);
                                                                const aspectRatioRule =
                                                                    ASPECT_RATIO_IMG.QUIZ_DIFFERENCE_IMAGE.toFixed(1);
                                                                if (aspectRatioImgUp !== aspectRatioRule) {
                                                                    setError(`${quizQuestionKey}.imageWrong`, {
                                                                        type: 'custom',
                                                                        message: trans(
                                                                            'formValidation.aspectRatioError',
                                                                            {
                                                                                args: {
                                                                                    aspectRatio: aspectRatioRule,
                                                                                },
                                                                            },
                                                                        ),
                                                                    });
                                                                    resolve(false);
                                                                } else {
                                                                    if (value.size > ONE_MB) {
                                                                        setError(`${quizQuestionKey}.imageWrong`, {
                                                                            type: 'custom',
                                                                            message: trans(
                                                                                'formValidation.max1MbFileError',
                                                                                {
                                                                                    args: {
                                                                                        uploadedSize: (
                                                                                            value.size / ONE_MB
                                                                                        ).toFixed(2),
                                                                                    },
                                                                                },
                                                                            ),
                                                                        });
                                                                        resolve(false);
                                                                    } else {
                                                                        clearErrors(`${quizQuestionKey}.imageWrong`);
                                                                        resolve(true);
                                                                    }
                                                                }
                                                            };
                                                        });
                                                    }}
                                                    error={!!itemErrors?.imageWrong}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Grid>
                                {/* Images Preview */}
                                <Grid item xs={6}>
                                    <Box
                                        borderRadius={'12px'}
                                        bgcolor={(theme) => theme.palette.background.default}
                                        p={4}
                                        display={'flex'}
                                        justifyContent={'center'}
                                    >
                                        <PreviewDifferenceQuestionScreen
                                            imageRight={watch(`${quizQuestionKey}.imageRight`)}
                                            imageWrong={watch(`${quizQuestionKey}.imageWrong`)}
                                            diffFound={questionIndex + 1}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider width={'100%'} sx={{ marginTop: 4, marginBottom: 4 }} />

                            {/* Select Difference Controller */}
                            <Grid container>
                                <Box display={'flex'} flexDirection={'column'} my={2}>
                                    <Typography variant={'subtitle2'} fontWeight={'bold'}>
                                        {trans('quiz.difference.selectDiffsTitle')}
                                    </Typography>
                                    <Typography
                                        variant={'caption'}
                                        sx={{ color: (theme) => theme.palette.text.secondary }}
                                    >
                                        {trans('quiz.difference.selectDiffsHint', {
                                            args: {
                                                nthCount: nthCountLabel,
                                            },
                                        })}
                                    </Typography>
                                </Box>
                                <Grid item xs={12}>
                                    <Box
                                        borderRadius={'12px'}
                                        bgcolor={(theme) => theme.palette.background.default}
                                        p={4}
                                        display={'flex'}
                                        justifyContent={'center'}
                                    >
                                        <Grid container sx={{ minHeight: '250px' }}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                        lineHeight: '16.8px',
                                                    }}
                                                    mb={3}
                                                >
                                                    {trans('quiz.difference.imageRight')}
                                                </Typography>
                                                <Controller
                                                    control={control}
                                                    name={`${quizQuestionKey}.cellWithDifferences`}
                                                    defaultValue={[]}
                                                    rules={{ required: trans('formValidation.requiredField') }}
                                                    render={({ field }) => (
                                                        <DifferenceGridSelector
                                                            formKey={quizQuestionKey}
                                                            gridRows={GRID_ROWS}
                                                            gridColumns={GRID_COLUMNS}
                                                            image={watch(`${quizQuestionKey}.imageRight`)}
                                                            {...field}
                                                            helperText={trans('formValidation.requiredField')}
                                                            error={itemErrors?.cellWithDifferences?.message}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                        lineHeight: '16.8px',
                                                    }}
                                                    mb={3}
                                                >
                                                    {trans('quiz.difference.imageWrong')}
                                                </Typography>

                                                <Controller
                                                    control={control}
                                                    name={`${quizQuestionKey}.cellWithDifferences`}
                                                    defaultValue={[]}
                                                    rules={{ required: trans('formValidation.requiredField') }}
                                                    render={({ field }) => (
                                                        <DifferenceGridSelector
                                                            formKey={quizQuestionKey}
                                                            gridRows={GRID_ROWS}
                                                            gridColumns={GRID_COLUMNS}
                                                            image={watch(`${quizQuestionKey}.imageWrong`)}
                                                            {...field}
                                                            helperText={trans('formValidation.requiredField')}
                                                            error={itemErrors?.cellWithDifferences?.message}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider width={'100%'} sx={{ marginTop: 4, marginBottom: 4 }} />

                            {/* Modal */}
                            <Grid container>
                                <Grid item xs={12} mb={8}>
                                    <Typography variant={'subtitle2'} fontWeight={'bold'}>
                                        {trans('quiz.difference.modalTitle')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ maxWidth: 500 }}>
                                        {/* Modal subTitle */}
                                        <Controller
                                            control={control}
                                            name={`${quizQuestionKey}.modal.subTitle`}
                                            rules={{
                                                validate: (value) => {
                                                    if (!value) {
                                                        return trans('formValidation.requiredField');
                                                    }
                                                    const textMap = parseJsonToTextMap(JSON.parse(value));
                                                    return textMap.length > 0 ? true : 'textmap assente';
                                                },
                                            }}
                                            render={({ field }) => (
                                                <LexicalTextEditor
                                                    disabled={formDisabled}
                                                    label={trans('quiz.difference.modalSubTitle')}
                                                    helperText={itemErrors?.modal?.subTitle?.message}
                                                    error={!!itemErrors?.modal?.subTitle}
                                                    inputProps={{ maxLength: 65 }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {/* Modal Description */}
                                        <Controller
                                            control={control}
                                            name={`${quizQuestionKey}.modal.description`}
                                            rules={{
                                                validate: (value) => {
                                                    if (!value) {
                                                        return trans('formValidation.requiredField');
                                                    }
                                                    const textMap = parseJsonToTextMap(JSON.parse(value));
                                                    return textMap.length > 0 ? true : 'textmap assente';
                                                },
                                            }}
                                            render={({ field }) => (
                                                <LexicalTextEditor
                                                    disabled={formDisabled}
                                                    label={trans('quiz.difference.modalDescription')}
                                                    helperText={itemErrors?.modal?.description?.message}
                                                    error={!!itemErrors?.modal?.description}
                                                    inputProps={{ maxLength: 200 }}
                                                    {...field}
                                                />
                                            )}
                                        />

                                        {/* Modal CTA Image */}
                                        <Controller
                                            control={control}
                                            name={`${quizQuestionKey}.modal.image`}
                                            defaultValue=""
                                            rules={{ required: trans('formValidation.requiredField') }}
                                            render={({ field: { onChange, value } }) => (
                                                <FileUploaderInput
                                                    disabled={formDisabled}
                                                    value={value}
                                                    label={trans('quiz.difference.modalImage')}
                                                    tooltipText={getImageUploadTooltip(
                                                        Images.QUIZ_CHALLENGE_MODAL_IMAGE,
                                                        ONE_MB,
                                                    )}
                                                    onChange={onChange}
                                                    helperText={
                                                        itemErrors?.modal?.image?.message ??
                                                        trans('quiz.fileMaxSizeHint')
                                                    }
                                                    validateFn={async (value) => {
                                                        if (!value || typeof value !== 'object') {
                                                            clearErrors(`${quizQuestionKey}.modal.image`);
                                                            return true;
                                                        }
                                                        return await new Promise((resolve) => {
                                                            let img = new Image();
                                                            img.src = window.URL.createObjectURL(value);
                                                            img.onload = () => {
                                                                const aspectRatioImgUp = (
                                                                    img.width / img.height
                                                                ).toFixed(1);
                                                                const aspectRatioRule = '1.0';
                                                                if (aspectRatioImgUp !== aspectRatioRule) {
                                                                    setError(`${quizQuestionKey}.modal.image`, {
                                                                        type: 'custom',
                                                                        message: trans(
                                                                            'formValidation.aspectRatioErrorWithUploaded',
                                                                            {
                                                                                args: {
                                                                                    aspectRatio: aspectRatioRule,
                                                                                    uploadedAspectRatio:
                                                                                        aspectRatioImgUp,
                                                                                },
                                                                            },
                                                                        ),
                                                                    });
                                                                    resolve(false);
                                                                } else {
                                                                    if (value.size > ONE_MB) {
                                                                        setError(`${quizQuestionKey}.modal.image`, {
                                                                            type: 'custom',
                                                                            message: trans(
                                                                                'formValidation.max1MbFileError',
                                                                                {
                                                                                    args: {
                                                                                        uploadedSize: (
                                                                                            value.size / ONE_MB
                                                                                        ).toFixed(2),
                                                                                    },
                                                                                },
                                                                            ),
                                                                        });
                                                                        resolve(false);
                                                                    } else {
                                                                        clearErrors(`${quizQuestionKey}.modal.image`);
                                                                        resolve(true);
                                                                    }
                                                                }
                                                            };
                                                        });
                                                    }}
                                                    error={!!itemErrors?.modal?.image}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Grid>
                                {/* Images Preview */}
                                <Grid item xs={6}>
                                    <Box
                                        borderRadius={'12px'}
                                        bgcolor={(theme) => theme.palette.background.default}
                                        p={4}
                                        display={'flex'}
                                        justifyContent={'center'}
                                    >
                                        <PreviewQuestionSuccessModalScreen
                                            subTitle={watch(`${quizQuestionKey}.modal.subTitle`)}
                                            description={watch(`${quizQuestionKey}.modal.description`)}
                                            image={watch(`${quizQuestionKey}.modal.image`)}
                                        >
                                            <PreviewDifferenceQuestionScreen
                                                imageRight={watch(`${quizQuestionKey}.imageRight`)}
                                                imageWrong={watch(`${quizQuestionKey}.imageWrong`)}
                                                diffFound={questionIndex + 1}
                                            />
                                        </PreviewQuestionSuccessModalScreen>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );
};

export default DifferenceQuestionForm;
