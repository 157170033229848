export const parseQueryParamsArray = (queryParams) => {
    const arrayQueryParams = {};
    for (const [key, value] of queryParams) {
        const queryParamsArrayRegex = /\[(\d+)\]$/;
        if (key.match(queryParamsArrayRegex)) {
            const paramKey = key.replace(queryParamsArrayRegex, '');
            if (!arrayQueryParams[paramKey]) {
                arrayQueryParams[paramKey] = [];
            }
            arrayQueryParams[paramKey].push(value);
        }
    }
    return arrayQueryParams;
};
