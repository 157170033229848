import React from 'react';
import { Box, LinearProgress, ThemeProvider, Typography } from '@mui/material';
import PreviewQuizHeader from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewQuizHeader';
import PreviewCtaButton from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewCtaButton';
import useTimer from '~js/hooks/useTimer';
import pampersTheme from '~js/pages/backoffice/Tools/Quiz/components/Previews/previewTheme';

const PROGRESS_REFRESH_RATE_MS = 500;

const PreviewTimeQuestionScreen = ({ headerTitle = 'Quiz', endDate, question, responses = [], timerSeconds }) => {
    const { progress: timerProgress, elapsed } = useTimer({
        seconds: timerSeconds,
        timeoutMs: PROGRESS_REFRESH_RATE_MS,
    });

    const nRightResponses = responses.reduce(
        (accumulator, response) => (response?.right ? accumulator + 1 : accumulator),
        0,
    );
    return (
        <ThemeProvider theme={pampersTheme}>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '250px',
                        height: '540px',
                        borderRadius: '22px',
                        boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.25)',
                        position: 'relative',
                        flexDirection: 'column',
                        overflow: 'hidden',
                    }}
                >
                    {/* Quiz Screen Header content */}
                    <PreviewQuizHeader title={headerTitle} endDate={endDate} />

                    {/* Quiz Question Body */}
                    <Box
                        px={'10px'}
                        width={'100%'}
                        height={'100%'}
                        sx={{
                            //background: 'rgb(237,248,249)',
                            background:
                                'linear-gradient(180deg, rgba(237,248,249,1) 0%, rgba(237,248,249,1) 75%, rgba(255,255,255,1) 85%, rgba(255,255,255,1) 100%)',
                        }}
                    >
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            height={'100%'}
                            py={2}
                        >
                            <Box width={'100%'}>
                                <Typography
                                    pb={2}
                                    display={'flex'}
                                    flexWrap={'wrap'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    sx={{
                                        wordWrap: 'break-word',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        lineHeight: '16px',
                                        color: '#354953',
                                        textAlign: 'center',
                                        height: 64,
                                    }}
                                >
                                    {question.slice(0, 50)}
                                </Typography>
                                <Box display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'}>
                                    {responses.map(({ response }, index) => (
                                        <Box
                                            key={index}
                                            display={'flex'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            textAlign={'center'}
                                            sx={{
                                                flex: '0 0 48%',
                                                minHeight: '80px',
                                                maxWidth: '48%',
                                                backgroundColor: '#FFFFFF',
                                                border: '0.75px solid #BEBEBE',
                                                borderRadius: 1,
                                                wordWrap: 'break-word',
                                                marginBottom: 1,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '13px',
                                                    lineHeight: '15px',
                                                    maxWidth: '90%',
                                                }}
                                            >
                                                {response?.slice(0, 50)}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                            <Box sx={{ position: 'relative' }}>
                                <LinearProgress
                                    variant="determinate"
                                    value={timerProgress}
                                    //value={100}
                                />
                                <Box
                                    width={'100%'}
                                    sx={{
                                        position: 'absolute',
                                        left: '-15px',
                                        top: '-15px',

                                        transform: `translateX(${timerProgress}%)`,
                                        transition: 'transform 0.5s',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: '#CCECEE',
                                            width: '30px',
                                            height: '30px',
                                            borderRadius: 40,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                                color: '#10a9ae',
                                            }}
                                        >
                                            {timerSeconds - elapsed}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography
                                    py={2}
                                    sx={{
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Seleziona <b>{nRightResponses} risposte</b>
                                </Typography>

                                <PreviewCtaButton ctaText={'Ho fatto, procedi'} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PreviewTimeQuestionScreen;
