import React, { useRef, useState } from 'react';
import { Box, FormControl, FormHelperText, MenuItem, Select, styled, Typography } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { isFuture, isPast } from 'date-fns';
import { trans } from 'matice';
import { clientSurveyStatus } from '~js/constants/missionStatuses';
import ChangeStatusAlertModal from '~js/pages/backoffice/Tools/Survey/components/ChangeStatusAlertModal';

export const CssTextField = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 5,
        position: 'relative',
        backgroundColor: 'transparent',
        fontSize: 16,
        width: '100%',
        padding: '10px 8px',
    },
}));

const statusBgColor = {
    [clientSurveyStatus.draft]: '#F8B700',
    [clientSurveyStatus.test]: '#F8B700',
    [clientSurveyStatus.prodNotActive]: '#21A700',
    [clientSurveyStatus.prodActive]: '#21A700',
    [clientSurveyStatus.expired]: '#CC0303',
    [clientSurveyStatus.revoked]: '#CC0303',
};

const readableStatusMap = {
    [clientSurveyStatus.draft]: trans('survey.draft'),
    [clientSurveyStatus.test]: trans('survey.test'),
    [clientSurveyStatus.prodNotActive]: trans('survey.readyForProduction'),
    [clientSurveyStatus.prodActive]: trans('survey.active'),
    [clientSurveyStatus.expired]: trans('survey.expired'),
    [clientSurveyStatus.revoked]: trans('survey.revoked'),
};

const SurveyStatusSelectInput = ({
    label = null,
    type = 'text',
    error,
    helperText,
    defaultValue = '',
    multiple,
    value,
    surveyStartDate,
    surveyEndDate,
    onChange,
    testerGroups,
    selectedGroupsTest = [],
    ...other
}) => {
    let currentStatus = value;

    if (value === 'prod') {
        if (isFuture(new Date(surveyStartDate))) {
            currentStatus = clientSurveyStatus.prodNotActive;
        } else if (isPast(new Date(surveyEndDate))) {
            currentStatus = clientSurveyStatus.expired;
        } else {
            currentStatus = clientSurveyStatus.prodActive;
        }
    }

    let selectOptions = [
        { _id: 1, label: trans('survey.draft'), value: clientSurveyStatus.draft },
        { _id: 2, label: trans('survey.test'), value: clientSurveyStatus.test },
        { _id: 3, label: trans('survey.readyForProduction'), value: clientSurveyStatus.prodNotActive },
        { _id: 4, label: trans('survey.active'), value: clientSurveyStatus.prodActive },
        { _id: 5, label: trans('survey.expired'), value: clientSurveyStatus.expired },
        { _id: 6, label: trans('survey.revoked'), value: clientSurveyStatus.revoked },
    ];

    if (currentStatus === 'draft') {
        selectOptions = [
            { _id: 1, label: trans('survey.draft'), value: clientSurveyStatus.draft },
            { _id: 2, label: trans('survey.test'), value: clientSurveyStatus.test },
        ];
    }

    if (currentStatus === 'test') {
        selectOptions = [
            { _id: 1, label: trans('survey.draft'), value: clientSurveyStatus.draft },
            { _id: 2, label: trans('survey.test'), value: clientSurveyStatus.test },
            { _id: 3, label: trans('survey.readyForProduction'), value: clientSurveyStatus.prodNotActive },
        ];
    }

    if (currentStatus === 'prodNotActive') {
        selectOptions = [
            { _id: 3, label: trans('survey.readyForProduction'), value: clientSurveyStatus.prodNotActive },
            { _id: 6, label: trans('survey.revoked'), value: clientSurveyStatus.revoked },
        ];
    }

    if (currentStatus === 'prodActive') {
        selectOptions = [
            { _id: 4, label: trans('survey.active'), value: clientSurveyStatus.prodActive },
            { _id: 6, label: trans('survey.revoke'), value: clientSurveyStatus.revoked },
        ];
    }

    if (currentStatus === 'expired') {
        selectOptions = [{ _id: 5, label: trans('survey.expired'), value: clientSurveyStatus.expired }];
    }

    if (currentStatus === 'revoked') {
        selectOptions = [{ _id: 6, label: trans('survey.revoked'), value: clientSurveyStatus.revoked }];
    }

    const [isLoading, setIsLoading] = useState(false);
    const [alertModal, setAlertModal] = useState({
        visible: false,
        title: '',
        description: '',
        allowCloseAction: false,
        allowConfirmAction: false,
    });
    const newStatusRef = useRef(null);

    const closeAlertModal = () => {
        setAlertModal({
            visible: false,
            title: '',
            description: '',
            allowCloseAction: false,
            allowConfirmAction: false,
        });
        newStatusRef.current = null;
    };

    const onChangeHandler = (e) => {
        let newStatusValue = e.target.value;

        newStatusRef.current = newStatusValue;

        // se provo a cambiare stato ma la data di inizio è passata
        if (
            (currentStatus === clientSurveyStatus.draft &&
                !isFuture(new Date(surveyStartDate)) &&
                newStatusValue === clientSurveyStatus.test) ||
            (currentStatus === clientSurveyStatus.test &&
                !isFuture(new Date(surveyStartDate)) &&
                (newStatusValue === clientSurveyStatus.prodActive ||
                    newStatusValue === clientSurveyStatus.prodNotActive))
        ) {
            setAlertModal({
                visible: true,
                title: trans('global.attention'),
                description: trans('survey.alertChangeStatusDataExpired'),
                allowCloseAction: true,
                allowConfirmAction: false,
            });
            return;
        }

        //alert se da prod passa a revocata
        if (currentStatus === clientSurveyStatus.prodNotActive || currentStatus === clientSurveyStatus.prodActive) {
            setAlertModal({
                visible: true,
                title: trans('survey.changeStatusTitleModal', {
                    args: {
                        currentStatus: readableStatusMap[currentStatus],
                        newStatus: readableStatusMap[newStatusValue],
                    },
                }),
                description: trans('survey.changeStatusProdToRevokedDescription'),
                allowCloseAction: true,
                allowConfirmAction: true,
            });
            return;
        }

        //se passa da draft a test
        if (currentStatus === clientSurveyStatus.draft && newStatusValue === clientSurveyStatus.test) {
            setAlertModal({
                visible: true,
                title: trans('survey.changeStatusTitleModal', {
                    args: {
                        currentStatus: readableStatusMap[currentStatus],
                        newStatus: readableStatusMap[newStatusValue],
                    },
                }),
                description: trans('survey.changeStatusDraftToTestDescription'),
                allowCloseAction: true,
                allowConfirmAction: true,
            });
            return;
        }

        //se passa da test a prod
        if (currentStatus === clientSurveyStatus.test && newStatusValue === clientSurveyStatus.prodNotActive) {
            setAlertModal({
                visible: true,
                title: trans('survey.changeStatusTitleModal', {
                    args: {
                        currentStatus: readableStatusMap[currentStatus],
                        newStatus: readableStatusMap[newStatusValue],
                    },
                }),
                description: trans('survey.changeStatusTestToProdDescription'),
                allowCloseAction: true,
                allowConfirmAction: true,
            });
            return;
        }

        setAlertModal({
            visible: true,
            title: trans('survey.changeStatusTitleModal', {
                args: {
                    currentStatus: readableStatusMap[currentStatus],
                    newStatus: readableStatusMap[newStatusValue],
                },
            }),
            description: '',
            allowCloseAction: true,
            allowConfirmAction: true,
        });
    };

    const confirmStatusChange = async (testGroups) => {
        if (
            newStatusRef.current === clientSurveyStatus.prodActive ||
            newStatusRef.current === clientSurveyStatus.prodNotActive
        ) {
            newStatusRef.current = 'prod';
        }
        try {
            setIsLoading(true);
            await onChange(newStatusRef.current, testGroups);
            closeAlertModal();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <FormControl variant="standard" fullWidth error={error} sx={{ margin: '12px 0' }} size="small">
                <Box display="flex" alignItems="center">
                    <Box width="10px" height="10px" borderRadius="5px" bgcolor={statusBgColor[currentStatus]}></Box>
                    {label && (
                        <Typography fontSize="14px" pb={0.2} pl="8px">
                            {label}:
                        </Typography>
                    )}
                    <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        input={<CssTextField />}
                        defaultValue={defaultValue}
                        displayEmpty
                        multiple={multiple}
                        {...other}
                        value={currentStatus}
                        onChange={onChangeHandler}
                    >
                        {selectOptions?.map((op, index) => {
                            return (
                                <MenuItem
                                    key={`${op.value}-${index}`}
                                    value={op.value}
                                    sx={index > 0 ? { borderTop: '1px solid #dfdfe4' } : {}}
                                >
                                    <Typography fontSize="14px">{op.label}</Typography>
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </Box>
            </FormControl>
            <ChangeStatusAlertModal
                show={alertModal.visible}
                title={alertModal.title}
                description={alertModal.description}
                containerSx={{ pb: 1 }}
                onClose={alertModal.allowCloseAction ? closeAlertModal : null}
                cancelButtonText={trans('global.close')}
                onConfirm={alertModal.allowConfirmAction ? confirmStatusChange : null}
                loading={isLoading}
                showGroupTestSelect={newStatusRef.current === clientSurveyStatus.test && alertModal.allowConfirmAction}
                testerGroups={testerGroups}
                selectedGroupsTest={selectedGroupsTest}
            />
        </>
    );
};

export default SurveyStatusSelectInput;
