import React from 'react';
import { Box, SvgIcon, ThemeProvider, Typography } from '@mui/material';
import pampersTheme from '~js/pages/backoffice/Tools/Quiz/components/Previews/previewTheme';
import IconCloseCoccoleSvg from '~/assets/images/icons/icon-close-coccole.svg?react';
import PreviewCtaButton from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewCtaButton';
import { NUM_CORRECT_PRODUCTS } from '~js/pages/backoffice/Tools/Quiz/components/FormSteps/SubSteps/DragWinFollowUpFormControllers';

const PreviewDragWinFollowUpScreen = ({ title = '', images = [] }) => {
    const imageSxProps = {
        backgroundColor: 'white',
        objectFit: 'contain',
        width: '100%',
        aspectRatio: 16 / 9,
        //userDrag: 'none',
        userSelect: 'none',
    };

    return (
        <ThemeProvider theme={pampersTheme}>
            <Box
                p={1.5}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '250px',
                    height: '540px',
                    borderRadius: '22px',
                    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.25)',
                    backgroundColor: 'rgba(16, 169, 174, 1)',
                    position: 'relative',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <Box width={'100%'}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'end'}>
                        <SvgIcon sx={{ color: '#FFFFFF' }} fontSize={'large'} inheritViewBox>
                            <IconCloseCoccoleSvg />
                        </SvgIcon>
                    </Box>
                    <Typography
                        py={3}
                        sx={{
                            fontSize: '15.74px',
                            fontWeight: 800,
                            lineHeight: '16px',
                            textAlign: 'center',
                            color: '#FFFFFF',
                        }}
                    >
                        {title}
                    </Typography>
                </Box>
                <Box>
                    {Array(NUM_CORRECT_PRODUCTS)
                        .fill('')
                        .map((image, index) => (
                            <Box
                                key={index.toString()}
                                mb={1}
                                sx={{
                                    width: 88,
                                    height: 88,
                                    backgroundColor: 'rgba(255, 255, 255, .2)',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {images[index] && (
                                    <Box
                                        component={'img'}
                                        src={images[index]}
                                        sx={{
                                            width: 75,
                                            height: 75,
                                            objectFit: 'contain',
                                        }}
                                    />
                                )}
                            </Box>
                        ))}
                </Box>

                <Box>
                    <Typography
                        mb={0.75}
                        sx={{
                            fontSize: '10px',
                            fontWeight: 200,
                            lineHeight: '16px',
                            textAlign: 'center',
                            color: '#FFFFFF',
                        }}
                    >
                        Trascinali nel carrello prima che scada il tempo!
                    </Typography>
                    <PreviewCtaButton ctaText={'Continua'} bgColor={'#FFFFFF'} color={'#10a9ae'} />
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PreviewDragWinFollowUpScreen;
