import React, { useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { trans } from 'matice';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import SuccessModal from '~js/components/SuccessModal';
import NewTesterGroupForm from './components/NewTesterGroupForm';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';

const breadcrumbs: BreadcrumbList = [
    {
        routeName: 'TesterGroups.create',
        routeLabel: 'Crea nuovo gruppo di test',
    },
    {
        routeName: 'Settings.index',
        routeLabel: 'Impostazioni',
    },
];

const TesterGroupsCreate = ({ externalDataSources, can }) => {
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const goToTesterGroupList = () => router.get(route('Settings.index'), { section: 'tester' });

    const createNewTestGroup = async (data) => {
        const promise = new Promise((resolve, reject) =>
            router.post(route('TesterGroups.store'), data, {
                onError: (e) => {
                    reject(e);
                    if (!!e?.generic) {
                        toast.error(e.generic);
                    }
                },
                onSuccess: () => {
                    resolve();
                    setShowModalSuccess(true);
                },
            }),
        );

        await promise;
    };
    return (
        <>
            <Head title={trans('surveyToolSettings.newTesterGroup')} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                    {trans('surveyToolSettings.newTesterGroup')}
                </Typography>
                <NewTesterGroupForm
                    onCancelPress={goToTesterGroupList}
                    createNewTestGroup={createNewTestGroup}
                    externalDataSourceOption={externalDataSources?.map((ds) => ({
                        _id: ds._id,
                        label: ds.name,
                        value: ds._id,
                    }))}
                    formDisabled={!can.create}
                    canDeleteTester={can?.deleteTester}
                />
            </ToolContentPageLayout>
            <SuccessModal
                open={showModalSuccess}
                handleClose={() => setShowModalSuccess(false)}
                title={trans('global.compliments')}
                description={trans('surveyToolSettings.testerGroupCreatedSuccess')}
                buttonText={trans('surveyToolSettings.backToTesterGroupList')}
                onPressButton={goToTesterGroupList}
                onExitPress={() => setShowModalSuccess(false)}
            />
        </>
    );
};

export default TesterGroupsCreate;
