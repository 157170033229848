import React from 'react';
import { Box } from '@mui/material';
import handIcon from '~/assets/images/tools/surveyTool/yesNoHandPreview.png';
import smileIcon from '~/assets/images/tools/surveyTool/yesNoSmilePreview.png';

const YesNoPreview = ({ type }) => {
    return (
        !!type && (
            <Box
                component="img"
                src={type === 'smile' ? smileIcon : handIcon}
                width="100%"
                mt="30px"
                sx={{ objectFit: 'contain' }}
            />
        )
    );
};

export default YesNoPreview;
