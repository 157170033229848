import React from 'react';
import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import CheckboxInput from '~js/components/CheckboxInput';

const EmailConfigurationForm = ({ formDisabled = false, isEditMode = false, editable = false }) => {
    const { control } = useFormContext();

    return (
        <Box
            width="100%"
            sx={{
                border: '1px solid #dfdfe4',
                borderRadius: 1,
                bgcolor: 'white',
            }}
            p={3}
            mt={5}
        >
            <Controller
                control={control}
                name="isEmailActive"
                shouldUnregister
                defaultValue={false}
                render={({ field }) => (
                    <CheckboxInput
                        disabled={formDisabled || (isEditMode && editable)}
                        label={trans('survey.mail')}
                        {...field}
                    />
                )}
            />
            {/*{!!watch('isEmailActive') && (
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px">
                            <Controller
                                control={control}
                                name="emailId"
                                shouldUnregister
                                defaultValue=""
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        label="ID"
                                        helperText={errors?.emailId?.message}
                                        error={!!errors?.emailId}
                                        {...field}
                                        ref={null}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px">
                            <Controller
                                control={control}
                                name="emailDeeplink"
                                shouldUnregister
                                defaultValue=""
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        label="Deeplink"
                                        helperText={errors?.emailDeeplink?.message}
                                        error={!!errors?.emailDeeplink}
                                        {...field}
                                        ref={null}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
            )}*/}
        </Box>
    );
};

export default EmailConfigurationForm;
