import React, { useEffect, useState } from 'react';
import { trans } from 'matice';
import { Box, FormControl, FormHelperText, IconButton, InputLabel, Typography } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InfoIcon from '@mui/icons-material/Info';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomTooltip from './CustomTooltip';
import csvFileIcon from '~/assets/images/tools/csv-file-format-extension.png';

const FileInput = ({
    value,
    label = null,
    onChange,
    helperText,
    error,
    accept = '.png, .jpg, .jpeg',
    disabled = false,
    placeholder = trans('survey.addImage'),
    tooltipText = null,
}) => {
    // drag state
    const [dragActive, setDragActive] = useState(false);
    // ref
    const inputRef = React.useRef(null);

    const [imgUrl, setImgUrl] = useState(null);

    useEffect(() => {
        if (!!value && typeof value === 'object') {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                if (value.type === 'text/csv') {
                    setImgUrl(csvFileIcon);
                } else {
                    setImgUrl(reader.result);
                }
            });
            reader.readAsDataURL(value);
        }

        if (!!value && typeof value === 'string') {
            setImgUrl(value);
        }
    }, [value]);

    // handle drag events
    const handleDrag = function (e) {
        if (disabled) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        if (disabled) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            onChange(e.dataTransfer.files[0]);
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        if (disabled) {
            return;
        }
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            onChange(e.target.files[0]);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = (e) => {
        if (disabled) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        inputRef.current.click();
    };

    const removeImage = (e) => {
        if (disabled) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        onChange('');
        setImgUrl(null);
    };

    return (
        <Box my="12px">
            <FormControl variant="standard" fullWidth error={error}>
                <Box my="24px" onDragEnter={handleDrag}>
                    <Box
                        disabled={disabled}
                        component="input"
                        ref={inputRef}
                        //workaround per selezionare la stessa immagine
                        onClick={(event) => {
                            event.target.value = null;
                        }}
                        type="file"
                        display="none"
                        onChange={handleChange}
                        accept={accept}
                    />
                    {label && (
                        <InputLabel
                            shrink
                            htmlFor="input-file-upload"
                            sx={{
                                color: '#5E6366',
                                pl: 1,
                                '&.Mui-focused': { color: error ? '#F9473C' : '#5E6366 !important' },
                            }}
                        >
                            {label}
                            {tooltipText && (
                                <CustomTooltip title={tooltipText} placement="bottom-start" sx={{ zIndex: 99 }}>
                                    <InfoIcon sx={{ color: '#828282', zIndex: 99 }} />
                                </CustomTooltip>
                            )}
                        </InputLabel>
                    )}
                    <Box
                        component="label"
                        htmlFor="input-file-upload"
                        className={dragActive ? 'drag-active' : ''}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '1px solid #5E6366',
                            borderRadius: 1,
                            height: '150px',
                            overflow: 'hidden',
                            position: 'relative',
                        }}
                    >
                        <Box
                            onClick={onButtonClick}
                            sx={{ cursor: 'pointer', overflow: 'hidden' }}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                        >
                            {imgUrl ? (
                                <>
                                    <Box
                                        component="img"
                                        src={imgUrl}
                                        width="100%"
                                        height="100%"
                                        sx={{ objectFit: 'contain' }}
                                    />
                                    <IconButton
                                        onClick={removeImage}
                                        sx={{
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 10,
                                            color: (theme) => theme.palette.primary.main,
                                            bgcolor: (theme) => theme.palette.primary.iconBackground,
                                            border: (theme) => `1px solid ${theme.palette.primary.main}`,
                                            '&:hover': {
                                                border: (theme) => `1px solid ${theme.palette.primary.main}`,
                                            },
                                        }}
                                        disabled={disabled}
                                    >
                                        <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                </>
                            ) : (
                                <>
                                    <FileUploadIcon />
                                    <Typography sx={{ textAlign: 'center' }}>{placeholder}</Typography>
                                </>
                            )}
                        </Box>
                    </Box>
                    {helperText && <FormHelperText sx={{ pl: 1 }}>{helperText}</FormHelperText>}
                    {dragActive && (
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '100%',
                                height: ' 100%',
                                borderRadius: '1rem',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                        />
                    )}
                </Box>
            </FormControl>
        </Box>
    );
};

export default FileInput;
