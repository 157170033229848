import React from 'react';
import { Box, Typography } from '@mui/material';

const ChoiceListPreview = ({ responses, useGridLayout }) => {
    return (
        <Box display="flex" width="100%" gap="8px" mt="12px" flexWrap="wrap" alignItems="flex-start">
            {responses?.map((r, index) => (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    key={index.toString()}
                    width={useGridLayout ? '48%' : '100%'}
                    minWidth={useGridLayout ? '48%' : '100%'}
                    minHeight="22px"
                    sx={{
                        bgcolor: 'white',
                        borderRadius: '10px',
                        p: '5px',
                        position: 'relative',
                    }}
                >
                    <Box
                        sx={{
                            width: '10px',
                            height: '10px',
                            borderRadius: '5px',
                            border: '.5px solid #dfdfe4',
                            position: 'absolute',
                            left: '10px',
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: '10px',
                            lineHeight: '12px',
                            color: 'black',
                            textAlign: 'center',
                            fontFamily: 'Nunito',
                            ml: '20px',
                            width: '100%',
                        }}
                    >
                        {r?.response}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

export default ChoiceListPreview;
