import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import layout from '~/assets/images/tools/surveyTool/layoutPreviewNoCtaText.png';
import ChoiceListPreview from './ChoiceListPreview';
import TextAreaPreview from './TextAreaPreview';
import DatePreview from './DatePreview';
import RatingPreview from './RatingPreview';
import ImageChoiceListPreview from './ImageChoiceListPreview';
import YesNoPreview from './YesNoPreview';
import SurveyContext from '../context';

const QuestionPreview = ({ ctaText = 'Procedi', questionData }) => {
    const { surveyTitle } = useContext(SurveyContext);

    return (
        <Box
            sx={{
                display: 'flex',
                //justifyContent: "center",
                alignItems: 'center',
                width: '250px',
                height: '540px',
                backgroundImage: `url(${layout})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                borderRadius: '22px',
                boxShadow: '0px 5.03227px 5.03227px rgba(0, 0, 0, 0.25)',
                position: 'relative',
                flexDirection: 'column',
                overflow: 'hidden',
                px: '10px',
            }}
        >
            <Box sx={{ height: '134px', overflow: 'hidden' }} display="flex" width="100%" pt="70px">
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: surveyTitle?.length > 32 ? '16px' : '20px',
                        lineHeight: '22px',
                        color: 'white',
                        width: '100%',
                        fontFamily: 'Nunito',
                    }}
                >
                    {surveyTitle ? surveyTitle.slice(0, 50) : 'Conosciamoci meglio'}
                </Typography>
            </Box>
            <Box
                className="noScrollBar"
                sx={{
                    maxWidth: '220px',
                    mt: '12px',
                    width: '100%',
                    maxHeight: '340px',
                    overflowY: 'scroll',
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: 'black',
                        textAlign: 'center',
                        fontFamily: 'Nunito',
                    }}
                >
                    {questionData?.question}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '10px',
                        lineHeight: '12px',
                        color: 'black',
                        textAlign: 'center',
                        mt: '8px',
                        fontFamily: 'Nunito',
                    }}
                >
                    {questionData?.caption}
                </Typography>

                {(questionData?.questionType === 'choiceList' ||
                    questionData?.questionType === 'multipleChoiceList' ||
                    questionData?.questionType === 'rankingResponse') && (
                    <ChoiceListPreview
                        useGridLayout={questionData?.useGridLayout}
                        responses={questionData?.responses}
                    />
                )}

                {questionData?.questionType === 'textArea' && <TextAreaPreview />}

                {questionData?.questionType === 'date' && <DatePreview />}

                {(questionData?.questionType === 'rating' || questionData?.questionType === 'multipleRating') && (
                    <RatingPreview responses={questionData?.responses} />
                )}

                {(questionData?.questionType === 'imageChoiceList' ||
                    questionData?.questionType === 'multipleImageChoiceList') && (
                    <ImageChoiceListPreview
                        responses={questionData?.responses}
                        useGridLayout={questionData?.useGridLayout}
                    />
                )}

                {questionData?.questionType === 'boolean' && <YesNoPreview type={questionData?.extraData?.iconType} />}
            </Box>
            <Typography
                sx={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                    position: 'absolute',
                    bottom: 22,
                    color: 'white',
                    textAlign: 'center',
                    fontFamily: 'Nunito',
                }}
            >
                {ctaText.slice(0, 20)}
            </Typography>
        </Box>
    );
};

export default QuestionPreview;
