import React from 'react';
import { Box } from '@mui/material';
import dateQuestionItem from '~/assets/images/tools/surveyTool/dateItemQuestion.png';

const DatePreview = () => {
    return (
        <Box
            component="img"
            src={dateQuestionItem}
            sx={{
                width: '100%',
                objectFit: 'contain',
                mt: '30px',
            }}
        />
    );
};

export default DatePreview;
