import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useFileReader from '~js/hooks/useFileReader';
import PreviewQuizHeader from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewQuizHeader';
import { PreviewAnswerCTA } from './PreviewAnswerCTA';
import { PreviewAnswerPaginator } from './PreviewAnswerPaginator';

import cardQuizQuestionDefault from '~/assets/images/tools/quizTool/cardQuizQuestionImageDefault.svg';
import coccoleCoin from '~/assets/images/tools/coccoleCoin.png';

const PreviewQuestionScreen = ({
    headerTitle = 'Quiz',
    headerSubTitle,
    endDate,
    image,
    question,
    positiveAnswer,
    negativeAnswer,
    points,
}) => {
    const { fileUrl: imageUrl } = useFileReader(image);
    const [highlighted, setHighlighted] = useState(null);
    const handleChange = (ev, value) => {
        switch (value) {
            case 1:
                setHighlighted('positive');
                break;
            case 3:
                setHighlighted('negative');
                break;
            case 2:
            default:
                setHighlighted(null);
                break;
        }
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '250px',
                    height: '540px',
                    borderRadius: '22px',
                    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.25)',
                    position: 'relative',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                {/* Quiz Screen Header content */}
                <PreviewQuizHeader title={headerTitle} subTitle={headerSubTitle} endDate={endDate} />

                {/* Quiz Question Body */}
                <Box px={'10px'} width={'100%'} height={'100%'} mb={4}>
                    <Box
                        position={'relative'}
                        height={'100%'}
                        overflow={'hidden'}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        bgcolor={'#FFFFFF'}
                        //boxShadow={'0px 0px 4px 0px rgba(69, 70, 78, .5)'}
                        borderRadius={'24px'}
                        border={'.5px solid #dfdfe4'}
                        my={2}
                    >
                        <Box
                            component="img"
                            src={imageUrl ?? cardQuizQuestionDefault}
                            sx={{
                                position: 'absolute',
                                objectFit: 'contain',
                                objectPosition: 'top',
                                width: '100%',
                                height: '100%',
                                zIndex: 0,
                                filter: imageUrl ? 'none' : 'grayscale(100%);',
                            }}
                        />
                        <Box
                            sx={{
                                zIndex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'end',
                                width: '100%',
                            }}
                        >
                            <Box
                                px={1}
                                py={0.5}
                                m={2}
                                bgcolor={highlighted === 'positive' ? (theme) => theme.palette.primary.main : 'white'}
                                boxShadow={highlighted === 'positive' ? '0px 0px 3px 0px rgb(58,206,144)' : 'none'}
                                border={1}
                                borderRadius={24}
                                borderColor={(theme) => theme.palette.primary.main}
                                width={'fit-content'}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito',
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                        lineHeight: '14px',
                                        textAlign: 'center',
                                        color:
                                            highlighted !== 'positive'
                                                ? (theme) => theme.palette.primary.main
                                                : '#FFFFFF',
                                    }}
                                >
                                    +{points}
                                    <Box
                                        component="img"
                                        src={coccoleCoin}
                                        sx={{
                                            width: '14px',
                                            height: '14px',
                                            objectFit: 'contain',
                                            ml: '4px',
                                            mb: '-2px',
                                        }}
                                    />
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ zIndex: 1, width: '100%' }}>
                            <Box mx={2} my={2}>
                                <Grid container>
                                    <Grid item xs={12} mb={3}>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Nunito',
                                                fontWeight: 'bold',
                                                fontSize: '14px',
                                                lineHeight: '16px',
                                                color: (theme) => theme.palette.text.primary,
                                                textAlign: 'center',
                                            }}
                                        >
                                            {question}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent={'space-around'}>
                                    <PreviewAnswerCTA
                                        answer={positiveAnswer}
                                        icon={<ChevronLeftIcon />}
                                        iconAlign={'start'}
                                        highlighted={highlighted === 'positive'}
                                    />
                                    <PreviewAnswerCTA
                                        answer={negativeAnswer}
                                        icon={<ChevronRightIcon />}
                                        highlighted={highlighted === 'negative'}
                                    />
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* Custom paginator for answer state */}
            <Box
                mt={2}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '250px',
                }}
            >
                <PreviewAnswerPaginator
                    paginationProps={{
                        count: 3,
                        defaultPage: 2,
                        onChange: handleChange,
                    }}
                />
            </Box>
        </Box>
    );
};

export default PreviewQuestionScreen;
