import React from 'react';
import QuizCreate from '~js/pages/backoffice/Tools/Quiz/QuizCreate';

const QuizDuplicate = ({ clusters, events, testerGroups, quizToDuplicate }) => {
    console.log('QuizDuplicate props', { clusters, events, testerGroups, quizToDuplicate });

    const clusterGroupAdministrationStep = quizToDuplicate.audience?.active
        ? quizToDuplicate.audience.definition.map((cg) => {
              const operatorWithPrevious = Object.keys(cg)[0];
              const operatorInsideGroup = Object.keys(cg[operatorWithPrevious])[0];
              const clusters = cg[operatorWithPrevious][operatorInsideGroup];
              return { operatorWithPrevious, operatorInsideGroup, clusters };
          })
        : [
              {
                  operatorWithPrevious: 'AND',
                  operatorInsideGroup: 'AND',
                  clusters: [' '],
              },
          ];

    const defaultValue = {
        general: {
            title: quizToDuplicate?.general?.title,
            description: quizToDuplicate?.general?.description,
            typology: quizToDuplicate?.general?.typology,
            startDate: new Date(quizToDuplicate?.general?.startDate),
            endDate: new Date(quizToDuplicate?.general?.endDate),
            points: quizToDuplicate?.general?.points,
        },
        introduction: {
            ...quizToDuplicate.introduction,
            ...(quizToDuplicate.introduction?.time
                ? {
                      time: {
                          ...quizToDuplicate.introduction.time,
                          introDescription: JSON.stringify(
                              quizToDuplicate.introduction.time.introDescription.editorState,
                          ),
                      },
                  }
                : {}),
        },
        quizQuestions: {
            questions: quizToDuplicate?.quizQuestions.questions,
        },
        closure: {
            ...quizToDuplicate.closure,
            ...(typeof quizToDuplicate.closure.inDepth.description === 'object'
                ? {
                      inDepth: {
                          ...quizToDuplicate.closure.inDepth,
                          description: JSON.stringify(quizToDuplicate.closure.inDepth.description.editorState),
                      },
                  }
                : {}),
        },
        administration: {
            audience: {
                active: quizToDuplicate?.audience?.active ?? false,
                clustersGroups: clusterGroupAdministrationStep,
            },
            event: quizToDuplicate?.general?.event || '',
            isEventQuiz: quizToDuplicate?.general?.event !== 'none',
            groupTest: quizToDuplicate?.general?.groupTest || [],
            realTimeActivation: quizToDuplicate?.audience?.realTimeActivation || false,
        },
        communication: {
            isEmailActive: quizToDuplicate?.general?.isEmailActive,
            isNotificationActive: quizToDuplicate?.general?.isNotificationActive,
        },
    };
    return (
        <QuizCreate
            clusters={clusters}
            events={events}
            testerGroups={testerGroups}
            defaultValueDuplicateQuiz={defaultValue}
        />
    );
};

export default QuizDuplicate;
