import React, { useState } from 'react';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { CustomTextInput } from '~js/components/TextInput';

const TesterGroupEmailInput = ({
    label = null,
    type = 'text',
    error,
    helperText,
    endAdornment = null,
    onChange,
    triggerValidation,
    addEmail,
    errorText,
    disabled,
}) => {
    const [inputValue, setInputValue] = useState('');

    const handleChangeInput = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            onChange(inputValue);
            const isValidEmail = await triggerValidation();
            if (isValidEmail) {
                await addEmail(inputValue);
                setInputValue('');
            }
        }
    };

    return (
        <FormControl variant="standard" fullWidth error={error} sx={{ margin: '12px 0' }}>
            {label && (
                <InputLabel shrink htmlFor="input" sx={{ color: '#5E6366', pl: 1 }}>
                    {label}
                </InputLabel>
            )}
            <CustomTextInput
                disabled={disabled}
                id="input"
                type={type}
                sx={
                    errorText &&
                    error && {
                        boxShadow: (theme) => `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
                        borderColor: (theme) => theme.palette.error.main,
                    }
                }
                value={inputValue}
                onChange={handleChangeInput}
                onKeyDown={handleKeyDown}
            />

            {helperText && <FormHelperText sx={{ pl: 1 }}>{helperText}</FormHelperText>}
            {errorText && <FormHelperText sx={{ pl: 1 }}>{errorText}</FormHelperText>}
        </FormControl>
    );
};

export default TesterGroupEmailInput;
