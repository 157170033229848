import React, { forwardRef } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const CustomInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 5,
        position: 'relative',
        backgroundColor: 'rgba(233, 233, 233, 1)',
        //border: "1px solid #8B8D97",
        fontSize: 16,
        width: '100%',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const ClusterSelectInput = forwardRef(
    ({ label = null, type = 'text', error, helperText, selectOptions = [], defaultValue = '', ...other }, ref) => {
        return (
            <FormControl variant="standard" fullWidth error={error} sx={{ margin: '0' }}>
                {label && (
                    <InputLabel shrink htmlFor="adv-input" sx={{ color: '#5E6366', pl: 1 }}>
                        {label}
                    </InputLabel>
                )}
                <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    input={<CustomInput />}
                    defaultValue={defaultValue}
                    displayEmpty
                    sx={
                        error
                            ? {
                                  boxShadow: (theme) => `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
                                  borderColor: (theme) => theme.palette.error.main,
                              }
                            : {}
                    }
                    {...other}
                    ref={ref}
                >
                    {selectOptions?.map((op, index) => {
                        return (
                            <MenuItem
                                key={`${op.value}-${index}`}
                                value={op.value}
                                sx={{
                                    borderTop: '1px solid',
                                    borderColor: index > 0 ? '#dfdfe4' : 'transparent',
                                }}
                            >
                                {op.label}
                            </MenuItem>
                        );
                    })}
                </Select>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        );
    },
);

export default ClusterSelectInput;
