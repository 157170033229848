import React, { useMemo } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, IconButton, Typography } from '@mui/material';
import { trans } from 'matice';
import ToolsUserForm from './ToolsUserForm';
import CustomModal from '~js/components/CustomModal';
import EditIcon from '@mui/icons-material/Edit';
import { router } from '@inertiajs/react';
import { toast } from 'react-toastify';

const UpdateToolsUserModal = ({ open, handleClose, toolUser, toolsRoles }) => {
    const updateToolUser = async ({ name, surname, roles }) =>
        new Promise((resolve, reject) => {
            router.patch(
                route('ToolsUsers.update', { user: toolUser.id }),
                {
                    name,
                    surname,
                    roleIds: roles.map((role) => role.value),
                },
                {
                    onSuccess: () => {
                        toast.success(trans('settings.tools-users.update-user-modal.success-message'));

                        handleClose();

                        resolve();
                    },
                    onError: (e) => {
                        toast.error(
                            !!e?.generic
                                ? e.generic
                                : trans('settings.tools-users.update-user-modal.generic-error-message'),
                        );
                        reject(e);
                    },
                },
            );
        });

    const updatingUser = useMemo(
        () => ({
            ...toolUser,
            roles: toolUser?.roles.map(({ id, name }) => ({
                value: id,
                label: name,
            })),
        }),
        [toolUser],
    );

    return (
        <CustomModal
            open={open}
            onClose={handleClose}
            sx={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: '700px', borderRadius: 2.3 }}
        >
            <Box maxWidth="500px" width="100%">
                <Box display="flex" justifyContent="space-between" flexWrap="wrap" mb={4}>
                    <Box width={'52px'} />
                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                        <Box
                            borderRadius={'100%'}
                            width={50}
                            height={50}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{
                                backgroundColor: (theme) => theme.palette.primary.main,
                            }}
                        >
                            <EditIcon htmlColor={'white'} />
                        </Box>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {trans(`settings.tools-users.update-user-modal.title`)}
                        </Typography>
                    </Box>
                    <Box width={52} height={52}>
                        <IconButton onClick={handleClose}>
                            <CancelIcon color="primary" fontSize="large" />
                        </IconButton>
                    </Box>
                </Box>

                <ToolsUserForm
                    mode={'update'}
                    toolsRoles={toolsRoles}
                    toolUser={updatingUser}
                    onSubmitPress={updateToolUser}
                    onCancelPress={handleClose}
                />
            </Box>
        </CustomModal>
    );
};

export default UpdateToolsUserModal;
