import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import TextInput from '~js/components/TextInput';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';
import SurveyContext from '../context';

const QuizQuestionInput = ({ questionIndex }) => {
    const { formDisabled } = useContext(SurveyContext);

    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext();

    const fieldBasePath = `questions.${questionIndex}`;
    const errorsBasePath =
        errors?.questions && errors.questions[questionIndex] ? errors.questions[questionIndex] : null;

    return (
        <>
            <Controller
                control={control}
                name={`${fieldBasePath}.question`}
                defaultValue={watch(`${fieldBasePath}.question`) || ''}
                shouldUnregister
                rules={{
                    required: trans('formValidation.requiredField'),
                    maxLength: {
                        value: 255,
                        message: trans('formValidation.fieldTooLong'),
                    },
                    pattern: { value: REGEX_ONLY_PLAIN_CHAR, message: trans('formValidation.charNotAllowed') },
                }}
                render={({ field }) => (
                    <TextInput
                        disabled={formDisabled}
                        label={trans('quiz.title')}
                        placeholder={trans('quiz.title')}
                        helperText={errorsBasePath && errorsBasePath?.question?.message}
                        error={!!errorsBasePath?.question}
                        {...field}
                    />
                )}
            />
            {/*  <Controller
                control={control}
                name={`${fieldBasePath}.caption`}
                defaultValue=""
                shouldUnregister
                rules={{
                    maxLength: {
                        value: 255,
                        message: trans("formValidation.fieldTooLong"),
                    },
                }}
                render={({ field }) => (
                    <TextInput
                        label={trans("survey.description")}
                        placeholder={trans("survey.description")}
                        helperText={
                            errorsBasePath && errorsBasePath?.caption?.message
                        }
                        error={!!errorsBasePath?.caption}
                        {...field}
                        ref={null}
                    />
                )}
            />*/}
        </>
    );
};

export default QuizQuestionInput;
