import { useEffect, useState } from 'react';

export const getFileUrl = async (file) =>
    await new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            resolve(reader.file);
        });
        reader.readAsDataURL(file);
    });

const useFileReader = (file) => {
    const [fileUrl, setFileUrl] = useState(null);

    useEffect(() => {
        if (file && typeof file === 'object') {
            (async () => setFileUrl(await getFileUrl(file)))();
            return;
        }

        if (!!file && typeof file === 'string') {
            setFileUrl(file);
            return;
        }

        setFileUrl(null);
    }, [file]);

    return { fileUrl, setFileUrl };
};
export default useFileReader;
