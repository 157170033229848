import React, { useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Controller, useForm } from 'react-hook-form';
import { trans } from 'matice';
import CtaButton from '~js/components/CtaButton';
import CustomModal from '~js/components/CustomModal';
import SelectInput from '~js/components/SelectInput';

const ChangeStatusAlertModal = ({
    show,
    onClose,
    title,
    description = '',
    onConfirm,
    loading,
    confirmButtonText = trans('global.confirm'),
    cancelButtonText = trans('global.cancel'),
    containerSx = {},
    testerGroups,
    selectedGroupsTest = [],
    showGroupTestSelect = false,
}) => {
    const groupTestOptions = testerGroups?.map((e) => ({
        _id: e._id,
        label: e.name,
        value: e._id,
    }));

    const {
        control,
        trigger,
        formState: { errors },
        watch,
        reset,
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            groupTest: selectedGroupsTest,
        },
    });

    useEffect(() => {
        if (selectedGroupsTest) {
            reset({ groupTest: selectedGroupsTest });
        }
    }, [selectedGroupsTest]);

    const onCloseHandler = () => {
        if (loading) {
            return;
        }
        onClose();
    };

    const onConfirmHandler = async () => {
        if (showGroupTestSelect) {
            const isValid = await trigger('groupTest');
            isValid && onConfirm(watch('groupTest'));
            return;
        }

        onConfirm([]);
    };
    return (
        <CustomModal
            open={show}
            handleClose={onCloseHandler}
            sx={{ maxWidth: '500px', borderRadius: 3, p: 0, ...containerSx }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItem: 'center',
                    justifyContent: 'center',
                    p: 1,
                }}
                //style={{ backgroundImage: `url(${bgimage})` }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mb: 1,
                    }}
                >
                    <IconButton onClick={onCloseHandler}>
                        <CancelIcon fontSize="large" color="primary" />
                    </IconButton>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                    <Box
                        sx={{
                            bgcolor: (theme) => theme.palette.primary.main,
                            width: 30,
                            height: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 500,
                            p: 2.5,
                        }}
                    >
                        <SaveAsIcon sx={{ color: 'white' }} />
                    </Box>
                </Box>
                <Box mx={2}>
                    <Typography variant="h5" sx={{ fontWeight: 700, textAlign: 'center' }}>
                        {title}
                    </Typography>
                    <Typography sx={{ textAlign: 'center', mt: 2 }}>{description}</Typography>
                </Box>

                {showGroupTestSelect && (
                    <Box px={4} mt={4}>
                        <Controller
                            control={control}
                            name={`groupTest`}
                            rules={{
                                required: trans('formValidation.requiredField'),
                            }}
                            render={({ field }) => (
                                <SelectInput
                                    multiple
                                    //label={trans('survey.selectGroupTest')}
                                    selectOptions={groupTestOptions}
                                    helperText={errors?.groupTest?.message}
                                    error={!!errors?.groupTest}
                                    {...field}
                                />
                            )}
                        />
                    </Box>
                )}
                <Box
                    sx={{
                        mb: 5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        flexWrap: 'wrap',
                        mt: 5,
                        gap: 2,
                    }}
                >
                    {!!onClose && (
                        <CtaButton onClick={onCloseHandler} sx={{ px: 6 }} variant="outlined" disabled={loading}>
                            {cancelButtonText}
                        </CtaButton>
                    )}
                    {!!onConfirm && (
                        <CtaButton onClick={onConfirmHandler} sx={{ px: 6 }} variant="contained" loading={loading}>
                            {confirmButtonText}
                        </CtaButton>
                    )}
                </Box>
            </Box>
        </CustomModal>
    );
};

export default ChangeStatusAlertModal;
