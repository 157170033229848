import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';

export const PreviewAnswerCTA = ({ answer, icon, highlighted = false, iconAlign = 'end' }) => {
    const theme = useTheme();
    const color = !highlighted ? 'transparent' : iconAlign === 'start' ? 'rgb(58,206,144)' : 'rgb(238,87,124)';
    const colorOutline = !highlighted ? theme.palette.primary.main : 'white';
    const Icon = React.cloneElement(icon, { sx: { height: 16, color: colorOutline } });
    return (
        <Grid
            item
            xs={5}
            p={0.5}
            px={1}
            minHeight={30}
            borderRadius={24}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            overflow={'hidden'}
            bgcolor={color}
            border={`1px solid ${colorOutline}`}
        >
            {!!icon && iconAlign === 'start' && Icon}
            <Typography
                sx={{
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    fontSize: '10px',
                    lineHeight: '12px',
                    textAlign: 'center',
                    width: '100%',
                    color: colorOutline,
                }}
            >
                {answer}
            </Typography>
            {!!icon && iconAlign === 'end' && Icon}
        </Grid>
    );
};
