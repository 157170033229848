import React from 'react';
import { Box, Modal } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xl: '70%', lg: '70%', md: '80%', sm: '90%', xs: '95%' },
    maxWidth: '900px',
    bgcolor: 'background.paper',
    //border: '2px solid #000',
    //boxShadow: 24,
    p: 4,
    borderRadius: 1,
    maxHeight: '80%',
    overflow: 'scroll',
};

const CustomModal = ({ open, handleClose, children, sx }) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box className="noScrollBar" sx={{ ...style, ...sx }}>
                {children}
            </Box>
        </Modal>
    );
};

export default CustomModal;
