import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import CustomModal from './CustomModal';
import CtaButton from './CtaButton';

const SuccessModal = ({ open, handleClose = null, title, description, buttonText, onPressButton, onExitPress }) => {
    return (
        <CustomModal open={open} handleClose={handleClose} sx={{ maxWidth: '500px', borderRadius: 3, p: 0 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItem: 'center',
                    justifyContent: 'center',
                    p: 1,
                }}
                //style={{ backgroundImage: `url(${bgimage})` }}
            >
                {handleClose ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            mb: 1,
                        }}
                    >
                        <IconButton onClick={onExitPress}>
                            <CancelIcon fontSize="large" color="primary" />
                        </IconButton>
                    </Box>
                ) : (
                    <Box pt={4} />
                )}
                <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                    <Box
                        sx={{
                            bgcolor: (theme) => theme.palette.primary.main,
                            width: 30,
                            height: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 500,
                            p: 2.5,
                        }}
                    >
                        <DoneAllOutlinedIcon sx={{ color: 'white' }} />
                    </Box>
                </Box>
                <Typography variant="h4" sx={{ fontWeight: 700, textAlign: 'center' }}>
                    {title}
                </Typography>
                <Typography sx={{ textAlign: 'center', mt: 1 }}>{description}</Typography>
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    <CtaButton onClick={onPressButton} variant="contained" sx={{ mt: 10 }}>
                        {buttonText}
                    </CtaButton>
                </Box>
            </Box>
        </CustomModal>
    );
};

export default SuccessModal;
