import React from 'react';
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { trans } from 'matice';

const TesterEmailTable = ({ emails, removeEmail, canDeleteTester = false }) => {
    return (
        <Box my={5}>
            <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                {trans('surveyToolSettings.addedEmailsList')}
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                            <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                                Stato
                            </TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {emails.map((row) => (
                            <TableRow key={row.email} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {row.email}
                                </TableCell>
                                <TableCell align="left">
                                    {row.status === 'active' && (
                                        <Typography sx={{ color: '#1A8A62' }}>{trans('global.accepted')}</Typography>
                                    )}
                                    {row.status === 'pending' && (
                                        <Typography sx={{ color: '#909090' }}>{trans('global.pending')}</Typography>
                                    )}
                                    {row.status === 'expired' && (
                                        <Typography sx={{ color: '#CC0000' }}>{trans('global.expired')}</Typography>
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton disabled={!canDeleteTester} onClick={() => removeEmail(row)}>
                                        <DeleteOutlineOutlinedIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default TesterEmailTable;
