import React from 'react';

const EventsShow = () => {
    return (
        <div>
            <p>
                This is a React component in{' '}
                <b>
                    <i>EventsShow page</i>
                </b>
                !
            </p>
            <hr />
            <p>Implement the Show logic here!</p>
        </div>
    );
};

export default EventsShow;
