import React, { useState } from "react";
import { Head, router } from "@inertiajs/react";
import { Box, Divider, Grid, List, ListItemText, ListSubheader } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { trans } from "matice";
import { PhotoAccountInput, TelephoneNumberInput, TextInput } from "~js/components/input";
import CtaButton from "~js/components/CtaButton";
import ToolContentPageLayout from "~js/layouts/Tools/ToolContentPageLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import LogoutIcon from "@mui/icons-material/Logout";
import EditPasswordModal from "./components/EditPasswordModal";
import type { BreadcrumbList } from "~js/components/Breadcrumbs";

const phoneRegExp =
    /^((\\+ *)|(\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const breadcrumbs: BreadcrumbList = [
    {
        routeName: "Account.index",
        routeLabel: "Account"
    }
];

const AccountIndex = ({ user, roles }) => {

    const SchemaValidation = yup.object().shape({
        name: yup
            .string()
            .min(3, trans("formValidation.fieldTooShort"))
            .matches(/^([A-Za-zèéàùòì'’‘’ \,]*)$/, trans("formValidation.nameNotValid"))
            .required(trans("formValidation.requiredField")),
        surname: yup
            .string()
            .min(3, trans("formValidation.fieldTooShort"))
            .matches(/^([A-Za-zèéàùòì'’‘’ \,]*)$/, trans("formValidation.surnameNotValid")),
        phoneNumber: yup.string().nullable().matches(phoneRegExp, trans("formValidation.telephoneNumberNotValid"))
    });

    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
        setError,
        setValue
    } = useForm({
        defaultValues: {
            name: user?.name || "",
            surname: user?.surname || "",
            email: user?.email || "",
            phoneNumber: user?.phoneNumber || "",
            avatar: user?.avatar || ""
        },
        resolver: yupResolver(SchemaValidation)
    });
    const [isLoading, setIsLoading] = useState(false);
    const [showModalEditPassword, setShowModalEditPassword] = useState(false);
    const [loadingLogout, setLoadingLogout] = useState(false);

    const openModalEditPassword = () => {
        setShowModalEditPassword(true);
    };
    const closeModalEditPassword = () => {
        setShowModalEditPassword(false);
    };

    const logout = () => {
        router.get(
            route("logout"),
            {},
            {
                onStart: () => setIsLoading(true),
                onError: () => setIsLoading(false)
            }
        );
    };

    const changePassword = async (oldPassword, newPassword) => {
        const promise = new Promise((resolve, reject) =>
            router.patch(
                route("Account.updateUserPassword"),
                { oldPassword, newPassword },
                {
                    onError: (e) => {
                        reject(e);
                        if (!!e?.generic) {
                            toast.error(e.generic);
                        }
                    },
                    onSuccess: () => {
                        resolve();
                        toast.success(trans("surveyToolSettings.passwordUpdated"));
                        setShowModalEditPassword(false);
                        //setShowModalSuccess(true);
                    }
                }
            )
        );
        await promise;
    };

    const onSubmit = (data) => {
        router.post(
            route("Settings.updateUserProfile"),
            { _method: "patch", ...data },
            {
                onStart: () => setIsLoading(true),
                onFinish: () => setIsLoading(false),
                onError: (e) => {
                    if (!!e?.generic) {
                        toast.error(e.generic);
                        return;
                    }
                    Object.keys(e).forEach((key) => {
                        setError(key, { type: "custom", message: e[key] });
                    });
                },
                onSuccess: () => {
                    toast.success(trans("surveyToolSettings.accountUpdated"));
                }
            }
        );
    };
    return (
        <>
            <Head title={trans("surveyToolSettings.account")} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                <Box display="flex" justifyContent="flex-end">
                    <CtaButton sx={{ textDecoration: "underline" }} onClick={logout} loading={loadingLogout}>
                        <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
                        Log out
                    </CtaButton>
                </Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container columnSpacing={4} rowSpacing={2}>
                        <Grid item xs={12} sm={12} md={3} textAlign={"center"} display={"flex"} flexDirection={"column"}
                              justifyContent={"center"} alignItems={"center"}>
                            <PhotoAccountInput
                                avatarUrl={watch("avatar")}
                                onChange={(imgData) => setValue("avatar", imgData)}
                                errorMessage={errors?.avatar?.message}
                            />
                            <Divider sx={{ my: 2 }} />
                            <List
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        Ruoli:
                                    </ListSubheader>
                                }
                            >
                                {roles.map((name, index) => (<ListItemText key={index.toString()}
                                                                           secondary={name} />))}
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box maxWidth="500px">
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({ field }) => (
                                        <TextInput
                                            label={trans("surveyToolSettings.name")}
                                            helperText={errors?.name?.message}
                                            error={!!errors?.name}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="surname"
                                    render={({ field }) => (
                                        <TextInput
                                            label={trans("surveyToolSettings.surname")}
                                            helperText={errors?.surname?.message}
                                            error={!!errors?.surname}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="email"
                                    render={({ field }) => (
                                        <TextInput
                                            disabled
                                            label={trans("surveyToolSettings.email")}
                                            helperText={errors?.email?.message}
                                            error={!!errors?.email}
                                            {...field}
                                        />
                                    )}
                                />

                                <Controller
                                    control={control}
                                    name="phoneNumber"
                                    render={({ field }) => (
                                        <TelephoneNumberInput
                                            label={trans("surveyToolSettings.phoneNumber")}
                                            helperText={errors?.phoneNumber?.message}
                                            error={!!errors?.phoneNumber}
                                            {...field}
                                            ref={null}
                                        />
                                    )}
                                />
                                <CtaButton sx={{ textDecoration: "underline", p: 0 }} onClick={openModalEditPassword}>
                                    {trans("surveyToolSettings.editPassword")}
                                </CtaButton>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flexWrap: "wrap",
                            mt: 5
                        }}
                    >
                        <CtaButton variant="contained" type="submit" loading={isLoading}>
                            {trans("global.saveChanges")}
                        </CtaButton>
                    </Box>
                </form>
                <EditPasswordModal
                    show={showModalEditPassword}
                    onClose={closeModalEditPassword}
                    changePassword={changePassword}
                />
            </ToolContentPageLayout>
        </>
    );
};

export default AccountIndex;
