import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import unionIcon from '~/assets/images/icons/unionIcon.png';
import intersectIcon from '~/assets/images/icons/intersectIcon.png';

const AndOrButton = ({ bgcolor, sx = {}, onChange = () => {}, value = 'AND', disabled = false }) => {
    const [internalValue, setInternalValue] = useState(value);

    useEffect(() => {
        if (value) {
            setInternalValue(value);
        }
    }, [value]);

    const onClickButton = () => {
        const newVal = internalValue === 'AND' ? 'OR' : 'AND';
        setInternalValue(newVal);
        onChange(newVal);
    };
    return (
        <IconButton
            sx={{
                width: '105px',
                borderRadius: 1,
                border: '1px solid #dfdfe4',
                color: 'white',
                bgcolor: bgcolor,
                ' &:hover': {
                    bgcolor: bgcolor,
                },
                ...sx,
            }}
            onClick={onClickButton}
            disabled={disabled}
        >
            <Typography fontSize="12px">{internalValue === 'AND' ? 'INTERSECT' : 'UNION'}</Typography>
            <Box component="img" src={internalValue === 'AND' ? intersectIcon : unionIcon} width="20px" ml="5px" />
        </IconButton>
    );
};

export default AndOrButton;
