import React, { forwardRef } from 'react';
import { Box, Slider, Typography } from '@mui/material';

const MIN = 1;
const MAX = 100000;

const CoverageSliderInput = forwardRef(({ value, onChange }, ref) => {
    const handleChange = (event, newValue) => {
        onChange(newValue);
    };
    return (
        <Box sx={{ border: '1px solid #E1E2E9', p: 2, borderRadius: 1, mt: 1 }}>
            <Slider
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                // getAriaValueText={valuetext}
                max={MAX}
                min={MIN}
                ref={ref}
            />
            <Box display="flex" justifyContent="space-between">
                <Typography fontSize="12px" color="#83898C">
                    {MIN}
                </Typography>
                <Typography fontSize="12px" color="#83898C">
                    {MAX}
                </Typography>
            </Box>
        </Box>
    );
});

export default CoverageSliderInput;
