import React, { useContext, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { trans, transChoice } from 'matice';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Controller, useFormContext } from 'react-hook-form';
import ConfirmCancelModal from '~js/components/ConfirmCancelModal';
import dragIcon from '~/assets/images/icons/dragIcon.png';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';
import { TextInput } from '~js/components/input';
import PreviewClassicQuestionScreen from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewClassicQuestionScreen';
import { ASPECT_RATIO_IMG, getImageUploadTooltip, Images, ONE_MB } from '~js/constants/images.jsx';
import FileUploaderInput from '~js/components/FileUploaderInput';
import { clientQuizStatus } from '~js/constants/missionStatuses';
import CtaButton from '~js/components/CtaButton';

const MAX_POINTS_PER_QUESTION = 100;

const accordionIconSx = {
    height: 24,
    width: 24,
    zIndex: 99,
    py: '0px',
    px: '6px',
    borderRadius: '50%',
    fontWeight: 'bold',
    backgroundColor: (theme) => theme.palette.primary.iconBackground,
    color: (theme) => theme.palette.primary.main,
};

const ClassicQuestionForm = ({
    currentFocusedQuestion,
    questionIndex,
    deleteQuestion,
    deleteButtonDisabled,
    duplicateQuestion,
    onArrowUpPress,
    onArrowDownPress,
    setCurrentFocusedQuestion,
}) => {
    const { formDisabled, quizTitle, quizEndDate, currentClientQuizStatus } = useContext(QuizContext);

    const {
        control,
        setError,
        clearErrors,
        formState: { errors },
        watch,
        trigger,
    } = useFormContext();

    const [openModalDeleteQuestion, setOpenModalDeleteQuestion] = useState(false);
    const [openModalDuplicateQuestion, setOpenModalDuplicateQuestion] = useState(false);
    const [openModalMoveQuestion, setOpenModalMoveQuestion] = useState(null);

    const quizQuestionsKey = `questions`;
    const quizQuestionKey = `${quizQuestionsKey}[${questionIndex}]`;
    const itemErrors = errors?.[quizQuestionsKey]?.[questionIndex];

    const collapseQuestion = () => {
        trigger(quizQuestionKey).then((isValid) => {
            if (isValid) {
                setCurrentFocusedQuestion(null);
            }
        });
    };

    const onConfirmDuplicateQuestion = () => {
        duplicateQuestion();
        if (!!openModalDuplicateQuestion) {
            setOpenModalDuplicateQuestion(false);
        }
    };

    const onConfirmMoveQuestion = () => {
        if (openModalMoveQuestion === 'down') {
            onArrowDownPress();
        }
        if (openModalMoveQuestion === 'up') {
            onArrowUpPress();
        }
        if (!!openModalMoveQuestion) {
            setOpenModalMoveQuestion(null);
        }
    };

    const toggleDeleteQuestionModal = () => setOpenModalDeleteQuestion((val) => !val);
    const toggleDuplicateQuestionModal = () => setOpenModalDuplicateQuestion((val) => !val);
    const closeMoveQuestionModal = () => setOpenModalMoveQuestion(null);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box
                width="100%"
                sx={{
                    border:
                        errors?.questions && errors.questions[questionIndex]
                            ? '1px solid #f44336'
                            : '1px solid #dfdfe4',
                    borderRadius: 1,
                    bgcolor: 'white',
                }}
            >
                <Box display="flex" justifyContent="center">
                    <Box component="img" src={dragIcon} sx={{ maxWidth: '25px', objectFit: 'contain', mt: 1 }} />
                </Box>

                <Box sx={{ px: 2 }}>
                    <Accordion elevation={0} disableGutters expanded={currentFocusedQuestion === questionIndex}>
                        <AccordionSummary
                            sx={{
                                '&.Mui-focusVisible': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                                <Grid container columnSpacing={2} alignItems={'center'}>
                                    <Grid
                                        item
                                        xs={12}
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                    >
                                        <Box display={'flex'} flexDirection={'row'}>
                                            {currentFocusedQuestion !== questionIndex ? (
                                                <KeyboardArrowDownIcon
                                                    sx={accordionIconSx}
                                                    onClick={() => setCurrentFocusedQuestion(questionIndex)}
                                                />
                                            ) : (
                                                <KeyboardArrowUpIcon
                                                    sx={accordionIconSx}
                                                    onClick={() => {
                                                        setCurrentFocusedQuestion(questionIndex);
                                                        collapseQuestion();
                                                    }}
                                                />
                                            )}
                                            <Box display={'flex'} flexDirection={'column'} sx={{ pl: 2 }}>
                                                <Typography variant={'subtitle2'} fontWeight={'bold'}>
                                                    {trans('quiz.question')} {questionIndex + 1}
                                                </Typography>
                                                {currentFocusedQuestion !== questionIndex && (
                                                    <Typography
                                                        variant={'caption'}
                                                        color={(theme) => theme.palette.text.secondary}
                                                    >
                                                        {trans('quiz.questionItem.classic.hint')}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>

                                        <Box display="flex" justifyContent="start">
                                            <CtaButton
                                                sx={{ mt: 2, textDecoration: 'underline' }}
                                                onClick={toggleDeleteQuestionModal}
                                                disabled={
                                                    deleteButtonDisabled ||
                                                    formDisabled ||
                                                    currentClientQuizStatus === clientQuizStatus.prodActive ||
                                                    currentClientQuizStatus === clientQuizStatus.prodNotActive
                                                }
                                            >
                                                {trans('quiz.deleteQuestion')}
                                            </CtaButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Grid container>
                                    {/* Question/Response details */}
                                    <Grid item xs={6} sx={{ maxWidth: 500 }}>
                                        {/* Question title */}
                                        <Controller
                                            control={control}
                                            name={`${quizQuestionKey}.question`}
                                            defaultValue={watch(`${quizQuestionKey}.question`) || ''}
                                            rules={{
                                                required: trans('formValidation.requiredField'),
                                                minLength: {
                                                    value: 3,
                                                    message: trans('formValidation.fieldTooShort'),
                                                },
                                                maxLength: {
                                                    value: 50,
                                                    message: trans('formValidation.fieldTooLong'),
                                                },
                                                pattern: {
                                                    value: REGEX_ONLY_PLAIN_CHAR,
                                                    message: trans('formValidation.charNotAllowed'),
                                                },
                                            }}
                                            render={({ field }) => (
                                                <TextInput
                                                    disabled={formDisabled}
                                                    label={trans('quiz.question')}
                                                    placeholder={trans('quiz.question')}
                                                    error={!!itemErrors?.question}
                                                    helperText={
                                                        itemErrors?.question?.message ||
                                                        trans('formValidation.charLimit', {
                                                            args: { chars: 50 },
                                                        })
                                                    }
                                                    inputProps={{ maxLength: 50 }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {/* Positive answer */}
                                        <Controller
                                            control={control}
                                            name={`${quizQuestionKey}.positiveAnswer`}
                                            defaultValue={watch(`${quizQuestionKey}.positiveAnswer`) || ''}
                                            rules={{
                                                required: trans('formValidation.requiredField'),
                                                minLength: {
                                                    value: 3,
                                                    message: trans('formValidation.fieldTooShort'),
                                                },
                                                maxLength: {
                                                    value: 50,
                                                    message: trans('formValidation.fieldTooLong'),
                                                },
                                                pattern: {
                                                    value: REGEX_ONLY_PLAIN_CHAR,
                                                    message: trans('formValidation.charNotAllowed'),
                                                },
                                            }}
                                            render={({ field }) => (
                                                <TextInput
                                                    disabled={formDisabled}
                                                    label={trans('quiz.positiveAnswer')}
                                                    placeholder={trans('quiz.positiveAnswer')}
                                                    error={!!itemErrors?.positiveAnswer}
                                                    helperText={
                                                        itemErrors?.positiveAnswer?.message ||
                                                        trans('formValidation.charLimit', {
                                                            args: { chars: 50 },
                                                        })
                                                    }
                                                    inputProps={{ maxLength: 50 }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {/* Negative answer */}
                                        <Controller
                                            control={control}
                                            name={`${quizQuestionKey}.negativeAnswer`}
                                            defaultValue={watch(`${quizQuestionKey}.positiveAnswer`) || ''}
                                            rules={{
                                                required: trans('formValidation.requiredField'),
                                                minLength: {
                                                    value: 3,
                                                    message: trans('formValidation.fieldTooShort'),
                                                },
                                                maxLength: {
                                                    value: 50,
                                                    message: trans('formValidation.fieldTooLong'),
                                                },
                                                pattern: {
                                                    value: REGEX_ONLY_PLAIN_CHAR,
                                                    message: trans('formValidation.charNotAllowed'),
                                                },
                                            }}
                                            render={({ field }) => (
                                                <TextInput
                                                    disabled={formDisabled}
                                                    label={trans('quiz.negativeAnswer')}
                                                    placeholder={trans('quiz.negativeAnswer')}
                                                    error={!!itemErrors?.negativeAnswer}
                                                    helperText={
                                                        itemErrors?.negativeAnswer?.message ||
                                                        trans('formValidation.charLimit', {
                                                            args: { chars: 50 },
                                                        })
                                                    }
                                                    inputProps={{ maxLength: 50 }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {/* Image */}
                                        <Controller
                                            control={control}
                                            name={`${quizQuestionKey}.image`}
                                            defaultValue=""
                                            rules={{ required: trans('formValidation.requiredField') }}
                                            render={({ field: { onChange, value } }) => (
                                                <FileUploaderInput
                                                    disabled={formDisabled}
                                                    value={value}
                                                    label={trans('quiz.image')}
                                                    tooltipText={getImageUploadTooltip(
                                                        Images.QUIZ_CLASSIC_CARD_BG,
                                                        ONE_MB,
                                                    )}
                                                    onChange={onChange}
                                                    helperText={
                                                        itemErrors?.image?.message ?? trans('quiz.fileMaxSizeHint')
                                                    }
                                                    validateFn={async (value) => {
                                                        if (!value || typeof value !== 'object') {
                                                            clearErrors(`${quizQuestionKey}.image`);
                                                            return true;
                                                        }
                                                        return await new Promise((resolve) => {
                                                            let img = new Image();
                                                            img.src = window.URL.createObjectURL(value);
                                                            img.onload = () => {
                                                                const aspectRatioImgUp = (
                                                                    img.width / img.height
                                                                ).toFixed(1);
                                                                const aspectRatioRule =
                                                                    ASPECT_RATIO_IMG.QUIZ_CLASSIC_CARD_BG.toFixed(1);
                                                                if (aspectRatioImgUp !== aspectRatioRule) {
                                                                    setError(`${quizQuestionKey}.image`, {
                                                                        type: 'custom',
                                                                        message: trans(
                                                                            'formValidation.aspectRatioError',
                                                                            {
                                                                                args: {
                                                                                    aspectRatio: aspectRatioRule,
                                                                                },
                                                                            },
                                                                        ),
                                                                    });
                                                                    resolve(false);
                                                                } else {
                                                                    if (value.size > ONE_MB) {
                                                                        setError(`${quizQuestionKey}.image`, {
                                                                            type: 'custom',
                                                                            message: trans(
                                                                                'formValidation.max1MbFileError',
                                                                                {
                                                                                    args: {
                                                                                        uploadedSize: (
                                                                                            value.size / ONE_MB
                                                                                        ).toFixed(2),
                                                                                    },
                                                                                },
                                                                            ),
                                                                        });
                                                                        resolve(false);
                                                                    } else {
                                                                        clearErrors(`${quizQuestionKey}.image`);
                                                                        resolve(true);
                                                                    }
                                                                }
                                                            };
                                                        });
                                                    }}
                                                    error={!!itemErrors?.image}
                                                />
                                            )}
                                        />
                                        {/* Points (question reward) */}
                                        <Controller
                                            control={control}
                                            name={`${quizQuestionKey}.points`}
                                            defaultValue={watch(`${quizQuestionKey}.points`) || ''}
                                            rules={{
                                                required: trans('formValidation.requiredField'),
                                                min: {
                                                    value: 20,
                                                    message: trans('formValidation.min20Points'),
                                                },
                                                max: {
                                                    value: MAX_POINTS_PER_QUESTION,
                                                    message: trans('formValidation.maxPointsExceeded'),
                                                },
                                                pattern: {
                                                    value: REGEX_ONLY_PLAIN_CHAR,
                                                    message: trans('formValidation.charNotAllowed'),
                                                },
                                            }}
                                            render={({ field }) => (
                                                <TextInput
                                                    disabled={formDisabled}
                                                    inputProps={{ min: 20, max: MAX_POINTS_PER_QUESTION, step: 10 }}
                                                    type="number"
                                                    label={trans('quiz.reward')}
                                                    placeholder={trans('quiz.reward')}
                                                    helperText={itemErrors?.points?.message}
                                                    error={!!itemErrors?.points}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {/* Question Preview */}
                                    <Grid item xs={6}>
                                        <Box
                                            borderRadius={'12px'}
                                            bgcolor={(theme) => theme.palette.background.default}
                                            p={4}
                                            display={'flex'}
                                            justifyContent={'center'}
                                        >
                                            <PreviewClassicQuestionScreen
                                                headerTitle={quizTitle}
                                                headerSubTitle={transChoice(
                                                    'quiz.quizQuestionsCountLabel',
                                                    watch(quizQuestionsKey)?.length ?? 4,
                                                )}
                                                endDate={quizEndDate}
                                                image={watch(`${quizQuestionKey}.image`)}
                                                question={watch(`${quizQuestionKey}.question`)}
                                                points={watch(`${quizQuestionKey}.points`)}
                                                positiveAnswer={watch(`${quizQuestionKey}.positiveAnswer`)}
                                                negativeAnswer={watch(`${quizQuestionKey}.negativeAnswer`)}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Box>

            {/*Modal delete question*/}
            <ConfirmCancelModal
                iconType="delete"
                show={openModalDeleteQuestion}
                onClose={toggleDeleteQuestionModal}
                title={trans('survey.deleteQuestionTitle')}
                onConfirm={deleteQuestion}
            />

            {/*Modal confirm duplicate question in survey multiflow*/}
            {/*}
            <ConfirmCancelModal
                iconType="duplicate"
                show={openModalDuplicateQuestion}
                onClose={toggleDuplicateQuestionModal}
                title={trans('survey.duplicateQuestionTitle')}
                description={trans('survey.duplicateQuestionDescription')}
                onConfirm={onConfirmDuplicateQuestion}
            />
            */}

            {/*Modal confirm move question in survey multiflow*/}
            {/*
            <ConfirmCancelModal
                iconType="move"
                show={!!openModalMoveQuestion}
                onClose={closeMoveQuestionModal}
                title={trans('survey.moveQuestionTitle')}
                description={trans('survey.moveQuestionDescription')}
                onConfirm={onConfirmMoveQuestion}
            />
            */}
        </Box>
    );
};

export default ClassicQuestionForm;
