import React, { useContext } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import ClusterGroup from './ClusterGroup';
import AndOrButton from './AndOrButton';
import SurveyContext from '../context';
import { clientSurveyStatus } from '~js/constants/missionStatuses';

const ComposeClusters = ({ clusters }) => {
    const { currentClientSurveyStatus, formDisabled } = useContext(SurveyContext);

    const clusterOptions = [
        {
            _id: 0,
            label: trans('survey.selectCluster'),
            value: ' ',
        },
        ...clusters?.map((c) => ({
            _id: c._id,
            label: c.title,
            value: c._id,
        })),
    ];

    const { control, watch, setValue } = useFormContext();

    const {
        fields: clusterGroupFields,
        append: clusterGroupAppend,
        remove: clusterGroupRemove,
    } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'clustersGroups', // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
    });

    const onChangeOperatorWithPrevious = (val) => {
        watch('clustersGroups').forEach((cg, index) => setValue(`clustersGroups.${index}.operatorWithPrevious`, val));
    };

    return (
        <Box
            width="100%"
            sx={{
                border: '1px solid #dfdfe4',
                borderRadius: 1,
                bgcolor: 'white',
            }}
        >
            <Box p={3}>
                <Typography width="100%" mb={1}>
                    {trans('survey.audience')}
                </Typography>

                {clusterGroupFields.map((cg, index) => (
                    <Box key={cg.id}>
                        {index > 0 && (
                            <Box width="100%" display="flex" alignItems="center" my={4}>
                                <Box width="100%" height="1px" bgcolor={'#dfdfe4'} />
                                <AndOrButton
                                    disabled={
                                        formDisabled ||
                                        currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                        currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                    }
                                    bgcolor="#FFBC00"
                                    value={watch(`clustersGroups.${index}.operatorWithPrevious`)}
                                    //onChange={(val) => setValue(`clustersGroups.${index}.operatorWithPrevious`, val)}
                                    onChange={onChangeOperatorWithPrevious}
                                />
                                <Box width="100%" height="1px" bgcolor={'#dfdfe4'} />
                            </Box>
                        )}

                        <Box
                            display="flex"
                            justifyContent="space-between"
                            sx={{ border: '1px solid #DFDFDF', borderRadius: 1, overflow: 'hidden' }}
                        >
                            <ClusterGroup clusters={clusters} clusterGroupId={index} clusterOptions={clusterOptions} />
                            {clusterGroupFields.length > 1 && (
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    sx={{ bgcolor: 'rgba(233, 233, 233, 1)' }}
                                    p={2}
                                >
                                    <IconButton
                                        disabled={
                                            clusterGroupFields.length === 1 ||
                                            formDisabled ||
                                            currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                            currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                        }
                                        onClick={() => clusterGroupRemove(index)}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box width="100%" display="flex" alignItems="center" mt={4} mb={10}>
                <Box width="100%" height="1px" bgcolor={'#dfdfe4'} />
                <IconButton
                    disabled={
                        watch(`clustersGroups.${clusterGroupFields.length - 1}.clusters`)[0] === ' ' ||
                        formDisabled ||
                        currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                        currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                    }
                    sx={{
                        width: '100px',
                        borderRadius: 1,
                        border: '1px solid #dfdfe4',
                    }}
                    onClick={() =>
                        clusterGroupAppend({
                            operatorWithPrevious: 'AND',
                            operatorInsideGroup: 'AND',
                            clusters: [' '],
                        })
                    }
                >
                    <AddIcon />
                </IconButton>
                <Box width="100%" height="1px" bgcolor={'#dfdfe4'} />
            </Box>
        </Box>
    );
};

export default ComposeClusters;
