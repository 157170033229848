import { Box, ThemeProvider, Typography } from '@mui/material';
import React from 'react';
import pampersTheme from '~js/pages/backoffice/Tools/Quiz/components/Previews/previewTheme';

const PreviewCtaButton = ({ ctaText = '', bgColor = '#10a9ae', color = 'white' }) => {
    return (
        <ThemeProvider theme={pampersTheme}>
            <Box width={'210px'} minHeight={'30px'} p={1} borderRadius={24} bgcolor={bgColor}>
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        lineHeight: '14px',
                        color: color,
                        textAlign: 'center',
                    }}
                >
                    {ctaText}
                </Typography>
            </Box>
        </ThemeProvider>
    );
};

export default PreviewCtaButton;
