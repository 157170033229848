import React, { useContext } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';
import { RadioBooleanGroup, TextInput } from '~js/components/input';
import { clientQuizStatus } from '~js/constants/missionStatuses';
import CtaButton from '~js/components/CtaButton';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';

export const QUIZ_TIME_MIN_RESPONSES = 2;
export const DEFAULT_QUIZ_TIME_RESPONSE_DATA = {
    response: '',
    right: null,
    //points: 20,
};
export const MAX_QUIZ_TIME_RESPONSE_POINTS = 100;
const TimeQuestionResponses = ({ questionIndex }) => {
    const { isEditMode, formDisabled, currentClientQuizStatus } = useContext(QuizContext);

    const {
        control,
        setError,
        clearErrors,
        formState: { errors },
        watch,
        trigger,
    } = useFormContext();

    const quizQuestionKey = `questions.${questionIndex}`;
    const quizQuestionResponsesKey = `${quizQuestionKey}.responses`;
    const {
        fields: responseField,
        append: appendResponse,
        remove: removeResponse,
        insert: insertResponse,
        move: moveResponse,
    } = useFieldArray({
        name: quizQuestionResponsesKey,
        control,
        shouldUnregister: true,
    });

    /**
     * Triggers current question validation, only if it passes adds new response
     *
     * @returns {Promise<void>}
     */
    const handleAppendResponse = async () => {
        if (await trigger(quizQuestionKey)) {
            appendResponse(DEFAULT_QUIZ_TIME_RESPONSE_DATA);
        }
    };
    const handleRemoveResponse = async (responseIndex) => {
        if (responseField.length > QUIZ_TIME_MIN_RESPONSES) {
            console.debug(`will remove: ${responseIndex}`);
            removeResponse(responseIndex);
        } else {
            console.debug(
                `You can't remove a response if responses count will be less then ${QUIZ_TIME_MIN_RESPONSES}.`,
            );
        }
    };

    const responseErrors = errors?.questions?.[questionIndex]?.responses;
    return (
        <>
            {responseField.map((item, rKey) => (
                <Grid
                    container
                    key={item.id.toString()}
                    border={'1px solid rgba(0,0,0,0.1)'}
                    borderRadius={1.2}
                    padding={2}
                    marginBottom={2}
                >
                    <Grid item xs={11}>
                        {/* response text */}
                        <Controller
                            control={control}
                            name={`${quizQuestionResponsesKey}.${rKey}.response`}
                            //value={watch(`${quizQuestionResponsesKey}.${rKey}.text`)}
                            defaultValue={watch(`${quizQuestionResponsesKey}.${rKey}.response`) || ''}
                            shouldUnregister
                            rules={{
                                required: trans('formValidation.requiredField'),
                                minLength: {
                                    value: 3,
                                    message: trans('formValidation.fieldTooShort'),
                                },
                                maxLength: {
                                    value: 45,
                                    message: trans('formValidation.fieldTooLong'),
                                },
                                pattern: {
                                    value: REGEX_ONLY_PLAIN_CHAR,
                                    message: trans('formValidation.charNotAllowed'),
                                },
                            }}
                            render={({ field }) => (
                                <TextInput
                                    disabled={formDisabled}
                                    inputProps={{ minLength: 3, maxLength: 45 }}
                                    label={trans('quiz.responseTextTitle', {
                                        args: {
                                            count: rKey + 1,
                                        },
                                    })}
                                    placeholder={trans('quiz.question')}
                                    helperText={
                                        responseErrors?.[rKey]?.response?.message ||
                                        trans('formValidation.charLimit', {
                                            args: {
                                                chars: 45,
                                            },
                                        })
                                    }
                                    error={!!responseErrors?.[rKey]?.response}
                                    {...field}
                                />
                            )}
                        />
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                            <Grid item sm={8} display={'flex'} alignItems={'center'}>
                                {/* right */}
                                <Controller
                                    control={control}
                                    name={`${quizQuestionResponsesKey}.${rKey}.right`}
                                    defaultValue={watch(`${quizQuestionResponsesKey}.${rKey}.right`) || undefined}
                                    shouldUnregister
                                    rules={{
                                        validate: (value) => {
                                            if (value === null || value === undefined) {
                                                return trans('formValidation.requiredField');
                                            }
                                            return true;
                                        },
                                    }}
                                    error={!!responseErrors?.[rKey]?.right}
                                    render={({ field }) => (
                                        <Box key={rKey}>
                                            <RadioBooleanGroup
                                                label={trans('quiz.typology')}
                                                error={responseErrors?.[rKey]?.right?.message}
                                                {...field}
                                                onChange={(value) => field.onChange(value === 'true')}
                                            />
                                        </Box>
                                    )}
                                />
                            </Grid>
                            <Grid item sm={4} display={'flex'} justifyContent={'end'}>
                                {/* response points */}
                                {/*
                                <Controller
                                    control={control}
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        max: {
                                            value: MAX_QUIZ_TIME_RESPONSE_POINTS,
                                            message: trans('formValidation.maxPointsExceeded'),
                                        },
                                        pattern: {
                                            value: REGEX_ONLY_PLAIN_CHAR,
                                            message: trans('formValidation.charNotAllowed'),
                                        },
                                    }}
                                    shouldUnregister
                                    name={`${quizQuestionResponsesKey}.${rKey}.points`}
                                    render={({ field }) => (
                                        <TextInput
                                            disabled={
                                                formDisabled ||
                                                currentClientQuizStatus === clientQuizStatus.prodActive ||
                                                currentClientQuizStatus === clientQuizStatus.prodNotActive
                                            }
                                            inputProps={{
                                                min: 0,
                                                max: MAX_QUIZ_TIME_RESPONSE_POINTS,
                                                step: 10,
                                            }}
                                            type="number"
                                            label={trans('quiz.responseReward')}
                                            placeholder={trans('quiz.responseReward')}
                                            helperText={responseErrors?.[rKey]?.message}
                                            error={!!responseErrors?.[rKey]?.points}
                                            {...field}
                                        />
                                    )}
                                />
                                */}
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={1} display={'flex'} justifyContent={'flex-end'}>
                        {responseField.length > QUIZ_TIME_MIN_RESPONSES && (
                            <IconButton
                                sx={{
                                    width: '35px',
                                    height: '35px',
                                    color: (theme) => theme.palette.primary.main,
                                    backgroundColor: (theme) => theme.palette.primary.iconBackground,
                                }}
                                aria-label={trans('quiz.removeResponse')}
                                onClick={() => handleRemoveResponse(rKey)}
                            >
                                <DeleteIcon
                                    sx={{
                                        width: '20px',
                                        height: '20px',
                                    }}
                                />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            ))}
            {!formDisabled &&
                currentClientQuizStatus !== clientQuizStatus.prodActive &&
                currentClientQuizStatus !== clientQuizStatus.prodNotActive && (
                    <Box width="100%" display="flex" justifyContent="start">
                        <CtaButton sx={{ textDecoration: 'underline' }} onClick={handleAppendResponse}>
                            + {trans('quiz.addResponse')}
                        </CtaButton>
                    </Box>
                )}
        </>
    );
};

export default TimeQuestionResponses;
