import { Box, FormHelperText } from '@mui/material';
import React, { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { alpha } from '@mui/material/styles';

const DifferenceGridSelector = forwardRef(
    ({ formKey, gridRows, gridColumns, image, value: cellWithDifferences, error, helperText = '' }, ref) => {
        const { setValue, trigger } = useFormContext();

        const onGridCellClick = (cellId) => {
            // if single accepted
            setValue(`${formKey}.cellWithDifferences`, [cellId]);
            trigger(`${formKey}.cellWithDifferences`);

            // if multiple accepted
            // if (cellWithDifferences.includes(cellId)) {
            //     setValue(
            //         `${formKey}.cellWithDifferences`,
            //         cellWithDifferences.filter((cellDiffId) => cellId !== cellDiffId),
            //     );
            // } else {
            //     setValue(`${formKey}.cellWithDifferences`, [...cellWithDifferences, cellId]);
            // }
        };

        function GridItem(props) {
            const { sx, cellId, ...other } = props;
            return (
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    onClick={() => onGridCellClick(cellId)}
                    sx={{
                        bgcolor: (theme) => theme.palette.primary.bgTransparency,
                        color: (theme) => theme.palette.text.primary,
                        border: '0.8px solid',
                        borderColor: (theme) => theme.palette.primary.contrastText,
                        m: 0.375,
                        fontSize: '12px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        ...sx,
                    }}
                    {...other}
                />
            );
        }

        return (
            <>
                <Box
                    ref={ref}
                    sx={{
                        //width: '450px',
                        height: '200px',
                        position: 'relative',
                        width: 'fit-content',
                    }}
                >
                    {/*

            <Box
                component={'img'}
                src={image}
                sx={{
                    aspectRatio: 16 / 9,
                    height: '200px',
                    objectFit: 'contain',
                }}
            />
            */}
                    <Box
                        position={'absolute'}
                        width={'100%'}
                        height={'100%'}
                        top={0}
                        display={'grid'}
                        gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr'}
                        gridTemplateRows={'1fr 1fr 1fr 1fr'}
                        sx={{
                            backgroundImage: `url(${image})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            width: `${200 * (16 / 9)}px`,
                            height: '200px',
                            backgroundColor: (theme) => theme.palette.primary.contrastText,
                            border: (theme) => `2px solid ${theme.palette.primary.main}`,

                            ...(error && {
                                boxShadow: (theme) => `${alpha(theme.palette.error.main, 0.5)} 0 0 0 0.2rem`,
                                border: 0,
                                borderRadius: '8px',
                            }),
                        }}
                    >
                        {Array(gridRows * gridColumns)
                            .fill('')
                            .map((_, index) => {
                                const columnIndex = Math.floor(index / gridColumns) + 1;
                                const rowLetter = String.fromCharCode(65 + (index % gridColumns));
                                const cellId = rowLetter + columnIndex;
                                return (
                                    <GridItem
                                        key={index.toString()}
                                        cellId={cellId}
                                        sx={
                                            cellWithDifferences.includes(cellId)
                                                ? {
                                                      border: (theme) => `2px solid`,
                                                      borderColor: (theme) => theme.palette.primary.main,
                                                      borderRadius: '12px',
                                                      boxShadow:
                                                          '0px 0px 12.39079761505127px 3.0976994037628174px rgba(201, 249, 246, 1) inset, 0px 0px 0px 3.0976994037628174px rgba(255, 255, 255, 1)',
                                                  }
                                                : {}
                                        }
                                    >
                                        {cellId}
                                    </GridItem>
                                );
                            })}
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'row'} mb={2}>
                    <FormHelperText
                        sx={{
                            pl: 1,
                            width: '80%',
                            ...(error && {
                                color: (theme) => theme.palette.error.main,
                            }),
                        }}
                    >
                        {error ?? helperText}
                    </FormHelperText>
                </Box>
            </>
        );
    },
);

export default DifferenceGridSelector;
