import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { trans } from 'matice';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import NewQuizActionButtons from '~js/pages/backoffice/Tools/Quiz/components/NewQuizActionButtons';
import DragWinQuestionForm from '~js/pages/backoffice/Tools/Quiz/components/FormChallenges/DragWinQuestionForm';

export const DEFAULT_QUIZ_DRAG_WIN_RESPONSE_DATA = {
    title: '',
    images: [],
    timer: 20,
};
const DragWinQuestionsForm = ({ handleStepNext, handleStepPrevious, stepFormValue, isLoading = false }) => {
    const { isEditMode, formDisabled, currentClientQuizStatus, quizTitle, quizEndDate } = useContext(QuizContext);

    const formMethods = useForm({
        //shouldUnregister: true,
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: stepFormValue ?? {
            questions: Array(1).fill(DEFAULT_QUIZ_DRAG_WIN_RESPONSE_DATA),
        },
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = formMethods;

    const { fields: questionsFields } = useFieldArray({
        name: 'questions',
        control,
        shouldUnregister: true,
    });
    const onSubmit = (data) => {
        console.log(data);
        handleStepNext({ quizQuestions: { ...data } });
    };

    return (
        <FormProvider {...formMethods}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                {questionsFields.map((question, qIndex) => (
                    <DragWinQuestionForm key={question.id} questionIndex={qIndex} errors={errors} />
                ))}

                {!formDisabled && (
                    <NewQuizActionButtons
                        isLoading={isLoading}
                        onLeftButtonClick={handleStepPrevious}
                        rightButtonText={isEditMode ? trans('global.save') : trans('global.next')}
                        leftButtonText={trans('global.back')}
                    />
                )}
            </Box>
        </FormProvider>
    );
};

export default DragWinQuestionsForm;
