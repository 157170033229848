import React, { useContext, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CtaButton from "./CtaButton";
import ToolContext from "~js/layouts/Tools/context";
import pampersAvatar from "~/assets/images/brands/pampers/avatar.svg";
import oldAvatar from "~/assets/images/brands/fater/old-avatar.png";
import { trans } from "matice";

const PhotoAccountInput = ({ onChange, avatarUrl, disabledUpload = false, errorMessage = null }) => {
    const { brand } = useContext(ToolContext);

    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    const [imgData, setImgData] = useState(avatarUrl);

    // Programmatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded) {
            onChange(fileUploaded);
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(fileUploaded);
        }
        //props.handleFile(fileUploaded);
    };

    const deleteAvatar = () => {
        onChange(null);
        setImgData(null);
    };

    const defaultAvatar = brand === 'pampers' ? pampersAvatar : oldAvatar;
    return (
        <Box sx={{ mt: 4, maxWidth: '150px' }}>
            <Box
                sx={{
                    width: {
                        xs: '100px',
                        sm: '100px',
                        md: '120px',
                        lg: '150px',
                    },
                    height: {
                        xs: '100px',
                        sm: '100px',
                        md: '120px',
                        lg: '150px',
                    },
                    bgcolor: '#F5F5F5',
                    borderRadius: 1,
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                {/*<IconButton
                    onClick={handleClick}
                    sx={{
                        position: "absolute",
                        bgcolor: "rgba(255, 242, 226, 1)",
                        color: "black",
                        borderRadius: 1,
                        width: 30,
                        height: 30,
                        right: 5,
                        top: 5,
                        "&:hover": {
                            bgcolor: "black",
                            color: "rgba(255, 242, 226, 1)",
                        },
                        zIndex: 99,
                    }}
                >
                    <CloudUploadOutlinedIcon />
                </IconButton>*/}
                {!!imgData && (
                    <IconButton
                        onClick={deleteAvatar}
                        sx={{
                            position: 'absolute',
                            bgcolor: 'rgba(134,134,134,0.7)',
                            color: 'white',
                            borderRadius: 30,
                            width: 30,
                            height: 30,
                            bottom: 5,
                            right: 5,
                            '&:hover': {
                                bgcolor: 'black',
                                color: 'white',
                            },
                            zIndex: 99,
                            border: '1px solid white',
                        }}
                    >
                        <DeleteOutlineOutlinedIcon fontSize="small" />
                    </IconButton>
                )}
                <Box
                    component="img"
                    sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                    src={imgData ? imgData : defaultAvatar}
                />
            </Box>
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                accept=".png, .jpg, .jpeg"
                style={{ display: 'none' }}
            />

            <Box
                display="flex"
                justifyContent="center"
                sx={{
                    width: {
                        xs: '100px',
                        sm: '100px',
                        md: '120px',
                        lg: '150px',
                    },
                }}
            >
                <CtaButton onClick={handleClick} sx={{ textDecoration: 'underline' }}>
                    {trans('account.profile-edit-cta')}
                </CtaButton>
            </Box>
            <Typography sx={{ color: '#f44336' }}>{errorMessage}</Typography>
        </Box>
    );
};

export default PhotoAccountInput;
