import React from 'react';
import { Box, SvgIcon, ThemeProvider, Typography } from '@mui/material';
import useDateFormat from '~js/hooks/useDateFormat';
import previewHeaderBackground from '~/assets/images/tools/quizTool/previewHeaderBackground.png';
import IconCloseCoccoleSvg from '~/assets/images/icons/icon-close-coccole.svg?react';
import pampersTheme from '~js/pages/backoffice/Tools/Quiz/components/Previews/previewTheme';

const PreviewQuizHeader = ({ title = 'Quiz', subTitle = null, endDate }) => {
    const { formatDate } = useDateFormat('dd MMM yyyy');
    return (
        <ThemeProvider theme={pampersTheme}>
            <Box position={'relative'} display="flex" flexDirection="column" width="100%">
                <Box
                    component="img"
                    src={previewHeaderBackground}
                    sx={{
                        position: 'absolute',
                        objectFit: 'cover',
                        objectPosition: 'bottom',
                        width: '100%',
                        height: '100%',
                        zIndex: 0,
                    }}
                />
                <Box px="10px" pt={'24px'} zIndex={1} display={'flex'} justifyContent={'space-between'}>
                    <Box minHeight={74} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '22px',
                                lineHeight: '26px',
                                color: 'white',
                                width: '100%',
                                marginBottom: '8px',
                            }}
                        >
                            {title.slice(0, 50)}
                        </Typography>
                        <Typography
                            variant={'body1'}
                            sx={{
                                fontSize: '10px',
                                lineHeight: '12px',
                                color: 'white',
                                width: '100%',
                                marginBottom: '10px',
                            }}
                        >
                            Disponibile fino al {formatDate(endDate)}
                        </Typography>
                        {subTitle && (
                            <Typography
                                variant={'body1'}
                                sx={{
                                    fontSize: '10px',
                                    lineHeight: '12px',
                                    fontWeight: 'bold',
                                    color: 'white',
                                    width: '100%',
                                    marginBottom: '8px',
                                }}
                            >
                                {subTitle.slice(0, 50)}
                            </Typography>
                        )}
                    </Box>
                    <Box>
                        <SvgIcon sx={{ color: '#FFFFFF' }} fontSize={'large'} inheritViewBox>
                            <IconCloseCoccoleSvg />
                        </SvgIcon>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PreviewQuizHeader;
