import React, { useContext, useEffect } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import FileInput from '~js/components/FileInput';
import { clientSurveyStatus } from '~js/constants/missionStatuses';
import { SURVEY_TYPOLOGIES } from '~js/constants/survey.js';
import { ASPECT_RATIO_IMG, ONE_MB } from '~js/constants/images.jsx';
import QuestionLayoutSelect from './QuestionLayoutSelect';
import MinMaxInput from './MinMaxInput';
import CaptionInput from './CaptionInput';
import SurveyContext from '../context';
import ActionMultiFlowResponse from './ActionMultiFlowResponse';
import PriorityMultiFlowResponseInput from './PriorityMultiFlowResponseInput';

const ImageChoiceListForm = ({ questionIndex, isMultiple }) => {
    const { currentClientSurveyStatus, formDisabled, surveyTypology } = useContext(SurveyContext);
    const isMultiFlowSurvey = surveyTypology === SURVEY_TYPOLOGIES.multiFlow;

    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext();

    const fieldBasePath = `questions.${questionIndex}`;
    const errorsBasePath =
        errors?.questions && errors.questions[questionIndex] ? errors.questions[questionIndex] : null;

    const {
        fields: responsesFields,
        append: responsesAppend,
        remove: responsesRemove,
    } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: `${fieldBasePath}.responses`, // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
        shouldUnregister: true,
    });

    useEffect(() => {
        if (responsesFields.length === 0) {
            responsesAppend({});
            responsesAppend({});
        }
    }, [responsesFields]);

    return (
        <Box>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box maxWidth="500px">
                        <CaptionInput questionIndex={questionIndex} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Box maxWidth="500px">
                        <QuestionLayoutSelect questionIndex={questionIndex} />
                        {isMultiple && (
                            <MinMaxInput questionIndex={questionIndex} responsesFieldsLength={responsesFields.length} />
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box maxWidth="500px">
                        <Grid container columnSpacing={4}>
                            {responsesFields.map((resField, index) => (
                                <Grid key={resField.id} item xs={isMultiFlowSurvey ? 12 : 6} display="flex" gap={4}>
                                    <Box sx={{ position: 'relative', width: '100%' }}>
                                        <Controller
                                            control={control}
                                            name={`${fieldBasePath}.responses.${index}.image`}
                                            defaultValue={watch(`${fieldBasePath}.responses.${index}.image`) || ''}
                                            rules={{
                                                required: trans('formValidation.requiredField'),
                                                validate: async (value) => {
                                                    if (!value || typeof value !== 'object') {
                                                        return true;
                                                    }
                                                    return await new Promise((resolve) => {
                                                        let hasError = false;
                                                        let img = new Image();
                                                        img.src = window.URL.createObjectURL(value);
                                                        img.onload = () => {
                                                            const aspectRadioImgUp = (img.width / img.height).toFixed(
                                                                1,
                                                            );
                                                            const aspectRadioRule = !!watch(
                                                                `${fieldBasePath}.useGridLayout`,
                                                            )
                                                                ? ASPECT_RATIO_IMG.CHOICE_IMAGE_GRID.toFixed(1)
                                                                : ASPECT_RATIO_IMG.CHOICE_IMAGE_LIST.toFixed(1);
                                                            if (aspectRadioImgUp !== aspectRadioRule) {
                                                                hasError = true;
                                                                resolve(
                                                                    trans(
                                                                        !!watch(`${fieldBasePath}.useGridLayout`)
                                                                            ? 'formValidation.aspectRatioErrorGridLayout'
                                                                            : 'formValidation.aspectRatioErrorListLayout',
                                                                        {
                                                                            args: {
                                                                                aspectRatio: aspectRadioRule,
                                                                            },
                                                                        },
                                                                    ),
                                                                );
                                                            } else {
                                                                if (value.size > ONE_MB) {
                                                                    resolve(trans('formValidation.max500KBImage'));
                                                                } else {
                                                                    resolve(true);
                                                                }
                                                            }
                                                        };
                                                    });
                                                },
                                                deps: [`${fieldBasePath}.useGridLayout`],
                                            }}
                                            shouldUnregister
                                            render={({ field: { onChange, value } }) => (
                                                <FileInput
                                                    disabled={
                                                        formDisabled ||
                                                        currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                                        currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                                    }
                                                    value={value}
                                                    onChange={onChange}
                                                    helperText={
                                                        !!errorsBasePath?.responses &&
                                                        errorsBasePath?.responses[index] &&
                                                        errorsBasePath?.responses[index]?.image?.message
                                                    }
                                                    error={
                                                        !!errorsBasePath?.responses &&
                                                        !!errorsBasePath?.responses[index]?.image
                                                    }
                                                />
                                            )}
                                        />
                                        {responsesFields.length > 2 &&
                                            !formDisabled &&
                                            currentClientSurveyStatus !== clientSurveyStatus.prodActive &&
                                            currentClientSurveyStatus !== clientSurveyStatus.prodNotActive && (
                                                <IconButton
                                                    disabled={responsesFields.length === 2}
                                                    onClick={() => responsesRemove(index)}
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 20,
                                                        right: -20,
                                                    }}
                                                >
                                                    <CancelIcon fontSize="medium" />
                                                </IconButton>
                                            )}
                                    </Box>

                                    {isMultiFlowSurvey && (
                                        <Box maxWidth="500px" width="100%">
                                            <ActionMultiFlowResponse
                                                questionIndex={questionIndex}
                                                fieldBasePath={`${fieldBasePath}.responses.${index}`}
                                                errorsBasePath={
                                                    errorsBasePath?.responses && errorsBasePath?.responses[index]
                                                }
                                            />
                                            {isMultiple && (
                                                <PriorityMultiFlowResponseInput
                                                    fieldBasePath={`${fieldBasePath}.responses.${index}`}
                                                    errorsBasePath={
                                                        errorsBasePath?.responses && errorsBasePath?.responses[index]
                                                    }
                                                />
                                            )}
                                        </Box>
                                    )}
                                </Grid>
                            ))}
                            {!formDisabled &&
                                currentClientSurveyStatus !== clientSurveyStatus.prodActive &&
                                currentClientSurveyStatus !== clientSurveyStatus.prodNotActive && (
                                    <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
                                        <Box
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '1px solid #5E6366',
                                                borderRadius: 1,
                                                height: '150px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => responsesAppend({})}
                                        >
                                            <AddCircleOutlineIcon fontSize="large" />
                                        </Box>
                                    </Grid>
                                )}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ImageChoiceListForm;
