import { Box, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import { trans } from 'matice';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';
import { TextInput } from '~js/components/input';
import FileUploaderInput from '~js/components/FileUploaderInput';
import { ASPECT_RATIO_IMG, ONE_MB } from '~js/constants/images';
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext, useMemo, useState } from 'react';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import PreviewDragWinQuestionScreen from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewDragWinQuestionScreen';

export const NUM_WRONG_PRODUCTS = 6;
Array.prototype.shuffle = function () {
    const shuffled = [...this];
    let currentIndex = this.length;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
        // Pick a remaining element...
        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [shuffled[currentIndex], shuffled[randomIndex]] = [shuffled[randomIndex], shuffled[currentIndex]];
    }
    return shuffled;
};

const DragWinQuestionForm = ({ questionIndex: qIndex, errors }) => {
    const { formDisabled, quizTitle, quizEndDate, quizDragCorrectProducts } = useContext(QuizContext);
    const { control, watch } = useFormContext();

    const {
        fields: wrongProductsFields,
        append,
        remove,
    } = useFieldArray({
        name: `questions.${qIndex}.wrongProducts`,
        control,
        shouldUnregister: true,
        rules: {
            minLength: {
                value: 6,
                message: trans('quiz.drag.imagesLengthError', { args: { length: NUM_WRONG_PRODUCTS } }),
            },
            maxLength: {
                value: 6,
                message: trans('quiz.drag.imagesLengthError', { args: { length: NUM_WRONG_PRODUCTS } }),
            },
            required: trans('formValidation.requiredField'),
        },
    });
    const [fileInputState, setFileInputState] = useState({
        value: undefined,
        error: undefined,
    });
    const previewImages = useMemo(
        () => [...quizDragCorrectProducts, ...wrongProductsFields].map((product) => product.image).shuffle(),
        [quizDragCorrectProducts, wrongProductsFields],
    );
    const generateWrongProduct = (fileUrl, filename) => ({
        image: fileUrl,
        name: filename,
    });
    console.log(errors);
    const questionErrors = errors?.questions?.[qIndex];
    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt={6}>
            <Grid container columnSpacing={2} py={5} border={'1px solid #DFDFE4'} borderRadius={'12px'}>
                <Grid item xs={12} mb={4}>
                    <Typography component={'h1'}>{trans('quiz.steps.questions')}</Typography>
                </Grid>
                {/* Question/Response details */}
                <Grid item xs={6} sx={{ maxWidth: 500 }}>
                    {/* Question title */}
                    <Controller
                        control={control}
                        name={`questions.${qIndex}.question`}
                        //defaultValue={watch(`questions.${qIndex}.question`) || ''}
                        rules={{
                            required: trans('formValidation.requiredField'),
                            minLength: {
                                value: 3,
                                message: trans('formValidation.fieldTooShort'),
                            },
                            maxLength: {
                                value: 40,
                                message: trans('formValidation.fieldTooLong'),
                            },
                            pattern: {
                                value: REGEX_ONLY_PLAIN_CHAR,
                                message: trans('formValidation.charNotAllowed'),
                            },
                        }}
                        render={({ field }) => (
                            <TextInput
                                inputProps={{ minLength: 3, maxLength: 40 }}
                                disabled={formDisabled}
                                label={trans('quiz.title')}
                                placeholder={trans('quiz.title')}
                                helperText={
                                    questionErrors?.question?.message ||
                                    trans('formValidation.charLimit', {
                                        args: {
                                            chars: 40,
                                        },
                                    })
                                }
                                error={!!questionErrors?.question}
                                {...field}
                            />
                        )}
                    />

                    {/* wrongProducts */}
                    <FileUploaderInput
                        disabled={formDisabled || wrongProductsFields.length >= NUM_WRONG_PRODUCTS}
                        value={fileInputState.value}
                        label={trans('quiz.imageProductsWrong')}
                        multiple={true}
                        numberOfFiles={NUM_WRONG_PRODUCTS - wrongProductsFields.length}
                        tooltipText={trans('formValidation.aspectRatioError', {
                            args: {
                                aspectRatio: ASPECT_RATIO_IMG.QUIZ_DRAG_IMAGE.toFixed(1),
                            },
                        })}
                        onChange={(fileUrl, uploadedFile) => {
                            console.log(`fileUrl: ${uploadedFile.name}`, fileUrl);
                            append(generateWrongProduct(fileUrl, uploadedFile.name));
                        }}
                        helperText={
                            fileInputState.error ||
                            questionErrors?.wrongProducts?.['root']?.message ||
                            trans('formValidation.maxMbFile', {
                                args: { mb: (ONE_MB / ONE_MB).toFixed(1) },
                            })
                        }
                        validateFn={async (value) => {
                            if (!value || typeof value !== 'object') {
                                setFileInputState({ error: undefined });
                                return true;
                            }
                            return await new Promise((resolve) => {
                                let img = new Image();
                                img.src = window.URL.createObjectURL(value);
                                img.onload = () => {
                                    const aspectRatioImgUp = (img.width / img.height).toFixed(1);
                                    const aspectRatioRule = ASPECT_RATIO_IMG.QUIZ_DRAG_IMAGE.toFixed(1);
                                    if (aspectRatioImgUp !== aspectRatioRule) {
                                        setFileInputState({
                                            error: trans('formValidation.aspectRatioError', {
                                                args: {
                                                    aspectRatio: aspectRatioRule,
                                                },
                                            }),
                                        });
                                        resolve(false);
                                    } else {
                                        if (value.size > ONE_MB) {
                                            setFileInputState({
                                                error: trans('formValidation.maxMbFileError', {
                                                    args: {
                                                        mb: (ONE_MB / ONE_MB).toFixed(1),
                                                        uploadedSize: (value.size / ONE_MB).toFixed(2),
                                                    },
                                                }),
                                            });
                                            resolve(false);
                                        } else {
                                            setFileInputState({ error: undefined });
                                            resolve(true);
                                        }
                                    }
                                };
                            });
                        }}
                        error={!!fileInputState.error || !!questionErrors?.wrongProducts?.['root']}
                    />

                    {/* wrongProducts */}
                    {(watch(`questions.${qIndex}.wrongProducts`) ?? []).map((wP, index) => {
                        return (
                            <Box
                                key={index}
                                display={'flex'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                sx={{ marginBottom: '20px' }}
                            >
                                <Box display={'flex'} flexDirection={'row'}>
                                    <Box
                                        sx={{
                                            height: '45px',
                                            padding: '5px',
                                            borderRadius: '5px',
                                            border: `1.5px solid #dfdfe4`,
                                            marginRight: '20px',
                                        }}
                                    >
                                        <Box
                                            component={'img'}
                                            src={watch(`questions.${qIndex}.wrongProducts.${index}.image`)}
                                            width={35}
                                            height={35}
                                        />
                                    </Box>
                                    {/* Name */}
                                    <Typography
                                        lineHeight={'45px'}
                                        width={'150px'}
                                        sx={{
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {watch(`questions.${qIndex}.wrongProducts.${index}.name`)}
                                    </Typography>
                                </Box>
                                <IconButton aria-label="remove" sx={{ marginLeft: 2 }} onClick={() => remove(index)}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        );
                    })}

                    {/* Timer */}
                    <Controller
                        control={control}
                        name={`questions.${qIndex}.timerSeconds`}
                        //defaultValue={watch(`questions.${qIndex}.timerSeconds`) || ''}
                        rules={{
                            required: trans('formValidation.requiredField'),
                            min: {
                                value: 20,
                                message: trans('formValidation.min20Points'),
                            },
                            max: {
                                value: 60,
                                message: trans('formValidation.maxPointsExceeded'),
                            },
                            pattern: {
                                value: REGEX_ONLY_PLAIN_CHAR,
                                message: trans('formValidation.charNotAllowed'),
                            },
                        }}
                        shouldUnregister
                        render={({ field }) => (
                            <TextInput
                                disabled={formDisabled}
                                inputProps={{ min: 20, max: 60, step: 10, 'aria-label': trans('quiz.timer') }}
                                type="number"
                                label={trans('quiz.timer')}
                                placeholder={trans('quiz.timer')}
                                helperText={questionErrors?.timerSeconds?.message}
                                error={!!questionErrors?.timerSeconds}
                                endAdornment={
                                    <InputAdornment position="end" sx={{ position: 'absolute', right: 40, bottom: 22 }}>
                                        sec
                                    </InputAdornment>
                                }
                                {...field}
                            />
                        )}
                    />
                </Grid>
                {/* Question Preview */}
                <Grid item xs={6}>
                    <Box
                        borderRadius={'12px'}
                        bgcolor={(theme) => theme.palette.background.default}
                        p={4}
                        display={'flex'}
                        justifyContent={'center'}
                    >
                        <PreviewDragWinQuestionScreen
                            title={watch(`questions.${qIndex}.question`)}
                            images={previewImages}
                            timerSeconds={watch(`questions.${qIndex}.timerSeconds`)}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DragWinQuestionForm;
