import React, { useEffect, useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { Box, Typography } from '@mui/material';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import FilterMenuTable, {
    QUIZ_TYPOLOGY_FILTER_OPTIONS,
} from '~js/pages/backoffice/Tools/Survey/components/FilterMenuTable';
import CtaButton from '~js/components/CtaButton';
import AppliedFilterSection from '~js/pages/backoffice/Tools/Survey/components/AppliedFilterSection';
import EmptySection from '~js/components/EmptySection';
import { toast } from 'react-toastify';
import { trans } from 'matice';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';
import QuizTable from '~js/pages/backoffice/Tools/Quiz/components/QuizTable';
import { parseQueryParamsArray } from '~js/utils/queryParams';

const breadcrumbs: BreadcrumbList = [
    {
        routeName: 'Quiz.index',
        routeLabel: 'Quiz',
    },
];
const QuizIndex = ({ quizzes, testerGroups, can, canReadToolIds, ...other }) => {
    const [appliedFilters, setAppliedFilters] = useState();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const showArchived = params.get('showArchived');
        const startDate = params.get('startDate');
        const endDate = params.get('endDate');
        const fromCoverage = params.get('fromCoverage');
        const toCoverage = params.get('toCoverage');

        const arrayQueryParams = parseQueryParamsArray(params);
        if (
            !!showArchived ||
            !!startDate ||
            !!endDate ||
            !!fromCoverage ||
            !!toCoverage ||
            Object.keys(arrayQueryParams).length > 0
        ) {
            const arrayFilters = {};
            if (arrayQueryParams?.typologies) {
                arrayFilters['typologies'] = QUIZ_TYPOLOGY_FILTER_OPTIONS.filter((option) =>
                    arrayQueryParams.typologies.includes(option.value),
                );
            }
            setAppliedFilters({
                showArchived,
                startDate,
                endDate,
                fromCoverage,
                toCoverage,
                ...arrayFilters,
            });
        } else {
            setAppliedFilters(null);
        }
    }, []);

    const goToCreateQuizPage = () => router.get(route('Quiz.create'));

    const deleteQuiz = async (idQuiz) => {
        const promise = new Promise((resolve, reject) =>
            router.delete(route('Quiz.destroy', idQuiz), {
                onSuccess: () => {
                    toast.success(trans('quiz.quizDeleted'));
                    resolve();
                },
                onError: (e) => {
                    reject();
                    toast.error(!!e?.generic ? e.generic : trans('quiz.errorOnDeleteQuiz'));
                },
            }),
        );
        await promise;
    };

    const changeQuizStatus = async (idQuiz, newStatus, groupTest = []) => {
        console.log(idQuiz, newStatus);
        const promise = new Promise((resolve, reject) =>
            router.patch(
                route('Quiz.changeStatus', {
                    id: idQuiz,
                    status: newStatus,
                }),
                groupTest.length > 0 ? { groupTest } : {},
                {
                    onSuccess: () => {
                        toast.success(trans('quiz.statusChanged'));
                        resolve();
                    },
                    onError: (e) => {
                        console.error('error while changeQuizStatus', e);
                        reject();
                        toast.error(!!e?.generic ? e.generic : trans('quiz.errorOnChangeStatus'));
                    },
                },
            ),
        );
        await promise;
    };

    const filterQuiz = async (queryObj) => {
        if (!queryObj.showArchived) {
            delete queryObj.showArchived;
        }
        const promise = new Promise((resolve, reject) =>
            router.get(
                route('Quiz.index', queryObj),
                {},
                {
                    onSuccess: () => {
                        toast.success(trans('quiz.quizFetchedSuccess'));
                        resolve();
                    },
                    onError: (e) => {
                        reject();
                        toast.error(!!e?.generic ? e.generic : trans('quiz.quizFetchedError'));
                    },
                },
            ),
        );

        await promise;
    };

    return (
        <>
            <Head title={trans('quiz.quiz')} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <FilterMenuTable
                        buttonFocused={!!appliedFilters}
                        onSubmit={filterQuiz}
                        appliedFilters={appliedFilters}
                        typology={'quiz'}
                    />

                    {quizzes.length > 0 && can.create && (
                        <CtaButton
                            variant="contained"
                            sx={{
                                px: 5,
                                ml: 2,
                            }}
                            onClick={goToCreateQuizPage}
                        >
                            {trans('quiz.createNew')}
                        </CtaButton>
                    )}
                </Box>
                {!!appliedFilters && (
                    <AppliedFilterSection appliedFilters={appliedFilters} onSubmit={filterQuiz} typology={'quiz'} />
                )}
                <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={2}>
                    <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                        {trans('quiz.quiz')}
                    </Typography>
                </Box>

                <QuizTable
                    quizzes={quizzes}
                    testerGroups={testerGroups}
                    deleteQuiz={deleteQuiz}
                    changeQuizStatus={changeQuizStatus}
                    canReadToolIds={canReadToolIds}
                    canUpdate={can.updateAny}
                    canDelete={can.deleteAny}
                    canArchive={can.archiveAny}
                    canDuplicate={can.create}
                    canChangeStatusToProd={can.changeStatusToProd}
                />

                {quizzes.length === 0 && (
                    <EmptySection
                        title={trans('quiz.noQuizTitle')}
                        description={
                            appliedFilters ? trans('quiz.noQuizFoundAfterFilter') : trans('quiz.noQuizDescription')
                        }
                        buttonComponent={
                            <CtaButton
                                variant="contained"
                                sx={{
                                    px: 5,
                                }}
                                onClick={goToCreateQuizPage}
                            >
                                {trans('quiz.createNew')}
                            </CtaButton>
                        }
                    />
                )}
            </ToolContentPageLayout>
        </>
    );
};

export default QuizIndex;
