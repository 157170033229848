import React from 'react';
import { Box, SpeedDial, SpeedDialAction } from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import telescopeLogo from '~/assets/images/icons/icon-telescope.svg';

const actions = [
    {
        icon: <Box component="img" src={telescopeLogo} />,
        name: 'Telescope',
        onClick: () => window.open(route('telescope'), '_blank'),
    },
    { icon: <QueuePlayNextIcon />, name: 'Horizon', onClick: () => window.open(route('horizon.index'), '_blank') },
    { icon: <DashboardIcon />, name: 'Dashboard', onClick: () => window.open('/admin/dashboard', '_blank') },
    /*
    {
        icon: <Box component="img" src={queueRestart} sx={{ objectFit: 'contain' }} width={30} />,
        name: 'Queue restart',
        onClick: () => window.open(route('queue.restart'), '_blank'),
    },
     */
];

const SpeedDialSuperAdmin = () => {
    return (
        <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'absolute', bottom: 5, right: 32 }}
                icon={<SpeedDialIcon icon={<SettingsIcon />} />}
                direction="left"
                FabProps={{ size: 'small', sx: { bgcolor: 'white', color: (theme) => theme.palette.primary.main } }}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={action.onClick}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
};

export default SpeedDialSuperAdmin;
