import React, { useState } from 'react';
import { Box, Chip, Typography } from '@mui/material';
import { differenceInDays } from 'date-fns';
import { trans } from 'matice';
import CtaButton from '~js/components/CtaButton';

const AppliedFilterSection = ({ appliedFilters, onSubmit, typology = 'survey' }) => {
    const [loading, setLoading] = useState(false);

    const resetFilter = () => {
        onSubmitHandler({});
    };

    const removeFilter = (type, extra) => {
        const queryObj = { ...appliedFilters };
        switch (type) {
            case 'coverage':
                delete queryObj.fromCoverage;
                delete queryObj.toCoverage;
                break;
            case 'date':
                delete queryObj.startDate;
                delete queryObj.endDate;
                break;
            case 'archived':
                delete queryObj.showArchived;
                break;
            case 'typologies':
                queryObj.typologies = queryObj.typologies
                    .filter(({ value }) => value !== extra.type)
                    .map((opt) => opt?.value ?? opt);
                break;
        }

        onSubmitHandler(queryObj);
    };

    const onSubmitHandler = async (data) => {
        try {
            setLoading(true);
            await onSubmit(data);
        } finally {
            //setLoading(false);
        }
    };
    return (
        <Box display="flex" justifyContent="space-between" my={4} flexWrap="wrap" gap={2}>
            <Box display="flex" gap={1} alignItems="flex-end" flexWrap="wrap">
                <Typography fontSize="14px">{trans('survey.selectedFilter')}</Typography>

                {appliedFilters.showArchived === '1' && (
                    <Chip
                        sx={{ borderRadius: '8px' }}
                        label={trans('survey.showArchived')}
                        variant="outlined"
                        onDelete={() => removeFilter('archived')}
                        disabled={loading}
                    />
                )}

                {appliedFilters.startDate && appliedFilters.endDate && (
                    <Chip
                        sx={{ borderRadius: '8px' }}
                        label={trans('survey.lastNDays', {
                            args: {
                                days: differenceInDays(
                                    new Date(appliedFilters.endDate),
                                    new Date(appliedFilters.startDate),
                                ),
                            },
                        })}
                        variant="outlined"
                        onDelete={() => removeFilter('date')}
                        disabled={loading}
                    />
                )}

                {appliedFilters.typologies && (
                    <>
                        {appliedFilters.typologies.map((type, index) => (
                            <Chip
                                key={`${index}-${type}`}
                                sx={{ borderRadius: '8px' }}
                                label={trans(`${typology}.typologies.${type.value}`)}
                                variant="outlined"
                                onDelete={() => removeFilter('typologies', { type: type.value })}
                                disabled={loading}
                            />
                        ))}
                    </>
                )}

                {appliedFilters.fromCoverage && appliedFilters.fromCoverage && (
                    <Chip
                        sx={{ borderRadius: '8px' }}
                        label={trans('survey.coverageFromNToN', {
                            args: {
                                fromCoverage: appliedFilters.fromCoverage,
                                toCoverage: appliedFilters.toCoverage,
                            },
                        })}
                        variant="outlined"
                        onDelete={() => removeFilter('coverage')}
                        disabled={loading}
                    />
                )}
            </Box>
            <CtaButton sx={{ textDecoration: 'underline', p: 0 }} loading={loading} onClick={resetFilter}>
                {trans('survey.removeFilter')}
            </CtaButton>
        </Box>
    );
};

export default AppliedFilterSection;
