import React, { useContext } from 'react';
import { Box, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { trans } from 'matice';
import { FileInput, TextInput } from '~js/components/input';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';
import { clientSurveyStatus } from '~js/constants/missionStatuses';
import { ASPECT_RATIO_IMG, ONE_MB } from '~js/constants/images.jsx';
import NewSurveyActionButtons from './NewSurveyActionButtons';
import IntroductionClosurePreview from './IntroductionClosurePreview';
import SurveyContext from '../context';

const MAX_POINTS = 1000;

const NewSurveyIntroductionClosureStep = ({
    handleStepNext,
    mode,
    handleStepPrevious,
    stepFormValue,
    points = null,
    isLoading = false,
}) => {
    const { isEditMode, currentClientSurveyStatus, formDisabled, surveyTitle } = useContext(SurveyContext);

    const defaultValue = {
        title: mode === 'introduction' ? 'Partecipa alla missione!' : 'Complimenti, hai completato la missione!',
        description: mode === 'introduction' ? 'Rispondi al questionario e ottieni subito:' : 'Hai guadagnato:',
        points: mode === 'introduction' ? 20 : points,
        ctaText: mode === 'introduction' ? 'Iniziamo!' : 'Vai alle Missioni',
        includeVideo: false,
        link: '',
    };

    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: !!stepFormValue ? stepFormValue : defaultValue,
    });

    const onSubmitHandler = async (data) => {
        const dataToSubmit = mode === 'introduction' ? { introduction: data } : { closure: data };
        handleStepNext(dataToSubmit);
    };

    return (
        <Box mt={4}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px">
                            <Controller
                                control={control}
                                name="title"
                                shouldUnregister
                                rules={{
                                    maxLength: {
                                        value: 200,
                                        message: trans('formValidation.fieldTooLong'),
                                    },
                                    pattern: {
                                        value: REGEX_ONLY_PLAIN_CHAR,
                                        message: trans('formValidation.charNotAllowed'),
                                    },
                                    required: trans('formValidation.requiredField'),
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        disabled={formDisabled}
                                        label={trans('survey.title')}
                                        placeholder={trans('survey.title')}
                                        helperText={errors?.title?.message}
                                        error={!!errors?.title}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="description"
                                shouldUnregister
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                    pattern: {
                                        value: REGEX_ONLY_PLAIN_CHAR,
                                        message: trans('formValidation.charNotAllowed'),
                                    },
                                    maxLength: {
                                        value: 200,
                                        message: trans('formValidation.fieldTooLong'),
                                    },
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        disabled={formDisabled}
                                        label={trans('survey.description')}
                                        placeholder={trans('survey.description')}
                                        helperText={errors?.description?.message}
                                        error={!!errors?.description}
                                        {...field}
                                    />
                                )}
                            />
                            <Box display="flex">
                                {mode === 'introduction' && (
                                    <Box width="100%" mr={4}>
                                        <Controller
                                            rules={{
                                                required: trans('formValidation.requiredField'),
                                                max: {
                                                    value: MAX_POINTS,
                                                    message: trans('formValidation.maxPointsExceeded'),
                                                },
                                                pattern: {
                                                    value: REGEX_ONLY_PLAIN_CHAR,
                                                    message: trans('formValidation.charNotAllowed'),
                                                },
                                            }}
                                            shouldUnregister
                                            control={control}
                                            name="points"
                                            render={({ field }) => (
                                                <TextInput
                                                    disabled={
                                                        formDisabled ||
                                                        currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                                        currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                                    }
                                                    inputProps={{ min: 1, max: MAX_POINTS }}
                                                    type="number"
                                                    label={trans('survey.rewards')}
                                                    placeholder={trans('survey.rewards')}
                                                    helperText={errors?.points?.message}
                                                    error={!!errors?.points}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                )}

                                <Controller
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        pattern: {
                                            value: REGEX_ONLY_PLAIN_CHAR,
                                            message: trans('formValidation.charNotAllowed'),
                                        },
                                        maxLength: {
                                            value: 50,
                                            message: trans('formValidation.fieldTooLong'),
                                        },
                                    }}
                                    shouldUnregister
                                    control={control}
                                    name="ctaText"
                                    render={({ field }) => (
                                        <TextInput
                                            disabled={formDisabled}
                                            label={trans('survey.ctaText')}
                                            placeholder={trans('survey.ctaText')}
                                            helperText={errors?.ctaText?.message}
                                            error={!!errors?.ctaText}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                            <Controller
                                control={control}
                                name="image"
                                defaultValue=""
                                shouldUnregister
                                rules={{
                                    validate: async (value) => {
                                        if (!value || typeof value !== 'object') {
                                            return true;
                                        }
                                        return await new Promise((resolve) => {
                                            let hasError = false;
                                            let img = new Image();
                                            img.src = window.URL.createObjectURL(value);
                                            img.onload = () => {
                                                const aspectRatioImgUp = (img.width / img.height).toFixed(1);
                                                const aspectRatioRule = ASPECT_RATIO_IMG.SURVEY_INTRO_IMAGE.toFixed(1);
                                                console.log({
                                                    aspectRatioRule,
                                                    aspectRatioImgUp,
                                                });
                                                if (aspectRatioImgUp !== aspectRatioRule) {
                                                    hasError = true;
                                                    resolve(
                                                        trans('formValidation.aspectRatioError', {
                                                            args: {
                                                                aspectRatio: aspectRatioRule,
                                                            },
                                                        }),
                                                    );
                                                } else {
                                                    if (value.size > ONE_MB) {
                                                        resolve(trans('formValidation.max500KBImage'));
                                                    } else {
                                                        resolve(true);
                                                    }
                                                }
                                            };
                                        });
                                    },
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <FileInput
                                        disabled={formDisabled}
                                        value={value}
                                        label={trans('survey.image')}
                                        onChange={onChange}
                                        helperText={errors?.image?.message}
                                        error={!!errors?.image}
                                    />
                                )}
                            />
                            {mode === 'closure' && (
                                <Controller
                                    control={control}
                                    name="link"
                                    shouldUnregister
                                    rules={
                                        {
                                            /*required: trans(
                                        "formValidation.requiredField"
                                    ),*/
                                        }
                                    }
                                    render={({ field }) => (
                                        <TextInput
                                            type="url"
                                            disabled={formDisabled}
                                            label={trans('survey.link')}
                                            placeholder="https://sito.it"
                                            helperText={errors?.link?.message}
                                            error={!!errors?.link}
                                            {...field}
                                        />
                                    )}
                                />
                            )}
                        </Box>
                        {/* {mode === "introduction" && (
                            <Controller
                                control={control}
                                name="includeVideo"
                                shouldUnregister
                                render={({ field }) => (
                                    <CheckboxInput
                                        label={trans("survey.insertAVideo")}
                                        {...field}
                                        ref={null}
                                    />
                                )}
                            />
                        )}*/}
                        {/*{!!watch("includeVideo") && (
                            <Controller
                                rules={{
                                    required: trans(
                                        "formValidation.requiredField"
                                    ),
                                }}
                                control={control}
                                name="video"
                                defaultValue=""
                                shouldUnregister
                                render={({ field: { onChange, value } }) => (
                                    <FileInput
                                        value={value}
                                        accept=".mp4, .wmv"
                                        label={trans("survey.video")}
                                        placeholder={trans("survey.addVideo")}
                                        onChange={onChange}
                                        helperText={errors?.video?.message}
                                        error={!!errors?.video}
                                    />
                                )}
                            />
                        )}*/}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} display="flex">
                        <IntroductionClosurePreview
                            isClosureMode={mode === 'closure'}
                            title={watch('title')}
                            description={watch('description')}
                            ctaText={watch('ctaText')}
                            points={watch('points')}
                            headerTitle={surveyTitle ? surveyTitle : 'Conosciamoci meglio'}
                            image={watch('image')}
                        />
                    </Grid>
                </Grid>
                {!formDisabled && (
                    <NewSurveyActionButtons
                        isLoading={isLoading}
                        onLeftButtonClick={handleStepPrevious}
                        rightButtonText={isEditMode ? trans('global.save') : trans('global.next')}
                        leftButtonText={trans('global.back')}
                    />
                )}
            </form>
        </Box>
    );
};

export default NewSurveyIntroductionClosureStep;
