import { Box, FormHelperText, Grid } from '@mui/material';
import CtaButton from '~js/components/CtaButton';
import { clientQuizStatus } from '~js/constants/missionStatuses';
import { trans } from 'matice';
import CustomTooltip from '~js/components/CustomTooltip';
import InfoIcon from '@mui/icons-material/Info';
import React, { useContext, useState } from 'react';
import QuizContext, { QuizAudienceContext } from '~js/pages/backoffice/Tools/Quiz/context';
import axios from 'axios';
import { useFormContext } from 'react-hook-form';

const ExposureCoverage = () => {
    const { currentClientQuizStatus, formDisabled } = useContext(QuizContext);
    const { setAudienceTested } = useContext(QuizAudienceContext);

    const [coverage, setCoverage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorCheck, setErrorCheck] = useState(null);

    const {
        control,
        watch,
        setValue,
        trigger,
        formState: { errors },
        clearErrors,
    } = useFormContext();

    const checkAudience = async () => {
        try {
            setCoverage(null);
            setErrorCheck(null);
            setAudienceTested(false);
            setValue('selectedAudience', '');
            const validated = await trigger('audience.clustersGroups');
            if (!validated) {
                setErrorCheck(trans('quiz.selectCluster'));
                return;
            }
            setLoading(true);
            const definition = watch('audience.clustersGroups').map((cg) => ({
                [cg.operatorWithPrevious]: {
                    [cg.operatorInsideGroup]: cg.clusters,
                },
            }));
            const response = await axios.post(route('Surveys.checkAudience'), {
                audience: { surveysToExclude: [], definition },
            });
            setCoverage(response.data.data.coverage);
            setAudienceTested(true);
            clearErrors('checkAudience');
        } catch (e) {
            setErrorCheck(trans('global.errorOccurred'));
            setAudienceTested(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid item xs={12} sm={12} md={6}>
            <Box maxWidth="500px" display="flex" alignItems="center">
                <CtaButton
                    disabled={
                        formDisabled ||
                        currentClientQuizStatus === clientQuizStatus.prodActive ||
                        currentClientQuizStatus === clientQuizStatus.prodNotActive
                    }
                    sx={{ py: 0, px: 1, textDecoration: 'underline' }}
                    onClick={checkAudience}
                    loading={loading}
                >
                    {trans('quiz.checkCoverage')}
                </CtaButton>
                <CustomTooltip title={trans('quiz.checkCoverageTooltip')} placement="bottom-start" sx={{ zIndex: 99 }}>
                    <InfoIcon sx={{ color: '#828282', zIndex: 99 }} />
                </CustomTooltip>
                <Box>
                    <Box
                        sx={{
                            borderRadius: '8px',
                            border: '1px solid #5E6366',
                            padding: '10px 12px',
                            minWidth: '200px',
                            ml: 1,
                            bgcolor: '#e9e9e9',
                        }}
                    >
                        {coverage !== null ? coverage : '-'}
                    </Box>
                    {(errorCheck || !!errors?.checkAudience?.message) && (
                        <FormHelperText sx={{ pl: 1 }} error>
                            {errorCheck ? errorCheck : errors?.checkAudience?.message}
                        </FormHelperText>
                    )}
                </Box>
            </Box>
        </Grid>
    );
};

export default ExposureCoverage;
