import React from 'react';
import CustomModal from './CustomModal';
import { Box, IconButton, Typography } from '@mui/material';
//import bgimage from '../../assets/images/tools/surveyTool/bgSuccessModal.png';
import CtaButton from './CtaButton';
import { trans } from 'matice';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import MoveDownIcon from '@mui/icons-material/MoveDown';

const ConfirmCancelModal = ({
    show,
    onClose,
    title,
    description = '',
    onConfirm,
    loading,
    confirmButtonText = trans('global.confirm'),
    cancelButtonText = trans('global.cancel'),
    containerSx = {},
    iconType = null,
    sxTitle = {},
}) => {
    const onCloseHandler = () => {
        if (loading) {
            return;
        }
        onClose();
    };
    return (
        <CustomModal
            open={show}
            handleClose={onCloseHandler}
            sx={{ maxWidth: '500px', borderRadius: 3, p: 0, ...containerSx }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItem: 'center',
                    justifyContent: 'center',
                    p: 1,
                }}
                //style={{ backgroundImage: `url(${bgimage})` }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mb: 1,
                    }}
                >
                    <IconButton onClick={onCloseHandler}>
                        <CancelIcon fontSize="large" color="primary" />
                    </IconButton>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                    {!!iconType && (
                        <Box
                            sx={{
                                bgcolor: (theme) => theme.palette.primary.main,
                                width: 30,
                                height: 30,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 500,
                                p: 2.5,
                            }}
                        >
                            {iconType === 'delete' && <DeleteOutlineOutlinedIcon sx={{ color: 'white' }} />}
                            {iconType === 'success' && <DoneAllOutlinedIcon sx={{ color: 'white' }} />}
                            {iconType === 'user' && <PersonAddOutlinedIcon sx={{ color: 'white' }} />}
                            {iconType === 'archive' && <Inventory2OutlinedIcon sx={{ color: 'white' }} />}
                            {iconType === 'unarchive' && <UnarchiveOutlinedIcon sx={{ color: 'white' }} />}
                            {iconType === 'duplicate' && <ContentCopyOutlinedIcon sx={{ color: 'white' }} />}
                            {iconType === 'move' && <MoveDownIcon sx={{ color: 'white' }} />}
                        </Box>
                    )}
                </Box>
                <Box mx={2}>
                    <Typography
                        sx={{ fontWeight: 700, textAlign: 'center', fontSize: '24px', lineHeight: '30px', ...sxTitle }}
                    >
                        {title}
                    </Typography>
                    <Typography sx={{ textAlign: 'center', mt: 2 }}>{description}</Typography>
                </Box>
                <Box
                    sx={{
                        mb: 5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        flexWrap: 'wrap',
                        mt: 5,
                        gap: 2,
                    }}
                >
                    {!!onClose && (
                        <CtaButton onClick={onCloseHandler} sx={{ px: 6 }} variant="outlined" disabled={loading}>
                            {cancelButtonText}
                        </CtaButton>
                    )}
                    {!!onConfirm && (
                        <CtaButton onClick={onConfirm} sx={{ px: 6 }} variant="contained" loading={loading}>
                            {confirmButtonText}
                        </CtaButton>
                    )}
                </Box>
            </Box>
        </CustomModal>
    );
};

export default ConfirmCancelModal;
