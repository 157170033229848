import { createContext } from 'react';

export default createContext({});
export const QuizAudienceContext = createContext({
    audienceEnabled: false,
    audienceTested: false,
    clusters: [],
    events: [],
    testerGroups: [],
});
