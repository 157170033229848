export const clientSurveyStatus = {
    draft: 'draft',
    test: 'test',
    prodNotActive: 'prodNotActive',
    prodActive: 'prodActive',

    expired: 'expired',
    revoked: 'revoked',
};

export const clientQuizStatus = {
    draft: 'draft',
    test: 'test',
    prodNotActive: 'prodNotActive',
    prodActive: 'prodActive',
    paused: 'paused',
    expired: 'expired',
    revoked: 'revoked',
};
