import React, { useContext } from 'react';
import { Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import ClusterGroup from './ClusterGroup';
import AndOrButton from './AndOrButton';
import { clientQuizStatus } from '~js/constants/missionStatuses';
import QuizContext, { QuizAudienceContext } from '~js/pages/backoffice/Tools/Quiz/context';
import { CheckboxInput } from '~js/components/input';
import ExposureCoverage from '~js/pages/backoffice/Tools/Quiz/components/Audience/ExposureCoverage';

const ClusterComposer = () => {
    const { currentClientQuizStatus, formDisabled } = useContext(QuizContext);
    const { clusters, setAudienceEnabled } = useContext(QuizAudienceContext);

    const clusterOptions = [
        {
            _id: 0,
            label: trans('quiz.selectCluster'),
            value: ' ',
        },
        ...(clusters ?? []).map((c) => ({
            _id: c._id,
            label: c.title,
            value: c._id,
        })),
    ];

    const {
        control,
        watch,
        setValue,
        trigger,
        formState: { errors },
        clearErrors,
    } = useFormContext();

    const {
        fields: clusterGroupFields,
        append: clusterGroupAppend,
        remove: clusterGroupRemove,
    } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'audience.clustersGroups', // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
    });

    const onChangeOperatorWithPrevious = (val) => {
        watch('audience.clustersGroups').forEach((cg, index) =>
            setValue(`audience.clustersGroups.${index}.operatorWithPrevious`, val),
        );
    };

    return (
        <Box width="100%" mt={4}>
            <Box
                width="100%"
                sx={{
                    border: '1px solid #dfdfe4',
                    borderRadius: 1,
                    bgcolor: 'white',
                }}
            >
                <Box p={3}>
                    <Box display="flex" flexDirection="row" alignItems="center" mb={watch('audience.active') ? 2 : 0}>
                        <Controller
                            control={control}
                            name="audience.active"
                            shouldUnregister
                            defaultValue={false}
                            render={({ field }) => (
                                <CheckboxInput
                                    disabled={
                                        formDisabled ||
                                        currentClientQuizStatus === clientQuizStatus.prodActive ||
                                        currentClientQuizStatus === clientQuizStatus.prodNotActive
                                    }
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    label={trans('quiz.audience')}
                                    {...field}
                                    onChange={({ target: { checked } }) => {
                                        setAudienceEnabled(checked);
                                        setValue('audience.clustersGroups', [
                                            {
                                                operatorWithPrevious: 'AND',
                                                operatorInsideGroup: 'AND',
                                                clusters: [' '],
                                            },
                                        ]);
                                        field.onChange(checked);
                                    }}
                                />
                            )}
                        />
                    </Box>

                    {watch('audience.active') && (
                        <>
                            {clusterGroupFields.map((cg, index) => (
                                <Box key={cg.id}>
                                    {index > 0 && (
                                        <Box width="100%" display="flex" alignItems="center" my={4}>
                                            <Box width="100%" height="1px" bgcolor={'#dfdfe4'} />
                                            <AndOrButton
                                                disabled={
                                                    formDisabled ||
                                                    currentClientQuizStatus === clientQuizStatus.prodActive ||
                                                    currentClientQuizStatus === clientQuizStatus.prodNotActive
                                                }
                                                bgcolor="#FFBC00"
                                                value={watch(`audience.clustersGroups.${index}.operatorWithPrevious`)}
                                                //onChange={(val) => setValue(`audience.clustersGroups.${index}.operatorWithPrevious`, val)}
                                                onChange={onChangeOperatorWithPrevious}
                                            />
                                            <Box width="100%" height="1px" bgcolor={'#dfdfe4'} />
                                        </Box>
                                    )}

                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        sx={{ border: '1px solid #DFDFDF', borderRadius: 1, overflow: 'hidden' }}
                                    >
                                        <ClusterGroup
                                            clusters={clusters}
                                            clusterGroupId={index}
                                            clusterOptions={clusterOptions}
                                        />
                                        {clusterGroupFields.length > 1 && (
                                            <Box
                                                display="flex"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                sx={{ bgcolor: 'rgba(233, 233, 233, 1)' }}
                                                p={2}
                                            >
                                                <IconButton
                                                    disabled={
                                                        clusterGroupFields.length === 1 ||
                                                        formDisabled ||
                                                        currentClientQuizStatus === clientQuizStatus.prodActive ||
                                                        currentClientQuizStatus === clientQuizStatus.prodNotActive
                                                    }
                                                    onClick={() => clusterGroupRemove(index)}
                                                >
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            ))}

                            {/* Add cluster group button */}
                            <Box width="100%" display="flex" alignItems="center" mt={4} mb={10}>
                                <Box width="100%" height="1px" bgcolor={'#dfdfe4'} />
                                <IconButton
                                    disabled={
                                        watch(
                                            `audience.clustersGroups.${clusterGroupFields.length - 1}.clusters`,
                                        )?.[0] === ' ' ||
                                        formDisabled ||
                                        currentClientQuizStatus === clientQuizStatus.prodActive ||
                                        currentClientQuizStatus === clientQuizStatus.prodNotActive
                                    }
                                    sx={{
                                        width: '100px',
                                        borderRadius: 1,
                                        border: '1px solid #dfdfe4',
                                    }}
                                    onClick={() =>
                                        clusterGroupAppend({
                                            operatorWithPrevious: 'AND',
                                            operatorInsideGroup: 'AND',
                                            clusters: [' '],
                                        })
                                    }
                                >
                                    <AddIcon />
                                </IconButton>
                                <Box width="100%" height="1px" bgcolor={'#dfdfe4'} />
                            </Box>

                            <ExposureCoverage />
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default ClusterComposer;
