import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import TextInput from '~js/components/TextInput';
import { clientSurveyStatus } from '~js/constants/missionStatuses';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';
import SurveyContext from '../context';

const PriorityMultiFlowResponseInput = ({ fieldBasePath, errorsBasePath }) => {
    const { formDisabled, currentClientSurveyStatus } = useContext(SurveyContext);

    const { control, watch } = useFormContext();

    return (
        <Controller
            control={control}
            name={`${fieldBasePath}.priority`}
            rules={{
                required: trans('formValidation.requiredField'),
                pattern: { value: REGEX_ONLY_PLAIN_CHAR, message: trans('formValidation.charNotAllowed') },
            }}
            defaultValue={watch(`${fieldBasePath}.priority`) || 0}
            shouldUnregister
            render={({ field }) => (
                <TextInput
                    disabled={
                        formDisabled ||
                        currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                        currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                    }
                    inputProps={{ min: 0 }}
                    type="number"
                    label={trans('survey.priority')}
                    helperText={errorsBasePath && errorsBasePath?.priority?.message}
                    error={!!errorsBasePath?.priority}
                    {...field}
                />
            )}
        />
    );
};

export default PriorityMultiFlowResponseInput;
