import React, { useRef, useState } from 'react';
import { router } from '@inertiajs/react';
import { DataGrid, itIT } from '@mui/x-data-grid';
import { Box, IconButton, SvgIcon, Tooltip } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import { trans } from 'matice';
import useDateFormat from '~js/hooks/useDateFormat';
import ConfirmCancelModal from '~js/components/ConfirmCancelModal';
import QuizStatusSelectInput from '~js/pages/backoffice/Tools/Quiz/components/QuizStatusSelectInput';
import { dateSortComparator } from '~js/utils/dateComparator';
import IconEdit from '~/assets/images/icons/icon-edit.svg?react';
import IconArchive from '~/assets/images/icons/icon-archive.svg?react';
import IconArchiveDisabled from '~/assets/images/icons/icon-archive-disabled.svg?react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';

const QuizTable = ({
    quizzes,
    deleteQuiz,
    changeQuizStatus,
    testerGroups,
    canReadToolIds = false,
    canUpdate,
    canDelete,
    canArchive,
    canDuplicate,
    canChangeStatusToProd,
}) => {
    const { formatDate } = useDateFormat();

    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [showArchiveConfirmModal, setShowArchiveConfirmModal] = useState(false);
    const [showUnArchiveConfirmModal, setShowUnArchiveConfirmModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const quizIdToDelete = useRef(null);
    const quizIdToArchive = useRef(null);
    const quizIdToUnArchive = useRef(null);

    const deleteQuizHandler = async () => {
        setLoading(true);
        try {
            await deleteQuiz(quizIdToDelete.current);
            onCloseModalDeleteHandler();
        } finally {
            setLoading(false);
        }
    };

    const archiveQuizHandler = async () => {
        setLoading(true);
        try {
            await changeQuizStatus(quizIdToArchive.current, 'archive');
            onCloseModalArchiveHandler();
        } finally {
            setLoading(false);
        }
    };

    const unArchiveQuizHandler = async () => {
        setLoading(true);
        try {
            await changeQuizStatus(quizIdToUnArchive.current, 'restore');
            onCloseModalUnArchiveHandler();
        } finally {
            setLoading(false);
        }
    };

    const onEditPress = (idToEdit) => {
        router.get(
            route('Quiz.edit', idToEdit),
            {},
            {
                onError: (e) => {
                    toast.error(!!e?.generic ? e.generic : trans('quiz.errorOnEditQuiz'));
                },
            },
        );
    };

    const onDuplicatePress = (idToDuplicate) => {
        router.get(
            route('Quiz.duplicate', idToDuplicate),
            {},
            {
                onError: (e) => {
                    toast.error(!!e?.generic ? e.generic : trans('quiz.errorOnDuplicateQuiz'));
                },
            },
        );
    };

    const onCloseModalDeleteHandler = () => {
        setShowDeleteConfirmModal(false);
        quizIdToDelete.current = null;
    };

    const onCloseModalArchiveHandler = () => {
        setShowArchiveConfirmModal(false);
        quizIdToArchive.current = null;
    };

    const onCloseModalUnArchiveHandler = () => {
        setShowUnArchiveConfirmModal(false);
        quizIdToUnArchive.current = null;
    };

    const renderCellAction = ({ row }) => {
        const onDeletePress = (e) => {
            e.stopPropagation();
            quizIdToDelete.current = row._id;
            setShowDeleteConfirmModal(true);
        };

        const onArchivePress = (e) => {
            e.stopPropagation();
            quizIdToArchive.current = row._id;
            setShowArchiveConfirmModal(true);
        };

        const onUnArchivePress = (e) => {
            e.stopPropagation();
            quizIdToUnArchive.current = row._id;
            setShowUnArchiveConfirmModal(true);
        };

        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                {!row.general.archived && (
                    <>
                        {canUpdate ? (
                            <Tooltip title={trans('quiz.editTooltip')}>
                                <IconButton onClick={() => onEditPress(row._id)} disabled={!canUpdate}>
                                    <SvgIcon inheritViewBox>
                                        <IconEdit />
                                    </SvgIcon>
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title={trans('quiz.openSummaryTooltip')}>
                                <IconButton onClick={() => onEditPress(row._id)}>
                                    <AssignmentOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                )}

                {row.general.archived && (
                    <Tooltip title={trans('quiz.openSummaryTooltip')}>
                        <IconButton onClick={() => onEditPress(row._id)}>
                            <AssignmentOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                )}

                <Tooltip title={trans('quiz.duplicateQuiz')}>
                    <IconButton onClick={() => onDuplicatePress(row._id)} disabled={!canDuplicate}>
                        {/*
                            <SvgIcon inheritViewBox>
                                <IconDuplicate />
                            </SvgIcon>
                        */}
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>

                {row.general.archived && (
                    <Tooltip title={trans('quiz.unarchiveTooltip')}>
                        <IconButton onClick={onUnArchivePress} disabled={!canArchive}>
                            <UnarchiveOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                )}

                {!row.general.archived && (
                    <>
                        {row.general.status === 'draft' || row.general.status === 'test' ? (
                            <Tooltip title={trans('quiz.deleteTooltip')}>
                                <IconButton onClick={onDeletePress} disabled={!canDelete}>
                                    <DeleteOutlineOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title={trans('quiz.archiveTooltip')}>
                                <span>
                                    <IconButton
                                        disabled={row.general.status === 'prod' || !canArchive}
                                        onClick={onArchivePress}
                                    >
                                        <SvgIcon inheritViewBox>
                                            {row.general.status === 'prod' || !canArchive ? (
                                                <IconArchiveDisabled />
                                            ) : (
                                                <IconArchive />
                                            )}
                                        </SvgIcon>
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                    </>
                )}
            </Box>
        );
    };

    const renderStatusCell = ({ row }) => {
        return (
            <Box>
                <QuizStatusSelectInput
                    disabled={!canUpdate}
                    canChangeStatusToProd={canChangeStatusToProd}
                    quizStartDate={row?.general?.startDate}
                    quizEndDate={row?.general?.endDate}
                    value={row?.general?.status}
                    onChange={(newStatus, testGroups) => changeQuizStatus(row._id, newStatus, testGroups)}
                    testerGroups={testerGroups}
                    selectedGroupsTest={row?.general?.groupTest}
                />
            </Box>
        );
    };

    const columns = [
        {
            field: 'externalData.rewarding.id',
            headerName: 'ID Rewarding',
            flex: 1,
            valueGetter: ({ row }) => row.externalData.rewarding.id,
        },
        {
            field: 'general.title',
            headerName: trans('cluster.title'),
            flex: 1,
            valueGetter: ({ row }) => row.general.title,
        },
        {
            field: 'general.description',
            headerName: trans('quiz.description'),
            flex: 1,
            valueGetter: ({ row }) => row.general.description,
        },
        {
            field: 'general.startDate',
            headerName: trans('quiz.dateStart'),
            flex: 1,
            valueGetter: ({ row }) => formatDate(row.general.startDate, 'Pp'),
            sortComparator: (d1, d2) => dateSortComparator(d1, d2),
        },
        {
            field: 'general.endDate',
            headerName: trans('quiz.dateEnd'),
            flex: 1,
            valueGetter: ({ row }) => formatDate(row.general.endDate, 'Pp'),
            sortComparator: (d1, d2) => dateSortComparator(d1, d2),
        },
        {
            field: 'general.updated_at',
            headerName: trans('quiz.lastEdit'),
            flex: 1,
            valueGetter: ({ row }) => formatDate(row.updated_at, 'Pp'),
            sortComparator: (d1, d2) => dateSortComparator(d1, d2),
        },
        {
            field: 'general.status',
            headerName: trans('quiz.status'),
            flex: 1,
            renderCell: renderStatusCell,
        },
        {
            headerName: ' ',
            field: ' ',
            flex: 0.5,
            sortable: false,
            renderCell: renderCellAction,
            minWidth: 150,
        },
    ];

    return (
        <>
            {quizzes.length > 0 && (
                <Box
                    sx={{
                        '& .archived': {
                            bgcolor: '#FFF6F4',
                        },
                    }}
                >
                    <DataGrid
                        rows={quizzes}
                        columns={[
                            ...(canReadToolIds
                                ? [
                                      {
                                          field: '_id',
                                          headerName: 'ID',
                                          flex: 1,
                                      },
                                  ]
                                : []),
                            ...columns,
                        ]}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        autoHeight
                        rowLength={5}
                        maxColumns={6}
                        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                        getRowId={(r) => r._id}
                        //onRowClick={(r) => onEditPress(r.id)}
                        getRowClassName={(params) => params.row.general.archived && `archived`}
                    />
                </Box>
            )}
            {/*Modal delete quiz*/}
            <ConfirmCancelModal
                iconType="delete"
                show={showDeleteConfirmModal}
                onClose={onCloseModalDeleteHandler}
                title={trans('quiz.deleteQuizTitle')}
                description={trans('quiz.deleteQuizDescription')}
                onConfirm={deleteQuizHandler}
                loading={loading}
            />

            {/*Modal archive quiz*/}
            <ConfirmCancelModal
                iconType="archive"
                show={showArchiveConfirmModal}
                onClose={onCloseModalArchiveHandler}
                title={trans('quiz.archiveQuizTitle')}
                description={trans('quiz.archiveQuizDescription')}
                onConfirm={archiveQuizHandler}
                loading={loading}
            />

            {/*Modal unarchive quiz*/}
            <ConfirmCancelModal
                iconType="unarchive"
                show={showUnArchiveConfirmModal}
                onClose={onCloseModalUnArchiveHandler}
                title={trans('quiz.unarchiveQuizTitle')}
                description={trans('quiz.unarchiveQuizDescription')}
                onConfirm={unArchiveQuizHandler}
                loading={loading}
            />
        </>
    );
};

export default QuizTable;
