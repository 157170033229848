import React, { useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { endOfDay } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { trans } from 'matice';
import { CheckboxInput, DatePickerInput, SelectInput, TextInput } from '~js/components/input';
import { clientQuizStatus } from '~js/constants/missionStatuses';
import { QUIZ_TYPOLOGIES, QUIZ_TYPOLOGIES_WITH_EXTRA_REWARD } from '~js/constants/quiz';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';
import NewQuizActionButtons from '~js/pages/backoffice/Tools/Quiz/components/NewQuizActionButtons';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import AutocompleteInput from '~js/components/AutocompleteInput';

const MAX_POINTS = 1000;

const typologyOptions = [
    { _id: 1, label: trans('quiz.typologies.classic'), value: QUIZ_TYPOLOGIES.classic },
    { _id: 2, label: trans('quiz.typologies.time'), value: QUIZ_TYPOLOGIES.time },
    { _id: 3, label: trans('quiz.typologies.difference'), value: QUIZ_TYPOLOGIES.difference },
    { _id: 4, label: trans('quiz.typologies.memory'), value: QUIZ_TYPOLOGIES.memory },
    { _id: 5, label: trans('quiz.typologies.drag'), value: QUIZ_TYPOLOGIES.drag },
];

const QuizStep0General = ({
    handleStepNext,
    handleStepPrevious,
    stepFormValue,
    advertising = [],
    isLoading = false,
}) => {
    const { isEditMode, currentClientQuizStatus, formDisabled } = useContext(QuizContext);

    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: stepFormValue,
    });

    const onSubmitHandler = async (data) => {
        handleStepNext({ general: data });
    };

    const hasExtraReward = QUIZ_TYPOLOGIES_WITH_EXTRA_REWARD.includes(watch('typology'));
    return (
        <Box mt={4}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Grid container columnSpacing={2} py={5} border={'1px solid #DFDFE4'} borderRadius={'12px'}>
                    <Grid item xs={12} mb={4}>
                        <Typography component={'h1'}>{trans('quiz.generalInfo')}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px">
                            <Controller
                                control={control}
                                name="title"
                                defaultValue=""
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                    minLength: {
                                        value: 3,
                                        message: trans('formValidation.fieldTooShort'),
                                    },
                                    maxLength: {
                                        value: 50,
                                        message: trans('formValidation.fieldTooLong'),
                                    },
                                    pattern: {
                                        value: REGEX_ONLY_PLAIN_CHAR,
                                        message: trans('formValidation.charNotAllowed'),
                                    },
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        disabled={formDisabled}
                                        label={trans('quiz.title')}
                                        placeholder={trans('quiz.title')}
                                        inputProps={{ maxLength: 50 }}
                                        helperText={errors?.title?.message}
                                        error={!!errors?.title}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="description"
                                defaultValue=""
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                    maxLength: {
                                        value: 1000,
                                        message: trans('formValidation.fieldTooLong'),
                                    },
                                    pattern: {
                                        value: REGEX_ONLY_PLAIN_CHAR,
                                        message: trans('formValidation.charNotAllowed'),
                                    },
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        multiline
                                        rows={4}
                                        disabled={formDisabled}
                                        label={trans('quiz.description')}
                                        placeholder={trans('quiz.description')}
                                        inputProps={{ maxLength: 1000 }}
                                        helperText={errors?.description?.message}
                                        error={!!errors?.description}
                                        {...field}
                                    />
                                )}
                            />
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ width: '100%', mr: 4 }}>
                                    <Controller
                                        control={control}
                                        name="startDate"
                                        rules={
                                            formDisabled || currentClientQuizStatus === clientQuizStatus.prodActive
                                                ? {}
                                                : {
                                                      required: trans('formValidation.requiredField'),
                                                      validate: (val) => {
                                                          console.log(val);
                                                          console.log(Date.parse(val) > new Date());
                                                          return (
                                                              Date.parse(val) > new Date() ||
                                                              trans('quiz.selectDateFuture')
                                                          );
                                                      },
                                                      deps: ['endDate'],
                                                  }
                                        }
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerInput
                                                disabled={
                                                    formDisabled ||
                                                    currentClientQuizStatus === clientQuizStatus.prodActive
                                                }
                                                label={trans('quiz.dateStart')}
                                                value={value}
                                                onChangeValue={onChange}
                                                helperText={errors?.startDate?.message}
                                                error={!!errors?.startDate}
                                            />
                                        )}
                                    />
                                </Box>
                                <Controller
                                    control={control}
                                    name="endDate"
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        validate: (val) =>
                                            val > watch('startDate') || trans('quiz.endMustBeGreaterThanStart'),
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerInput
                                            disabled={formDisabled}
                                            label={trans('quiz.dateEnd')}
                                            value={value}
                                            onChangeValue={(e) => {
                                                onChange(endOfDay(e));
                                            }}
                                            helperText={errors?.endDate?.message}
                                            error={!!errors?.endDate}
                                            timeEnabled={false}
                                            minDateTime={watch('startDate')}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{ maxWidth: '500px' }}>
                            <Controller
                                control={control}
                                name="typology"
                                defaultValue={QUIZ_TYPOLOGIES.classic}
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                }}
                                render={({ field }) => (
                                    <SelectInput
                                        disabled={isEditMode}
                                        placeholderLabel={'seleziona una tipologia'}
                                        label={trans('quiz.typology')}
                                        selectOptions={typologyOptions}
                                        helperText={errors?.typology?.message}
                                        error={!!errors?.typology}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                    max: { value: MAX_POINTS, message: trans('formValidation.maxPointsExceeded') },
                                    pattern: {
                                        value: REGEX_ONLY_PLAIN_CHAR,
                                        message: trans('formValidation.charNotAllowed'),
                                    },
                                }}
                                shouldUnregister
                                name="points"
                                defaultValue={20}
                                render={({ field }) => (
                                    <TextInput
                                        disabled={
                                            formDisabled ||
                                            currentClientQuizStatus === clientQuizStatus.prodActive ||
                                            currentClientQuizStatus === clientQuizStatus.prodNotActive
                                        }
                                        inputProps={{ min: 0, max: MAX_POINTS, step: 10 }}
                                        type="number"
                                        label={trans('quiz.' + (hasExtraReward ? 'rewards' : 'reward'))}
                                        placeholder={trans('quiz.' + (hasExtraReward ? 'rewards' : 'reward'))}
                                        helperText={errors?.points?.message}
                                        error={!!errors?.points}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid
                    container
                    columnSpacing={2}
                    my={4}
                    py={5}
                    px={2}
                    border={'1px solid #DFDFE4'}
                    borderRadius={'12px'}
                >
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="advertisingEnabled"
                            defaultValue={false}
                            shouldUnregister
                            render={({ field }) => (
                                <CheckboxInput
                                    disabled={
                                        formDisabled ||
                                        (!!watch('advertisingEnabled') &&
                                            (currentClientQuizStatus === clientQuizStatus.prodActive ||
                                                currentClientQuizStatus === clientQuizStatus.prodNotActive))
                                    }
                                    label={trans('quiz.advertisingIdsCheckboxLabel')}
                                    {...field}
                                />
                            )}
                        />
                        {watch('advertisingEnabled') && (
                            <Controller
                                control={control}
                                name="advertisingIds"
                                defaultValue={stepFormValue?.advertisingIds ?? []}
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                    validate: (value, formValues) => {
                                        if (Array.isArray(value) && value.length < 1) {
                                            return trans('quiz.advertisingIdsAtLeastOne');
                                        }
                                    },
                                }}
                                render={({ field }) => (
                                    <AutocompleteInput
                                        label={trans('quiz.advertisingIds')}
                                        placeholder={trans('quiz.search')}
                                        helperText={errors?.advertisingIds?.message}
                                        error={!!errors?.advertisingIds}
                                        options={advertising}
                                        multiple={true}
                                        {...field}
                                    />
                                )}
                            />
                        )}
                    </Grid>
                </Grid>
                {!formDisabled && (
                    <NewQuizActionButtons
                        isLoading={isLoading}
                        onLeftButtonClick={handleStepPrevious}
                        rightButtonText={isEditMode ? trans('global.save') : trans('global.next')}
                        leftButtonText={trans('global.cancel')}
                    />
                )}
            </form>
        </Box>
    );
};

export default QuizStep0General;
