import React, { useState } from "react";
import { DateTimePicker, DesktopDatePicker } from "@mui/x-date-pickers";
import TextInput from "./TextInput";
import { Box, ClickAwayListener } from "@mui/material";

const DatePickerInput = ({
    label,
    value,
    helperText,
    error,
    onChangeValue = () => {},
    disabled,
    disablePast = true,
    timeEnabled = true,
    minDateTime = null,
}) => {
    //const [dateValue, setDateValue] = useState(value);
    const [openPicker, setOpenPicker] = useState(false);

    const handleChangeDate = (newValue) => {
        //setDateValue(newValue);
        onChangeValue(new Date(newValue));
        setOpenPicker(false);
    };

    const renderInput = (params) => {
        if(value === undefined) {
            params['inputProps']['value'] = '';
        }
        return <TextInput {...params} helperText={helperText} error={error} />;
    }

    return (
        <>
            {/*    <MobileDateTimePicker
                disabled={disabled}
                label={label}
                inputFormat="dd/MM/yyyy  HH:mm"
                ampm={false}
                disablePast={disablePast}
                minutesStep={30}
                value={dateValue}
                onChange={handleChangeDate}
                minDateTime={new Date()}
                renderInput={(params) => <TextInput {...params} helperText={helperText} error={error} />}
            />*/}
            <ClickAwayListener onClickAway={() => setOpenPicker(false)}>
                <Box
                    sx={{ width: '100%', position: 'relative' }}
                    onClick={!disabled ? () => setOpenPicker(true) : () => {}}
                >
                    {timeEnabled ? (
                        <DateTimePicker
                            open={openPicker}
                            disabled={disabled}
                            label={label}
                            inputFormat="dd/MM/yyyy  HH:mm"
                            ampm={false}
                            disablePast={disablePast}
                            minutesStep={30}
                            value={value}
                            onChange={handleChangeDate}
                            minDate={minDateTime ? minDateTime : new Date()}
                            renderInput={renderInput}
                        />
                    ) : (
                        <DesktopDatePicker
                            open={openPicker}
                            disabled={disabled}
                            label={label}
                            inputFormat="dd/MM/yyyy HH:mm"
                            disablePast={disablePast}
                            value={value}
                            onChange={handleChangeDate}
                            minDate={minDateTime ? minDateTime : new Date()}
                            renderInput={renderInput}
                        />
                    )}
                    {/*for disabled input compilation by keyboard*/}
                    <Box sx={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0 }} />
                </Box>
            </ClickAwayListener>
        </>
    );
};

export default DatePickerInput;
