import React, { useContext, useState } from 'react';
import { Box } from '@mui/material';
import { trans } from 'matice';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { isArray } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CtaButton from '~js/components/CtaButton';
import SwitchInput from '~js/components/SwitchInput';
import { clientSurveyStatus } from '~js/constants/missionStatuses';
import { SURVEY_TYPOLOGIES } from '~js/constants/survey.js';
import SurveyContext from '../context';
import NewSurveyActionButtons from './NewSurveyActionButtons';
import QuestionItem from './QuestionItem';

const NewSurveyQuestionsStep = ({ handleStepNext, handleStepPrevious, stepFormValue, isLoading = false }) => {
    const { isEditMode, formDisabled, currentClientSurveyStatus, surveyTypology } = useContext(SurveyContext);

    const formMethods = useForm({
        //shouldUnregister: true,
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: !!stepFormValue
            ? stepFormValue
            : {
                  questions: [
                      {
                          questionType: '',
                      },
                  ],
                  randomizeResponses: true,
              },
    });

    const {
        handleSubmit,
        watch,
        control,
        trigger,
        setError,
        setValue,
        formState: { errors },
        getValues,
        resetField,
    } = formMethods;

    console.debug({ ERRORS: errors, VALUES: getValues() });

    const {
        fields: questionsFields,
        append: questionsAppend,
        remove: questionsRemove,
        insert: questionsInsert,
        move: questionsMove,
    } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'questions', // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
        shouldUnregister: true,
    });

    const [currentFocusedQuestion, setCurrentFocusedQuestion] = useState(null);

    const resetNextQuestionIdInAllResponse = () => {
        watch('questions').forEach((q, qIndex) => {
            console.log(q);
            if (!q.responses && !isArray(q.responses)) {
                return;
            }
            q.responses.forEach((r, rIndex) => {
                if (!!r.nextQuestionId) {
                    const respNextQuestionId = `questions.${qIndex}.responses.${rIndex}.nextQuestionId`;
                    setValue(respNextQuestionId, '');
                    resetField(respNextQuestionId);
                    console.debug(`resetted: ${respNextQuestionId}`);
                }
            });
        });
    };

    const duplicateQuestion = async (questionIdToDuplicate) => {
        const questionToDuplicate = getValues(`questions.${questionIdToDuplicate}`);
        questionsInsert(questionIdToDuplicate + 1, { ...questionToDuplicate });
        setCurrentFocusedQuestion(questionIdToDuplicate + 1);

        if (surveyTypology === SURVEY_TYPOLOGIES.multiFlow) {
            //vengono resettati tutti i nextQuestionId per evitare riferimenti a domande errate
            resetNextQuestionIdInAllResponse();
        }
    };

    const moveQuestion = (fromIndex, toIndex) => {
        if (toIndex <= questionsFields.length - 1 && toIndex >= 0) {
            if (surveyTypology === SURVEY_TYPOLOGIES.multiFlow) {
                //vengono resettati tutti i nextQuestionId per evitare riferimenti a domande errate
                resetNextQuestionIdInAllResponse();
            }

            const fromIndexQ = getValues(`questions.${fromIndex}`);
            questionsMove(fromIndex, toIndex);

            //workaround per evitare errore di validazione non pertinenti alla domanda spostata
            questionsRemove(toIndex);
            setTimeout(() => {
                questionsInsert(toIndex, { ...fromIndexQ });
            }, 0);
        }
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        moveQuestion(result.source.index, result.destination.index);
    };

    const appendQuestion = () => {
        trigger().then((formIsValid) => {
            if (formIsValid) {
                questionsAppend({ questionType: '', caption: '' });
                setCurrentFocusedQuestion((val) => val + 1);
            }
        });
    };

    const onSubmit = (data) => {
        if (surveyTypology === SURVEY_TYPOLOGIES.linear) {
            handleStepNext({ questions: data });
            return;
        }

        const { questions } = data;
        let hasError = false;

        questions.forEach((question, questionIndex) => {
            question?.responses?.forEach((response, responseIndex) => {
                if (response.nextQuestionId === '') {
                    setError(`questions.${questionIndex}.responses.${responseIndex}.nextQuestionId`, {
                        type: 'custom',
                        message: trans('formValidation.requiredField'),
                    });
                    hasError = true;
                }

                if (response.nextQuestionId !== '' && response.nextQuestionId > questions.length - 1) {
                    setValue(`questions.${questionIndex}.responses.${responseIndex}.nextQuestionId`, '');
                    setError(`questions.${questionIndex}.responses.${responseIndex}.nextQuestionId`, {
                        type: 'custom',
                        message: trans('formValidation.requiredField'),
                    });
                    hasError = true;
                }
            });
        });

        if (hasError) {
            return;
        }

        const mappedQuestion = questions.map((q) => {
            if (q.questionType !== 'multipleRating') {
                return q;
            }

            const nextQId = q.responses[0].nextQuestionId;

            const newResponses = q.responses.map((r) => ({ ...r, nextQuestionId: nextQId }));

            return {
                ...q,
                responses: newResponses,
            };
        });
        handleStepNext({ questions: { ...data, questions: mappedQuestion } });
    };

    return (
        <FormProvider {...formMethods}>
            <Box display="flex" justifyContent="flex-end" sx={{ mt: 6 }}>
                <Controller
                    control={control}
                    name="randomizeResponses"
                    render={({ field }) => (
                        <SwitchInput
                            disabled={
                                formDisabled ||
                                currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                            }
                            label={trans('survey.randomizeResponses')}
                            {...field}
                        />
                    )}
                />
            </Box>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="questionsDroppable">
                        {(provided) => (
                            <Box {...provided.droppableProps} ref={provided.innerRef}>
                                {questionsFields.map((question, index) => (
                                    <Draggable
                                        key={question.id}
                                        draggableId={question.id}
                                        index={index}
                                        isDragDisabled={
                                            surveyTypology === SURVEY_TYPOLOGIES.multiFlow ||
                                            formDisabled ||
                                            currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                            currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                        }
                                    >
                                        {(provided) => (
                                            <Box
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                mt={6}
                                            >
                                                <QuestionItem
                                                    questionIndex={index}
                                                    setCurrentFocusedQuestion={setCurrentFocusedQuestion}
                                                    currentFocusedQuestion={currentFocusedQuestion}
                                                    deleteQuestion={() => questionsRemove(index)}
                                                    deleteButtonDisabled={questionsFields.length === 1}
                                                    duplicateQuestion={() => duplicateQuestion(index)}
                                                    onArrowUpPress={() => moveQuestion(index, index - 1)}
                                                    onArrowDownPress={() => moveQuestion(index, index + 1)}
                                                />
                                            </Box>
                                        )}
                                    </Draggable>
                                ))}

                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>

                {!formDisabled &&
                    currentClientSurveyStatus !== clientSurveyStatus.prodActive &&
                    currentClientSurveyStatus !== clientSurveyStatus.prodNotActive && (
                        <Box width="100%" display="flex" justifyContent="center">
                            <CtaButton sx={{ mt: 2 }} onClick={appendQuestion}>
                                {trans('survey.addQuestion')}
                            </CtaButton>
                        </Box>
                    )}
                {!formDisabled && (
                    <NewSurveyActionButtons
                        isLoading={isLoading}
                        onLeftButtonClick={handleStepPrevious}
                        rightButtonText={isEditMode ? trans('global.save') : trans('global.next')}
                        leftButtonText={trans('global.back')}
                    />
                )}
            </Box>
        </FormProvider>
    );
};

export default NewSurveyQuestionsStep;
