import React from 'react';
import { Box } from '@mui/material';
import ImageChoiceListItemPreview from './ImageChoiceListItemPreview';

const ImageChoiceListPreview = ({ responses, useGridLayout }) => {
    return (
        <Box display="flex" width="100%" gap="8px" mt="12px" flexWrap="wrap">
            {responses.map((res, index) => (
                <Box
                    key={index.toString()}
                    width={useGridLayout ? '48%' : '100%'}
                    minWidth={useGridLayout ? '48%' : '100%'}
                >
                    <ImageChoiceListItemPreview image={res.image} useGridLayout={useGridLayout} />
                </Box>
            ))}
        </Box>
    );
};

export default ImageChoiceListPreview;
