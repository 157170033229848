import { createTheme, responsiveFontSizes } from '@mui/material';

const basicOptions = {
    typography: {
        fontFamily: 'Lato',
    },
    components: {
        MuiDataGrid: {
            styleOverrides: {
                columnHeaderTitle: {
                    color: '#2C2D33',
                },
                cellContent: {
                    color: '#6E7079',
                },
            },
        },
    },
    overrides: {
        MuiSwitch: {
            root: {
                width: 42,
                height: 26,
                padding: 0,
                margin: 8,
            },
            switchBase: {
                padding: 1,
                '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
                    transform: 'translateX(16px)',
                    color: '#fff',
                    '& + $track': {
                        opacity: 1,
                        border: 'none',
                    },
                },
            },
            thumb: {
                width: 24,
                height: 24,
            },
            track: {
                borderRadius: 13,
                border: '1px solid #bdbdbd',
                backgroundColor: '#fafafa',
                opacity: 1,
                transition:
                    'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
        },
    },
    shape: {
        borderRadius: 10,
    },
};

const companyPalette = {
    palette: {
        type: 'light',
        primary: {
            main: '#1F16A5',
            light: 'rgb(75, 68, 183)',
            dark: '#150F73',
            textBackground: 'rgba(31,22,165,0.15)',
            iconBackground: '#FFCEBD',
        },
        secondary: {
            main: '#f15f2d',
            light: '#FFE6DE',
            dark: '#bb451e',
        },
        background: {
            paper: '#FFFFFF',
            light: '#EDF8F9',
            default: '#FAFAFA',
        },
        text: {
            primary: '#45464E',
            secondary: '#8B8D97',
        },
        error: {
            main: '#F9473C',
        },
        divider: '#dfdfe4',
    },
};

const linesPalette = {
    palette: {
        type: 'light',
        primary: {
            main: '#7D5A98',
            light: '#977BAC',
            dark: '#573E6A',
            textBackground: 'rgba(125,90,152,0.15)',
            iconBackground: '#FFCEBD',
        },
        secondary: {
            main: '#f15f2d',
            light: '#FFE6DE',
            dark: '#bb451e',
        },
        background: {
            paper: '#FFFFFF',
            light: '#EDF8F9',
            default: '#FAFAFA',
        },
        text: {
            primary: '#45464E',
            secondary: '#8B8D97',
        },
        error: {
            main: '#F9473C',
        },
        divider: '#dfdfe4',
    },
};

const pampersPalette = {
    palette: {
        type: 'light',
        primary: {
            main: '#10a9ae',
            light: '#3FBABE',
            dark: '#0B7679',
            contrastText: '#ffffff',
            textBackground: 'rgba(16,169,174,0.15)',
            iconBackground: 'rgba(16,169,174,0.15)',
            bgTransparency: 'rgba(143,206,202,.15)',
        },
        secondary: {
            main: '#f50057',
        },
        background: {
            paper: '#FFFFFF',
            light: '#EDF8F9',
            default: '#FAFAFA',
            overlay: 'rgba(53, 73, 83, .8)',
        },
        text: {
            primary: '#45464E',
            secondary: '#8B8D97',
        },
        error: {
            main: '#F9473C',
        },
        divider: '#dfdfe4',
    },
};

export const THEME = {
    COMPANY: 'company',
    PAMPERS: 'pampers',
    LINES: 'lines',
};
export const getResponsiveTheme = (themeId, customThemeProps = {}) => {
    let palette;
    switch (themeId) {
        case THEME.PAMPERS:
            palette = pampersPalette;
            break;
        case THEME.LINES:
            palette = linesPalette;
            break;
        default:
            palette = companyPalette;
            break;
    }

    return responsiveFontSizes(
        createTheme({
            ...basicOptions,
            ...palette,
            ...customThemeProps,
        }),
    );
};
