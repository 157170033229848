import React from 'react';
import { AppBar, Box, Container, ThemeProvider, Toolbar } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getResponsiveTheme, THEME } from '~js/constants/themes';

const theme = getResponsiveTheme(THEME.PAMPERS);

const AuthLayout = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <Box
                display="flex"
                flexDirection={'column'}
                justifyContent="center"
                alignItems="center"
                height="100vh"
                sx={{
                    bgcolor: (theme) => theme.palette.background.default,
                }}
            >
                <AppBar
                    position="fixed"
                    sx={{
                        bgcolor: (theme) => theme.palette.primary.main,
                        zIndex: 999,
                    }}
                    elevation={0}
                >
                    <Toolbar />
                </AppBar>
                <Container>
                    {children}
                    <ToastContainer />
                </Container>
            </Box>
        </ThemeProvider>
    );
};

export default AuthLayout;
