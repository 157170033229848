import React, { useContext, useEffect } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';
import TextInput from '~js/components/TextInput';
import { SURVEY_TYPOLOGIES } from '~js/constants/survey.js';
import { clientSurveyStatus } from '~js/constants/missionStatuses';
import ActionMultiFlowResponse from './ActionMultiFlowResponse';
import CtaButton from '~js/components/CtaButton';
import CaptionInput from './CaptionInput';
import SurveyContext from '../context';

const RankingResponseForm = ({ questionIndex }) => {
    const { currentClientSurveyStatus, formDisabled, surveyTypology } = useContext(SurveyContext);
    const isMultiFlowSurvey = surveyTypology === SURVEY_TYPOLOGIES.multiFlow;

    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext();

    const fieldBasePath = `questions.${questionIndex}`;
    const errorsBasePath =
        errors?.questions && errors.questions[questionIndex] ? errors.questions[questionIndex] : null;

    const {
        fields: responsesFields,
        append: responsesAppend,
        remove: responsesRemove,
    } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: `${fieldBasePath}.responses`, // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
        shouldUnregister: true,
    });

    useEffect(() => {
        if (responsesFields.length === 0) {
            responsesAppend({});
            responsesAppend({});
        }
    }, [responsesFields]);

    return (
        <Box>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box maxWidth="500px">
                        <Box sx={{ pr: '40px' }}>
                            <CaptionInput questionIndex={questionIndex} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            {responsesFields.map((resField, index) => (
                <Grid container columnSpacing={2} key={resField.id}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px">
                            <Box display="flex" alignItems="center">
                                <Controller
                                    control={control}
                                    name={`${fieldBasePath}.responses.${index}.response`}
                                    defaultValue={watch(`${fieldBasePath}.responses.${index}.response`) || ''}
                                    shouldUnregister
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        maxLength: {
                                            value: 255,
                                            message: trans('formValidation.fieldTooLong'),
                                        },
                                        pattern: {
                                            value: REGEX_ONLY_PLAIN_CHAR,
                                            message: trans('formValidation.charNotAllowed'),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            disabled={formDisabled}
                                            label={`${trans('survey.response')} ${index + 1}`}
                                            helperText={
                                                !!errorsBasePath?.responses &&
                                                errorsBasePath?.responses[index] &&
                                                errorsBasePath?.responses[index]?.response?.message
                                            }
                                            error={!!errorsBasePath?.responses && !!errorsBasePath?.responses[index]}
                                            {...field}
                                        />
                                    )}
                                />
                                {responsesFields.length > 2 &&
                                !formDisabled &&
                                currentClientSurveyStatus !== clientSurveyStatus.prodNotActive &&
                                currentClientSurveyStatus !== clientSurveyStatus.prodActive ? (
                                    <IconButton
                                        disabled={responsesFields.length === 2}
                                        onClick={() => responsesRemove(index)}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                ) : (
                                    <Box width="40px" />
                                )}
                            </Box>
                        </Box>
                    </Grid>

                    {isMultiFlowSurvey && (
                        <Grid item xs={12} sm={12} md={6}>
                            <Box maxWidth="500px">
                                <ActionMultiFlowResponse
                                    label={trans('survey.rankingResponseLabelMultiFlowAction')}
                                    questionIndex={questionIndex}
                                    fieldBasePath={`${fieldBasePath}.responses.${index}`}
                                    errorsBasePath={errorsBasePath?.responses && errorsBasePath?.responses[index]}
                                />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            ))}
            <CtaButton
                type="button"
                onClick={() => responsesAppend({})}
                disabled={
                    formDisabled ||
                    currentClientSurveyStatus === clientSurveyStatus.prodNotActive ||
                    currentClientSurveyStatus === clientSurveyStatus.prodActive
                }
            >
                {trans('survey.addResponse')}
            </CtaButton>
        </Box>
    );
};

export default RankingResponseForm;
