import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';
import { TextInput } from '~js/components/input';
import FileUploaderInput from '~js/components/FileUploaderInput';
import { ASPECT_RATIO_IMG, ONE_MB } from '~js/constants/images';
import React, { useContext, useState } from 'react';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MAX_POINTS_PER_PRODUCT = 100;
export const NUM_CORRECT_PRODUCTS = 3;
const DragWinFollowUpFormControllers = ({}) => {
    const { formDisabled } = useContext(QuizContext);
    const {
        watch,
        setError,
        clearErrors,
        formState: { errors },
        control,
    } = useFormContext();

    const [fileInputState, setFileInputState] = useState({
        value: undefined,
        error: undefined,
    });

    const correctProductsKey = 'followUp.correctProducts';

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        rules: {
            minLength: {
                value: 3,
                message: trans('quiz.drag.imagesLengthError', { args: { length: NUM_CORRECT_PRODUCTS } }),
            },
            maxLength: {
                value: 3,
                message: trans('quiz.drag.imagesLengthError', { args: { length: NUM_CORRECT_PRODUCTS } }),
            },
            required: trans('formValidation.requiredField'),
        },
        name: correctProductsKey,
        shouldUnregister: true,
    });

    const generateCorrectProduct = (fileUrl, filename) => ({
        image: fileUrl,
        points: undefined,
        name: filename,
    });

    console.log('DragWinFollowUpFormControllers', watch('followUp'), errors?.followUp);
    return (
        <>
            {/* FollowUp title */}
            <Controller
                control={control}
                name="followUp.title"
                defaultValue=""
                rules={{
                    required: trans('formValidation.requiredField'),
                    minLength: {
                        value: 3,
                        message: trans('formValidation.fieldTooShort'),
                    },
                    maxLength: {
                        value: 40,
                        message: trans('formValidation.fieldTooLong'),
                    },
                    pattern: {
                        value: REGEX_ONLY_PLAIN_CHAR,
                        message: trans('formValidation.charNotAllowed'),
                    },
                }}
                render={({ field }) => (
                    <TextInput
                        disabled={formDisabled}
                        label={trans('quiz.title')}
                        placeholder={trans('quiz.title')}
                        helperText={errors?.followUp?.title?.message}
                        inputProps={{ maxLength: 40 }}
                        error={!!errors?.followUp?.title}
                        {...field}
                    />
                )}
            />

            {/* FollowUp correctProducts */}
            <FileUploaderInput
                disabled={formDisabled || fields.length >= NUM_CORRECT_PRODUCTS}
                value={fileInputState.value}
                label={trans('quiz.imageProductsCorrect')}
                multiple={true}
                numberOfFiles={NUM_CORRECT_PRODUCTS - fields.length}
                tooltipText={trans('formValidation.aspectRatioError', {
                    args: {
                        aspectRatio: ASPECT_RATIO_IMG.QUIZ_DRAG_IMAGE.toFixed(1),
                    },
                })}
                onChange={(fileUrl, uploadedFile) => {
                    console.log(`fileUrl: ${uploadedFile.name}`, fileUrl);
                    append(generateCorrectProduct(fileUrl, uploadedFile.name));
                }}
                helperText={
                    fileInputState.error ||
                    trans('formValidation.maxMbFile', {
                        args: { mb: (ONE_MB / ONE_MB).toFixed(1) },
                    })
                }
                validateFn={async (value) => {
                    if (!value || typeof value !== 'object') {
                        setFileInputState({ error: undefined });
                        return true;
                    }
                    return await new Promise((resolve) => {
                        let img = new Image();
                        img.src = window.URL.createObjectURL(value);
                        img.onload = () => {
                            const aspectRatioImgUp = (img.width / img.height).toFixed(1);
                            const aspectRatioRule = ASPECT_RATIO_IMG.QUIZ_DRAG_IMAGE.toFixed(1);
                            if (aspectRatioImgUp !== aspectRatioRule) {
                                setFileInputState({
                                    error: trans('formValidation.aspectRatioError', {
                                        args: {
                                            aspectRatio: aspectRatioRule,
                                        },
                                    }),
                                });
                                resolve(false);
                            } else {
                                if (value.size > ONE_MB) {
                                    setFileInputState({
                                        error: trans('formValidation.maxMbFileError', {
                                            args: {
                                                mb: (ONE_MB / ONE_MB).toFixed(1),
                                                uploadedSize: (value.size / ONE_MB).toFixed(2),
                                            },
                                        }),
                                    });
                                    resolve(false);
                                } else {
                                    setFileInputState({ error: undefined });
                                    resolve(true);
                                }
                            }
                        };
                    });
                }}
                error={!!fileInputState.error}
            />

            {fields.map((cP, index) => {
                return (
                    <Box
                        key={cP.id}
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        sx={{ marginBottom: '20px' }}
                    >
                        <Box display={'flex'} flexDirection={'column'} sx={{ height: '80px' }}>
                            <Typography mb={1.5}>
                                {trans('quiz.drag.productNth', { args: { nth: index + 1 } })}
                            </Typography>

                            <Box display={'flex'} flexDirection={'row'}>
                                <Box
                                    sx={{
                                        height: '45px',
                                        padding: '5px',
                                        borderRadius: '5px',
                                        border: `1.5px solid #dfdfe4`,
                                        marginRight: '20px',
                                    }}
                                >
                                    <Box component={'img'} src={cP.image} width={35} height={35} />
                                </Box>
                                {/* Name */}
                                <Typography
                                    lineHeight={'45px'}
                                    width={'150px'}
                                    sx={{
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {watch(`${correctProductsKey}.${index}.name`)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} sx={{ marginLeft: 2, height: '80px' }}>
                            <Typography mb={1.5}>
                                {trans('quiz.drag.rewardNth', { args: { nth: index + 1 } })}
                            </Typography>
                            {/* Points (question reward) */}
                            <Controller
                                control={control}
                                name={`${correctProductsKey}.${index}.points`}
                                defaultValue={watch(`${correctProductsKey}.${index}.points`) || ''}
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                    min: {
                                        value: 20,
                                        message: trans('formValidation.min20Points'),
                                    },
                                    max: {
                                        value: MAX_POINTS_PER_PRODUCT,
                                        message: trans('formValidation.maxPointsExceeded'),
                                    },
                                    pattern: {
                                        value: REGEX_ONLY_PLAIN_CHAR,
                                        message: trans('formValidation.charNotAllowed'),
                                    },
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        disabled={formDisabled}
                                        inputProps={{ min: 20, max: MAX_POINTS_PER_PRODUCT, step: 10 }}
                                        type="number"
                                        //label={trans('quiz.reward')}
                                        placeholder={trans('quiz.reward')}
                                        helperText={errors?.followUp?.correctProducts?.[index]?.points?.message}
                                        error={!!errors?.followUp?.correctProducts?.[index]?.points}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                        <IconButton aria-label="remove" sx={{ marginLeft: 2 }} onClick={() => remove(index)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                );
            })}
            {!!errors?.followUp?.correctProducts?.['root'] && (
                <Typography variant="body2" gutterBottom color={(theme) => theme.palette.error.main}>
                    {errors?.followUp?.correctProducts?.['root'].message}
                </Typography>
            )}
        </>
    );
};

export default DragWinFollowUpFormControllers;
