import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Grid } from '@mui/material';
import { trans } from 'matice';
import { SelectInput, TextInput } from '~js/components/input';
import FullPageLoader from '~js/components/FullPageLoader';
import CtaButton from '~js/components/CtaButton';
import TesterGroupEmailInput from './TesterGroupEmailInput';
import TesterEmailTable from './TesterEmailTable';
import useTesterGroupForm from '../hooks/useTesterGroupForm';

const NewTesterGroupForm = ({
    externalDataSourceOption,
    createNewTestGroup,
    onCancelPress,
    formDisabled = false,
    canDeleteTester = false,
}) => {
    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
        trigger,
        setValue,
        setError,
    } = useForm({
        defaultValues: {
            name: '',
            externalDataSource: '',
            userEmails: [],
        },
    });

    const {
        fetchEntitiesFromDataSource,
        fetchAttributesFromEntity,
        isLoadingFetchOptions,
        entitiesOptions,
        attributesOptions,
    } = useTesterGroupForm();

    const [isLoading, setIsLoading] = useState(false);

    const removeEmailFromUserEmails = (objToRemove) => {
        const newUserEmail = watch('userEmails').filter((e) => e !== objToRemove);
        setValue('userEmails', newUserEmail);
    };

    const onSubmitHandler = async (data) => {
        console.log(data);
        const { userEmails, name, externalDataSource, table, columnIdentifier, columnEmail } = data;
        if (userEmails.length === 0) {
            setError('email', { type: 'custom', message: trans('surveyToolSettings.enterAtLeastOneAddress') });
            return;
        }
        setIsLoading(true);
        try {
            await createNewTestGroup({
                name,
                emails: userEmails.map((e) => e.email),
                settings: { connection: externalDataSource, table, columnIdentifier, columnEmail },
            });
        } catch (e) {
            if (!e?.generic) {
                Object.keys(e).forEach((key) => {
                    setError(key, { type: 'custom', message: e[key] });
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <FullPageLoader isVisible={isLoadingFetchOptions} />
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box maxWidth="500px">
                        <Controller
                            control={control}
                            name="name"
                            rules={{
                                maxLength: {
                                    value: 1000,
                                    message: trans('formValidation.fieldTooLong'),
                                },
                                required: trans('formValidation.requiredField'),
                            }}
                            render={({ field }) => (
                                <TextInput
                                    disabled={formDisabled}
                                    label={trans('surveyToolSettings.testGroupName')}
                                    placeholder={trans('surveyToolSettings.insertName')}
                                    helperText={errors?.name?.message}
                                    error={!!errors?.name}
                                    {...field}
                                />
                            )}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box maxWidth="500px">
                        <Controller
                            control={control}
                            name="externalDataSource"
                            rules={{
                                required: trans('formValidation.requiredField'),
                            }}
                            render={({ field }) => (
                                <SelectInput
                                    disabled={formDisabled}
                                    label={trans('cluster.connection')}
                                    selectOptions={externalDataSourceOption}
                                    helperText={errors?.externalDataSource?.message}
                                    error={!!errors?.externalDataSource}
                                    {...field}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        fetchEntitiesFromDataSource(e.target.value);
                                    }}
                                    ref={null}
                                />
                            )}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                    <Box maxWidth="500px">
                        <Controller
                            control={control}
                            name="table"
                            rules={{
                                required: trans('formValidation.requiredField'),
                            }}
                            render={({ field }) => (
                                <SelectInput
                                    label="Tabella"
                                    disabled={entitiesOptions.length === 0 || formDisabled}
                                    selectOptions={entitiesOptions}
                                    helperText={errors?.table?.message}
                                    error={!!errors?.table}
                                    {...field}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        fetchAttributesFromEntity(watch('externalDataSource'), e.target.value);
                                    }}
                                    ref={null}
                                />
                            )}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box maxWidth="500px">
                        <Controller
                            control={control}
                            name="columnEmail"
                            rules={{
                                required: trans('formValidation.requiredField'),
                            }}
                            render={({ field }) => (
                                <SelectInput
                                    label="Colonna email"
                                    disabled={attributesOptions.length === 0 || formDisabled}
                                    selectOptions={attributesOptions}
                                    helperText={errors?.columnEmail?.message}
                                    error={!!errors?.columnEmail}
                                    {...field}
                                    ref={null}
                                />
                            )}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Box maxWidth="500px">
                        <Controller
                            control={control}
                            name="columnIdentifier"
                            rules={{
                                required: trans('formValidation.requiredField'),
                            }}
                            render={({ field }) => (
                                <SelectInput
                                    label="Colonna identificativa"
                                    disabled={attributesOptions.length === 0 || formDisabled}
                                    selectOptions={attributesOptions}
                                    helperText={errors?.columnIdentifier?.message}
                                    error={!!errors?.columnIdentifier}
                                    {...field}
                                    ref={null}
                                />
                            )}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box maxWidth="500px">
                        <Controller
                            control={control}
                            name="email"
                            rules={{
                                maxLength: {
                                    value: 1000,
                                    message: trans('formValidation.fieldTooLong'),
                                },
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: trans('formValidation.invalidEmail'),
                                },
                            }}
                            render={({ field }) => (
                                <TesterGroupEmailInput
                                    disabled={formDisabled}
                                    type="email"
                                    label={trans('surveyToolSettings.addUserTest')}
                                    placeholder={trans('surveyToolSettings.insertEmail')}
                                    errorText={errors?.email?.message}
                                    helperText={trans('surveyToolSettings.insertEmailAndPressEnter')}
                                    error={!!errors?.email}
                                    triggerValidation={() => trigger('email')}
                                    addEmail={(email) =>
                                        setValue('userEmails', [...watch('userEmails'), { email, status: 'pending' }])
                                    }
                                    {...field}
                                    ref={null}
                                />
                            )}
                        />
                    </Box>
                </Grid>
            </Grid>
            <TesterEmailTable
                emails={watch('userEmails')}
                removeEmail={removeEmailFromUserEmails}
                canDeleteTester={canDeleteTester}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 5,
                }}
            >
                <CtaButton type="button" onClick={onCancelPress} variant="outlined" disabled={isLoading}>
                    {trans('global.cancel')}
                </CtaButton>
                <CtaButton
                    variant="contained"
                    //type="submit"
                    onClick={handleSubmit(onSubmitHandler)}
                    loading={isLoading}
                    disabled={formDisabled}
                >
                    {trans('global.add')}
                </CtaButton>
            </Box>
        </form>
    );
};

export default NewTesterGroupForm;
