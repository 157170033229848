import React, { useImperativeHandle, useRef, useState } from 'react';
import { __ } from 'matice';
import EmptySection from '~js/components/EmptySection';
import CtaButton from '~js/components/CtaButton';
import ToolsUsersTable from './ToolsUsersTable';
import AddToolsUserModal from '~js/pages/backoffice/Settings/ToolsUsers/components/AddToolsUserModal';
import UpdateToolsUserModal from '~js/pages/backoffice/Settings/ToolsUsers/components/UpdateToolsUserModal';
import DeleteToolsUserModal from '~js/pages/backoffice/Settings/ToolsUsers/components/DeleteToolsUserModal';

const ToolsUsersSection = React.forwardRef(({ toolsUsers, toolsRoles, canUpdate = false, canDelete = false }, ref) => {
    const [addToolsUserModalShown, setAddToolsUserModalShown] = useState(false);
    const [updateToolsUserModalShown, setUpdateToolsUserModalShown] = useState(false);
    const [deleteToolsUserModalShown, setDeleteToolsUserModalShown] = useState(false);

    const rowUser = useRef(null);

    useImperativeHandle(
        ref,
        () => {
            return {
                showNewToolsUserAddModal() {
                    setAddToolsUserModalShown(true);
                },
            };
        },
        [],
    );

    const showNewToolsUserUpdateModal = (toolUser) => {
        rowUser.current = toolUser;
        setUpdateToolsUserModalShown(true);
    };
    const hideNewToolsUserUpdateModal = () => {
        rowUser.current = null;
        setUpdateToolsUserModalShown(false);
    };
    const showNewToolsUserDeleteModal = async (toolUser) => {
        rowUser.current = toolUser;
        setDeleteToolsUserModalShown(true);
    };
    const hideNewToolsUserDeleteModal = async () => {
        rowUser.current = null;
        setDeleteToolsUserModalShown(false);
    };
    return (
        <>
            <ToolsUsersTable
                toolsUsers={toolsUsers}
                updateToolUser={showNewToolsUserUpdateModal}
                deleteToolUser={showNewToolsUserDeleteModal}
                canUpdate={canUpdate}
                canDelete={canDelete}
            />
            {toolsUsers.length === 0 && (
                <EmptySection
                    title={__('settings.tools-users.empty.title')}
                    description={__('settings.tools-users.empty.description')}
                    buttonComponent={
                        <CtaButton
                            variant="contained"
                            sx={{
                                px: 5,
                            }}
                            onClick={() => setAddToolsUserModalShown(true)}
                        >
                            {__('settings.tools-users.empty.cta')}
                        </CtaButton>
                    }
                />
            )}
            <AddToolsUserModal
                open={addToolsUserModalShown}
                handleClose={() => setAddToolsUserModalShown(false)}
                toolsRoles={toolsRoles}
            />
            <UpdateToolsUserModal
                open={updateToolsUserModalShown}
                handleClose={hideNewToolsUserUpdateModal}
                toolUser={rowUser.current}
                toolsRoles={toolsRoles}
            />
            <DeleteToolsUserModal
                open={deleteToolsUserModalShown}
                handleClose={hideNewToolsUserDeleteModal}
                toolUser={rowUser.current}
                toolsRoles={toolsRoles}
            />
        </>
    );
});

export default ToolsUsersSection;
