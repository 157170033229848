import React from 'react';
import { trans } from 'matice';
import { toast } from 'react-toastify';
import { Head, router } from '@inertiajs/react';
import { Box, Typography } from '@mui/material';
import CtaButton from '~js/components/CtaButton';
import EmptySection from '~js/components/EmptySection';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import ClusterTable from './components/ClusterTable';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';

const breadcrumbs: BreadcrumbList = [
    {
        routeName: 'Cluster.index',
        routeLabel: 'Clusters',
    },
];

const ClusterIndex = ({ clusters, can, canReadToolIds, ...other }) => {
    console.log('ClusterIndex props', { clusters, can, ...other });

    const goToCreateClusterPage = () => router.get(route('Cluster.create'));

    const deleteCluster = async (idCluster) => {
        const promise = new Promise((resolve, reject) =>
            router.delete(route('Cluster.destroy', idCluster), {
                onSuccess: () => {
                    toast.success(trans('cluster.clusterDeleted'));
                    resolve();
                },
                onError: (e) => {
                    reject();
                    toast.error(!!e?.generic ? e.generic : trans('cluster.errorOnDeleteCluster'));
                },
            }),
        );
        await promise;
    };

    return (
        <>
            <Head title={trans('cluster.cluster')} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                {clusters.length > 0 && (can.create || can.createCsvCluster) && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <CtaButton
                            variant="contained"
                            sx={{
                                px: 5,
                            }}
                            onClick={goToCreateClusterPage}
                        >
                            {trans('global.createNew')}
                        </CtaButton>
                    </Box>
                )}
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                    {trans('cluster.cluster')}
                </Typography>
                <ClusterTable
                    clusters={clusters}
                    deleteCluster={deleteCluster}
                    canReadToolIds={canReadToolIds}
                    canUpdate={can.updateAny}
                    canDelete={can.deleteAny}
                    canUpdateCsvCluster={can.updateCsvCluster}
                    canDeleteCsvCluster={can.deleteCsvCluster}
                />

                {clusters.length === 0 && (
                    <EmptySection
                        title={trans('cluster.noClusterTitle')}
                        description={trans('cluster.noClusterDescription')}
                        buttonComponent={
                            <CtaButton
                                variant="contained"
                                sx={{
                                    px: 5,
                                }}
                                onClick={goToCreateClusterPage}
                            >
                                {trans('global.createNew')}
                            </CtaButton>
                        }
                    />
                )}
            </ToolContentPageLayout>
        </>
    );
};

export default ClusterIndex;
