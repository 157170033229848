import React from 'react';
import { Box, Typography } from '@mui/material';

const TextAreaPreview = () => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '80px',
                bgcolor: 'white',
                mt: '30px',
                borderRadius: '5px',
                border: '.5px solid #dfdfe4',
            }}
        >
            <Typography
                sx={{
                    fontSize: '10px',
                    lineHeight: '12px',
                    color: '#dfdfe4',
                    p: '10px',
                    fontFamily: 'Nunito',
                }}
            >
                Scrivi qui la tua risposta...
            </Typography>
        </Box>
    );
};

export default TextAreaPreview;
