import React, { useContext, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import { FileInput, SelectInput, TextInput } from '~js/components/input';
import CtaButton from '~js/components/CtaButton';
import { clientSurveyStatus } from '~js/constants/missionStatuses';
import { SURVEY_TYPOLOGIES } from '~js/constants/survey.js';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';
import { ASPECT_RATIO_IMG, ONE_MB } from '~js/constants/images.jsx';
import CaptionInput from './CaptionInput';
import SurveyContext from '../context';
import ActionMultiFlowResponse from './ActionMultiFlowResponse';

const RatingForm = ({ questionIndex, isMultiple = false }) => {
    const { currentClientSurveyStatus, formDisabled, surveyTypology } = useContext(SurveyContext);
    const isMultiFlowSurvey = surveyTypology === SURVEY_TYPOLOGIES.multiFlow;

    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext();

    const fieldBasePath = `questions.${questionIndex}`;
    const errorsBasePath =
        errors?.questions && errors.questions[questionIndex] ? errors.questions[questionIndex] : null;

    const toSelectOptions = [
        {
            _id: 1,
            label: 5,
            value: 5,
        },
        {
            _id: 2,
            label: 10,
            value: 10,
        },
    ];

    const {
        fields: responsesFields,
        append: responsesAppend,
        remove: responsesRemove,
    } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: `${fieldBasePath}.responses`, // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
        shouldUnregister: true,
    });

    useEffect(() => {
        if (responsesFields.length === 0) {
            responsesAppend({});
        }
    }, [responsesFields]);

    useEffect(() => {
        if (responsesFields.length > 1 && !isMultiple) {
            const fieldsToDelete = [...Array(responsesFields.length - 1).keys()].map((i) => i + 1);
            responsesRemove(fieldsToDelete);
        }
    }, [isMultiple, responsesFields]);

    return (
        <Box>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box maxWidth="500px">
                        <CaptionInput questionIndex={questionIndex} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Box maxWidth="500px">
                        {isMultiFlowSurvey && (
                            <Box maxWidth="500px">
                                <ActionMultiFlowResponse
                                    questionIndex={questionIndex}
                                    fieldBasePath={`${fieldBasePath}.responses.${0}`}
                                    errorsBasePath={errorsBasePath?.responses && errorsBasePath?.responses[0]}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
            {responsesFields.map((resField, index) => (
                <Box
                    key={resField.id}
                    sx={
                        isMultiple
                            ? {
                                  borderTop: '1px solid #dfdfe4',
                                  mt: 4,
                                  pt: 4,
                              }
                            : {}
                    }
                >
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box maxWidth="500px">
                                {isMultiple && (
                                    <Controller
                                        control={control}
                                        name={`${fieldBasePath}.responses.${index}.response`}
                                        defaultValue={watch(`${fieldBasePath}.responses.${index}.response`) || ''}
                                        shouldUnregister
                                        rules={{
                                            required: trans('formValidation.requiredField'),
                                            pattern: {
                                                value: REGEX_ONLY_PLAIN_CHAR,
                                                message: trans('formValidation.charNotAllowed'),
                                            },
                                        }}
                                        render={({ field }) => (
                                            <TextInput
                                                disabled={formDisabled}
                                                label={trans('survey.ratingTitle')}
                                                helperText={
                                                    !!errorsBasePath?.responses &&
                                                    errorsBasePath?.responses[index] &&
                                                    errorsBasePath?.responses[index]?.response?.message
                                                }
                                                error={
                                                    !!errorsBasePath?.responses &&
                                                    !!errorsBasePath?.responses[index]?.response
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                )}
                                <Controller
                                    control={control}
                                    name={`${fieldBasePath}.responses.${index}.image`}
                                    defaultValue={watch(`${fieldBasePath}.responses.${index}.image`) || ''}
                                    shouldUnregister
                                    rules={{
                                        validate: async (value) => {
                                            if (!value || typeof value !== 'object') {
                                                return true;
                                            }
                                            return await new Promise((resolve) => {
                                                let hasError = false;
                                                let img = new Image();
                                                img.src = window.URL.createObjectURL(value);
                                                img.onload = () => {
                                                    const aspectRadioImgUp = (img.width / img.height).toFixed(1);
                                                    const aspectRadioRule = ASPECT_RATIO_IMG.RATING.toFixed(1);
                                                    if (aspectRadioImgUp !== aspectRadioRule) {
                                                        hasError = true;
                                                        resolve(
                                                            trans('formValidation.aspectRatioError', {
                                                                args: {
                                                                    aspectRatio: aspectRadioRule,
                                                                },
                                                            }),
                                                        );
                                                    } else {
                                                        if (value.size > ONE_MB) {
                                                            resolve(trans('formValidation.max500KBImage'));
                                                        } else {
                                                            resolve(true);
                                                        }
                                                    }
                                                };
                                            });
                                        },
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <FileInput
                                            disabled={
                                                formDisabled ||
                                                currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                                currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                            }
                                            value={value}
                                            label={trans('survey.image')}
                                            onChange={onChange}
                                            helperText={
                                                !!errorsBasePath?.responses &&
                                                errorsBasePath?.responses[index] &&
                                                errorsBasePath?.responses[index]?.image?.message
                                            }
                                            error={
                                                !!errorsBasePath?.responses && !!errorsBasePath?.responses[index]?.image
                                            }
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box maxWidth="500px" display="flex">
                                <Controller
                                    control={control}
                                    name={`${fieldBasePath}.responses.${index}.min`}
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        pattern: {
                                            value: REGEX_ONLY_PLAIN_CHAR,
                                            message: trans('formValidation.charNotAllowed'),
                                        },
                                    }}
                                    defaultValue={watch(`${fieldBasePath}.responses.${index}.min`) || '1'}
                                    shouldUnregister
                                    render={({ field }) => (
                                        <TextInput
                                            disabled
                                            label={trans('survey.from')}
                                            helperText={
                                                !!errorsBasePath?.responses &&
                                                errorsBasePath?.responses[index] &&
                                                errorsBasePath?.responses[index]?.min?.message
                                            }
                                            error={
                                                !!errorsBasePath?.responses && !!errorsBasePath?.responses[index]?.min
                                            }
                                            {...field}
                                        />
                                    )}
                                />

                                <Box width="100%" pl={3}>
                                    <Controller
                                        control={control}
                                        name={`${fieldBasePath}.responses.${index}.max`}
                                        rules={{
                                            required: trans('formValidation.requiredField'),
                                        }}
                                        defaultValue={watch(`${fieldBasePath}.responses.${index}.max`) || ''}
                                        shouldUnregister
                                        render={({ field }) => (
                                            <SelectInput
                                                disabled={
                                                    formDisabled ||
                                                    currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                                    currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                                }
                                                label={trans('survey.to')}
                                                selectOptions={toSelectOptions}
                                                helperText={
                                                    !!errorsBasePath?.responses &&
                                                    errorsBasePath?.responses[index] &&
                                                    errorsBasePath?.responses[index]?.max?.message
                                                }
                                                error={
                                                    !!errorsBasePath?.responses &&
                                                    !!errorsBasePath?.responses[index]?.max
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    {watch(`${fieldBasePath}.responses.${index}.max`) === 5 && (
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box maxWidth="500px">
                                    <Controller
                                        control={control}
                                        name={`${fieldBasePath}.responses.${index}.negativeLabel`}
                                        defaultValue={watch(`${fieldBasePath}.responses.${index}.negativeLabel`) || ''}
                                        shouldUnregister
                                        rules={{
                                            maxLength: {
                                                value: 30,
                                                message: trans('formValidation.fieldTooLong'),
                                            },
                                            pattern: {
                                                value: REGEX_ONLY_PLAIN_CHAR,
                                                message: trans('formValidation.charNotAllowed'),
                                            },
                                        }}
                                        render={({ field }) => (
                                            <TextInput
                                                disabled={formDisabled}
                                                label={trans('survey.negativeLabel')}
                                                helperText={
                                                    !!errorsBasePath?.responses &&
                                                    errorsBasePath?.responses[index] &&
                                                    errorsBasePath?.responses[index]?.negativeLabel?.message
                                                }
                                                error={
                                                    !!errorsBasePath?.responses &&
                                                    !!errorsBasePath?.responses[index]?.negativeLabel
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box maxWidth="500px">
                                    <Controller
                                        control={control}
                                        name={`${fieldBasePath}.responses.${index}.positiveLabel`}
                                        defaultValue={watch(`${fieldBasePath}.responses.${index}.positiveLabel`) || ''}
                                        shouldUnregister
                                        rules={{
                                            maxLength: {
                                                value: 30,
                                                message: trans('formValidation.fieldTooLong'),
                                            },
                                            pattern: {
                                                value: REGEX_ONLY_PLAIN_CHAR,
                                                message: trans('formValidation.charNotAllowed'),
                                            },
                                        }}
                                        render={({ field }) => (
                                            <TextInput
                                                disabled={formDisabled}
                                                label={trans('survey.positiveLabel')}
                                                helperText={
                                                    !!errorsBasePath?.responses &&
                                                    errorsBasePath?.responses[index] &&
                                                    errorsBasePath?.responses[index]?.positiveLabel?.message
                                                }
                                                error={
                                                    !!errorsBasePath?.responses &&
                                                    !!errorsBasePath?.responses[index]?.positiveLabel
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                    {isMultiple &&
                        responsesFields.length > 1 &&
                        !formDisabled &&
                        currentClientSurveyStatus !== clientSurveyStatus.prodActive &&
                        currentClientSurveyStatus !== clientSurveyStatus.prodNotActive && (
                            <CtaButton
                                type="button"
                                disabled={responsesFields.length === 1}
                                onClick={() => responsesRemove(index)}
                            >
                                {trans('survey.deleteRating')}
                            </CtaButton>
                        )}
                </Box>
            ))}
            {isMultiple &&
                !formDisabled &&
                currentClientSurveyStatus !== clientSurveyStatus.prodActive &&
                currentClientSurveyStatus !== clientSurveyStatus.prodNotActive && (
                    <CtaButton type="button" onClick={() => responsesAppend({})}>
                        {trans('survey.addRating')}
                    </CtaButton>
                )}
        </Box>
    );
};

export default RatingForm;
