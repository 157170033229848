import React from 'react';
import { Box, Grid, ThemeProvider, Typography, useTheme } from '@mui/material';
import useDateFormat from '~js/hooks/useDateFormat';
import useFileReader from '~js/hooks/useFileReader';
import cardQuizDefault from '~/assets/images/tools/quizTool/cardQuizImageDefault.svg';
import coccoleCoin from '~/assets/images/tools/coccoleCoin.png';
import pampersTheme from '~js/pages/backoffice/Tools/Quiz/components/Previews/previewTheme';

const PreviewQuizCard = ({ image, title, points, endDate }) => {
    const { fileUrl: imgUrl } = useFileReader(image);
    const theme = useTheme();

    const { formatDate } = useDateFormat('dd MMM yyyy');

    return (
        <ThemeProvider theme={pampersTheme}>
            <Box
                justifyContent={'space-between'}
                sx={{
                    display: 'flex',
                    width: '265px',
                    minHeight: '100px',
                    borderRadius: '18px',
                    border: 1,
                    borderColor: theme.palette.divider,
                    backgroundColor: '#FFFFFF',
                    px: '12px',
                    py: '8px',
                }}
            >
                <Grid container>
                    <Grid item xs={3} mb={1}>
                        <Typography
                            variant={'body2'}
                            sx={{
                                color: theme.palette.primary.main,
                                fontSize: 12,
                                fontWeight: 'bold',
                            }}
                        >
                            Quiz
                        </Typography>
                    </Grid>
                    <Grid item alignItems={'end'} xs={9}>
                        <Typography
                            variant={'body2'}
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                textAlign: 'end',
                            }}
                        >
                            Fino al {formatDate(endDate)}
                        </Typography>
                    </Grid>

                    <Grid item xs={8} justifyContent={'space-between'}>
                        <Typography
                            variant={'subtitle1'}
                            mb={1}
                            sx={{
                                fontSize: '16px',
                                lineHeight: '20px',
                                fontWeight: 'bold',
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant={'subtitle1'}
                            sx={{
                                fontSize: '16px',
                                lineHeight: '20px',
                                color: theme.palette.primary.main,
                                fontWeight: 'bold',
                            }}
                        >
                            +{points}
                            <Box
                                component="img"
                                src={coccoleCoin}
                                sx={{
                                    width: '22px',
                                    height: '22px',
                                    objectFit: 'contain',
                                    ml: '5px',
                                }}
                            />
                        </Typography>
                    </Grid>

                    <Grid item xs={4} display={'flex'} flexDirection={'column'} justifyContent={'end'}>
                        <Box
                            component={'img'}
                            src={imgUrl ?? cardQuizDefault}
                            alt=""
                            width="100%"
                            sx={{
                                filter: imgUrl ? 'none' : 'grayscale(100%);',
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default PreviewQuizCard;
