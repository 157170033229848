import React from 'react';
import { Controller } from 'react-hook-form';
import { trans } from 'matice';
import TextInput from '~js/components/TextInput';

const ExternalDataSourceMongodbInput = ({ control, errors, formDisabled = false }) => {
    return (
        <>
            <Controller
                control={control}
                name="config.database"
                render={({ field }) => (
                    <TextInput
                        id={'config-database'}
                        disabled={formDisabled}
                        label={trans('surveyToolSettings.database')}
                        helperText={errors?.config?.database?.message}
                        error={!!errors?.config?.database}
                        {...field}
                    />
                )}
            />
            <Controller
                control={control}
                name="config.dns"
                render={({ field }) => (
                    <TextInput
                        id={'config-dns'}
                        disabled={formDisabled}
                        label={trans('surveyToolSettings.dsn')}
                        helperText={errors?.config?.dns?.message}
                        error={!!errors?.config?.dns}
                        {...field}
                    />
                )}
            />
        </>
    );
};

export default ExternalDataSourceMongodbInput;
