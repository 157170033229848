export const QUIZ_TYPOLOGIES = {
    classic: 'classic',
    time: 'time',
    difference: 'difference',
    memory: 'memory',
    drag: 'drag',
};

export const QUIZ_TYPOLOGIES_WITH_VIDEO = [QUIZ_TYPOLOGIES.classic, QUIZ_TYPOLOGIES.time];
export const QUIZ_TYPOLOGIES_WITH_EXTRA_REWARD = [QUIZ_TYPOLOGIES.classic, QUIZ_TYPOLOGIES.time];
export const QUIZ_TYPOLOGIES_WITH_CHALLENGE = [
    QUIZ_TYPOLOGIES.difference,
    QUIZ_TYPOLOGIES.memory,
    QUIZ_TYPOLOGIES.drag,
];
