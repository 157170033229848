import React, { useContext, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { trans } from 'matice';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import NewQuizActionButtons from '~js/pages/backoffice/Tools/Quiz/components/NewQuizActionButtons';
import DifferenceQuestionForm from '~js/pages/backoffice/Tools/Quiz/components/FormChallenges/DifferenceQuestionForm';

export const QUIZ_DIFFERENCE_MIN_QUESTIONS = 3;
export const DEFAULT_QUIZ_DIFFERENCE_QUESTION_DATA = {
    imageRight: null,
    imageWrong: null,
    modal: {
        subTitle: null,
        description: null,
        image: null,
    },
    cellWithDifferences: [],
};
const DifferenceQuestionsForm = ({ handleStepNext, handleStepPrevious, stepFormValue, isLoading = false }) => {
    const { isEditMode, formDisabled, currentClientQuiz } = useContext(QuizContext);

    const [currentFocusedQuestion, setCurrentFocusedQuestion] = useState(0);
    const formMethods = useForm({
        //shouldUnregister: true,
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: !!stepFormValue
            ? stepFormValue
            : {
                  questions: Array(QUIZ_DIFFERENCE_MIN_QUESTIONS).fill(DEFAULT_QUIZ_DIFFERENCE_QUESTION_DATA),
              },
    });

    const {
        handleSubmit,
        control,
        trigger,
        formState: { errors },
        getValues,
    } = formMethods;

    console.debug({ ERRORS: errors, VALUES: getValues() });

    const { fields } = useFieldArray({
        name: 'questions',
        control,
        shouldUnregister: true,
    });
    const onSubmit = (data) => handleStepNext({ quizQuestions: { ...data } });

    const nextButtonDisabled = fields.length < QUIZ_DIFFERENCE_MIN_QUESTIONS;
    return (
        <FormProvider {...formMethods}>
            <Typography variant={'subtitle2'} fontWeight={'bold'} my={5}>
                {trans('quiz.difference.configure')}
            </Typography>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                {fields.map((question, index) => (
                    <DifferenceQuestionForm
                        key={index.toString()}
                        questionIndex={index}
                        currentFocusedQuestion={currentFocusedQuestion}
                        setCurrentFocusedQuestion={setCurrentFocusedQuestion}
                    />
                ))}

                {!formDisabled && (
                    <NewQuizActionButtons
                        isLoading={isLoading}
                        onLeftButtonClick={handleStepPrevious}
                        rightButtonText={isEditMode ? trans('global.save') : trans('global.next')}
                        leftButtonText={trans('global.back')}
                        rightDisabled={nextButtonDisabled}
                        rightTooltip={
                            nextButtonDisabled
                                ? trans('quiz.minQuestionButtonDisabled', {
                                      args: { minQuestion: QUIZ_DIFFERENCE_MIN_QUESTIONS },
                                  })
                                : null
                        }
                    />
                )}
            </Box>
        </FormProvider>
    );
};

export default DifferenceQuestionsForm;
