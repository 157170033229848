import React from 'react';
import { Box, ThemeProvider, Typography } from '@mui/material';
import useFileReader from '~js/hooks/useFileReader';
import PreviewQuizHeader from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewQuizHeader';
import previewInDepthImageDefault from '~/assets/images/tools/quizTool/previewInDepthImageDefault.svg';
import PreviewCtaButton from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewCtaButton';
import pampersTheme from '~js/pages/backoffice/Tools/Quiz/components/Previews/previewTheme';

const PreviewClassicInDepthScreen = ({
    headerTitle = 'Quiz',
    headerSubTitle,
    endDate,
    title,
    description,
    ctaText,
    image,
}) => {
    const { fileUrl: imgUrl } = useFileReader(image);

    return (
        <ThemeProvider theme={pampersTheme}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '236px',
                    height: '512px',
                    borderRadius: '22px',
                    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.25)',
                    position: 'relative',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                {/* Quiz Screen Header content */}
                <PreviewQuizHeader title={headerTitle} subTitle={headerSubTitle} endDate={endDate} />

                {/* Quiz In Depth Screen Body */}
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    height={'100%'}
                    bgcolor={'#EDF8F9'}
                >
                    <Box flex={2} width={'100%'} height={'100%'} position={'relative'}>
                        <Box
                            component="img"
                            src={imgUrl ?? previewInDepthImageDefault}
                            sx={{
                                position: 'absolute',
                                objectFit: 'cover',
                                objectPosition: 'center',
                                width: '100%',
                                height: '100%',
                                zIndex: 0,
                                filter: imgUrl ? 'none' : 'grayscale(100%);',
                            }}
                        />
                    </Box>
                    <Box
                        //flex={1}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        pb={2}
                        px={'15px'}
                        bgcolor={'white'}
                        borderRadius={'15px'}
                        width="100%"
                    >
                        <Typography
                            mt={'20px'}
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '11px',
                                lineHeight: '15px',
                                color: '#10a9ae',
                                textAlign: 'center',
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography
                            mt={'10px'}
                            mb={'15px'}
                            sx={{
                                fontSize: '9px',
                                lineHeight: '12px',
                                color: '#354953',
                                textAlign: 'center',
                            }}
                        >
                            {description}
                        </Typography>

                        <PreviewCtaButton ctaText={ctaText} />
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PreviewClassicInDepthScreen;
