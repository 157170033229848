import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Tooltip } from '@mui/material';

const CtaButton = ({ variant, children, sx, tooltip = null, ...other }) => {
    return (
        <Tooltip title={tooltip}>
            <span>
                <LoadingButton
                    sx={{ textTransform: 'none', px: 3, ...sx }}
                    variant={variant}
                    disableElevation
                    {...other}
                >
                    {children}
                </LoadingButton>
            </span>
        </Tooltip>
    );
};

export default CtaButton;
