import React, { useEffect } from 'react';
import { usePage } from '@inertiajs/react';
import * as Sentry from '@sentry/react';
import ToolContext from '~js/layouts/Tools/context';
import ToolLayout from '~js/layouts/Tools/ToolLayout';
import AuthLayout from '~js/layouts/AuthLayout';
import './../app.css';

const inertiaRoutePrefix = window.inertiaRoutePrefix;
const toolRoutePrefix = `/${inertiaRoutePrefix}`;

const AppLayout = ({ children }) => {
    const { url, props } = usePage();

    useEffect(() => {
        Sentry.setUser(props.user ? { email: props.user.email } : null);
    }, [props.user]);

    // const pathParts = url.split(toolRoutePrefix);
    // const brand = pathParts[0].split('brand/')[1];
    const route = url.split(`/${props.brand}`)[1];
    //
    // console.debug({ url, pathParts, toolRoutePrefix, route });

    //set specific layout based on tool
    if (route && route.startsWith(`${toolRoutePrefix}/`)) {
        return (
            <ToolContext.Provider value={{ brand: props.brand }}>
                <ToolLayout routePrefix={toolRoutePrefix}>{children}</ToolLayout>
            </ToolContext.Provider>
        );
    }

    return <AuthLayout routePrefix={toolRoutePrefix}>{children}</AuthLayout>;
};

export default AppLayout;
