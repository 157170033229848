import React, { useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { trans } from 'matice';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import SuccessModal from '~js/components/SuccessModal';
import TriggerForm from './components/TriggerForm';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';

const TriggersEdit = ({ trigger, can, ...other }) => {
    console.log('TriggersEdit props', { trigger, can, ...other });

    const formDefaultValues = {
        ...trigger,
        description: trigger?.description ? trigger.description : '',
        id: trigger._id,
    };
    delete formDefaultValues._id;
    delete formDefaultValues.created_at;
    delete formDefaultValues.updated_at;

    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const goToClusterListPage = () => router.get(route('Triggers.index'));

    const onSubmit = async (data) => {
        console.log(data);
        const promise = new Promise((resolve, reject) =>
            router.patch(route('Triggers.update', trigger._id), data, {
                onError: (e) => {
                    reject(e);
                    if (!!e?.generic) {
                        toast.error(e.generic);
                    } else {
                        toast.error(trans('triggers.errorOnUpdateTrigger'));
                    }
                },
                onSuccess: () => {
                    resolve();
                    setShowModalSuccess(true);
                },
            }),
        );

        await promise;
    };
    const breadcrumbs: BreadcrumbList = [
        {
            routeName: 'Triggers.edit',
            routeLabel: 'Modifica Trigger',
            params: {
                id: trigger._id,
            },
        },
        {
            routeName: 'Triggers.index',
            routeLabel: 'Triggers',
        },
    ];
    return (
        <>
            <Head title={trans('triggers.editTrigger')} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                    {trans('triggers.editTrigger')}
                </Typography>
                <TriggerForm
                    onCancelPress={goToClusterListPage}
                    onSubmit={onSubmit}
                    formDefaultValues={formDefaultValues}
                    formDisabled={!can.update}
                />
            </ToolContentPageLayout>
            <SuccessModal
                open={showModalSuccess}
                handleClose={() => setShowModalSuccess(false)}
                title={trans('global.compliments')}
                description={trans('triggers.triggerEditedSuccess')}
                buttonText={trans('triggers.backToTriggerList')}
                onPressButton={goToClusterListPage}
                onExitPress={() => setShowModalSuccess(false)}
            />
        </>
    );
};

export default TriggersEdit;
