import React, { useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { Typography } from '@mui/material';
import { trans } from 'matice';
import { toast } from 'react-toastify';
import ExternalDataSourceForm from './components/ExternalDataSourceForm';
import SuccessModal from '~js/components/SuccessModal';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';

const ExternalDataSourceEdit = ({ externalDataSource, can, ...other }) => {
    console.log('ExternalDataSourceEdit props', {
        externalDataSource,
        can,
        ...other,
    });

    const formDefaultValues = {
        ...externalDataSource,
        id: externalDataSource._id,
    };
    delete formDefaultValues._id;
    delete formDefaultValues.created_at;
    delete formDefaultValues.updated_at;

    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const onSubmit = async (data) => {
        const promise = new Promise((resolve, reject) =>
            router.patch(route('ExternalDataSource.update', externalDataSource._id), data, {
                onError: (e) => {
                    reject(e);
                    if (!!e?.generic) {
                        toast.error(e.generic);
                    } else {
                        toast.error(trans('surveyToolSettings.errorOnUpdateDataSource'));
                    }
                },
                onSuccess: () => {
                    resolve();
                    setShowModalSuccess(true);
                },
            }),
        );

        await promise;
    };

    const closeHandler = () => {
        router.get(route('Settings.index'), { section: 'connections' });
    };

    const breadcrumbs: BreadcrumbList = [
        {
            routeName: 'ExternalDataSource.edit',
            routeLabel: 'Modifica connessione',
            params: {
                id: externalDataSource._id,
            },
        },
        {
            routeName: 'Settings.index',
            routeLabel: 'Impostazioni',
        },
    ];
    return (
        <>
            <Head title={trans('surveyToolSettings.editConnection')} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                    {trans('surveyToolSettings.editConnection')}
                </Typography>
                <ExternalDataSourceForm
                    onSubmit={onSubmit}
                    closeHandler={closeHandler}
                    formDefaultValues={formDefaultValues}
                    formDisabled={!can.update}
                />
            </ToolContentPageLayout>
            <SuccessModal
                open={showModalSuccess}
                handleClose={() => setShowModalSuccess(false)}
                title={trans('surveyToolSettings.compliments')}
                description={trans('surveyToolSettings.connectionEditedSuccess')}
                buttonText={trans('surveyToolSettings.backToSettings')}
                onPressButton={closeHandler}
                onExitPress={() => setShowModalSuccess(false)}
            />
        </>
    );
};

export default ExternalDataSourceEdit;
