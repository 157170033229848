import React, { useContext } from 'react';
import { trans } from 'matice';
import SurveyContext from '../context';
import { Controller, useFormContext } from 'react-hook-form';
import SelectInput from '~js/components/SelectInput';
import { clientSurveyStatus } from '~js/constants/missionStatuses';

const ActionMultiFlowResponse = ({ label = trans('survey.action'), fieldBasePath, errorsBasePath, questionIndex }) => {
    const { formDisabled, currentClientSurveyStatus } = useContext(SurveyContext);

    const { control, watch } = useFormContext();

    const goToQuestionOptions = Array.from({ length: watch()?.questions?.length }, (_, i) => ({
        _id: i,
        label: `${trans('survey.goToQuestion')} ${i + 1}`,
        value: i,
    }))?.filter((o) => o._id > questionIndex);

    const actionOptions = [
        { _id: 'select', label: trans('survey.selectAction'), value: '' },
        { _id: 'endSurvey', label: trans('survey.endSurvey'), value: -1 },
        ...goToQuestionOptions,
    ];

    return (
        <Controller
            control={control}
            name={`${fieldBasePath}.nextQuestionId`}
            rules={
                {
                    //required: trans('formValidation.requiredField'),
                }
            }
            defaultValue={watch(`${fieldBasePath}.nextQuestionId`) || ''}
            shouldUnregister
            render={({ field }) => (
                <SelectInput
                    disabled={
                        formDisabled ||
                        currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                        currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                    }
                    label={label}
                    selectOptions={actionOptions}
                    helperText={errorsBasePath && errorsBasePath?.nextQuestionId?.message}
                    error={!!errorsBasePath?.nextQuestionId}
                    {...field}
                />
            )}
        />
    );
};

export default ActionMultiFlowResponse;
