import { formatISO, isAfter, isBefore, parse } from 'date-fns';

const getIsoDate = (dateString, formatDateToCompare = 'dd/MM/yyyy HH:mm') => {
    const dateObj = parse(dateString, formatDateToCompare, new Date());
    const formattedDateString = formatISO(dateObj);

    return new Date(formattedDateString);
};

export const dateSortComparator = (v1, v2, formatDateToCompare = 'dd/MM/yyyy HH:mm') => {
    const date1 = getIsoDate(v1, formatDateToCompare);
    const date2 = getIsoDate(v2, formatDateToCompare);

    if (isBefore(date1, date2)) {
        return -1;
    }
    if (isAfter(date1, date2)) {
        return 1;
    }
    return 0;
};
