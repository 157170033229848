import React, { useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { trans } from 'matice';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import NewQuizActionButtons from '~js/pages/backoffice/Tools/Quiz/components/NewQuizActionButtons';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';
import { TextInput } from '~js/components/input';
import PreviewTimeQuestionScreen from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewTimeQuestionScreen';
import TimeQuestionResponses, {
    DEFAULT_QUIZ_TIME_RESPONSE_DATA,
    QUIZ_TIME_MIN_RESPONSES,
} from '~js/pages/backoffice/Tools/Quiz/components/FormChallenges/TimeQuestionResponses';

export const DEFAULT_QUIZ_TIME_QUESTION_DATA = {
    question: '',
    timerSeconds: 30,
    responses: Array(QUIZ_TIME_MIN_RESPONSES).fill(DEFAULT_QUIZ_TIME_RESPONSE_DATA),
};
const TimeQuestionsForm = ({ handleStepNext, handleStepPrevious, stepFormValue, isLoading = false }) => {
    const { isEditMode, formDisabled, currentClientQuizStatus, quizTitle, quizEndDate, quizTimePointsPerResponse } =
        useContext(QuizContext);

    const formMethods = useForm({
        //shouldUnregister: true,
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: stepFormValue ?? {
            questions: Array(1).fill(DEFAULT_QUIZ_TIME_QUESTION_DATA),
        },
    });

    const {
        handleSubmit,
        control,
        trigger,
        formState: { errors },
        getValues,
        watch,
    } = formMethods;

    const {
        fields: questionsFields,
        append,
        remove,
        insert,
        move,
    } = useFieldArray({
        name: 'questions',
        control,
        shouldUnregister: true,
    });
    const onSubmit = (data) => {
        for (const questionKey in data.questions) {
            for (const responseKey in data.questions[questionKey].responses) {
                data.questions[questionKey].responses[responseKey] = {
                    ...data.questions[questionKey].responses[responseKey],
                    points: quizTimePointsPerResponse,
                };
            }
        }
        handleStepNext({ quizQuestions: { ...data } });
    };

    const questionErrors = errors?.questions;
    return (
        <FormProvider {...formMethods}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                {questionsFields.map((question, qIndex) => (
                    <Box display="flex" flexDirection="column" alignItems="center" mt={6} key={qIndex}>
                        <Grid container columnSpacing={2} py={5} border={'1px solid #DFDFE4'} borderRadius={'12px'}>
                            <Grid item xs={12} mb={4}>
                                <Typography component={'h1'}>{trans('quiz.steps.questions')}</Typography>
                            </Grid>
                            {/* Question/Response details */}
                            <Grid item xs={6} sx={{ maxWidth: 500 }}>
                                {/* Question title */}
                                <Controller
                                    control={control}
                                    name={`questions.${qIndex}.question`}
                                    //defaultValue={watch(`questions.${qIndex}.question`) || ''}
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        minLength: {
                                            value: 3,
                                            message: trans('formValidation.fieldTooShort'),
                                        },
                                        maxLength: {
                                            value: 40,
                                            message: trans('formValidation.fieldTooLong'),
                                        },
                                        pattern: {
                                            value: REGEX_ONLY_PLAIN_CHAR,
                                            message: trans('formValidation.charNotAllowed'),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            inputProps={{ minLength: 3, maxLength: 40 }}
                                            disabled={formDisabled}
                                            label={trans('quiz.questionTitle')}
                                            placeholder={trans('quiz.question')}
                                            helperText={
                                                questionErrors?.[qIndex]?.question?.message ||
                                                trans('formValidation.charLimit', {
                                                    args: {
                                                        chars: 40,
                                                    },
                                                })
                                            }
                                            error={!!questionErrors?.[qIndex]?.question}
                                            {...field}
                                        />
                                    )}
                                />
                                {/* Timer */}
                                <Controller
                                    control={control}
                                    name={`questions.${qIndex}.timerSeconds`}
                                    //defaultValue={watch(`questions.${qIndex}.timerSeconds`) || ''}
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        min: {
                                            value: 20,
                                            message: trans('formValidation.min20Points'),
                                        },
                                        max: {
                                            value: 60,
                                            message: trans('formValidation.maxPointsExceeded'),
                                        },
                                        pattern: {
                                            value: REGEX_ONLY_PLAIN_CHAR,
                                            message: trans('formValidation.charNotAllowed'),
                                        },
                                    }}
                                    shouldUnregister
                                    render={({ field }) => (
                                        <TextInput
                                            disabled={formDisabled}
                                            inputProps={{ min: 20, max: 60, step: 10 }}
                                            type="number"
                                            label={trans('quiz.timer')}
                                            placeholder={trans('quiz.timer')}
                                            helperText={questionErrors?.[qIndex]?.timerSeconds?.message}
                                            error={!!questionErrors?.[qIndex]?.timerSeconds}
                                            {...field}
                                        />
                                    )}
                                />

                                <Grid item xs={12} height={'100%'}>
                                    <Box
                                        p={2}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        borderRadius={'12px'}
                                        bgcolor={(theme) => theme.palette.background.default}
                                        justifyContent={'center'}
                                    >
                                        <TimeQuestionResponses questionIndex={qIndex} />
                                    </Box>
                                </Grid>
                            </Grid>
                            {/* Question Preview */}
                            <Grid item xs={6}>
                                <Box
                                    borderRadius={'12px'}
                                    bgcolor={(theme) => theme.palette.background.default}
                                    p={4}
                                    display={'flex'}
                                    justifyContent={'center'}
                                >
                                    <PreviewTimeQuestionScreen
                                        headerTitle={quizTitle}
                                        endDate={quizEndDate}
                                        question={watch(`questions.${qIndex}.question`)}
                                        responses={watch(`questions.${qIndex}.responses`)}
                                        timerSeconds={watch(`questions.${qIndex}.timerSeconds`)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                ))}

                {!formDisabled && (
                    <NewQuizActionButtons
                        isLoading={isLoading}
                        onLeftButtonClick={handleStepPrevious}
                        rightButtonText={isEditMode ? trans('global.save') : trans('global.next')}
                        leftButtonText={trans('global.back')}
                    />
                )}
            </Box>
        </FormProvider>
    );
};

export default TimeQuestionsForm;
