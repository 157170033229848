import { useEffect } from 'react';

const enabled = false;

const useConfirmReload = () => {
    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = '';
            return '';
        };

        const popStateCallback = (event) => {
            return window.confirm('Sei Sicuro di voler uscire?');
        };

        enabled && window.addEventListener('beforeunload', unloadCallback);

        enabled && window.addEventListener('popstate', popStateCallback);
        return () => {
            enabled && window.removeEventListener('beforeunload', unloadCallback);
            enabled && window.removeEventListener('popstate', popStateCallback);
        };
    }, []);
};

export default useConfirmReload;
