import React, { useContext } from 'react';
import { Box, Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import { SURVEY_TYPOLOGIES } from '~js/constants/survey.js';
import DatePickerInput from '~js/components/DatePickerInput';
import CaptionInput from './CaptionInput';
import ActionMultiFlowResponse from './ActionMultiFlowResponse';
import SurveyContext from '../context';

const DateForm = ({ questionIndex }) => {
    const { formDisabled, surveyTypology } = useContext(SurveyContext);
    const isMultiFlowSurvey = surveyTypology === SURVEY_TYPOLOGIES.multiFlow;

    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext();

    const fieldBasePath = `questions.${questionIndex}`;
    const errorsBasePath =
        errors?.questions && errors.questions[questionIndex] ? errors.questions[questionIndex] : null;

    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12} md={6}>
                <Box sx={{ maxWidth: '500px' }}>
                    <CaptionInput questionIndex={questionIndex} />
                    <Box display="flex">
                        <Controller
                            control={control}
                            name={`${fieldBasePath}.responses.${0}.minDate`}
                            defaultValue={watch(`${fieldBasePath}.responses.${0}.minDate`) || ''}
                            rules={{
                                required: trans('formValidation.requiredField'),
                                /* validate: (value) =>
                                    value <=
                                        watch(`${fieldBasePath}.max`) ||
                                    trans("survey.minMustBeLessThanMax"),*/
                                deps: [`${fieldBasePath}.responses.${0}.maxDate`],
                            }}
                            shouldUnregister
                            render={({ field: { onChange, value } }) => (
                                <DatePickerInput
                                    disabled={formDisabled}
                                    label={trans('survey.minDate')}
                                    value={value}
                                    onChangeValue={onChange}
                                    helperText={
                                        !!errorsBasePath?.responses &&
                                        errorsBasePath?.responses[0] &&
                                        errorsBasePath?.responses[0]?.minDate?.message
                                    }
                                    error={!!errorsBasePath?.responses && !!errorsBasePath?.responses[0]?.minDate}
                                    timeEnabled={false}
                                />
                            )}
                        />
                        <Box width="100%" pl={3}>
                            <Controller
                                control={control}
                                name={`${fieldBasePath}.responses.${0}.maxDate`}
                                defaultValue={watch(`${fieldBasePath}.responses.${0}.maxDate`) || ''}
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                    validate: (value) =>
                                        new Date(value) > new Date(watch(`${fieldBasePath}.responses.${0}.minDate`)) ||
                                        trans('survey.endMustBeGreaterThanStart'),
                                    deps: [`${fieldBasePath}.responses.${0}.minDate`],
                                }}
                                shouldUnregister
                                render={({ field: { onChange, value } }) => (
                                    <DatePickerInput
                                        disabled={formDisabled}
                                        label={trans('survey.maxDate')}
                                        value={value}
                                        onChangeValue={onChange}
                                        helperText={
                                            !!errorsBasePath?.responses &&
                                            errorsBasePath?.responses[0] &&
                                            errorsBasePath?.responses[0]?.maxDate?.message
                                        }
                                        error={!!errorsBasePath?.responses && !!errorsBasePath?.responses[0]?.maxDate}
                                        timeEnabled={false}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                </Box>
            </Grid>
            {isMultiFlowSurvey && (
                <Grid item xs={12} sm={12} md={6}>
                    <Box maxWidth="500px">
                        <ActionMultiFlowResponse
                            questionIndex={questionIndex}
                            fieldBasePath={`${fieldBasePath}.responses.${0}`}
                            errorsBasePath={errorsBasePath?.responses && errorsBasePath?.responses[0]}
                        />
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};

export default DateForm;
