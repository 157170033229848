import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

const ImageChoiceListItemPreview = ({ image, useGridLayout }) => {
    const [imgUrl, setImgUrl] = useState(null);

    useEffect(() => {
        if (image && typeof image === 'object') {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImgUrl(reader.result);
            });
            reader.readAsDataURL(image);
            return;
        }

        if (image && typeof image === 'string') {
            setImgUrl(image);
            return;
        }

        setImgUrl('');
    }, [image]);

    return (
        <Box
            component="img"
            src={imgUrl}
            sx={{
                height: useGridLayout ? '140px' : '100px',
                width: '100%',
                objectFit: 'cover',
                borderRadius: '10px',
                my: '8px',
            }}
        />
    );
};

export default ImageChoiceListItemPreview;
