import React, { useState } from "react";
import { trans } from "matice";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";
import { Head, router } from "@inertiajs/react";
import ClusterForm from "./components/ClusterForm";
import SuccessModal from "~js/components/SuccessModal";
import ToolContentPageLayout from "~js/layouts/Tools/ToolContentPageLayout";
import type { BreadcrumbList } from "~js/components/Breadcrumbs";

const ClusterEdit = ({ externalDataSources, cluster, triggers, missionTypes, can, ...other }) => {
    console.log('ClusterEdit props', {
        externalDataSources,
        cluster,
        triggers,
        can,
        ...other,
    });

    let formDefaultValues;

    if (!!cluster.externalDataSource) {
        formDefaultValues = {
            ...cluster,
            type: 'externalDataSource',
            description: cluster?.description ? cluster.description : '',
            id: cluster._id,
            triggers: cluster?.triggers ? cluster.triggers : [],
            columnIdentifier: cluster?.columnIdentifier ? cluster.columnIdentifier : '',
        };
    } else {
        formDefaultValues = {
            ...cluster,
            type: 'csv',
            description: cluster?.description ? cluster.description : '',
            id: cluster._id,
            columnIdentifier: cluster?.columnIdentifier ? cluster.columnIdentifier : '',
        };
    }
    delete formDefaultValues._id;
    delete formDefaultValues.created_at;
    delete formDefaultValues.updated_at;

    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const goToClusterListPage = () => router.get(route('Cluster.index'));

    const onSubmit = async (data) => {
        let dataToSend = data;
        if (data.type === 'csv') {
            dataToSend = {
                title: data.title,
                description: data.description,
            };
        }
        if (!data.triggers || data.triggers.length === 0) {
            dataToSend['triggers'] = [];
        }
        const promise = new Promise((resolve, reject) =>
            router.patch(route('Cluster.update', cluster._id), dataToSend, {
                onError: (e) => {
                    reject(e);
                    if (!!e?.generic) {
                        toast.error(e.generic);
                    } else {
                        toast.error(trans('cluster.errorOnUpdateCluster'));
                    }
                },
                onSuccess: () => {
                    resolve();
                    setShowModalSuccess(true);
                },
            }),
        );

        await promise;
    };
    const breadcrumbs: BreadcrumbList = [
        {
            routeName: 'Cluster.edit',
            routeLabel: 'Modifica Clusters',
            params: {
                id: cluster._id,
            },
        },
        {
            routeName: 'Cluster.index',
            routeLabel: 'Clusters',
        },
    ];
    return (
        <>
            <Head title={trans('cluster.editCluster')} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                    {trans('cluster.editCluster')}
                </Typography>
                <ClusterForm
                    clusterCoverage={cluster.coverage}
                    triggers={triggers.map((t) => ({
                        _id: t._id,
                        label: t.name,
                        value: t._id,
                    }))}
                    externalDataSourceOption={externalDataSources?.map((ds) => ({
                        _id: ds._id,
                        label: ds.name,
                        value: ds._id,
                    }))}
                    missionTypes={missionTypes}
                    onCancelPress={goToClusterListPage}
                    onSubmit={onSubmit}
                    formDefaultValues={formDefaultValues}
                    formDisable={!can.update && !can.updateCsvCluster}
                    canAssociateTriggerToSQLCluster={can?.associateTriggerToSQLCluster}
                />
            </ToolContentPageLayout>
            <SuccessModal
                open={showModalSuccess}
                handleClose={() => setShowModalSuccess(false)}
                title={trans('global.compliments')}
                description={trans('cluster.clusterEditedSuccess')}
                buttonText={trans('cluster.backToClusterList')}
                onPressButton={goToClusterListPage}
                onExitPress={() => setShowModalSuccess(false)}
            />
        </>
    );
};

export default ClusterEdit;
