import React from 'react';
import ClassicQuestionsForm from '~js/pages/backoffice/Tools/Quiz/components/FormChallenges/ClassicQuestionsForm';
import NewQuizTimeQuestionStep from '~js/pages/backoffice/Tools/Quiz/components/FormChallenges/TimeQuestionsForm';
import DifferenceQuestionsForm from '~js/pages/backoffice/Tools/Quiz/components/FormChallenges/DifferenceQuestionsForm';
import MemoryQuestionsForm from '~js/pages/backoffice/Tools/Quiz/components/FormChallenges/MemoryQuestionsForm';
import { QUIZ_TYPOLOGIES } from '~js/constants/quiz';
import DragWinQuestionsForm from '~js/pages/backoffice/Tools/Quiz/components/FormChallenges/DragWinQuestionsForm';

const QuizStep2Challenge = ({ handleStepNext, handleStepPrevious, stepFormValue, typology }) => {
    return (
        <>
            {typology === QUIZ_TYPOLOGIES.classic && (
                <ClassicQuestionsForm
                    handleStepNext={handleStepNext}
                    handleStepPrevious={handleStepPrevious}
                    stepFormValue={stepFormValue}
                />
            )}

            {typology === QUIZ_TYPOLOGIES.time && (
                <NewQuizTimeQuestionStep
                    handleStepNext={handleStepNext}
                    handleStepPrevious={handleStepPrevious}
                    stepFormValue={stepFormValue}
                />
            )}

            {typology === QUIZ_TYPOLOGIES.difference && (
                <DifferenceQuestionsForm
                    handleStepNext={handleStepNext}
                    handleStepPrevious={handleStepPrevious}
                    stepFormValue={stepFormValue}
                />
            )}

            {typology === QUIZ_TYPOLOGIES.memory && (
                <MemoryQuestionsForm
                    handleStepNext={handleStepNext}
                    handleStepPrevious={handleStepPrevious}
                    stepFormValue={stepFormValue}
                />
            )}

            {typology === QUIZ_TYPOLOGIES.drag && (
                <DragWinQuestionsForm
                    handleStepNext={handleStepNext}
                    handleStepPrevious={handleStepPrevious}
                    stepFormValue={stepFormValue}
                />
            )}
        </>
    );
};

export default QuizStep2Challenge;
