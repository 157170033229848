import React from 'react';
import { Box, SvgIcon, ThemeProvider, Typography } from '@mui/material';
import pampersTheme from '~js/pages/backoffice/Tools/Quiz/components/Previews/previewTheme';
import iconGameChecked from '~/assets/images/icons/icon-game-checked.svg';
import iconGameUnchecked from '~/assets/images/icons/icon-game-unchecked.svg';
import IconCloseCoccoleSvg from '~/assets/images/icons/icon-close-coccole.svg?react';
import previewDifferenceImageDefault from '~/assets/images/tools/quizTool/previewDifferenceImageDefault.svg';
import { QUIZ_DIFFERENCE_MIN_QUESTIONS } from '~js/pages/backoffice/Tools/Quiz/components/FormChallenges/DifferenceQuestionsForm';
import useFileReader from '~js/hooks/useFileReader';

const PreviewDifferenceQuestionScreen = ({
    imageRight,
    imageWrong,
    diffFound = 1,
    diffToFind = QUIZ_DIFFERENCE_MIN_QUESTIONS,
}) => {
    const imageSxProps = {
        backgroundColor: 'white',
        objectFit: 'contain',
        width: '100%',
        aspectRatio: 16 / 9,
        //userDrag: 'none',
        userSelect: 'none',
    };

    const { fileUrl: imgUrlRight } = useFileReader(imageRight);
    const { fileUrl: imgUrlWrong } = useFileReader(imageWrong);

    return (
        <ThemeProvider theme={pampersTheme}>
            <Box
                p={1.5}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '250px',
                    height: '540px',
                    borderRadius: '22px',
                    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.25)',
                    backgroundColor: '#EDF8F9',
                    position: 'relative',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <Box>
                    <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'end'}>
                        <SvgIcon sx={{ color: '#FFFFFF' }} fontSize={'large'} inheritViewBox>
                            <IconCloseCoccoleSvg />
                        </SvgIcon>
                    </Box>
                    <Typography
                        py={3}
                        sx={{
                            fontSize: '15.74px',
                            fontWeight: 800,
                            lineHeight: '16px',
                            textAlign: 'center',
                        }}
                    >
                        Concentrati e cerca le differenze fra le due immagini
                    </Typography>
                </Box>
                <Box>
                    <Box
                        component={'img'}
                        src={imgUrlRight ?? previewDifferenceImageDefault}
                        mb={1}
                        borderRadius={'6px'}
                        sx={imageSxProps}
                    />
                    <Box
                        component={'img'}
                        src={imgUrlWrong ?? previewDifferenceImageDefault}
                        borderRadius={'6px'}
                        sx={imageSxProps}
                    />
                </Box>
                <Box mb={2}>
                    <Typography
                        sx={{
                            fontSize: '11px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            textAlign: 'center',
                            marginBottom: '12px',
                        }}
                    >
                        Differenze trovate:{' '}
                        <b>
                            {diffFound} di {diffToFind}
                        </b>
                    </Typography>
                    <Box display={'flex'} justifyContent={'center'} sx={{ height: '25px' }}>
                        {Array(diffToFind)
                            .fill('')
                            .map((_, index) => (
                                <Box
                                    key={index.toString()}
                                    component={'img'}
                                    src={index + 1 <= diffFound ? iconGameChecked : iconGameUnchecked}
                                    mx={0.5}
                                />
                            ))}
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PreviewDifferenceQuestionScreen;
