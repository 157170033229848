import React, { forwardRef } from 'react';
import { FormControlLabel, Switch, Typography } from '@mui/material';

const SwitchInput = forwardRef(({ label, onChange, value, ...other }, ref) => {
    return (
        <FormControlLabel
            sx={{ fontSize: '10px' }}
            label={
                <Typography fontSize="14px" color="#8B8D97">
                    {label}
                </Typography>
            }
            labelPlacement="start"
            control={<Switch onChange={onChange} checked={value} {...other} ref={ref} />}
        />
    );
});

export default SwitchInput;
