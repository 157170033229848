import { Controller, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';
import { TextInput } from '~js/components/input';
import LexicalTextEditor, { parseJsonToTextMap } from '~js/pages/backoffice/Tools/Quiz/components/LexicalEditor';
import { MAX_QUIZ_TIME_RESPONSE_POINTS } from '~js/pages/backoffice/Tools/Quiz/components/FormChallenges/TimeQuestionResponses';
import { clientQuizStatus } from '~js/constants/missionStatuses';
import FileUploaderInput from '~js/components/FileUploaderInput';
import { ASPECT_RATIO_IMG, getImageUploadTooltip, Images, ONE_MB } from '~js/constants/images';
import React, { useContext } from 'react';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';

const TimeIntroFormControllers = ({}) => {
    const { formDisabled, currentClientQuizStatus } = useContext(QuizContext);
    const {
        watch,
        setError,
        clearErrors,
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <>
            {/* Timed Intro title */}
            <Controller
                control={control}
                name="time.introTitle"
                defaultValue=""
                rules={{
                    required: trans('formValidation.requiredField'),
                    minLength: {
                        value: 3,
                        message: trans('formValidation.fieldTooShort'),
                    },
                    maxLength: {
                        value: 50,
                        message: trans('formValidation.fieldTooLong'),
                    },
                    pattern: {
                        value: REGEX_ONLY_PLAIN_CHAR,
                        message: trans('formValidation.charNotAllowed'),
                    },
                }}
                render={({ field }) => (
                    <TextInput
                        disabled={formDisabled}
                        label={trans('quiz.title')}
                        placeholder={trans('quiz.title')}
                        helperText={errors?.time?.introTitle?.message}
                        inputProps={{ maxLength: 50 }}
                        error={!!errors?.time?.introTitle}
                        {...field}
                    />
                )}
            />
            {/* Timed Intro description */}
            <Controller
                control={control}
                name="time.introDescription"
                rules={{
                    validate: (value) => {
                        if (!value) {
                            return trans('formValidation.requiredField');
                        }
                        const textMap = parseJsonToTextMap(JSON.parse(value));
                        return textMap.length > 0 ? true : 'textmap assente';
                    },
                }}
                render={({ field }) => (
                    <LexicalTextEditor
                        disabled={formDisabled}
                        label={trans('quiz.description')}
                        helperText={errors?.time?.introDescription?.message}
                        error={!!errors?.time?.introDescription}
                        inputProps={{ maxLength: 200 }}
                        {...field}
                    />
                )}
            />

            {/* Timed Intro response points */}
            <Controller
                control={control}
                rules={{
                    required: trans('formValidation.requiredField'),
                    max: {
                        value: MAX_QUIZ_TIME_RESPONSE_POINTS,
                        message: trans('formValidation.maxPointsExceeded'),
                    },
                    pattern: {
                        value: REGEX_ONLY_PLAIN_CHAR,
                        message: trans('formValidation.charNotAllowed'),
                    },
                }}
                shouldUnregister
                name="time.responsePoints"
                defaultValue={20}
                render={({ field }) => (
                    <TextInput
                        disabled={
                            formDisabled ||
                            currentClientQuizStatus === clientQuizStatus.prodActive ||
                            currentClientQuizStatus === clientQuizStatus.prodNotActive
                        }
                        inputProps={{ min: 0, max: MAX_QUIZ_TIME_RESPONSE_POINTS, step: 10 }}
                        type="number"
                        label={trans('quiz.reward')}
                        placeholder={trans('quiz.reward')}
                        helperText={errors?.time?.responsePoints?.message}
                        error={!!errors?.time?.responsePoints}
                        {...field}
                    />
                )}
            />

            {/* Timed Intro image */}
            <Controller
                control={control}
                name="time.introImage"
                defaultValue=""
                shouldUnregister
                rules={{ required: trans('formValidation.requiredField') }}
                render={({ field: { onChange, value } }) => (
                    <FileUploaderInput
                        disabled={formDisabled}
                        value={value}
                        label={trans('quiz.image')}
                        tooltipText={getImageUploadTooltip(Images.QUIZ_CLASSIC_START_BG, ONE_MB)}
                        onChange={onChange}
                        helperText={
                            errors?.image?.message ||
                            trans('formValidation.maxMbFile', {
                                args: { mb: (ONE_MB / ONE_MB).toFixed(1) },
                            })
                        }
                        validateFn={async (value) => {
                            if (!value || typeof value !== 'object') {
                                clearErrors('image');
                                return true;
                            }
                            return await new Promise((resolve) => {
                                let img = new Image();
                                img.src = window.URL.createObjectURL(value);
                                img.onload = () => {
                                    const aspectRatioImgUp = (img.width / img.height).toFixed(1);
                                    const aspectRatioRule = ASPECT_RATIO_IMG.QUIZ_CLASSIC_START_BG.toFixed(1);
                                    if (aspectRatioImgUp !== aspectRatioRule) {
                                        setError('time.introImage', {
                                            type: 'custom',
                                            message: trans('formValidation.aspectRatioError', {
                                                args: {
                                                    aspectRatio: aspectRatioRule,
                                                },
                                            }),
                                        });
                                        resolve(false);
                                    } else {
                                        if (value.size > ONE_MB) {
                                            setError('time.introImage', {
                                                type: 'custom',
                                                message: trans('formValidation.maxMbFileError', {
                                                    args: {
                                                        mb: (ONE_MB / ONE_MB).toFixed(1),
                                                        uploadedSize: (value.size / ONE_MB).toFixed(2),
                                                    },
                                                }),
                                            });
                                            resolve(false);
                                        } else {
                                            clearErrors('time.introImage');
                                            resolve(true);
                                        }
                                    }
                                };
                            });
                        }}
                        error={!!errors?.image}
                    />
                )}
            />

            {/* Timed Intro  CTA Text */}
            <Controller
                control={control}
                name="time.introCtaText"
                defaultValue=""
                shouldUnregister
                rules={{
                    required: trans('formValidation.requiredField'),
                    pattern: {
                        value: REGEX_ONLY_PLAIN_CHAR,
                        message: trans('formValidation.charNotAllowed'),
                    },
                    maxLength: {
                        value: 20,
                        message: trans('formValidation.fieldTooLong'),
                    },
                }}
                render={({ field }) => (
                    <TextInput
                        disabled={formDisabled}
                        label={trans('quiz.ctaText')}
                        placeholder={trans('quiz.ctaText')}
                        helperText={
                            errors?.time?.introCtaText?.message ||
                            trans('formValidation.charLimit', {
                                args: { chars: 20 },
                            })
                        }
                        inputProps={{ maxLength: 20 }}
                        error={!!errors?.time?.introCtaText}
                        {...field}
                    />
                )}
            />
        </>
    );
};

export default TimeIntroFormControllers;
