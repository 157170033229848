import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { trans } from 'matice';
import NewQuizActionButtons from '~js/pages/backoffice/Tools/Quiz/components/NewQuizActionButtons';
import ClusterGroupsComposer from '~js/pages/backoffice/Tools/Quiz/components/Audience/ClusterGroupsComposer';
import SelectTestGroupForm from '~js/pages/backoffice/Tools/Quiz/components/SelectTestGroupForm';
import QuizContext, { QuizAudienceContext } from '~js/pages/backoffice/Tools/Quiz/context';
import ExposureForm from '~js/pages/backoffice/Tools/Quiz/components/Audience/ExposureForm';

const QuizStep4Administration = ({
    handleStepNext,
    handleStepPrevious,
    clusters,
    events,
    isLoading,
    stepFormValue,
    testerGroups,
}) => {
    const { isEditMode, formDisabled } = useContext(QuizContext);

    const [audienceEnabled, setAudienceEnabled] = useState(false);
    const [audienceTested, setAudienceTested] = useState(false);

    const formMethods = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: stepFormValue
            ? stepFormValue
            : {
                  audience: {
                      active: false,
                      clustersGroups: [
                          {
                              operatorWithPrevious: 'AND',
                              operatorInsideGroup: 'AND',
                              clusters: [' '],
                          },
                      ],
                  },
                  silentActivation: false,
                  realTimeActivation: false,
                  isEventQuiz: false,
                  event: 'none',
                  groupTest: [],
              },
    });

    const { handleSubmit, setError, control } = formMethods;

    const onSubmit = (data) => {
        if (audienceEnabled && !audienceTested) {
            setError('checkAudience', { type: 'custom', message: trans('survey.checkAudienceRequired') });
            return;
        }
        handleStepNext({ administration: data });
    };

    useEffect(() => {
        const { audience } = formMethods.getValues();
        setAudienceEnabled(audience?.active);
    }, []);

    return (
        <FormProvider {...formMethods}>
            <QuizAudienceContext.Provider
                value={{
                    events,
                    clusters,
                    testerGroups,
                    audienceEnabled,
                    setAudienceEnabled,
                    audienceTested,
                    setAudienceTested,
                }}
            >
                <Box component="form" width="100%" onSubmit={handleSubmit(onSubmit)}>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={6}>
                        <SelectTestGroupForm />
                        <ClusterGroupsComposer />
                        <ExposureForm />
                        <Box width="100%">
                            {!formDisabled && (
                                <NewQuizActionButtons
                                    isLoading={isLoading}
                                    onLeftButtonClick={handleStepPrevious}
                                    rightButtonText={isEditMode ? trans('global.save') : trans('global.next')}
                                    leftButtonText={trans('global.back')}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </QuizAudienceContext.Provider>
        </FormProvider>
    );
};

export default QuizStep4Administration;
