import React, { useMemo } from 'react';
import { Alert, AlertTitle, AppBar, Box, ThemeProvider, Toolbar, Typography } from '@mui/material';
import { trans } from 'matice';
import CtaButton from '~js/components/CtaButton';
import { getResponsiveTheme, THEME } from '~js/constants/themes';
import pampersErrorImg from '~/assets/images/brands/pampers/error.svg';
import errorImg from '~/assets/images/errorGeneric.png';

const ErrorPage = ({ errors, error, props: { initialPage } }) => {
    const brand = initialPage.props?.brand;
    const theme = useMemo(() => {
        if (initialPage.props.brand === THEME.PAMPERS) {
            return getResponsiveTheme(THEME.PAMPERS);
        } else {
            return getResponsiveTheme(THEME.COMPANY);
        }
    }, [brand]);

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    bgcolor: (theme) => theme.palette.background.default,
                    minHeight: '100vh',
                }}
            >
                <AppBar
                    position="fixed"
                    sx={{
                        bgcolor: (theme) => theme.palette.primary.default,
                        zIndex: 999,
                    }}
                    elevation={0}
                >
                    <Toolbar />
                </AppBar>
                <Toolbar />
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" p={4}>
                    <Box my={8}>
                        <Typography variant="h4" sx={{ fontFamily: 'Lato', color: '#45464E' }}>
                            {trans('global.errorBoundariesTitle')}
                        </Typography>
                        {error?.message && (
                            <Box mt={3}>
                                <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {error.message}
                                </Alert>
                            </Box>
                        )}
                    </Box>
                    <Box
                        component="img"
                        src={brand === 'pampers' ? pampersErrorImg : errorImg}
                        width="80%"
                        maxWidth={'80vh'}
                    ></Box>
                    {errors?.generic?.message && (
                        <Typography mb={2} variant="p" sx={{ fontFamily: 'Lato', color: '#aeaeae' }}>
                            {errors.generic.message}
                        </Typography>
                    )}

                    <CtaButton
                        variant="contained"
                        sx={{
                            bgcolor: (theme) => theme.palette.primary.default,
                            '&: hover': { bgcolor: (theme) => theme.palette.primary.default },
                        }}
                        onClick={() => (window.location.href = route('BrandIndex'))}
                    >
                        {trans('global.backToTools')}
                    </CtaButton>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default ErrorPage;
