import { List, ListItem } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from 'react';
import usePagination from '@mui/material/usePagination';

export const PreviewAnswerPaginator = ({ paginationProps }) => {
    const { items } = usePagination({
        ...paginationProps,
    });

    return (
        <List sx={{ display: 'flex' }}>
            {items.map(({ page, type, selected, ...item }, index) => {
                let children = null;

                if (type === 'page') {
                    children = selected ? (
                        <CircleIcon
                            type={'button'}
                            sx={{ fontSize: 12, color: (theme) => theme.palette.primary.main }}
                            {...item}
                        />
                    ) : (
                        <CircleOutlinedIcon
                            type={'button'}
                            sx={{ fontSize: 12, color: (theme) => theme.palette.primary.main }}
                            {...item}
                        />
                    );
                } else if (type === 'previous') {
                    children = (
                        <ChevronLeftIcon
                            type={'button'}
                            sx={{ fontSize: 16, color: (theme) => theme.palette.primary.main }}
                            {...item}
                        ></ChevronLeftIcon>
                    );
                } else if (type === 'next') {
                    children = (
                        <ChevronRightIcon
                            type={'button'}
                            sx={{ fontSize: 16, color: (theme) => theme.palette.primary.main }}
                            {...item}
                        />
                    );
                }

                return (
                    <ListItem key={index} px={0.5}>
                        {children}
                    </ListItem>
                );
            })}
        </List>
    );
};
