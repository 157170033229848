import React, { useRef, useState } from 'react';
import { router } from '@inertiajs/react';
import { DataGrid, itIT } from '@mui/x-data-grid';
import { Box, IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { trans } from 'matice';
import ConfirmCancelModal from '~js/components/ConfirmCancelModal';
import useDateFormat from '~js/hooks/useDateFormat';
import { dateSortComparator } from '~js/utils/dateComparator';

const TesterGroupTable = ({ testerGroups, deleteTesterGroup, canView = true, canDelete = false }) => {
    const { formatDate } = useDateFormat();

    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const testerGroupIdToDelete = useRef(null);

    const deleteTesterGroupHandler = async () => {
        setLoadingDelete(true);
        try {
            await deleteTesterGroup(testerGroupIdToDelete.current);
            onCloseModalDeleteHandler();
        } finally {
            setLoadingDelete(false);
        }
    };

    const onCloseModalDeleteHandler = () => {
        setShowDeleteConfirmModal(false);
        testerGroupIdToDelete.current = null;
    };

    const onEditPress = (testerGroupIdToEdit) => {
        router.get(route('TesterGroups.edit', testerGroupIdToEdit));
    };

    const renderCellAction = (params) => {
        const onDeletePress = (e) => {
            e.stopPropagation();

            testerGroupIdToDelete.current = params.id;
            setShowDeleteConfirmModal(true);
        };

        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <IconButton onClick={() => onEditPress(params.id)} disabled={!canView}>
                    <SearchIcon />
                </IconButton>
                <IconButton onClick={(e) => onDeletePress(e)} disabled={!canDelete}>
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
            </Box>
        );
    };

    const columns = [
        /*     {
            field: "_id",
            headerName: "ID",
            flex: 1,
        },*/
        {
            field: 'name',
            headerName: trans('surveyToolSettings.testingTeam'),
            flex: 1,
        },
        {
            field: 'tester',
            headerName: trans('surveyToolSettings.users'),
            flex: 1,
            valueGetter: ({ row }) => row.tester.length,
        },
        {
            field: 'created_at',
            headerName: trans('surveyToolSettings.createdDate'),
            flex: 1,
            valueGetter: ({ row }) => formatDate(row.created_at, 'P'),
            sortComparator: (d1, d2) => dateSortComparator(d1, d2, 'dd/MM/yyyy'),
        },
        {
            headerName: ' ',
            field: ' ',
            flex: 0.5,
            sortable: false,
            renderCell: renderCellAction,
            minWidth: 80,
        },
    ];
    return (
        <>
            {testerGroups.length > 0 && (
                <DataGrid
                    rows={testerGroups}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    autoHeight
                    rowLength={5}
                    maxColumns={6}
                    localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                    getRowId={(r) => r._id}
                    //onRowClick={(r) => onEditPress(r.id)}
                />
            )}
            <ConfirmCancelModal
                iconType="delete"
                show={showDeleteConfirmModal}
                onClose={onCloseModalDeleteHandler}
                title={trans('surveyToolSettings.deleteTesterGroup')}
                description={trans('surveyToolSettings.deleteTesterGroupDescription')}
                onConfirm={deleteTesterGroupHandler}
                loading={loadingDelete}
            />
        </>
    );
};

export default TesterGroupTable;
