import { QUIZ_TYPOLOGIES, QUIZ_TYPOLOGIES_WITH_EXTRA_REWARD } from '~js/constants/quiz';

export const calculateTotalPoints = (quizData) => {
    if (!quizData) return 0;

    const typology = quizData.general?.typology;
    if (typology === QUIZ_TYPOLOGIES.classic) {
        return calculateTotalPointsClassic(quizData.general?.points, quizData.quizQuestions);
    }
    if (typology === QUIZ_TYPOLOGIES.time) {
        return calculateTotalPointsTime(
            quizData.general?.points,
            quizData.introduction?.time?.responsePoints,
            quizData.quizQuestions,
        );
    }
    if (typology === QUIZ_TYPOLOGIES.drag) {
        return calculateTotalPointsDragWin(quizData.general?.points, quizData.introduction?.followUp?.correctProducts);
    }
    if (typology && !QUIZ_TYPOLOGIES_WITH_EXTRA_REWARD.includes(typology)) {
        return quizData.general?.points;
    }
};

const calculateTotalPointsClassic = (basePoints, quizQuestions) => {
    let extraPoints = 0;
    const typeofPoints = typeof basePoints;
    if (typeofPoints === 'string') {
        extraPoints = Number.parseInt(basePoints);
    } else if (typeofPoints === 'number') {
        extraPoints = basePoints;
    }
    return quizQuestions?.questions
        ? quizQuestions?.questions.reduce((accumulator, { points }) => accumulator + +points, extraPoints)
        : extraPoints;
};

const calculateTotalPointsTime = (basePoints, responsePoints = 0, quizQuestions) => {
    return quizQuestions?.questions[0].responses
        ? quizQuestions?.questions[0].responses.reduce(
              (accumulator, { right }) => accumulator + (right ? +responsePoints : 0),
              +basePoints,
          )
        : +basePoints;
};

const calculateTotalPointsDragWin = (basePoints, correctProducts = []) => {
    return correctProducts
        ? correctProducts.reduce((accumulator, { points }) => accumulator + (+points ?? 0), +basePoints)
        : +basePoints;
};
