import React from 'react';
import { Controller } from 'react-hook-form';
import { trans } from 'matice';
import TextInput from '~js/components/TextInput';

const ExternalDataSourceMySqlInput = ({ control, errors, formDisabled = false }) => {
    return (
        <>
            <Controller
                control={control}
                name="config.host"
                render={({ field }) => (
                    <TextInput
                        disabled={formDisabled}
                        label={trans('surveyToolSettings.host')}
                        helperText={errors?.config?.host?.message}
                        error={!!errors?.config?.host}
                        {...field}
                    />
                )}
            />
            <Controller
                control={control}
                name="config.port"
                render={({ field }) => (
                    <TextInput
                        id={'config-port'}
                        disabled={formDisabled}
                        type="number"
                        inputProps={{ min: 0 }}
                        label={trans('surveyToolSettings.port')}
                        helperText={errors?.config?.port?.message}
                        error={!!errors?.config?.port}
                        {...field}
                    />
                )}
            />
            <Controller
                control={control}
                name="config.database"
                render={({ field }) => (
                    <TextInput
                        id={'config-database'}
                        disabled={formDisabled}
                        label={trans('surveyToolSettings.database')}
                        helperText={errors?.config?.database?.message}
                        error={!!errors?.config?.database}
                        {...field}
                    />
                )}
            />
            <Controller
                control={control}
                name="config.username"
                render={({ field }) => (
                    <TextInput
                        id={'config-username'}
                        disabled={formDisabled}
                        label={trans('surveyToolSettings.username')}
                        helperText={errors?.config?.username?.message}
                        error={!!errors?.config?.username}
                        {...field}
                    />
                )}
            />
            <Controller
                control={control}
                name="config.password"
                render={({ field }) => (
                    <TextInput
                        id={'config-password'}
                        disabled={formDisabled}
                        type="password"
                        autoComplete={'off'}
                        label={trans('surveyToolSettings.password')}
                        helperText={errors?.config?.password?.message}
                        error={!!errors?.config?.password}
                        {...field}
                    />
                )}
            />
            <Controller
                control={control}
                name="config.charset"
                render={({ field }) => (
                    <TextInput
                        id={'config-charset'}
                        disabled={formDisabled}
                        label={trans('surveyToolSettings.charset')}
                        helperText={errors?.config?.charset?.message}
                        error={!!errors?.config?.charset}
                        {...field}
                    />
                )}
            />
            <Controller
                control={control}
                name="config.collation"
                render={({ field }) => (
                    <TextInput
                        id={'config-collation'}
                        disabled={formDisabled}
                        label={trans('surveyToolSettings.collation')}
                        helperText={errors?.config?.collation?.message}
                        error={!!errors?.config?.collation}
                        {...field}
                    />
                )}
            />
            <Controller
                control={control}
                name="config.prefix"
                render={({ field }) => (
                    <TextInput
                        id={'config-prefix'}
                        disabled={formDisabled}
                        label={trans('surveyToolSettings.prefix')}
                        helperText={errors?.config?.prefix?.message}
                        error={!!errors?.config?.prefix}
                        {...field}
                    />
                )}
            />
            <Controller
                control={control}
                name="config.prefix_indexes"
                render={({ field }) => (
                    <TextInput
                        id={'config-prefix-indexes'}
                        disabled={formDisabled}
                        label={trans('surveyToolSettings.prefix_indexes')}
                        helperText={errors?.config?.prefix_indexes?.message}
                        error={!!errors?.config?.prefix_indexes}
                        {...field}
                    />
                )}
            />
            <Controller
                control={control}
                name="config.strict"
                render={({ field }) => (
                    <TextInput
                        id={'config-strict'}
                        disabled={formDisabled}
                        label={trans('surveyToolSettings.strict')}
                        helperText={errors?.config?.strict?.message}
                        error={!!errors?.config?.strict}
                        {...field}
                    />
                )}
            />
            <Controller
                control={control}
                name="config.unix_socket"
                render={({ field }) => (
                    <TextInput
                        id={'config-unix-socket'}
                        disabled={formDisabled}
                        label={trans('surveyToolSettings.unix_socket')}
                        helperText={errors?.config?.unix_socket?.message}
                        error={!!errors?.config?.unix_socket}
                        {...field}
                    />
                )}
            />
            <Controller
                control={control}
                name="config.engine"
                render={({ field }) => (
                    <TextInput
                        id={'config-engine'}
                        disabled={formDisabled}
                        label={trans('surveyToolSettings.engine')}
                        helperText={errors?.config?.engine?.message}
                        error={!!errors?.config?.engine}
                        {...field}
                    />
                )}
            />
        </>
    );
};

export default ExternalDataSourceMySqlInput;
