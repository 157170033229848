import React, { useRef, useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { toast } from 'react-toastify';
import { trans } from 'matice';
import ConfirmCancelModal from '~js/components/ConfirmCancelModal';
import SuccessModal from '~js/components/SuccessModal';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import useConfirmReload from '~js/hooks/useConfirmReload';
import NewSurveyCommunicationsStep from './components/NewSurveyCommunicationsStep';
import NewSurveyTypologyStep from './components/NewSurveyTypologyStep';
import NewSurveyQuestionsStep from './components/NewSurveyQuestionsStep';
import NewSurveyIntroductionClosureStep from './components/NewSurveyIntroductionClosureStep';
import NewSurveyAdministrationStep from './components/NewSurveyAdministrationStep';
import SurveyContext from './context';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';

const steps = [
    trans('survey.typology'),
    trans('survey.questions'),
    trans('survey.introduction'),
    trans('survey.closure'),
    trans('survey.administration'),
    trans('survey.communications'),
];

const breadcrumbs: BreadcrumbList = [
    {
        routeName: 'Surveys.create',
        routeLabel: 'Crea Survey',
    },
    {
        routeName: 'Surveys.index',
        routeLabel: 'Surveys',
    },
];

const SurveyCreate = ({ clusters, events, testerGroups, surveys, defaultValueDuplicateSurvey = null }) => {
    useConfirmReload();

    const [activeStep, setActiveStep] = useState(0);
    const [showExitModal, setShowExitModal] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const formData = useRef(defaultValueDuplicateSurvey ? defaultValueDuplicateSurvey : {});
    const createdSurveyId = useRef();

    const handleStepNext = (stepData) => {
        formData.current = { ...formData.current, ...stepData };
        console.log('form data', formData.current);

        if (activeStep === steps.length - 1) {
            const { general, questions, introduction, closure, administration, communication } = formData.current;

            let dataToSubmit = {
                questions: questions.questions,
                introduction,
                closure,
                audience: {
                    definition: administration.clustersGroups.map((cg) => ({
                        [cg.operatorWithPrevious]: {
                            [cg.operatorInsideGroup]: cg.clusters,
                        },
                    })),
                    surveysToExclude: administration.surveysToExclude || [],
                    selectedAudience: administration.selectedAudience || null,
                },
            };
            delete administration.clustersGroups;
            delete administration.surveysToExclude;
            delete administration.selectedAudience;

            dataToSubmit['general'] = {
                randomizeResponses: questions.randomizeResponses,
                ...general,
                ...administration,
                ...communication,
            };
            createSurvey(dataToSubmit);
            return;
        }

        setActiveStep((val) => val + 1);
    };

    const handleStepPrevious = () => {
        if (activeStep === 0) {
            setShowExitModal(true);
            return;
        }
        setActiveStep((val) => val - 1);
    };

    const goToSurveyListPage = () => router.get(route('Surveys.index'));
    const goToSurveySummary = () => router.get(route('Surveys.edit', createdSurveyId.current));

    const createSurvey = (data) => {
        console.log('data to sub', data);
        router.post(route('Surveys.store'), data, {
            onStart: () => setIsLoading(true),
            onFinish: () => setIsLoading(false),
            onSuccess: (data) => {
                setShowModalSuccess(true);
                createdSurveyId.current = data?.props?.createdSurveyId;
            },
            onError: (e) => {
                if (!!e?.generic) {
                    toast.error(e.generic);
                } else {
                    toast.error(trans('survey.errorOnCreateSurvey'));
                }
            },
        });
    };

    return (
        <>
            <Head title={trans('survey.createNewSurvey')} />

            <SurveyContext.Provider
                value={{
                    isEditMode: false,
                    currentClientSurveyStatus: 'draft',
                    formDisabled: false,
                    surveyTypology: formData.current?.general?.typology,
                    surveyTitle: formData.current?.general?.title,
                    surveyStartDate: formData.current?.general?.startDate,
                    surveyEndDate: formData.current?.general?.endDate,
                }}
            >
                <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    {activeStep === 0 && (
                        <NewSurveyTypologyStep
                            handleStepNext={handleStepNext}
                            handleStepPrevious={handleStepPrevious}
                            stepFormValue={formData.current?.general}
                        />
                    )}

                    {activeStep === 1 && (
                        <NewSurveyQuestionsStep
                            handleStepNext={handleStepNext}
                            handleStepPrevious={handleStepPrevious}
                            stepFormValue={formData.current?.questions}
                        />
                    )}

                    {activeStep === 2 && (
                        <NewSurveyIntroductionClosureStep
                            handleStepNext={handleStepNext}
                            handleStepPrevious={handleStepPrevious}
                            mode="introduction"
                            stepFormValue={formData.current?.introduction}
                        />
                    )}

                    {activeStep === 3 && (
                        <NewSurveyIntroductionClosureStep
                            handleStepNext={handleStepNext}
                            handleStepPrevious={handleStepPrevious}
                            mode="closure"
                            stepFormValue={formData.current?.closure}
                            points={formData.current?.introduction?.points}
                        />
                    )}

                    {activeStep === 4 && (
                        <NewSurveyAdministrationStep
                            handleStepNext={handleStepNext}
                            handleStepPrevious={handleStepPrevious}
                            clusters={clusters}
                            events={events}
                            isLoading={isLoading}
                            testerGroups={testerGroups}
                            surveys={surveys}
                            stepFormValue={formData.current?.administration}
                        />
                    )}
                    {activeStep === 5 && (
                        <NewSurveyCommunicationsStep
                            handleStepNext={handleStepNext}
                            handleStepPrevious={handleStepPrevious}
                            isLoading={isLoading}
                            stepFormValue={formData.current?.communication}
                        />
                    )}

                    <ConfirmCancelModal
                        show={showExitModal}
                        title={trans('global.exit')}
                        description={trans('survey.exitSurvey')}
                        onClose={() => setShowExitModal(false)}
                        onConfirm={goToSurveyListPage}
                    />
                    <SuccessModal
                        open={showModalSuccess}
                        title={trans('global.compliments')}
                        description={trans('survey.surveyCreatedSuccess')}
                        buttonText={trans('survey.goToSummary')}
                        onPressButton={goToSurveySummary}
                        onExitPress={() => setShowModalSuccess(false)}
                    />
                </ToolContentPageLayout>
            </SurveyContext.Provider>
        </>
    );
};

export default SurveyCreate;
