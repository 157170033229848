import React from 'react';
import TesterGroupTable from './TesterGroupTable';
import { router } from '@inertiajs/react';
import { toast } from 'react-toastify';
import { trans } from 'matice';
import EmptySection from '~js/components/EmptySection';
import CtaButton from '~js/components/CtaButton';

const TesterSettingSection = ({ testerGroups, canView = true, canDelete = false }) => {
    const deleteTesterGroup = async (idTesterGroup) => {
        const promise = new Promise((resolve, reject) =>
            router.delete(route('TesterGroups.destroy', idTesterGroup), {
                onSuccess: () => {
                    toast.success(trans('surveyToolSettings.testerGroupDeleted'));
                    resolve();
                },
                onError: (e) => {
                    reject();
                    toast.error(!!e?.generic ? e.generic : trans('surveyToolSettings.errorOnDeleteTesterGroup'));
                },
            }),
        );

        await promise;
    };
    return (
        <>
            <TesterGroupTable
                testerGroups={testerGroups}
                deleteTesterGroup={deleteTesterGroup}
                canView={canView}
                canDelete={canDelete}
            />
            {testerGroups.length === 0 && (
                <EmptySection
                    title={trans('surveyToolSettings.noTesterTitle')}
                    description={trans('surveyToolSettings.noTesterDescription')}
                    buttonComponent={
                        <CtaButton
                            variant="contained"
                            sx={{
                                px: 5,
                            }}
                            onClick={() => router.get(route('TesterGroups.create'))}
                        >
                            {trans('survey.createNew')}
                        </CtaButton>
                    }
                />
            )}
        </>
    );
};

export default TesterSettingSection;
