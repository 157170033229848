import React from 'react';
import { Box, ThemeProvider, Typography } from '@mui/material';
import useFileReader from '~js/hooks/useFileReader';
import PreviewQuizHeader from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewQuizHeader';
import coccoleCoin from '~/assets/images/tools/coccoleCoin.png';
import previewFinishImageDefault from '~/assets/images/tools/quizTool/previewFinishImageDefault.svg';
import PreviewCtaButton from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewCtaButton';
import pampersTheme from '~js/pages/backoffice/Tools/Quiz/components/Previews/previewTheme';

const PreviewFinishScreen = ({
    headerTitle = 'Quiz',
    headerSubTitle,
    endDate,
    title,
    ctaText,
    image,
    earnedPoints,
    points,
}) => {
    const { fileUrl: imgUrl } = useFileReader(image);

    return (
        <ThemeProvider theme={pampersTheme}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '236px',
                    height: '512px',
                    borderRadius: '22px',
                    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.25)',
                    position: 'relative',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                {/* Quiz Screen Header content */}
                <PreviewQuizHeader title={headerTitle} subTitle={headerSubTitle} endDate={endDate} />

                {/* Quiz Finish Screen Body */}
                <Box
                    position={'relative'}
                    overflow={'hidden'}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    bgcolor={'#FFFFFF'}
                    width={'100%'}
                    height={'100%'}
                >
                    <Box
                        component="img"
                        src={imgUrl ?? previewFinishImageDefault}
                        sx={{
                            position: 'absolute',
                            objectFit: 'cover',
                            objectPosition: 'top',
                            width: '100%',
                            height: '100%',
                            zIndex: 0,
                            filter: imgUrl ? 'none' : 'grayscale(100%);',
                        }}
                    />
                    <Box
                        px="10px"
                        height={'100%'}
                        sx={{
                            zIndex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'end',
                        }}
                    >
                        <Box flex={1}></Box>
                        <Box
                            flex={1}
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            mb={2}
                            px={2}
                        >
                            <Box flex={1} justifyContent={'center'} pt="40px" width={'100%'}>
                                <Typography
                                    px={2}
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                        lineHeight: '16px',
                                        color: '#354953',
                                        textAlign: 'center',
                                    }}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    py={'8px'}
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: '10px',
                                        lineHeight: '12px',
                                        color: '#354953',
                                    }}
                                >
                                    Hai guadagnato:
                                </Typography>

                                <Box
                                    bgcolor={'#daf1f2'}
                                    border={'.5px solid daf1f2'}
                                    borderRadius={'15px'}
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'end'}
                                    justifyContent={'center'}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '24px',
                                            lineHeight: '26px',
                                            color: '#25A6AB',
                                            textAlign: 'center',
                                            py: 1,
                                        }}
                                    >
                                        +{earnedPoints}
                                        <Box
                                            component="img"
                                            src={coccoleCoin}
                                            sx={{
                                                width: '22px',
                                                height: '20px',
                                                objectFit: 'contain',
                                                ml: '5px',
                                            }}
                                        />
                                    </Typography>
                                </Box>
                            </Box>

                            <Box flex={1} display={'flex'} flexDirection={'column'} justifyContent={'end'}>
                                <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    justifyContent={'space-between'}
                                    alignItems={'end'}
                                    pt={1}
                                    pb={2}
                                >
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            fontSize: '9px',
                                            lineHeight: '12px',
                                            color: '#354953',
                                        }}
                                    >
                                        I tuoi Punti Coccole:
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                            lineHeight: '16px',
                                            color: '#25A6AB',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {earnedPoints + points}
                                        <Box
                                            component="img"
                                            src={coccoleCoin}
                                            sx={{
                                                width: '14px',
                                                height: '12px',
                                                objectFit: 'contain',
                                                ml: '3px',
                                            }}
                                        />
                                    </Typography>
                                </Box>
                                <PreviewCtaButton ctaText={ctaText} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PreviewFinishScreen;
