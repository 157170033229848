import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import layout from '~/assets/images/tools/surveyTool/layoutPreviewNoCtaText.png';
import introductionDefault from '~/assets/images/tools/surveyTool/introductionDefault.png';
import coccoleCoin from '~/assets/images/tools/coccoleCoin.png';

const IntroductionClosurePreview = ({
    title,
    description,
    ctaText,
    points,
    headerTitle = 'Conosciamoci meglio',
    image,
    isClosureMode,
}) => {
    const [imgUrl, setImgUrl] = useState(null);

    useEffect(() => {
        if (image && typeof image === 'object') {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImgUrl(reader.result);
            });
            reader.readAsDataURL(image);
            return;
        }

        if (!!image && typeof image === 'string') {
            setImgUrl(image);
            return;
        }

        setImgUrl(null);
    }, [image]);
    return (
        <Box
            sx={{
                display: 'flex',
                //justifyContent: "center",
                alignItems: 'center',
                width: '250px',
                height: '540px',
                backgroundImage: `url(${layout})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                borderRadius: '22px',
                boxShadow: '0px 5.03227px 5.03227px rgba(0, 0, 0, 0.25)',
                position: 'relative',
                flexDirection: 'column',
                overflow: 'hidden',
                px: '10px',
            }}
        >
            <Box sx={{ height: '134px' }} display="flex" width="100%" pt="70px">
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '20px',
                        lineHeight: '22px',
                        color: 'white',
                        width: '100%',
                    }}
                >
                    {headerTitle.slice(0, 50)}
                </Typography>
            </Box>
            <Box
                component="img"
                src={imgUrl ? imgUrl : introductionDefault}
                sx={{
                    width: '110px',
                    height: '110px',
                    objectFit: 'contain',
                    mt: '30px',
                }}
            />
            <Box sx={{ maxWidth: '220px' }}>
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        lineHeight: '16px',
                        color: 'black',
                        textAlign: 'center',
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '10px',
                        lineHeight: '12px',
                        color: 'black',
                        textAlign: 'center',
                        mt: '20px',
                    }}
                >
                    {description}
                </Typography>

                {points && (
                    <Box
                        sx={{
                            bgcolor: '#d7f0f2',
                            borderRadius: '12px',
                            width: '210px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '25px',
                                color: '#25A6AB',
                                textAlign: 'center',
                                mt: '20px',
                            }}
                        >
                            {`+${points}`}
                            <Box
                                component="img"
                                src={coccoleCoin}
                                sx={{
                                    width: '22px',
                                    height: '22px',
                                    objectFit: 'contain',
                                    ml: '5px',
                                }}
                            />
                        </Typography>
                    </Box>
                )}

                {isClosureMode && (
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt="10px">
                        <Box>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '10px',
                                    lineHeight: '12px',
                                    color: 'black',
                                    textAlign: 'center',
                                }}
                            >
                                I tuoi punti coccole:
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '22px',
                                    color: '#25A6AB',
                                    textAlign: 'center',
                                    //mt: "20px",
                                }}
                            >
                                14.305
                                <Box
                                    component="img"
                                    src={coccoleCoin}
                                    sx={{
                                        width: '22px',
                                        height: '22px',
                                        objectFit: 'contain',
                                        ml: '5px',
                                    }}
                                />
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
            <Typography
                sx={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                    position: 'absolute',
                    bottom: 22,
                    color: 'white',
                    textAlign: 'center',
                }}
            >
                {ctaText.slice(0, 20)}
            </Typography>
        </Box>
    );
};

export default IntroductionClosurePreview;
