import React, { useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { trans } from 'matice';
import SuccessModal from '~js/components/SuccessModal';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import EventForm from './components/EventForm';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';

const EventsEdit = ({ event, can, ...other }) => {
    console.log('EventsEdit props', { event, ...other });

    const formDefaultValues = {
        ...event,
        description: event?.description ? event.description : '',
        id: event._id,
    };
    delete formDefaultValues._id;
    delete formDefaultValues.created_at;
    delete formDefaultValues.updated_at;

    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const goToClusterListPage = () => router.get(route('Events.index'));

    const onSubmit = async (data) => {
        console.log(data);
        const promise = new Promise((resolve, reject) =>
            router.patch(route('Events.update', event._id), data, {
                onError: (e) => {
                    reject(e);
                    if (!!e?.generic) {
                        toast.error(e.generic);
                    } else {
                        toast.error(trans('events.errorOnUpdateEvent'));
                    }
                },
                onSuccess: () => {
                    resolve();
                    setShowModalSuccess(true);
                },
            }),
        );

        await promise;
    };

    const breadcrumbs: BreadcrumbList = [
        {
            routeName: 'Events.edit',
            routeLabel: 'Modifica evento',
            params: {
                id: event._id,
            },
        },
        {
            routeName: 'Events.index',
            routeLabel: 'Eventi',
        },
    ];
    return (
        <>
            <Head title={trans('events.editEvent')} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                    {trans('events.editEvent')}
                </Typography>
                <EventForm
                    onCancelPress={goToClusterListPage}
                    onSubmit={onSubmit}
                    formDefaultValues={formDefaultValues}
                    formDisabled={!can.update}
                />
            </ToolContentPageLayout>
            <SuccessModal
                open={showModalSuccess}
                handleClose={() => setShowModalSuccess(false)}
                title={trans('global.compliments')}
                description={trans('events.eventEditedSuccess')}
                buttonText={trans('events.backToEventList')}
                onPressButton={goToClusterListPage}
                onExitPress={() => setShowModalSuccess(false)}
            />
        </>
    );
};

export default EventsEdit;
