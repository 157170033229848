import { Box, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { forwardRef } from 'react';

const RadioBooleanGroup = forwardRef(({ key, value, label, error = null, onChange }, ref) => {
    const handleChange = (event) => onChange(event.target.value);
    return (
        <Box ml={1}>
            <FormControl>
                <FormLabel
                    id={`radio-buttons-group-resp-${key}-label`}
                    sx={{
                        marginTop: (theme) => theme.spacing(1),
                        fontSize: 16,
                        lineHeight: '23px',
                        color: '#5E6366',
                    }}
                    error={!!error}
                >
                    {label}
                </FormLabel>
                <RadioGroup
                    ref={ref}
                    row
                    aria-labelledby={`radio-buttons-group-resp-${key}-label`}
                    defaultValue="false"
                    name="radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                >
                    <FormControlLabel value="true" control={<Radio size="small" />} label="Vero" />
                    <FormControlLabel value="false" control={<Radio size="small" />} label="Falso" />
                </RadioGroup>
                {error && (
                    <FormHelperText sx={{ color: (theme) => theme.palette.error.main, marginLeft: 0 }}>
                        {error}
                    </FormHelperText>
                )}
            </FormControl>
        </Box>
    );
});

export default RadioBooleanGroup;
