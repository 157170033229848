import React, { useContext } from 'react';
import { Box, ThemeProvider, Typography } from '@mui/material';
import useFileReader from '~js/hooks/useFileReader';
import PreviewQuizHeader from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewQuizHeader';
import coccoleCoin from '~/assets/images/tools/coccoleCoin.png';
import previewStartImageDefault from '~/assets/images/tools/quizTool/previewStartImageDefault.svg';
import PreviewCtaButton from '~js/pages/backoffice/Tools/Quiz/components/Previews/PreviewCtaButton';
import pampersTheme from '~js/pages/backoffice/Tools/Quiz/components/Previews/previewTheme';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import { QUIZ_TYPOLOGIES } from '~js/constants/quiz';

const PreviewStartScreen = ({
    headerTitle = '',
    headerSubTitle,
    endDate,
    title,
    description,
    subDescription,
    ctaText,
    points,
    image,
    footerDescription,
}) => {
    const { quizTypology } = useContext(QuizContext);
    const { fileUrl: imgUrl } = useFileReader(image);
    return (
        <ThemeProvider theme={pampersTheme}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '236px',
                    height: '512px',
                    borderRadius: '22px',
                    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.25)',
                    position: 'relative',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                {/* Quiz Screen Header content */}
                <PreviewQuizHeader
                    title={headerTitle}
                    subTitle={quizTypology === QUIZ_TYPOLOGIES.classic ? headerSubTitle : null}
                    endDate={endDate}
                />

                {/* Quiz Start Screen Body */}
                <Box
                    position={'relative'}
                    overflow={'hidden'}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    bgcolor={'#FFFFFF'}
                    width={'100%'}
                    height={'100%'}
                >
                    <Box
                        component="img"
                        src={imgUrl ?? previewStartImageDefault}
                        sx={{
                            position: 'absolute',
                            objectFit: 'cover',
                            objectPosition: 'top',
                            width: '100%',
                            height: '100%',
                            zIndex: 0,
                            filter: imgUrl ? 'none' : 'grayscale(100%);',
                        }}
                    />
                    <Box
                        px="10px"
                        height={'100%'}
                        sx={{
                            zIndex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'end',
                        }}
                    >
                        <Box flex={1}></Box>
                        <Box
                            flex={1}
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            mb={2}
                            px={2}
                        >
                            <Box flex={1} justifyContent={'center'} pt="40px">
                                {title?.split('\n').map((str, index) => (
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '14px',
                                            lineHeight: '16px',
                                            color: '#354953',
                                            textAlign: 'center',
                                        }}
                                        key={index.toString()}
                                    >
                                        {str}
                                    </Typography>
                                ))}

                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: '11px',
                                        lineHeight: '15px',
                                        color: '#354953',
                                        mt: 1,
                                        mb: 1.5,
                                    }}
                                >
                                    {description}
                                </Typography>

                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: '10px',
                                        lineHeight: '12px',
                                        color: '#354953',
                                    }}
                                >
                                    {subDescription}
                                </Typography>

                                {points && (
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'end'}
                                        justifyContent={'center'}
                                        sx={{
                                            pt: 0.5,
                                            pb: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: '13px',
                                                lineHeight: '18px',
                                                color: '#25A6AB',
                                                textAlign: 'center',
                                                mr: '5px',
                                            }}
                                        >
                                            fino a
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: '25px',
                                                lineHeight: '26px',
                                                color: '#25A6AB',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {points}
                                            <Box
                                                component="img"
                                                src={coccoleCoin}
                                                sx={{
                                                    width: '22px',
                                                    height: '20px',
                                                    objectFit: 'contain',
                                                    ml: '5px',
                                                }}
                                            />
                                        </Typography>
                                    </Box>
                                )}
                            </Box>

                            <Box flex={1} display={'flex'} flexDirection={'column'} justifyContent={'end'}>
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        fontWeight: quizTypology !== 'time' ? 'bold' : 'regular',
                                        fontSize: '8px',
                                        lineHeight: '12px',
                                        color: '#354953',
                                        mb: 1,
                                    }}
                                >
                                    {footerDescription}
                                </Typography>

                                <PreviewCtaButton ctaText={ctaText} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PreviewStartScreen;
