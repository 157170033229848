import React from 'react';
import { Box, Grid, LinearProgress, styled, SvgIcon, ThemeProvider, Typography } from '@mui/material';
import pampersTheme from '~js/pages/backoffice/Tools/Quiz/components/Previews/previewTheme';
import IconCloseCoccoleSvg from '~/assets/images/icons/icon-close-coccole.svg?react';
import previewDragWinCart from '~/assets/images/tools/quizTool/previewDragWinCart.png';
import useTimer from '~js/hooks/useTimer';
import { NUM_CORRECT_PRODUCTS } from '~js/pages/backoffice/Tools/Quiz/components/FormSteps/SubSteps/DragWinFollowUpFormControllers';
import { NUM_WRONG_PRODUCTS } from '~js/pages/backoffice/Tools/Quiz/components/FormChallenges/DragWinQuestionForm';

const PROGRESS_REFRESH_RATE_MS = 500;

const CustomLinearProgress = styled(LinearProgress)({
    '.MuiLinearProgress-root': {
        backgroundColor: '#ba96d0',
    },
    '.MuiLinearProgress-bar': {
        backgroundColor: '#8E51A5',
    },
});

const PreviewDragWinQuestionScreen = ({ title = '', images = [], timerSeconds = 15 }) => {
    const { progress: timerProgress, elapsed } = useTimer({
        seconds: timerSeconds,
        timeoutMs: PROGRESS_REFRESH_RATE_MS,
    });

    return (
        <ThemeProvider theme={pampersTheme}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '250px',
                    height: '540px',
                    borderRadius: '22px',
                    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.25)',
                    backgroundColor: 'rgba(16, 169, 174, 1)',
                    position: 'relative',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <Box width={'100%'} height={'150px'} p={1.5}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'end'}>
                        <SvgIcon sx={{ color: '#FFFFFF' }} fontSize={'large'} inheritViewBox>
                            <IconCloseCoccoleSvg />
                        </SvgIcon>
                    </Box>
                    <Typography
                        py={3}
                        sx={{
                            fontSize: '15.74px',
                            fontWeight: 800,
                            lineHeight: '16px',
                            textAlign: 'center',
                            color: '#FFFFFF',
                        }}
                    >
                        {title}
                    </Typography>
                </Box>

                <Grid container sx={{ zIndex: 5 }} px={1.5}>
                    {Array(NUM_CORRECT_PRODUCTS + NUM_WRONG_PRODUCTS)
                        .fill('')
                        .map((image, index) => (
                            <Grid
                                item
                                xs={4}
                                key={index.toString()}
                                component={images[index] ? 'img' : 'div'}
                                src={images[index]}
                                sx={{
                                    objectFit: 'contain',
                                    padding: '5px',
                                }}
                            />
                        ))}
                </Grid>

                <Box width={'100%'} height={'165px'} sx={{ position: 'relative', textAlign: 'center' }}>
                    <Box
                        sx={{
                            borderRadius: '100%',
                            width: '400px',
                            height: '250px',
                            bottom: '-75px',
                            left: '-75px',
                            backgroundColor: 'white',
                            position: 'absolute',
                            zIndex: 2,
                        }}
                    />
                    <Box
                        sx={{
                            borderRadius: '100%',
                            width: '400px',
                            height: '250px',
                            bottom: '-50px',
                            left: '-75px',
                            backgroundColor: 'rgba(255, 255, 255, 0.25)',
                            position: 'absolute',
                            zIndex: 2,
                        }}
                    />
                    <Box
                        p={1.5}
                        sx={{
                            position: 'relative',
                            zIndex: 3,
                        }}
                    >
                        <Box
                            component={'img'}
                            src={previewDragWinCart}
                            sx={{
                                height: '100px',
                                width: '100px',
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: '11px',
                                lineHeight: '22px',
                            }}
                        >
                            {timerSeconds - elapsed} secondi
                        </Typography>
                        <CustomLinearProgress
                            variant="determinate"
                            value={timerProgress}
                            //value={100}
                        />
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PreviewDragWinQuestionScreen;
