import React from 'react';
import { Head } from '@inertiajs/react';
import { trans } from 'matice';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';

const breadcrumbs: BreadcrumbList = [
    {
        routeName: 'Settings.index',
        routeLabel: 'Impostazioni',
    },
    {
        routeName: 'ToolsUsers.index',
        routeLabel: 'Partecipanti',
    },
];

const ToolsUsersIndex = ({ user, externalDataSources, testerGroups, section, can, ...other }) => {
    return (
        <>
            <Head title={trans('navBarSurvey.toolsUsers')} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}></ToolContentPageLayout>
        </>
    );
};

export default ToolsUsersIndex;
