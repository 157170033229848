import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { trans } from 'matice';
import TextInput from '~js/components/TextInput';
import CtaButton from '~js/components/CtaButton';

const CommunicationsSettingsSection = () => {
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: {
            username: '',
            password: '',
            port: '',
        },
    });

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = (data) => {
        console.log(data);
        window.alert('Da sviluppare');
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Typography>{trans('surveyToolSettings.communicationsSettings')}</Typography>
            <Grid container columnSpacing={4} rowSpacing={2} mt={5}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box maxWidth="400px">
                        <Controller
                            control={control}
                            name="username"
                            rules={{
                                required: trans('formValidation.requiredField'),
                            }}
                            render={({ field }) => (
                                <TextInput
                                    label={trans('surveyToolSettings.username')}
                                    helperText={errors?.username?.message}
                                    error={!!errors?.username}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="password"
                            rules={{
                                required: trans('formValidation.requiredField'),
                            }}
                            render={({ field }) => (
                                <TextInput
                                    type="password"
                                    label={trans('surveyToolSettings.password')}
                                    helperText={errors?.password?.message}
                                    error={!!errors?.password}
                                    {...field}
                                />
                            )}
                        />
                        <Box maxWidth="220px">
                            <Controller
                                control={control}
                                name="port"
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        type="number"
                                        inputProps={{ min: 0, max: 9999 }}
                                        label={trans('surveyToolSettings.port')}
                                        helperText={errors?.port?.message}
                                        error={!!errors?.port}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexWrap: 'wrap',
                    mt: 5,
                }}
            >
                <CtaButton variant="contained" type="submit" loading={isLoading}>
                    {trans('global.saveChanges')}
                </CtaButton>
            </Box>
        </form>
    );
};

export default CommunicationsSettingsSection;
