import React, { useMemo } from 'react';
import { Box, ThemeProvider, Typography } from '@mui/material';
import pampersTheme from '~js/pages/backoffice/Tools/Quiz/components/Previews/previewTheme';
import modalRibbon from '~/assets/images/tools/quizTool/differenceModalRibbon.svg';
import modalCta from '~/assets/images/tools/quizTool/differenceModalCta.svg';
import previewDifferenceModalImageDefault from '~/assets/images/tools/quizTool/previewDifferenceModalImageDefault.svg';
import { parseJsonToTextMap, parseTextMapToMuiText } from '~js/pages/backoffice/Tools/Quiz/components/LexicalEditor';
import useFileReader from '~js/hooks/useFileReader';

const PreviewQuestionSuccessModalScreen = ({ subTitle, description, image, children }) => {
    const { fileUrl } = useFileReader(image);
    const subTitleTextMap = useMemo(() => parseJsonToTextMap(JSON.parse(subTitle ?? '{}')) ?? [], [subTitle]);
    const descriptionTextMap = useMemo(() => parseJsonToTextMap(JSON.parse(description ?? '{}')) ?? [], [description]);
    return (
        <ThemeProvider theme={pampersTheme}>
            <Box sx={{ position: 'relative' }}>
                {children}
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    p={3}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: '22px',
                        backgroundColor: (theme) => theme.palette.background.overlay,
                    }}
                >
                    <Box
                        pt={5}
                        px={2.5}
                        pb={2}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{
                            width: '100%',
                            position: 'relative',
                            border: '3px solid rgba(0, 0, 0, 0.1)',
                            borderRadius: '18px',
                            backgroundColor: (theme) => theme.palette.background.paper,
                        }}
                    >
                        <Box
                            component={'img'}
                            src={modalRibbon}
                            sx={{
                                top: -35,
                                left: -18,
                                //left: -116.5,
                                position: 'absolute',
                            }}
                        />

                        <Typography
                            component={'p'}
                            sx={{
                                textAlign: 'center',
                                fontSize: '10px',
                                lineHeight: '12px',
                                color: '#354953',
                                mb: 2,
                            }}
                        >
                            {parseTextMapToMuiText(subTitleTextMap)}
                        </Typography>
                        <Typography
                            component={'p'}
                            sx={{
                                textAlign: 'center',
                                fontSize: '10px',
                                lineHeight: '16px',
                                color: '#354953',
                                mb: 2,
                            }}
                        >
                            {parseTextMapToMuiText(descriptionTextMap)}
                        </Typography>
                        <Box
                            component={'img'}
                            src={fileUrl ?? previewDifferenceModalImageDefault}
                            width={'156px'}
                            height={'156px'}
                            mb={1.5}
                        />
                        <Box component={'img'} src={modalCta} />
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PreviewQuestionSuccessModalScreen;
