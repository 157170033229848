import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, IconButton, Typography } from '@mui/material';
import { trans } from 'matice';
import ToolsUserForm from './ToolsUserForm';
import CustomModal from '~js/components/CustomModal';
import AddUserIcon from '~/assets/images/icons/add-user.svg?react';
import { router } from '@inertiajs/react';
import { toast } from 'react-toastify';

const AddToolsUserModal = ({ open, handleClose, toolsRoles }) => {
    const inviteToolUser = async ({ name, surname, email, password, roles }) =>
        new Promise((resolve, reject) => {
            router.post(
                route('ToolsUsers.store'),
                {
                    name,
                    surname,
                    email,
                    password,
                    roleIds: roles.map((role) => role.value),
                },
                {
                    onSuccess: () => {
                        toast.success(trans('settings.tools-users.add-user-modal.success-message'));

                        handleClose();
                        resolve();
                    },
                    onError: (e) => {
                        toast.error(
                            !!e?.generic
                                ? e.generic
                                : trans('settings.tools-users.add-user-modal.generic-error-message'),
                        );

                        reject(e);
                    },
                },
            );
        });
    return (
        <CustomModal
            open={open}
            onClose={handleClose}
            sx={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: '700px', borderRadius: 2.3 }}
        >
            <Box maxWidth="500px" width="100%">
                <Box display="flex" justifyContent="space-between" flexWrap="wrap" mb={4}>
                    <Box width={'52px'} />
                    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                        <Box
                            borderRadius={'100%'}
                            width={50}
                            height={50}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{
                                backgroundColor: (theme) => theme.palette.primary.main,
                            }}
                        >
                            <AddUserIcon />
                        </Box>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {trans('settings.tools-users.add-user-modal.title')}
                        </Typography>
                    </Box>
                    <Box width={52} height={52}>
                        <IconButton onClick={handleClose}>
                            <CancelIcon color="primary" fontSize="large" />
                        </IconButton>
                    </Box>
                </Box>

                <ToolsUserForm toolsRoles={toolsRoles} onSubmitPress={inviteToolUser} onCancelPress={handleClose} />
            </Box>
        </CustomModal>
    );
};

export default AddToolsUserModal;
