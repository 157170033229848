import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import ToolContext from '~js/layouts/Tools/context';
import faterEmptyImage from '~/assets/images/brands/fater/empty-image.png';
import pampersEmptyImage from '~/assets/images/brands/pampers/empty-image.svg';

const emptyImagePerBrand = {
    fater: faterEmptyImage,
    pampers: pampersEmptyImage,
};

const EmptySection = ({ title, description, buttonComponent }) => {
    const { brand } = useContext(ToolContext);
    const image = emptyImagePerBrand[brand];
    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" my={4}>
            <Box component="img" src={image} maxWidth="320px" />
            <Typography variant="h4" mt={2}>
                {title}
            </Typography>
            <Typography mt={1}>{description}</Typography>
            <Box mt={4}>{buttonComponent}</Box>
        </Box>
    );
};

export default EmptySection;
