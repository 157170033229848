import React, { useState } from 'react';
import * as yup from 'yup';
import { trans } from 'matice';
import { Box, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { TextInput } from '~js/components/input';
import CtaButton from '~js/components/CtaButton';

const EventForm = ({ onSubmit, onCancelPress, formDefaultValues, formDisabled = false }) => {
    const SchemaValidation = yup.object().shape({
        name: yup
            .string()
            .required(trans('formValidation.requiredField'))
            .min(3, trans('formValidation.fieldTooShort'))
            .max(50, trans('formValidation.fieldTooLong')),
        description: yup
            .string()
            //.required(trans("formValidation.requiredField"))
            //.min(3, trans("formValidation.fieldTooShort"))
            .max(1000, trans('formValidation.fieldTooLong')),
    });

    const {
        handleSubmit,
        formState: { errors },
        control,
        setError,
    } = useForm({
        defaultValues: formDefaultValues
            ? formDefaultValues
            : {
                  name: '',
                  description: '',
              },
        resolver: yupResolver(SchemaValidation),
    });

    const [isLoading, setIsLoading] = useState(false);

    const onSubmitHandler = async (data) => {
        setIsLoading(true);
        try {
            await onSubmit(data);
        } catch (e) {
            Object.keys(e).forEach((key) => {
                setError(key, { type: 'custom', message: e[key] });
            });
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ maxWidth: '500px' }}>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field }) => (
                                <TextInput
                                    disabled={formDisabled}
                                    label={trans('events.name')}
                                    placeholder={trans('events.name')}
                                    helperText={errors?.name?.message}
                                    error={!!errors?.name}
                                    {...field}
                                />
                            )}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ maxWidth: '500px' }}>
                        <Controller
                            control={control}
                            name="description"
                            render={({ field }) => (
                                <TextInput
                                    multiline
                                    rows={4}
                                    disabled={formDisabled}
                                    label={trans('events.description')}
                                    placeholder={trans('events.description')}
                                    helperText={errors?.description?.message}
                                    error={!!errors?.description}
                                    {...field}
                                />
                            )}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    mt: 5,
                }}
            >
                <CtaButton variant="outlined" type="button" disabled={isLoading} onClick={onCancelPress}>
                    {trans('global.cancel')}
                </CtaButton>
                <CtaButton disabled={formDisabled} variant="contained" type="submit" loading={isLoading}>
                    {trans('global.saveChanges')}
                </CtaButton>
            </Box>
        </form>
    );
};

export default EventForm;
