import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const useTesterGroupForm = () => {
    const [isLoadingFetchOptions, setIsLoadingFetchOptions] = useState(false);
    const [entitiesOptions, setEntitiesOptions] = useState([]);
    const [attributesOptions, setAttributesOptions] = useState([]);

    const fetchEntitiesFromDataSource = async (dataSourceId) => {
        try {
            setIsLoadingFetchOptions(true);
            const res = await axios.get(route('ExternalDataSource.getEntities', { id: dataSourceId }));
            const entities = res.data.data.entities.map((e) => ({
                _id: e,
                label: e,
                value: e,
            }));
            setEntitiesOptions(entities ? entities : []);
        } catch (e) {
            console.log(e);
            let message = 'Error';
            if (e?.response?.data?.message) {
                message = e?.response?.data?.message;
            }
            toast.error(message);
        } finally {
            setIsLoadingFetchOptions(false);
        }
    };

    const fetchAttributesFromEntity = async (dataSourceId, entity) => {
        try {
            setIsLoadingFetchOptions(true);
            const res = await axios.get(route('ExternalDataSource.getAttributes', { id: dataSourceId, entity }));
            const attributes = res.data.data.attributes.map((a) => ({
                _id: a,
                label: a,
                value: a,
            }));
            setAttributesOptions(attributes ? attributes : []);
        } catch (e) {
            let message = 'Error';
            if (e?.response?.data?.message) {
                message = e?.response?.data?.message;
            }
            toast.error(message);
        } finally {
            setIsLoadingFetchOptions(false);
        }
    };
    return {
        fetchEntitiesFromDataSource,
        fetchAttributesFromEntity,
        isLoadingFetchOptions,
        entitiesOptions,
        attributesOptions,
    };
};

export default useTesterGroupForm;
