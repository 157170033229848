import React, { useCallback, useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND, SELECTION_CHANGE_COMMAND } from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { Box, Button, Typography } from '@mui/material';

const LowPriority = 1;

const LexicalToolbarPlugin = React.forwardRef((props, ref) => {
    const [editor] = useLexicalComposerContext();
    const [isBold, setIsBold] = useState(false);

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            // Update text format
            setIsBold(selection.hasFormat('bold'));
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, newEditor) => {
                    updateToolbar();
                    return false;
                },
                LowPriority,
            ),
        );
    }, [editor, updateToolbar]);

    return (
        <Box
            ref={ref}
            className={'lex-toolbar'}
            sx={{
                border: (theme) => `1px solid ${theme.palette.text.primary}`,
                borderLeftWidth: '0px',
                borderTopWidth: '0px',
                borderRightWidth: '0px',
            }}
        >
            <Button
                className={'lex-btn-bold'}
                sx={{ color: 'black' }}
                variant="text"
                onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
                aria-label="Format Bold"
            >
                <Typography sx={{ fontWeight: isBold ? '900' : 'regular' }}>B</Typography>
            </Button>
        </Box>
    );
});

export default LexicalToolbarPlugin;
