import React, { useMemo, useRef, useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { trans } from 'matice';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import { Step, StepLabel, Stepper } from '@mui/material';
import ConfirmCancelModal from '~js/components/ConfirmCancelModal';
import SuccessModal from '~js/components/SuccessModal';
import useConfirmReload from '~js/hooks/useConfirmReload';
import { toast } from 'react-toastify';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';
import {
    QuizStep0General,
    QuizStep1Introduction,
    QuizStep2Challenge,
    QuizStep3Closure,
    QuizStep4Administration,
} from '~js/pages/backoffice/Tools/Quiz/components/FormSteps';
import { calculateTotalPoints } from '~js/pages/backoffice/Tools/Quiz/utils';
import { QUIZ_TYPOLOGIES_WITH_CHALLENGE } from '~js/constants/quiz';

const breadcrumbs: BreadcrumbList = [
    {
        routeName: 'Quiz.create',
        routeLabel: 'Crea Quiz',
    },
    {
        routeName: 'Quiz.index',
        routeLabel: 'Quiz',
    },
];

const QuizCreate = ({ clusters, events, testerGroups, advertising, defaultValueDuplicateQuiz = null }) => {
    useConfirmReload();

    const [activeStep, setActiveStep] = useState(0);
    const [showExitModal, setShowExitModal] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const formData = useRef(
        defaultValueDuplicateQuiz ??
            {
                //...QUIZ_CLASSIC_SCHEMA_EXAMPLE,
                //...QUIZ_TIME_SCHEMA_EXAMPLE,
                //...QUIZ_DIFFERENCE_SCHEMA_EXAMPLE,
                //...QUIZ_MEMORY_SCHEMA_EXAMPLE,
                //...QUIZ_DRAG_AND_WIN_SCHEMA_EXAMPLE,
            },
    );
    const stepperRef = useRef();
    const createdQuizId = useRef();

    const steps = useMemo(() => {
        return [
            trans('quiz.steps.general'),
            trans('quiz.steps.introduction'),
            QUIZ_TYPOLOGIES_WITH_CHALLENGE.includes(formData.current?.general?.typology)
                ? trans('quiz.steps.challenge')
                : trans('quiz.steps.questions'),
            trans('quiz.steps.closure'),
            trans('quiz.steps.administration'),
            //trans("quiz.steps.communications")
        ];
    }, [formData.current?.general?.typology]);

    const handleStepNext = (stepData) => {
        console.log({ stepData });
        formData.current = { ...formData.current, ...stepData };
        if (activeStep === steps.length - 1) {
            const { general, introduction, quizQuestions, closure, administration, communication } = formData.current;
            const adminCopy = { ...administration };

            try {
                let dataToSubmit = {
                    quizQuestions,
                    introduction,
                    closure,
                    audience: {
                        active: administration.audience.active,
                        ...(administration.audience.active
                            ? {
                                  definition: administration.audience.clustersGroups.map((cg) => ({
                                      [cg.operatorWithPrevious]: {
                                          [cg.operatorInsideGroup]: cg.clusters,
                                      },
                                  })),
                              }
                            : {}),
                    },
                };
                delete administration.audience.clustersGroups;
                delete administration.selectedAudience;

                dataToSubmit['general'] = {
                    ...general,
                    ...administration,
                    ...communication,
                };
                createQuizSchema(dataToSubmit);
            } catch (e) {
                formData.current.administration = adminCopy;
            }
            return;
        }

        setActiveStep((val) => val + 1);
        stepperRef.current.scrollIntoView();
    };

    const handleStepPrevious = () => {
        if (activeStep === 0) {
            setShowExitModal(true);
            return;
        }
        setActiveStep((val) => val - 1);
        stepperRef.current.scrollIntoView();
    };

    const goToQuizListPage = () => router.get(route('Quiz.index'));
    const goToQuizSummary = () => router.get(route('Quiz.edit', createdQuizId.current));

    const createQuizSchema = (data) => {
        router.post(route('Quiz.store'), data, {
            onStart: () => setIsLoading(true),
            onFinish: () => setIsLoading(false),
            onSuccess: (data) => {
                setShowModalSuccess(true);
                createdQuizId.current = data?.props?.createdQuizId;
            },
            onError: (e) => {
                console.log(e);
                if (!!e?.generic) {
                    toast.error(e.generic);
                } else {
                    toast.error(trans('quiz.errorOnCreateQuiz'));
                }
                throw e;
            },
        });
    };

    const totalPoints = useMemo(() => calculateTotalPoints(formData.current), [formData.current]);

    return (
        <>
            <Head title={trans('quiz.createNewQuiz')} />

            <QuizContext.Provider
                value={{
                    isEditMode: false,
                    currentClientQuizStatus: 'draft',
                    formDisabled: false,
                    quizTypology: formData.current?.general?.typology,
                    quizTitle: formData.current?.introduction?.name,
                    quizStartDate: formData.current?.general?.startDate,
                    quizEndDate: formData.current?.general?.endDate,
                    quizQuestionsCount: formData.current?.quizQuestions?.questions?.length ?? 4,
                    quizTimePointsPerResponse: formData.current?.introduction?.time?.responsePoints,
                    quizDragCorrectProducts: formData.current?.introduction?.followUp?.correctProducts,
                }}
            >
                <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                    <Stepper ref={stepperRef} activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    {activeStep === 0 && (
                        <QuizStep0General
                            handleStepNext={handleStepNext}
                            handleStepPrevious={handleStepPrevious}
                            stepFormValue={formData.current?.general}
                            advertising={advertising}
                        />
                    )}

                    {activeStep === 1 && (
                        <QuizStep1Introduction
                            handleStepNext={handleStepNext}
                            handleStepPrevious={handleStepPrevious}
                            mode="introduction"
                            stepFormValue={formData.current?.introduction}
                            points={totalPoints}
                        />
                    )}

                    {activeStep === 2 && (
                        <QuizStep2Challenge
                            handleStepNext={handleStepNext}
                            handleStepPrevious={handleStepPrevious}
                            stepFormValue={formData.current?.quizQuestions}
                            typology={formData.current?.general?.typology}
                        />
                    )}

                    {activeStep === 3 && (
                        <QuizStep3Closure
                            handleStepNext={handleStepNext}
                            handleStepPrevious={handleStepPrevious}
                            stepFormValue={formData.current?.closure}
                            points={totalPoints}
                        />
                    )}

                    {activeStep === 4 && (
                        <QuizStep4Administration
                            handleStepNext={handleStepNext}
                            handleStepPrevious={handleStepPrevious}
                            isLoading={isLoading}
                            stepFormValue={formData.current?.administration}
                            clusters={clusters}
                            events={events}
                            testerGroups={testerGroups}
                        />
                    )}

                    {/*activeStep === 5 && (
                            <NewQuizCommunicationsStep
                                handleStepNext={handleStepNext}
                                handleStepPrevious={handleStepPrevious}
                                isLoading={isLoading}
                                stepFormValue={formData.current?.communication}
                            />
                        )*/}

                    <ConfirmCancelModal
                        show={showExitModal}
                        title={trans('global.exit')}
                        description={trans('quiz.exitQuiz')}
                        onClose={() => setShowExitModal(false)}
                        onConfirm={goToQuizListPage}
                    />
                    <SuccessModal
                        open={showModalSuccess}
                        title={trans('global.compliments')}
                        description={trans('quiz.quizCreatedSuccess')}
                        buttonText={trans('quiz.goToSummary')}
                        onPressButton={goToQuizSummary}
                        onExitPress={() => setShowModalSuccess(false)}
                    />
                </ToolContentPageLayout>
            </QuizContext.Provider>
        </>
    );
};
export default QuizCreate;
