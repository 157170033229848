import React, { useRef, useState } from 'react';
import { router } from '@inertiajs/react';
import { DataGrid, itIT } from '@mui/x-data-grid';
import { trans } from 'matice';
import { Box, IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ConfirmCancelModal from '~js/components/ConfirmCancelModal';
import useDateFormat from '~js/hooks/useDateFormat';
import { dateSortComparator } from '~js/utils/dateComparator';

const ExternalDataSourceTable = ({ externalDataSources, deleteExternalDataSource, canReadToolIds = false }) => {
    const { formatDate } = useDateFormat();

    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const externalDataSourceIdToDelete = useRef(null);

    const deleteExternalDataSourceHandler = async () => {
        setLoadingDelete(true);
        try {
            await deleteExternalDataSource(externalDataSourceIdToDelete.current);
            onCloseModalDeleteHandler();
        } finally {
            setLoadingDelete(false);
        }
    };

    const onCloseModalDeleteHandler = () => {
        setShowDeleteConfirmModal(false);
        externalDataSourceIdToDelete.current = null;
    };

    const onEditPress = (externalDataSourceIdToEdit) => {
        router.get(route('ExternalDataSource.edit', externalDataSourceIdToEdit));
    };

    const renderCellAction = (params) => {
        const { row } = params;
        const onDeletePress = () => {
            externalDataSourceIdToDelete.current = params.id;
            setShowDeleteConfirmModal(true);
        };

        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <IconButton onClick={() => onEditPress(params.id)} disabled={!row.can.update}>
                    <EditOutlinedIcon />
                </IconButton>
                <IconButton onClick={onDeletePress} disabled={!row.can.delete}>
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
            </Box>
        );
    };

    const columns = [
        {
            field: 'name',
            headerName: trans('surveyToolSettings.connectionName'),
            flex: 1,
        },
        {
            field: 'driver',
            headerName: trans('surveyToolSettings.driver'),
            flex: 1,
        },
        {
            field: 'created_at',
            headerName: trans('surveyToolSettings.createdDate'),
            flex: 1,
            valueGetter: ({ row }) => formatDate(row.created_at, 'P'),
            sortComparator: (d1, d2) => dateSortComparator(d1, d2, 'dd/MM/yyyy'),
        },
        {
            headerName: ' ',
            field: ' ',
            flex: 0.5,
            sortable: false,
            renderCell: renderCellAction,
            minWidth: 80,
        },
    ];

    return (
        <>
            {externalDataSources.length > 0 && (
                <DataGrid
                    rows={externalDataSources}
                    columns={[
                        ...(canReadToolIds
                            ? [
                                  {
                                      field: '_id',
                                      headerName: 'ID',
                                      flex: 1,
                                  },
                              ]
                            : []),
                        ...columns,
                    ]}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    autoHeight
                    rowLength={5}
                    maxColumns={6}
                    localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                    getRowId={(r) => r._id}
                    // onRowClick={r => goToSummaryPage(r.id)}
                />
            )}
            <ConfirmCancelModal
                iconType="delete"
                show={showDeleteConfirmModal}
                onClose={onCloseModalDeleteHandler}
                title={trans('surveyToolSettings.deleteConnection')}
                description={trans('surveyToolSettings.deleteConnectionDescription')}
                onConfirm={deleteExternalDataSourceHandler}
                loading={loadingDelete}
            />
        </>
    );
};

export default ExternalDataSourceTable;
