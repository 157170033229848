import React, { useContext, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import { CheckboxInput, SelectInput } from '~js/components/input';
import QuizContext, { QuizAudienceContext } from '~js/pages/backoffice/Tools/Quiz/context';
import { clientQuizStatus } from '~js/constants/missionStatuses';
import CustomTooltip from '~js/components/CustomTooltip';
import InfoIcon from '@mui/icons-material/Info';

const ExposureForm = () => {
    const { currentClientQuizStatus, formDisabled, quizStartDate, quizEndDate } = useContext(QuizContext);
    const { events, audienceEnabled } = useContext(QuizAudienceContext);

    const {
        control,
        formState: { errors },
        watch,
        setValue,
        getValues,
    } = useFormContext();

    const eventsOptions = [
        {
            _id: 0,
            label: trans('quiz.eventSelectPlaceholder'),
            value: 'none',
        },
        ...events.map((e) => ({
            _id: e._id,
            label: e.name,
            value: e._id,
        })),
    ];

    useEffect(() => {
        if (audienceEnabled) {
            setValue('silentActivation', false);
        }
    }, [audienceEnabled]);

    return (
        <Box width="100%" mt={4}>
            <Box
                width="100%"
                sx={{
                    border: '1px solid #dfdfe4',
                    borderRadius: 1,
                    bgcolor: 'white',
                }}
            >
                <Box p={3}>
                    <Typography width="100%" mb={2} fontWeight={'bold'}>
                        {trans('quiz.exposureSettings')}
                    </Typography>

                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box maxWidth="500px">
                                <Controller
                                    control={control}
                                    name="isEventQuiz"
                                    shouldUnregister
                                    render={({ field }) => (
                                        <CheckboxInput
                                            disabled={
                                                formDisabled ||
                                                !audienceEnabled ||
                                                currentClientQuizStatus === clientQuizStatus.prodActive ||
                                                currentClientQuizStatus === clientQuizStatus.prodNotActive
                                            }
                                            label={trans('quiz.eventQuiz')}
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                if (!!e.target.checked) {
                                                    setValue('realTimeActivation', false);
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                        {watch('isEventQuiz') && (
                            <Grid item xs={12} sm={12} md={6}>
                                <Box maxWidth="500px">
                                    <Controller
                                        control={control}
                                        name={`event`}
                                        rules={{
                                            required: trans('formValidation.requiredField'),
                                            validate: (value) => {
                                                if (value === 'none') {
                                                    return trans('quiz.eventRequired');
                                                }
                                                return true;
                                            },
                                        }}
                                        shouldUnregister
                                        render={({ field }) => (
                                            <SelectInput
                                                disabled={formDisabled}
                                                label={trans('events.events')}
                                                selectOptions={eventsOptions}
                                                helperText={errors?.event?.message}
                                                error={!!errors?.event}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                        )}
                    </Grid>

                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box maxWidth="500px" display="flex" alignItems="center">
                                <Controller
                                    control={control}
                                    name="realTimeActivation"
                                    shouldUnregister
                                    render={({ field }) => (
                                        <CheckboxInput
                                            disabled={
                                                formDisabled ||
                                                !audienceEnabled ||
                                                (!!watch('isEventQuiz') &&
                                                    (currentClientQuizStatus === clientQuizStatus.prodActive ||
                                                        currentClientQuizStatus === clientQuizStatus.prodNotActive))
                                            }
                                            label={trans('quiz.continuousActivation')}
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                if (!!e.target.checked) {
                                                    setValue('isEventQuiz', false);
                                                    setValue('silentActivation', false);
                                                }
                                            }}
                                            tooltipText={
                                                !!watch('withExclusions') &&
                                                trans('quiz.realTimeActivationDisabledTooltip')
                                            }
                                        />
                                    )}
                                />

                                <CustomTooltip
                                    title={trans('quiz.continuousActivationDescription')}
                                    placement="bottom-start"
                                    sx={{ zIndex: 99 }}
                                >
                                    <InfoIcon sx={{ color: '#828282', zIndex: 99 }} />
                                </CustomTooltip>
                            </Box>

                            <Box maxWidth="500px" display="flex" alignItems="center">
                                <Controller
                                    control={control}
                                    name="silentActivation"
                                    shouldUnregister
                                    render={({ field }) => (
                                        <CheckboxInput
                                            disabled={
                                                formDisabled ||
                                                audienceEnabled ||
                                                (!!watch('isEventQuiz') &&
                                                    (currentClientQuizStatus === clientQuizStatus.prodActive ||
                                                        currentClientQuizStatus === clientQuizStatus.prodNotActive))
                                            }
                                            label={trans('quiz.silentActivation')}
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                if (!!e.target.checked) {
                                                    setValue('isEventQuiz', false);
                                                    setValue('realTimeActivation', false);
                                                }
                                            }}
                                            tooltipText={
                                                (audienceEnabled || !!watch('realTimeActivation')) &&
                                                trans('quiz.silentActivationDisabledTooltip')
                                            }
                                        />
                                    )}
                                />

                                <CustomTooltip
                                    title={trans('quiz.silentActivationDescription')}
                                    placement="bottom-start"
                                    sx={{ zIndex: 99 }}
                                >
                                    <InfoIcon sx={{ color: '#828282', zIndex: 99 }} />
                                </CustomTooltip>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default ExposureForm;
