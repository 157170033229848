import React from 'react';
import { Box, IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { trans } from 'matice';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DataGrid, itIT } from '@mui/x-data-grid';

const ToolsUsersTable = ({ toolsUsers, updateToolUser, deleteToolUser, canUpdate, canDelete }) => {
    const renderCellAction = ({ row: user }) => (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
            }}
        >
            <IconButton onClick={() => updateToolUser(user)} disabled={!canUpdate}>
                <EditOutlinedIcon />
            </IconButton>
            <IconButton onClick={() => deleteToolUser(user)} disabled={!canDelete}>
                <DeleteOutlineOutlinedIcon />
            </IconButton>
        </Box>
    );

    const columns = [
        {
            field: 'email',
            headerName: trans('settings.tools-users.fields.participant'),
            flex: 1,
        },
        {
            field: 'name',
            headerName: trans('settings.tools-users.fields.name'),
            flex: 1,
            valueGetter: ({ row }) => `${row.name} ${row.surname}`,
        },
        {
            field: 'roleNames',
            headerName: trans('settings.tools-users.fields.role'),
            flex: 1,
        },
        {
            headerName: ' ',
            field: ' ',
            flex: 0.5,
            sortable: false,
            renderCell: renderCellAction,
            minWidth: 80,
        },
    ];

    return (
        <>
            {/*
            <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                {trans('surveyToolSettings.addedEmailsList')}
            </Typography>
            */}

            {toolsUsers.length > 0 && (
                <DataGrid
                    rows={toolsUsers}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    autoHeight
                    rowLength={5}
                    maxColumns={6}
                    localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                    getRowId={(r) => r.email}
                    // onRowClick={r => goToSummaryPage(r.id)}
                />
            )}
        </>
    );
};

export default ToolsUsersTable;
