import React, { useState } from 'react';
import CtaButton from '~js/components/CtaButton';
import TextInput from '~js/components/TextInput';
import CustomModal from '~js/components/CustomModal';
import { trans } from 'matice';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Box, IconButton, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

const EditPasswordModal = ({ show, onClose, changePassword }) => {
    const [loading, setLoading] = useState(false);

    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
        setError,
    } = useForm({});

    const onSubmit = async (data) => {
        //console.log(data);
        const { currentPassword, newPassword } = data;
        try {
            setLoading(true);
            await changePassword(currentPassword, newPassword);
        } catch (e) {
            if (!!e?.generic) {
                return;
            }
            Object.keys(e).forEach((key) => {
                setError(key, { type: 'custom', message: e[key] });
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <CustomModal open={show} handleClose={onClose} sx={{ maxWidth: '600px', borderRadius: 3, p: 0 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItem: 'center',
                        justifyContent: 'center',
                        p: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            mb: 1,
                        }}
                    >
                        <IconButton onClick={onClose}>
                            <CancelIcon fontSize="large" color="primary" />
                        </IconButton>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                        <Box
                            sx={{
                                bgcolor: (theme) => theme.palette.primary.main,
                                width: 30,
                                height: 30,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 500,
                                p: 2.5,
                            }}
                        >
                            <SaveAsIcon sx={{ color: 'white' }} />
                        </Box>
                    </Box>
                    <Box mx={2}>
                        <Typography variant="h5" sx={{ fontWeight: 700, textAlign: 'center' }}>
                            {trans('surveyToolSettings.editPassword')}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={3}>
                        <Box width="100%" maxWidth="400px">
                            <Controller
                                control={control}
                                name="currentPassword"
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                }}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextInput
                                        type="password"
                                        placeholder={trans('surveyToolSettings.insertCurrentPassword')}
                                        helperText={errors?.currentPassword?.message}
                                        error={!!errors?.currentPassword}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="newPassword"
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                }}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextInput
                                        type="password"
                                        placeholder={trans('surveyToolSettings.insertNewPassword')}
                                        helperText={errors?.newPassword?.message}
                                        error={!!errors?.newPassword}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="confirmNewPassword"
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                    validate: (val) =>
                                        watch('newPassword') === val || trans('formValidation.passwordMustMatch'),
                                }}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextInput
                                        type="password"
                                        placeholder={trans('surveyToolSettings.confirmNewPassword')}
                                        helperText={errors?.confirmNewPassword?.message}
                                        error={!!errors?.confirmNewPassword}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            mb: 5,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            flexWrap: 'wrap',
                            mt: 5,
                            gap: 2,
                        }}
                    >
                        <CtaButton onClick={onClose} sx={{ px: 6 }} variant="outlined" disabled={loading}>
                            {trans('global.cancel')}
                        </CtaButton>

                        <CtaButton sx={{ px: 6 }} variant="contained" loading={loading} type="submit">
                            {trans('global.confirm')}
                        </CtaButton>
                    </Box>
                </Box>
            </form>
        </CustomModal>
    );
};

export default EditPasswordModal;
