import React, { useContext, useState } from 'react';
import { Box } from '@mui/material';
import { trans } from 'matice';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CtaButton from '~js/components/CtaButton';
import { clientQuizStatus } from '~js/constants/missionStatuses';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import NewQuizActionButtons from '~js/pages/backoffice/Tools/Quiz/components/NewQuizActionButtons';
import ClassicQuestionForm from '~js/pages/backoffice/Tools/Quiz/components/FormChallenges/ClassicQuestionForm';

export const QUIZ_CLASSIC_MIN_QUESTION = 1;
export const DEFAULT_QUIZ_CLASSIC_QUESTION_DATA = {
    question: '',
    positiveAnswer: '',
    negativeAnswer: '',
    image: '',
    points: '',
};
const ClassicQuestionsForm = ({ handleStepNext, handleStepPrevious, stepFormValue, isLoading = false }) => {
    const { isEditMode, formDisabled, currentClientQuiz } = useContext(QuizContext);

    const formMethods = useForm({
        //shouldUnregister: true,
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: !!stepFormValue
            ? stepFormValue
            : {
                  questions: Array(QUIZ_CLASSIC_MIN_QUESTION).fill(DEFAULT_QUIZ_CLASSIC_QUESTION_DATA),
              },
    });

    const {
        handleSubmit,
        control,
        trigger,
        formState: { errors },
        getValues,
    } = formMethods;

    console.debug({ ERRORS: errors, VALUES: getValues() });

    const { fields, append, remove, insert, move } = useFieldArray({
        name: 'questions',
        control,
        shouldUnregister: true,
    });

    const [currentFocusedQuestion, setCurrentFocusedQuestion] = useState(0);

    const moveQuestion = (fromIndex, toIndex) => {
        if (toIndex <= fields.length - 1 && toIndex >= 0) {
            const fromIndexQ = getValues(`questions.${fromIndex}`);
            move(fromIndex, toIndex);

            //workaround per evitare errore di validazione non pertinenti alla domanda spostata
            remove(toIndex);
            setTimeout(() => {
                insert(toIndex, fromIndexQ);
            }, 0);
        }
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        moveQuestion(result.source.index, result.destination.index);
    };

    const appendQuestion = () => {
        trigger().then((formIsValid) => {
            if (formIsValid) {
                append({});
                setCurrentFocusedQuestion(() => getValues().questions.length - 1);
            }
        });
    };

    const onSubmit = (data) => handleStepNext({ quizQuestions: { ...data } });

    const nextButtonDisabled = fields.length < QUIZ_CLASSIC_MIN_QUESTION;
    return (
        <FormProvider {...formMethods}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="quizQuestionsDroppable">
                        {(provided) => (
                            <Box {...provided.droppableProps} ref={provided.innerRef}>
                                {fields.map((question, index) => (
                                    <Draggable
                                        key={question.id}
                                        draggableId={question.id}
                                        index={index}
                                        isDragDisabled={
                                            formDisabled ||
                                            currentClientQuiz === clientQuizStatus.prodActive ||
                                            currentClientQuiz === clientQuizStatus.prodNotActive
                                        }
                                    >
                                        {(provided) => (
                                            <Box
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                mt={6}
                                            >
                                                <ClassicQuestionForm
                                                    questionIndex={index}
                                                    setCurrentFocusedQuestion={setCurrentFocusedQuestion}
                                                    currentFocusedQuestion={currentFocusedQuestion}
                                                    deleteQuestion={() => remove(index)}
                                                    deleteButtonDisabled={fields.length === 1}
                                                    //duplicateQuestion={() => duplicateQuestion(index)}
                                                    //onArrowUpPress={() => moveQuestion(index, index - 1)}
                                                    //onArrowDownPress={() => moveQuestion(index, index + 1)}
                                                />
                                            </Box>
                                        )}
                                    </Draggable>
                                ))}

                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>

                {!formDisabled &&
                    currentClientQuiz !== clientQuizStatus.prodActive &&
                    currentClientQuiz !== clientQuizStatus.prodNotActive && (
                        <Box width="100%" display="flex" justifyContent="center">
                            <CtaButton sx={{ mt: 2 }} onClick={appendQuestion}>
                                {trans('quiz.addQuestion')}
                            </CtaButton>
                        </Box>
                    )}
                {!formDisabled && (
                    <NewQuizActionButtons
                        isLoading={isLoading}
                        onLeftButtonClick={handleStepPrevious}
                        rightButtonText={isEditMode ? trans('global.save') : trans('global.next')}
                        leftButtonText={trans('global.back')}
                        rightDisabled={nextButtonDisabled}
                        rightTooltip={
                            nextButtonDisabled
                                ? trans('quiz.minQuestionButtonDisabled', {
                                      args: { minQuestion: QUIZ_CLASSIC_MIN_QUESTION },
                                  })
                                : null
                        }
                    />
                )}
            </Box>
        </FormProvider>
    );
};

export default ClassicQuestionsForm;
