import './styles.css';
import React, { useEffect, useRef, useState } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import LexicalToolbarPlugin from '~js/pages/backoffice/Tools/Quiz/components/LexicalEditor/LexicalToolbar';
import { Box, FormControl, FormHelperText, InputLabel, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import CustomTooltip from '~js/components/CustomTooltip';
import InfoIcon from '@mui/icons-material/Info';
import { MaxLengthPlugin } from '~js/pages/backoffice/Tools/Quiz/components/LexicalEditor/MaxLengthPlugin';
import { RootNode } from 'lexical';

// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
function MyCustomAutoFocusPlugin() {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        // Focus the editor when the effect fires!
        editor.focus();
    }, [editor]);

    return null;
}

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error) {
    console.error(error);
}

const EditorCapturePlugin = React.forwardRef((props: any, ref: any) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        ref.current = editor;
        return () => {
            ref.current = null;
        };
    }, [editor, ref]);

    return null;
});

const LexicalEditor = React.forwardRef(
    (
        { label = 'Enter some text...', onChange, value, error, helperText, tooltipText = null, inputProps = {} },
        ref,
    ) => {
        const initialConfig = {
            namespace: 'MyEditor',
            //theme,
            onError,
            editorState: value ?? null,
        };
        const editorRef = useRef(null);
        const [editorState, setEditorState] = useState();
        const [currentLength, setCurrentLength] = useState(0);
        const theme = useTheme();

        function onChangeHandler(editorState) {
            // Call toJSON on the EditorState object, which produces a serialization safe string
            const editorStateJSON = editorState.toJSON();
            // However, we still have a JavaScript object, so we need to convert it to an actual string with JSON.stringify
            setEditorState(JSON.stringify(editorStateJSON));
            onChange(JSON.stringify(editorStateJSON));
        }
        useEffect(() => {
            return editorRef.current?.registerNodeTransform(RootNode, (rootNode: RootNode) => {
                const textContentSize = rootNode.getTextContentSize();
                setCurrentLength(textContentSize);
            });
        }, [editorRef.current]);

        const containerRef = useRef(null);
        const toolbarRef = useRef(null);
        return (
            <Box my="12px">
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <InputLabel
                        shrink
                        sx={{
                            color: error ? '#F9473C' : '#5E6366',
                            pl: 1,
                            '&.Mui-focused': { color: error ? '#F9473C' : '#5E6366 !important' },
                        }}
                    >
                        {label ?? ''}
                    </InputLabel>
                    {tooltipText && (
                        <CustomTooltip title={tooltipText} placement="bottom-start" sx={{ zIndex: 99 }}>
                            <InfoIcon sx={{ color: '#828282', zIndex: 99 }} />
                        </CustomTooltip>
                    )}
                </Box>
                <FormControl variant="standard" fullWidth error={error}>
                    <Box
                        component="div"
                        ref={containerRef}
                        sx={{
                            border: '1px solid #5E6366',
                            borderRadius: 1,
                            overflow: 'hidden',
                            minHeight: '100px',
                            //borderColor: (theme) => error ? `${theme.palette.error.main} !important` : "#5E6366 !important",
                            boxShadow: (theme) =>
                                error ? `${alpha(theme.palette.error.main, 0.5)} 0 0 0 0.2rem !important` : 'unset',
                        }}
                    >
                        <LexicalComposer initialConfig={initialConfig}>
                            <div className="editor-toolbar-container">
                                <LexicalToolbarPlugin ref={toolbarRef} />
                            </div>

                            <div className="editor-inner">
                                <RichTextPlugin
                                    contentEditable={
                                        <ContentEditable
                                            onFocus={() => {
                                                containerRef.current.style.boxShadow = `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`;
                                                containerRef.current.style.borderColor = theme.palette.primary.main;
                                            }}
                                            onBlur={() => {
                                                containerRef.current.style.boxShadow = 'unset';
                                                containerRef.current.style.borderColor = 'unset';
                                            }}
                                            className={'editor-editable'}
                                        />
                                    }
                                    placeholder={''}
                                    ErrorBoundary={LexicalErrorBoundary}
                                />
                            </div>
                            <EditorCapturePlugin ref={ref} />
                            <EditorRefPlugin editorRef={editorRef} />
                            <OnChangePlugin onChange={onChangeHandler} />
                            {inputProps?.maxLength && <MaxLengthPlugin maxLength={inputProps.maxLength} />}
                        </LexicalComposer>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'}>
                        <FormHelperText sx={{ width: '80%' }}>{helperText ? helperText : ' '}</FormHelperText>
                        <FormHelperText sx={{ width: '20%', textAlign: 'end' }}>
                            {inputProps?.maxLength ? `${currentLength || 0} / ${inputProps.maxLength}` : ''}
                        </FormHelperText>
                    </Box>
                </FormControl>
            </Box>
        );
    },
);

export default LexicalEditor;

export function parseJsonToTextMap(jsonData) {
    const resultArray = [];

    function parseNode(node) {
        if (node.type === 'text') {
            resultArray.push({
                text: node.text,
                type: node.format === 1 ? 'bold' : 'normal',
                textStyle: {},
            });
        } else if (node.type === 'linebreak') {
            resultArray.push({
                type: 'linebreak',
            });
        }

        if (node.children && node.children.length > 0) {
            for (const childNode of node.children) {
                parseNode(childNode);
            }
        }
        if (node.type === 'paragraph') {
            resultArray.push({
                type: 'linebreak',
            });
        }
    }

    if (jsonData?.root && jsonData.root.children && jsonData.root.children.length > 0) {
        for (const childNode of jsonData.root.children) {
            parseNode(childNode);
        }
    }

    return resultArray;
}

export function parseTextMapToMuiText(textMap) {
    return textMap.map((node, index) => {
        if (node.type === 'normal') {
            return <span key={index}>{node.text}</span>;
        } else if (node.type === 'bold') {
            return <b key={index}>{node.text}</b>;
        } else if (node.type === 'linebreak') {
            return <br key={index} />;
        }

        return null;
    });
}
