import React, { useRef, useState } from 'react';
import { router } from '@inertiajs/react';
import { DataGrid, itIT } from '@mui/x-data-grid';
import { Box, IconButton, Tooltip } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { trans } from 'matice';
import useDateFormat from '~js/hooks/useDateFormat';
import ConfirmCancelModal from '~js/components/ConfirmCancelModal';
import SurveyStatusSelectInput from '~js/pages/backoffice/Tools/Survey/components/SurveyStatusSelectInput';
import { dateSortComparator } from '~js/utils/dateComparator';

const SurveyTable = ({
    surveys,
    deleteSurvey,
    changeSurveyStatus,
    testerGroups,
    canReadToolIds = false,
    canUpdate,
    canDelete,
    canArchive,
    canDuplicate,
}) => {
    const { formatDate } = useDateFormat();

    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [showArchiveConfirmModal, setShowArchiveConfirmModal] = useState(false);
    const [showUnArchiveConfirmModal, setShowUnArchiveConfirmModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const surveyIdToDelete = useRef(null);
    const surveyIdToArchive = useRef(null);
    const surveyIdToUnArchive = useRef(null);

    const deleteSurveyHandler = async () => {
        setLoading(true);
        try {
            await deleteSurvey(surveyIdToDelete.current);
            onCloseModalDeleteHandler();
        } finally {
            setLoading(false);
        }
    };

    const archiveSurveyHandler = async () => {
        setLoading(true);
        try {
            await changeSurveyStatus(surveyIdToArchive.current, 'archive');
            onCloseModalArchiveHandler();
        } finally {
            setLoading(false);
        }
    };

    const unArchiveSurveyHandler = async () => {
        setLoading(true);
        try {
            await changeSurveyStatus(surveyIdToUnArchive.current, 'restore');
            onCloseModalUnArchiveHandler();
        } finally {
            setLoading(false);
        }
    };

    const onEditPress = (idToEdit) => router.get(route('Surveys.edit', idToEdit));

    const onDuplicatePress = (idToDuplicate) => router.get(route('Surveys.duplicate', idToDuplicate));

    const onCloseModalDeleteHandler = () => {
        setShowDeleteConfirmModal(false);
        surveyIdToDelete.current = null;
    };

    const onCloseModalArchiveHandler = () => {
        setShowArchiveConfirmModal(false);
        surveyIdToArchive.current = null;
    };

    const onCloseModalUnArchiveHandler = () => {
        setShowUnArchiveConfirmModal(false);
        surveyIdToUnArchive.current = null;
    };

    const renderCellAction = ({ row }) => {
        const onDeletePress = (e) => {
            e.stopPropagation();
            surveyIdToDelete.current = row._id;
            setShowDeleteConfirmModal(true);
        };

        const onArchivePress = (e) => {
            e.stopPropagation();
            surveyIdToArchive.current = row._id;
            setShowArchiveConfirmModal(true);
        };

        const onUnArchivePress = (e) => {
            e.stopPropagation();
            surveyIdToUnArchive.current = row._id;
            setShowUnArchiveConfirmModal(true);
        };

        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                {!row.general.archived && (
                    <>
                        {canUpdate ? (
                            <Tooltip title={trans('survey.editTooltip')}>
                                <IconButton onClick={() => onEditPress(row._id)} disabled={!canUpdate}>
                                    <EditOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title={trans('survey.openSummaryTooltip')}>
                                <IconButton onClick={() => onEditPress(row._id)}>
                                    <AssignmentOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                )}

                {row.general.archived && (
                    <Tooltip title={trans('survey.openSummaryTooltip')}>
                        <IconButton onClick={() => onEditPress(row._id)}>
                            <AssignmentOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                )}

                <Tooltip title={trans('survey.duplicateSurvey')}>
                    <IconButton onClick={() => onDuplicatePress(row._id)} disabled={!canDuplicate}>
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>

                {row.general.archived && (
                    <Tooltip title={trans('survey.unarchiveTooltip')}>
                        <IconButton onClick={onUnArchivePress} disabled={!canArchive}>
                            <UnarchiveOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                )}

                {!row.general.archived && (
                    <>
                        {row.general.status === 'draft' || row.general.status === 'test' ? (
                            <Tooltip title={trans('survey.deleteTooltip')}>
                                <IconButton onClick={onDeletePress} disabled={!canDelete}>
                                    <DeleteOutlineOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title={trans('survey.archiveTooltip')}>
                                <span>
                                    <IconButton
                                        disabled={row.general.status === 'prod' || !canArchive}
                                        onClick={onArchivePress}
                                    >
                                        <Inventory2OutlinedIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                    </>
                )}
            </Box>
        );
    };

    const renderStatusCell = ({ row }) => {
        return (
            <Box>
                <SurveyStatusSelectInput
                    disabled={!canUpdate}
                    surveyStartDate={row?.general?.startDate}
                    surveyEndDate={row?.general?.endDate}
                    value={row?.general?.status}
                    onChange={(newStatus, testGroups) => changeSurveyStatus(row._id, newStatus, testGroups)}
                    testerGroups={testerGroups}
                    selectedGroupsTest={row?.general?.groupTest}
                />
            </Box>
        );
    };

    const columns = [
        {
            field: 'externalData.rewarding.id',
            headerName: 'ID Rewarding',
            flex: 1,
            valueGetter: ({ row }) => row?.externalData?.rewarding?.id,
        },
        {
            field: 'general.title',
            headerName: trans('cluster.title'),
            flex: 1,
            valueGetter: ({ row }) => row.general.title,
        },
        {
            field: 'general.description',
            headerName: trans('survey.description'),
            flex: 1,
            valueGetter: ({ row }) => row.general.description,
        },
        {
            field: 'general.startDate',
            headerName: trans('survey.dateStart'),
            flex: 1,
            valueGetter: ({ row }) => formatDate(row.general.startDate, 'Pp'),
            sortComparator: (d1, d2) => dateSortComparator(d1, d2),
        },
        {
            field: 'general.endDate',
            headerName: trans('survey.dateEnd'),
            flex: 1,
            valueGetter: ({ row }) => formatDate(row.general.endDate, 'Pp'),
            sortComparator: (d1, d2) => dateSortComparator(d1, d2),
        },
        {
            field: 'general.updated_at',
            headerName: trans('survey.lastEdit'),
            flex: 1,
            valueGetter: ({ row }) => formatDate(row.updated_at, 'Pp'),
            sortComparator: (d1, d2) => dateSortComparator(d1, d2),
        },
        {
            field: 'general.status',
            headerName: trans('survey.status'),
            flex: 1,
            renderCell: renderStatusCell,
        },
        {
            headerName: ' ',
            field: ' ',
            flex: 0.5,
            sortable: false,
            renderCell: renderCellAction,
            minWidth: 150,
        },
    ];

    return (
        <>
            {surveys.length > 0 && (
                <Box
                    sx={{
                        '& .archived': {
                            bgcolor: '#FFF6F4',
                        },
                    }}
                >
                    <DataGrid
                        rows={surveys}
                        columns={[
                            ...(canReadToolIds
                                ? [
                                      {
                                          field: '_id',
                                          headerName: 'ID',
                                          flex: 1,
                                      },
                                  ]
                                : []),
                            ...columns,
                        ]}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        autoHeight
                        rowLength={5}
                        maxColumns={6}
                        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                        getRowId={(r) => r._id}
                        //onRowClick={(r) => onEditPress(r.id)}
                        getRowClassName={(params) => params.row.general.archived && `archived`}
                    />
                </Box>
            )}
            {/*Modal delete survey*/}
            <ConfirmCancelModal
                iconType="delete"
                show={showDeleteConfirmModal}
                onClose={onCloseModalDeleteHandler}
                title={trans('survey.deleteSurveyTitle')}
                description={trans('survey.deleteSurveyDescription')}
                onConfirm={deleteSurveyHandler}
                loading={loading}
            />

            {/*Modal archive survey*/}
            <ConfirmCancelModal
                iconType="archive"
                show={showArchiveConfirmModal}
                onClose={onCloseModalArchiveHandler}
                title={trans('survey.archiveSurveyTitle')}
                description={trans('survey.archiveSurveyDescription')}
                onConfirm={archiveSurveyHandler}
                loading={loading}
            />

            {/*Modal unarchive survey*/}
            <ConfirmCancelModal
                iconType="unarchive"
                show={showUnArchiveConfirmModal}
                onClose={onCloseModalUnArchiveHandler}
                title={trans('survey.unarchiveSurveyTitle')}
                description={trans('survey.unarchiveSurveyDescription')}
                onConfirm={unArchiveSurveyHandler}
                loading={loading}
            />
        </>
    );
};

export default SurveyTable;
