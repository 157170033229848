import React from 'react';
import { Box, IconButton, Modal } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import QuestionPreview from './QuestionPreview';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xl: '70%', lg: '70%', md: '80%', sm: '90%', xs: '95%' },
    maxWidth: '400px',
    bgcolor: 'background.paper',
    //border: '2px solid #000',
    //boxShadow: 24,
    p: 2,
    borderRadius: 1,
    maxHeight: '80%',
    overflow: 'scroll',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
};

const ModalQuestionPreview = ({ open, handleClose, questionData }) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ ...style }}>
                <Box display="flex" width="100%" justifyContent="flex-end" mb={2}>
                    <IconButton sx={{ bgcolor: (theme) => theme.palette.primary.main }} onClick={handleClose}>
                        <ClearIcon sx={{ color: 'white' }} />
                    </IconButton>
                </Box>
                <QuestionPreview questionData={questionData} />
            </Box>
        </Modal>
    );
};

export default ModalQuestionPreview;
