import React, { useRef, useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { Box, Grid, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Controller, useForm } from 'react-hook-form';
import { trans } from 'matice';
import { toast } from 'react-toastify';
import FullPageLoader from '~js/components/FullPageLoader';
import ConfirmCancelModal from '~js/components/ConfirmCancelModal';
import CtaButton from '~js/components/CtaButton';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import EditTesterGroupModal from './components/EditTesterGroupModal';
import TesterGroupEmailInput from './components/TesterGroupEmailInput';
import TesterEmailTable from './components/TesterEmailTable';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';

const TesterGroupsEdit = ({ testerGroup, externalDataSources, can, ...other }) => {
    console.log('TesterGroupsEdit Props', { testerGroup, externalDataSources, can, ...other });

    const {
        formState: { errors },
        control,
        trigger,
    } = useForm({
        defaultValues: {
            userEmails: [],
        },
    });

    const [isLoading, setIsLoading] = useState(false);
    const [showModalDeleteUser, setShowModalDeleteUser] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [shoModalEditGroup, setShoModalEditGroup] = useState(false);
    const [showModalConfirmAddUser, setShowModalConfirmAddUser] = useState(false);
    const emailTesterUserToDelete = useRef(null);
    const emailTesterUserToAdd = useRef(null);

    const deleteTesterUser = () => {
        //route('TesterGroups.removeUserToGroup', {groupId, email})
        router.delete(
            route('TesterGroups.removeUserToGroup', {
                groupId: testerGroup._id,
                email: emailTesterUserToDelete.current,
            }),
            {
                onStart: () => setLoadingDelete(true),
                onFinish: () => setLoadingDelete(false),
                onError: (e) => {
                    if (e.generic) {
                        toast.error(e.generic);
                    }
                },
                onSuccess: () => {
                    toast.success(trans('surveyToolSettings.userDeletedFromGroup'));
                    setShowModalDeleteUser(false);
                    emailTesterUserToDelete.current = null;
                },
            },
        );
    };

    const onCloseModalDeleteHandler = () => {
        setShowModalDeleteUser(false);
        emailTesterUserToDelete.current = null;
    };

    const onCloseModalAddUserHandler = () => {
        setShowModalConfirmAddUser(false);
        emailTesterUserToAdd.current = null;
    };

    const removeEmailFromUserEmails = (objToRemove) => {
        emailTesterUserToDelete.current = objToRemove.email;
        setShowModalDeleteUser(true);
    };

    const addUserToGroupHandler = (email) => {
        emailTesterUserToAdd.current = email;
        setShowModalConfirmAddUser(true);
    };

    const addUserToGroup = () => {
        router.post(
            route('TesterGroups.addUserToGroup', testerGroup._id),
            { emails: [emailTesterUserToAdd.current] },
            {
                onStart: () => setIsLoading(true),
                onFinish: () => setIsLoading(false),
                onError: (e) => {
                    if (e.generic) {
                        toast.error(e.generic);
                    }
                },
                onSuccess: () => {
                    toast.success(trans('surveyToolSettings.userInvitationSent'));
                    onCloseModalAddUserHandler();
                },
            },
        );
    };

    const goToTesterList = () => router.get(route('Settings.index'), { section: 'tester' });

    const onSubmitHandler = async (data) => {
        console.log(data);
    };

    const breadcrumbs: BreadcrumbList = [
        {
            routeName: 'TesterGroups.edit',
            routeLabel: 'Modifica gruppo di test',
            params: {
                id: testerGroup._id,
            },
        },
        {
            routeName: 'Settings.index',
            routeLabel: 'Impostazioni',
        },
    ];
    return (
        <>
            <Head title={trans('surveyToolSettings.tester')} />
            <FullPageLoader isVisible={isLoading} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" mb={4}>
                    <Box>
                        <CtaButton sx={{ px: 0, mb: 2 }} onClick={goToTesterList}>
                            <ArrowBackIcon />{' '}
                            <Typography sx={{ textDecoration: 'underline' }}>
                                {trans('surveyToolSettings.backToTesterList')}
                            </Typography>
                        </CtaButton>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {testerGroup?.name}
                        </Typography>
                    </Box>
                    {can.updateTesterGroupSettings && (
                        <CtaButton onClick={() => setShoModalEditGroup(true)} disabled={!can.updateTesterGroupSettings}>
                            <SettingsIcon sx={{ color: '#C4C4C4', mr: 1 }} />{' '}
                            <Typography
                                sx={{
                                    textDecoration: 'underline',
                                    color: '#45464E',
                                }}
                            >
                                {trans('navBarSurvey.settings')}
                            </Typography>
                        </CtaButton>
                    )}
                </Box>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px">
                            <Controller
                                control={control}
                                name="email"
                                rules={{
                                    maxLength: {
                                        value: 1000,
                                        message: trans('formValidation.fieldTooLong'),
                                    },
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: trans('formValidation.invalidEmail'),
                                    },
                                }}
                                render={({ field }) => (
                                    <TesterGroupEmailInput
                                        disabled={!can.addTesterToGroup}
                                        type="email"
                                        label={trans('surveyToolSettings.addUserTest')}
                                        placeholder={trans('surveyToolSettings.insertEmail')}
                                        errorText={errors?.email?.message}
                                        helperText={trans('surveyToolSettings.insertEmailAndPressEnter')}
                                        error={!!errors?.email}
                                        triggerValidation={() => trigger('email')}
                                        addEmail={(email) => addUserToGroupHandler(email)}
                                        {...field}
                                        ref={null}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <TesterEmailTable
                    emails={testerGroup?.tester}
                    removeEmail={removeEmailFromUserEmails}
                    canDeleteTester={can?.deleteTester}
                />
            </ToolContentPageLayout>
            <ConfirmCancelModal
                iconType="delete"
                show={showModalDeleteUser}
                onClose={onCloseModalDeleteHandler}
                title={trans('surveyToolSettings.deleteUser')}
                description={trans('surveyToolSettings.deleteUserDescription', {
                    args: {
                        email: emailTesterUserToDelete.current,
                        testerGroupName: testerGroup.name,
                    },
                })}
                onConfirm={deleteTesterUser}
                loading={loadingDelete}
            />
            {/*confirm add user modal*/}
            <ConfirmCancelModal
                iconType="user"
                show={showModalConfirmAddUser}
                onClose={onCloseModalAddUserHandler}
                title={trans('surveyToolSettings.confirmAddUserTitle', {
                    args: {
                        email: emailTesterUserToAdd.current,
                    },
                })}
                description={trans('surveyToolSettings.confirmAddUserDescription', {
                    args: {
                        email: emailTesterUserToAdd.current,
                    },
                })}
                onConfirm={addUserToGroup}
                loading={isLoading}
                sxTitle={{ fontSize: '24px' }}
            />
            <EditTesterGroupModal
                open={shoModalEditGroup}
                handleClose={() => setShoModalEditGroup(false)}
                externalDataSourceOption={externalDataSources?.map((ds) => ({
                    _id: ds._id,
                    label: ds.name,
                    value: ds._id,
                }))}
                testerGroupData={testerGroup}
            />
        </>
    );
};

export default TesterGroupsEdit;
