import React from 'react';
import { Box, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import CheckboxInput from '~js/components/CheckboxInput';

const NotificationConfigurationForm = ({
    formDisabled = false,
    isEditMode = false,
    editable = false,
    type = 'survey',
}) => {
    const { control } = useFormContext();

    return (
        <Box
            width="100%"
            sx={{
                border: '1px solid #dfdfe4',
                borderRadius: 1,
                bgcolor: 'white',
            }}
            p={3}
            mt={5}
        >
            <Controller
                control={control}
                name="isNotificationActive"
                shouldUnregister
                defaultValue={false}
                render={({ field }) => (
                    <CheckboxInput
                        disabled={formDisabled || (isEditMode && editable)}
                        label={trans(`${type}.notifications`)}
                        {...field}
                    />
                )}
            />
            <Typography sx={{ color: '#A7A7A7' }}>{trans(`${type}.notificationsAlert`)}</Typography>

            {/*{!!watch('isNotificationActive') && (
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px">
                            <Controller
                                control={control}
                                name="notificationId"
                                shouldUnregister
                                defaultValue=""
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        label="ID"
                                        helperText={errors?.notificationId?.message}
                                        error={!!errors?.notificationId}
                                        {...field}
                                        ref={null}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px">
                            <Controller
                                control={control}
                                name="notificationDeeplink"
                                shouldUnregister
                                defaultValue=""
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        label="Deeplink"
                                        helperText={errors?.notificationDeeplink?.message}
                                        error={!!errors?.notificationDeeplink}
                                        {...field}
                                        ref={null}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
            )}*/}
        </Box>
    );
};

export default NotificationConfigurationForm;
