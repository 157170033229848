import React from 'react';
import { router } from '@inertiajs/react';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, IconButton, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { trans } from 'matice';
import EditTesterGroupForm from './EditTesterGroupForm';
import CustomModal from '~js/components/CustomModal';

const EditTesterGroupModal = ({ open, handleClose, externalDataSourceOption, testerGroupData }) => {
    const updateGroup = async (data) => {
        const promise = new Promise((resolve, reject) =>
            router.patch(
                route('TesterGroups.update', { id: testerGroupData._id }),
                { id: testerGroupData._id, ...data },
                {
                    onError: (e) => {
                        reject(e);
                        if (!!e?.generic) {
                            toast.error(e.generic);
                        }
                    },
                    onSuccess: () => {
                        toast.success(trans('surveyToolSettings.testGroupUpdated'));
                        resolve();
                        handleClose();
                    },
                },
            ),
        );

        await promise;
    };
    return (
        <CustomModal
            open={open}
            onClose={handleClose}
            sx={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: '700px', borderRadius: 2.3 }}
        >
            <Box maxWidth="500px" width="100%">
                <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" mb={4}>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {trans('navBarSurvey.settings')}
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CancelIcon color="primary" fontSize="large" />
                    </IconButton>
                </Box>

                <EditTesterGroupForm
                    externalDataSourceOption={externalDataSourceOption}
                    testerGroupData={testerGroupData}
                    updateGroup={updateGroup}
                    onCancelPress={handleClose}
                />
            </Box>
        </CustomModal>
    );
};

export default EditTesterGroupModal;
