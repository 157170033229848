import { Controller, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import LexicalTextEditor, { parseJsonToTextMap } from '~js/pages/backoffice/Tools/Quiz/components/LexicalEditor';
import FileUploaderInput from '~js/components/FileUploaderInput';
import { ONE_MB } from '~js/constants/images';
import React from 'react';

const QuestionSuccessModalForm = ({ questionKey, questionValidationErrors, formDisabled = false }) => {
    const { control, setError, clearErrors } = useFormContext();

    return (
        <>
            {/* Modal subTitle */}
            <Controller
                control={control}
                name={`${questionKey}.modal.subTitle`}
                rules={{
                    validate: (value) => {
                        if (!value) {
                            return trans('formValidation.requiredField');
                        }
                        const textMap = parseJsonToTextMap(JSON.parse(value));
                        return textMap.length > 0 ? true : 'textmap assente';
                    },
                }}
                render={({ field }) => (
                    <LexicalTextEditor
                        disabled={formDisabled}
                        label={trans('quiz.memory.modalSubTitle')}
                        helperText={questionValidationErrors?.modal?.subTitle?.message}
                        error={!!questionValidationErrors?.modal?.subTitle}
                        inputProps={{ maxLength: 65 }}
                        {...field}
                    />
                )}
            />
            {/* Modal Description */}
            <Controller
                control={control}
                name={`${questionKey}.modal.description`}
                rules={{
                    validate: (value) => {
                        if (!value) {
                            return trans('formValidation.requiredField');
                        }
                        const textMap = parseJsonToTextMap(JSON.parse(value));
                        return textMap.length > 0 ? true : 'textmap assente';
                    },
                }}
                render={({ field }) => (
                    <LexicalTextEditor
                        disabled={formDisabled}
                        label={trans('quiz.memory.modalDescription')}
                        helperText={questionValidationErrors?.modal?.description?.message}
                        error={!!questionValidationErrors?.modal?.description}
                        inputProps={{ maxLength: 200 }}
                        {...field}
                    />
                )}
            />

            {/* Modal CTA Image */}
            <Controller
                control={control}
                name={`${questionKey}.modal.image`}
                defaultValue=""
                rules={{ required: trans('formValidation.requiredField') }}
                render={({ field: { onChange, value } }) => (
                    <FileUploaderInput
                        disabled={formDisabled}
                        value={value}
                        label={trans('quiz.memory.modalImage')}
                        tooltipText={trans('formValidation.aspectRatioError', {
                            args: {
                                aspectRatio: 1,
                            },
                        })}
                        onChange={onChange}
                        helperText={questionValidationErrors?.modal?.image?.message ?? trans('quiz.fileMaxSizeHint')}
                        validateFn={async (value) => {
                            if (!value || typeof value !== 'object') {
                                clearErrors(`${questionKey}.modal.image`);
                                return true;
                            }
                            return await new Promise((resolve) => {
                                let img = new Image();
                                img.src = window.URL.createObjectURL(value);
                                img.onload = () => {
                                    const aspectRatioImgUp = (img.width / img.height).toFixed(1);
                                    const aspectRatioRule = '1.0';
                                    if (aspectRatioImgUp !== aspectRatioRule) {
                                        setError(`${questionKey}.modal.image`, {
                                            type: 'custom',
                                            message: trans('formValidation.aspectRatioErrorWithUploaded', {
                                                args: {
                                                    aspectRatio: aspectRatioRule,
                                                    uploadedAspectRatio: aspectRatioImgUp,
                                                },
                                            }),
                                        });
                                        resolve(false);
                                    } else {
                                        if (value.size > ONE_MB) {
                                            setError(`${questionKey}.modal.image`, {
                                                type: 'custom',
                                                message: trans('formValidation.max1MbFileError', {
                                                    args: {
                                                        uploadedSize: (value.size / ONE_MB).toFixed(2),
                                                    },
                                                }),
                                            });
                                            resolve(false);
                                        } else {
                                            clearErrors(`${questionKey}.modal.image`);
                                            resolve(true);
                                        }
                                    }
                                };
                            });
                        }}
                        error={!!questionValidationErrors?.modal?.image}
                    />
                )}
            />
        </>
    );
};

export default QuestionSuccessModalForm;
