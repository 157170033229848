import React, { useContext, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, Typography } from '@mui/material';
import { trans } from 'matice';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Controller, useFormContext } from 'react-hook-form';
import SelectInput from '~js/components/SelectInput';
import CtaButton from '~js/components/CtaButton';
import { clientSurveyStatus } from '~js/constants/missionStatuses';
import { SURVEY_TYPOLOGIES } from '~js/constants/survey.js';
import ConfirmCancelModal from '~js/components/ConfirmCancelModal';
import dragIcon from '~/assets/images/icons/dragIcon.png';
import RankingResponseForm from './RankingResponseForm';
import ChoiceListForm from './ChoiceListForm';
import MultipleChoiceListForm from './MultipleChoiceListForm';
import TextAreaForm from './TextAreaForm';
import RatingForm from './RatingForm';
import DateForm from './DateForm';
import ImageChoiceListForm from './ImageChoiceListForm';
import QuestionInput from './QuestionInput';
import ModalQuestionPreview from './ModalQuestionPreview';
import BooleanForm from './BooleanForm';
import SurveyContext from '../context';

const typologyOptions = [
    { _id: 0, label: trans('survey.selectQuestionType'), value: '' },
    { _id: 1, label: trans('survey.choiceList'), value: 'choiceList' },
    {
        _id: 2,
        label: trans('survey.multipleChoiceList'),
        value: 'multipleChoiceList',
    },
    { _id: 3, label: trans('survey.textArea'), value: 'textArea' },
    { _id: 4, label: trans('survey.rating'), value: 'rating' },
    { _id: 5, label: trans('survey.multipleRating'), value: 'multipleRating' },
    { _id: 6, label: trans('survey.date'), value: 'date' },
    {
        _id: 7,
        label: trans('survey.imageChoiceList'),
        value: 'imageChoiceList',
    },
    {
        _id: 8,
        label: trans('survey.multipleImageChoiceList'),
        value: 'multipleImageChoiceList',
    },
    {
        _id: 9,
        label: trans('survey.yesNo'),
        value: 'boolean',
    },
    {
        _id: 9,
        label: trans('survey.rankingResponse'),
        value: 'rankingResponse',
    },
];

const QuestionItem = ({
    currentFocusedQuestion,
    questionIndex,
    deleteQuestion,
    deleteButtonDisabled,
    duplicateQuestion,
    onArrowUpPress,
    onArrowDownPress,
    setCurrentFocusedQuestion,
}) => {
    const { currentClientSurveyStatus, formDisabled, surveyTypology } = useContext(SurveyContext);
    const isMultiFlowSurvey = surveyTypology === SURVEY_TYPOLOGIES.multiFlow;

    const {
        control,
        formState: { errors },
        watch,
        trigger,
    } = useFormContext();

    const [showModalPreview, setShowModalPreview] = useState(false);
    const [openModalDeleteQuestion, setOpenModalDeleteQuestion] = useState(false);
    const [openModalDuplicateQuestion, setOpenModalDuplicateQuestion] = useState(false);
    const [openModalMoveQuestion, setOpenModalMoveQuestion] = useState(null);

    const collapseQuestion = () => {
        trigger(`questions.${questionIndex}`).then((isValid) => {
            if (isValid) {
                setCurrentFocusedQuestion(null);
            }
        });
    };

    const onMoveQuestionPress = (direction) => {
        const questions = watch('questions') || [];
        if (
            !questions ||
            (!!questions && questions?.length === 1) ||
            (direction === 'down' && questionIndex === questions?.length - 1) ||
            (direction === 'up' && questionIndex === 0)
        ) {
            return;
        }
        if (surveyTypology === SURVEY_TYPOLOGIES.multiFlow) {
            setOpenModalMoveQuestion(direction);
            return;
        }
        //down or up
        if (direction === 'down') {
            onArrowDownPress();
        }

        if (direction === 'up') {
            onArrowUpPress();
        }
    };

    const onDuplicatePress = async () => {
        const formIsValid = await trigger();
        if (!formIsValid) {
            return;
        }
        if (surveyTypology === SURVEY_TYPOLOGIES.multiFlow) {
            setOpenModalDuplicateQuestion(true);
            return;
        }
        duplicateQuestion();
    };

    const onConfirmDuplicateQuestion = () => {
        duplicateQuestion();
        if (!!openModalDuplicateQuestion) {
            setOpenModalDuplicateQuestion(false);
        }
    };

    const onConfirmMoveQuestion = () => {
        if (openModalMoveQuestion === 'down') {
            onArrowDownPress();
        }
        if (openModalMoveQuestion === 'up') {
            onArrowUpPress();
        }
        if (!!openModalMoveQuestion) {
            setOpenModalMoveQuestion(null);
        }
    };

    const toggleDeleteQuestionModal = () => setOpenModalDeleteQuestion((val) => !val);
    const toggleDuplicateQuestionModal = () => setOpenModalDuplicateQuestion((val) => !val);
    const closeMoveQuestionModal = () => setOpenModalMoveQuestion(null);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box
                width="100%"
                sx={{
                    border:
                        errors?.questions && errors.questions[questionIndex]
                            ? '1px solid #f44336'
                            : '1px solid #dfdfe4',
                    borderRadius: 1,
                    bgcolor: 'white',
                }}
            >
                {!isMultiFlowSurvey ? (
                    <Box display="flex" justifyContent="center">
                        <Box component="img" src={dragIcon} sx={{ maxWidth: '25px', objectFit: 'contain', mt: 1 }} />
                    </Box>
                ) : (
                    <Box pt={2} />
                )}

                <Box sx={{ px: 2 }}>
                    <Accordion
                        elevation={0}
                        disableGutters
                        expanded={currentFocusedQuestion === questionIndex || isMultiFlowSurvey}
                    >
                        <AccordionSummary
                            sx={{
                                '&.Mui-focusVisible': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                                <Typography width="100%" mb={1}>
                                    {trans('survey.question')} {questionIndex + 1}
                                </Typography>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Box sx={{ maxWidth: '500px' }}>
                                            <QuestionInput questionIndex={questionIndex} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Box sx={{ maxWidth: '500px' }}>
                                            <Controller
                                                control={control}
                                                name={`questions.${questionIndex}.questionType`}
                                                defaultValue={watch(`questions.${questionIndex}.questionType`) || ''}
                                                rules={{
                                                    required: trans('formValidation.requiredField'),
                                                }}
                                                render={({ field }) => (
                                                    <SelectInput
                                                        disabled={
                                                            formDisabled ||
                                                            currentClientSurveyStatus ===
                                                                clientSurveyStatus.prodActive ||
                                                            currentClientSurveyStatus ===
                                                                clientSurveyStatus.prodNotActive
                                                        }
                                                        label={trans('survey.typology')}
                                                        selectOptions={typologyOptions}
                                                        helperText={
                                                            errors?.questions &&
                                                            errors?.questions[questionIndex]?.questionType &&
                                                            errors?.questions[questionIndex]?.questionType?.message
                                                        }
                                                        error={
                                                            errors?.questions &&
                                                            !!errors?.questions[questionIndex]?.questionType
                                                        }
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setCurrentFocusedQuestion(questionIndex);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                {currentFocusedQuestion !== questionIndex &&
                                    surveyTypology === SURVEY_TYPOLOGIES.linear &&
                                    !!watch(`questions.${questionIndex}.questionType`) && (
                                        <CtaButton
                                            sx={{ zIndex: 99 }}
                                            onClick={() => setCurrentFocusedQuestion(questionIndex)}
                                        >
                                            {trans('survey.expand')} <KeyboardArrowDownIcon />
                                        </CtaButton>
                                    )}
                            </Box>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Box width="100%">
                                    {watch(`questions.${questionIndex}.questionType`) === 'choiceList' && (
                                        <ChoiceListForm questionIndex={questionIndex} />
                                    )}
                                    {watch(`questions.${questionIndex}.questionType`) === 'multipleChoiceList' && (
                                        <MultipleChoiceListForm questionIndex={questionIndex} />
                                    )}
                                    {watch(`questions.${questionIndex}.questionType`) === 'textArea' && (
                                        <TextAreaForm questionIndex={questionIndex} />
                                    )}
                                    {watch(`questions.${questionIndex}.questionType`) === 'rating' && (
                                        <RatingForm questionIndex={questionIndex} isMultiple={false} />
                                    )}
                                    {watch(`questions.${questionIndex}.questionType`) === 'multipleRating' && (
                                        <RatingForm questionIndex={questionIndex} isMultiple={true} />
                                    )}
                                    {watch(`questions.${questionIndex}.questionType`) === 'date' && (
                                        <DateForm questionIndex={questionIndex} />
                                    )}

                                    {watch(`questions.${questionIndex}.questionType`) === 'imageChoiceList' && (
                                        <ImageChoiceListForm questionIndex={questionIndex} isMultiple={false} />
                                    )}
                                    {watch(`questions.${questionIndex}.questionType`) === 'multipleImageChoiceList' && (
                                        <ImageChoiceListForm questionIndex={questionIndex} isMultiple={true} />
                                    )}
                                    {watch(`questions.${questionIndex}.questionType`) === 'boolean' && (
                                        <BooleanForm questionIndex={questionIndex} />
                                    )}
                                    {watch(`questions.${questionIndex}.questionType`) === 'rankingResponse' && (
                                        <RankingResponseForm questionIndex={questionIndex} />
                                    )}
                                </Box>
                                {currentFocusedQuestion === questionIndex &&
                                    surveyTypology === SURVEY_TYPOLOGIES.linear &&
                                    !!watch(`questions.${questionIndex}.questionType`) && (
                                        <CtaButton onClick={collapseQuestion} sx={{ zIndex: 99 }}>
                                            {trans('survey.collapse')} <KeyboardArrowUpIcon />
                                        </CtaButton>
                                    )}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>

                <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{
                        borderTop: '1px solid #dfdfe4',
                        px: 4,
                        py: 1,
                    }}
                >
                    <IconButton
                        onClick={() => onMoveQuestionPress('up')}
                        disabled={
                            formDisabled ||
                            currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                            currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                        }
                    >
                        <ArrowUpwardOutlinedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                        onClick={() => onMoveQuestionPress('down')}
                        disabled={
                            formDisabled ||
                            currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                            currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                        }
                    >
                        <ArrowDownwardOutlinedIcon fontSize="small" />
                    </IconButton>

                    <IconButton
                        onClick={onDuplicatePress}
                        disabled={
                            formDisabled ||
                            currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                            currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                        }
                    >
                        <ContentCopyOutlinedIcon fontSize="small" />
                    </IconButton>

                    <IconButton
                        onClick={toggleDeleteQuestionModal}
                        disabled={
                            deleteButtonDisabled ||
                            formDisabled ||
                            currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                            currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                        }
                    >
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                    <Box
                        sx={{
                            borderLeft: '1px solid #dfdfe4',
                            pl: 4,
                            ml: 4,
                        }}
                        display="flex"
                        alignItems="center"
                    >
                        <CtaButton
                            variant="contained"
                            sx={{ py: 0.8, zIndex: 99 }}
                            onClick={() => setShowModalPreview(true)}
                        >
                            <RemoveRedEyeOutlinedIcon fontSize="small" />
                            <Typography fontSize="14px" pl={1}>
                                {trans('survey.preview')}
                            </Typography>
                        </CtaButton>
                        {/*<Typography fontSize="14px">{trans('survey.required')}</Typography>
                        <Controller
                            control={control}
                            name={`questions.${questionIndex}.isRequired`}
                            defaultValue={true}
                            render={({ field }) => <Switch disabled defaultChecked {...field} />}
                        />*/}
                    </Box>
                </Box>
            </Box>
            <ModalQuestionPreview
                open={showModalPreview}
                handleClose={() => setShowModalPreview(false)}
                questionData={watch(`questions.${questionIndex}`)}
            />

            {/*Modal delete question*/}
            <ConfirmCancelModal
                iconType="delete"
                show={openModalDeleteQuestion}
                onClose={toggleDeleteQuestionModal}
                title={trans('survey.deleteQuestionTitle')}
                onConfirm={deleteQuestion}
            />

            {/*Modal confirm duplicate question in survey multiflow*/}
            <ConfirmCancelModal
                iconType="duplicate"
                show={openModalDuplicateQuestion}
                onClose={toggleDuplicateQuestionModal}
                title={trans('survey.duplicateQuestionTitle')}
                description={trans('survey.duplicateQuestionDescription')}
                onConfirm={onConfirmDuplicateQuestion}
            />

            {/*Modal confirm move question in survey multiflow*/}
            <ConfirmCancelModal
                iconType="move"
                show={!!openModalMoveQuestion}
                onClose={closeMoveQuestionModal}
                title={trans('survey.moveQuestionTitle')}
                description={trans('survey.moveQuestionDescription')}
                onConfirm={onConfirmMoveQuestion}
            />
        </Box>
    );
};

export default QuestionItem;
