import React from 'react';
import { Head, router } from '@inertiajs/react';
import { toast } from 'react-toastify';
import { trans } from 'matice';
import { Box, Typography } from '@mui/material';
import CtaButton from '~js/components/CtaButton';
import EmptySection from '~js/components/EmptySection';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import EventsTable from './components/EventsTable';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';

const breadcrumbs: BreadcrumbList = [
    {
        routeName: 'Events.index',
        routeLabel: 'Eventi',
    },
];

const EventsIndex = ({ events, can, canReadToolIds, ...other }) => {
    console.log('EventsIndex props', { events, can, ...other });

    const goToCreateEventPage = () => router.get(route('Events.create'));

    const deleteEvent = async (idEvent) => {
        const promise = new Promise((resolve, reject) =>
            router.delete(route('Events.destroy', idEvent), {
                onSuccess: () => {
                    toast.success(trans('events.eventDeleted'));
                    resolve();
                },
                onError: (e) => {
                    reject();
                    toast.error(!!e?.generic ? e.generic : trans('events.errorOnDeleteEvent'));
                },
            }),
        );

        await promise;
    };

    return (
        <>
            <Head title={trans('events.events')} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                {events.length > 0 && can.create && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <CtaButton
                            variant="contained"
                            sx={{
                                px: 5,
                            }}
                            onClick={goToCreateEventPage}
                        >
                            {trans('global.createNew')}
                        </CtaButton>
                    </Box>
                )}
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                    {trans('events.events')}
                </Typography>
                <EventsTable
                    events={events}
                    deleteEvent={deleteEvent}
                    canReadToolIds={canReadToolIds}
                    canDelete={can.deleteAny}
                    canUpdate={can.updateAny}
                />

                {events.length === 0 && (
                    <EmptySection
                        title={trans('events.noEventTitle')}
                        description={trans('events.noEventDescription')}
                        buttonComponent={
                            <CtaButton
                                variant="contained"
                                sx={{
                                    px: 5,
                                }}
                                onClick={goToCreateEventPage}
                            >
                                {trans('global.createNew')}
                            </CtaButton>
                        }
                    />
                )}
            </ToolContentPageLayout>
        </>
    );
};

export default EventsIndex;
