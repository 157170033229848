import { enGB, es, it } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';

const locales = { enGB, it, es, 'it-IT': it };
const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const useDateFormat = (formatStr = 'PP') => {
    const formatDate = (date) => {
        const timeZone = defaultTimezone;
        return formatInTimeZone(date, timeZone, formatStr, {
            locale: locales['it'], // or global.__localeId__
        });
    };
    return {
        formatDate,
    };
};

export default useDateFormat;
