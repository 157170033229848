import React from 'react';
import { Box } from '@mui/material';
import CtaButton from '~js/components/CtaButton';

const NewQuizActionButtons = ({
    rightButtonText,
    leftButtonText,
    onLeftButtonClick = null,
    onRightButtonClick = null,
    leftButtonType = 'button',
    rightButtonType = 'submit',
    isLoading = false,
    rightDisabled = false,
    rightTooltip = null,
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 5,
            }}
        >
            {onLeftButtonClick ? (
                <CtaButton type={leftButtonType} onClick={onLeftButtonClick} variant="outlined" disabled={isLoading}>
                    {leftButtonText}
                </CtaButton>
            ) : (
                <Box />
            )}

            <CtaButton
                variant="contained"
                type={rightButtonType}
                onClick={onRightButtonClick}
                loading={isLoading}
                disabled={rightDisabled}
                tooltip={rightTooltip}
            >
                {rightButtonText}
            </CtaButton>
        </Box>
    );
};

export default NewQuizActionButtons;
