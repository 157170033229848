import React, { useContext, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { trans } from 'matice';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import QuizContext from '~js/pages/backoffice/Tools/Quiz/context';
import NewQuizActionButtons from '~js/pages/backoffice/Tools/Quiz/components/NewQuizActionButtons';
import MemoryQuestionForm from '~js/pages/backoffice/Tools/Quiz/components/FormChallenges/MemoryQuestionForm';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';
import { TextInput } from '~js/components/input';

export const QUIZ_MEMORY_MIN_QUESTIONS = 3;
export const QUIZ_MEMORY_CARDS_LENGTH = QUIZ_MEMORY_MIN_QUESTIONS * 2;
export const DEFAULT_QUIZ_MEMORY_QUESTION_DATA = {
    image: null,
    modal: {
        subTitle: null,
        description: null,
        image: null,
    },
};

const generateCardCoupleDictionary = () => {
    const cardsCouplesRandomDictionary = {};
    // https://en.wikipedia.org/wiki/Schwartzian_transform
    const randomValues = [...Array(QUIZ_MEMORY_CARDS_LENGTH).keys()]
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
    for (let coupleIndex in [...Array(QUIZ_MEMORY_MIN_QUESTIONS).keys()]) {
        const coupleImageIndexes = {};
        randomValues
            .slice(coupleIndex * 2, coupleIndex * 2 + 2)
            .map((coupleIndex) => (coupleImageIndexes[coupleIndex] = null));
        cardsCouplesRandomDictionary[coupleIndex] = coupleImageIndexes;
    }
    return cardsCouplesRandomDictionary;
};

const MemoryQuestionsForm = ({ handleStepNext, handleStepPrevious, stepFormValue, isLoading = false }) => {
    const { isEditMode, formDisabled, currentClientQuiz } = useContext(QuizContext);

    const [currentFocusedQuestion, setCurrentFocusedQuestion] = useState(0);
    const [cardsCouplesDictionary, setCardsCouplesDictionary] = useState(generateCardCoupleDictionary());
    const cardsCouplesFlattenedArray = useMemo(() => {
        const array = Array(QUIZ_MEMORY_CARDS_LENGTH);
        for (let couple of Object.values(cardsCouplesDictionary)) {
            for (let [key, value] of Object.entries(couple)) {
                array[key] = value;
            }
        }

        return array;
    }, [cardsCouplesDictionary]);

    const handleCardCoupleUpdate = (imageUrl, questionIndex) => {
        setCardsCouplesDictionary((prevState) => {
            const couplesDict = { ...prevState };
            const coupleUpdated = {};
            for (const coupleUpdatedKey of Object.keys(prevState[questionIndex])) {
                coupleUpdated[coupleUpdatedKey] = imageUrl;
            }
            couplesDict[questionIndex] = coupleUpdated;
            return couplesDict;
        });
    };

    const formMethods = useForm({
        //shouldUnregister: true,
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: !!stepFormValue
            ? stepFormValue
            : {
                  title: '',
                  questions: Array(QUIZ_MEMORY_MIN_QUESTIONS).fill(DEFAULT_QUIZ_MEMORY_QUESTION_DATA),
              },
    });

    const {
        handleSubmit,
        control,
        trigger,
        formState: { errors },
        getValues,
    } = formMethods;

    console.debug({ ERRORS: errors, VALUES: getValues() });

    const { fields } = useFieldArray({
        name: 'questions',
        control,
        shouldUnregister: true,
    });
    const onSubmit = (data) => {
        console.debug(data);
        handleStepNext({ quizQuestions: { ...data } });
    };

    const nextButtonDisabled = fields.length < QUIZ_MEMORY_MIN_QUESTIONS;
    return (
        <FormProvider {...formMethods}>
            <Typography variant={'subtitle2'} fontWeight={'bold'} my={5}>
                {trans('quiz.memory.configure')}
            </Typography>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        {/* Title */}
                        <Controller
                            control={control}
                            name="title"
                            defaultValue=""
                            shouldUnregister
                            rules={{
                                required: trans('formValidation.requiredField'),
                                pattern: {
                                    value: REGEX_ONLY_PLAIN_CHAR,
                                    message: trans('formValidation.charNotAllowed'),
                                },
                                maxLength: {
                                    value: 64,
                                    message: trans('formValidation.fieldTooLong'),
                                },
                            }}
                            render={({ field }) => (
                                <TextInput
                                    disabled={formDisabled}
                                    label={trans('quiz.memory.questionsTitle')}
                                    placeholder={trans('quiz.memory.questionsTitle')}
                                    helperText={
                                        errors?.title?.message ||
                                        trans('formValidation.charLimit', {
                                            args: { chars: 64 },
                                        })
                                    }
                                    error={!!errors?.title}
                                    inputProps={{ maxLength: 64 }}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                {fields.map((question, index) => (
                    <MemoryQuestionForm
                        key={index.toString()}
                        questionIndex={index}
                        currentFocusedQuestion={currentFocusedQuestion}
                        setCurrentFocusedQuestion={setCurrentFocusedQuestion}
                        cardCouples={cardsCouplesDictionary[index]}
                        cardImages={cardsCouplesFlattenedArray}
                        setCardCoupleImage={(fileUrl) => handleCardCoupleUpdate(fileUrl, index)}
                    />
                ))}

                {!formDisabled && (
                    <NewQuizActionButtons
                        isLoading={isLoading}
                        onLeftButtonClick={handleStepPrevious}
                        rightButtonText={isEditMode ? trans('global.save') : trans('global.next')}
                        leftButtonText={trans('global.back')}
                        rightDisabled={nextButtonDisabled}
                        rightTooltip={
                            nextButtonDisabled
                                ? trans('quiz.minQuestionButtonDisabled', {
                                      args: { minQuestion: QUIZ_MEMORY_MIN_QUESTIONS },
                                  })
                                : null
                        }
                    />
                )}
            </Box>
        </FormProvider>
    );
};

export default MemoryQuestionsForm;
