import React from 'react';
import { Box, FormControl, FormHelperText, InputLabel, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const CustomTelephoneInput = styled(InputBase)(({ theme }) => ({
    width: '100%',
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 1,
        position: 'relative',
        backgroundColor: 'transparent',
        //border: '1px solid #8B8D97',
        fontSize: 16,
        width: '100%',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const TelephoneNumberInput = ({ label = null, type = 'text', error, helperText, endAdornment = null, ...other }) => {
    // workaround for InputProps Error
    delete other?.InputProps;
    return (
        <FormControl variant="standard" fullWidth error={error} sx={{ margin: '35px 0' }}>
            {label && (
                <InputLabel shrink htmlFor="input" sx={{ color: '#5E6366', pl: 1, mt: '-24px' }}>
                    {label}
                </InputLabel>
            )}
            <Box
                id="input"
                display="flex"
                alignItems="center"
                width="100%"
                sx={{
                    border: '1px solid #8B8D97',
                    borderRadius: '5px',
                    overflow: 'hidden',
                }}
            >
                <Box sx={{ padding: '10px 12px', bgcolor: '#EBEBEB', borderRadius: '5px' }}>
                    <Typography>+39</Typography>
                </Box>
                <CustomTelephoneInput
                    type={type}
                    sx={
                        helperText && {
                            boxShadow: (theme) => `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
                            borderColor: (theme) => theme.palette.error.main,
                        }
                    }
                    {...other}
                />
            </Box>

            {helperText && <FormHelperText sx={{ pl: 1 }}>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default TelephoneNumberInput;
